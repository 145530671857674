import React from 'react';
import PropTypes from 'prop-types';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconComp } from 'components/Common';
import { roleColors } from 'consts';
import { generateColorIndex } from '../../ModelViews/Documents/DocumentPdfViewer/utils/roles';

export const RoleHeadIcon = ({ role, solid = false, ...other }) => {
  let roleColorIndex = generateColorIndex(role);
  let roleColor = roleColors[roleColorIndex];
  return (
    <FontAwesomeIconComp
      icon={solid ? faUserSolid : faUser}
      color={roleColor}
      {...other}
    />
  );
};

RoleHeadIcon.propTypes = {
  role: PropTypes.object,
  roleIndex: PropTypes.number,
  solid: PropTypes.bool,
};
