import React from 'react';
import { Grid } from '@mui/material';
import NotesHomeCard from 'pages/HomePages/HomePage/components/NotesHomeCard';
import StaticPropertyFlowCards from 'pages/HomePages/HomePage/components/PropertyFlowCards/StaticPropertyFlowCards';
import PropertyUpdatesCard from 'pages/HomePages/HomePage/components/PropertyUpdatesCard';
import { StatCard } from 'pages/HomePages/HomePage/components/StatCard';
import TaskHomeCard from 'pages/HomePages/HomePage/components/TaskHomeCard';
import TimeframeHomeCard from 'pages/HomePages/HomePage/components/TimeframesHomeCard';
import { SalesPipelineCard } from './components/SalesPipelineCard';

export const HomePage = () => {
  return (
    <Grid container spacing={2}>
      {/* Left half: Sales Pipeline */}

      {/* Right half: container with 4 items (each 1/4 of the right half) */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <StaticPropertyFlowCards />
          <TaskHomeCard />
          <TimeframeHomeCard />
          <PropertyUpdatesCard />
          <NotesHomeCard />
          <StatCard
            sm={12}
            md={6}
            lg={4}
            iconModule="In Progress Documents"
            count={7}
            label="Pending Documents"
          />
          {/*<StatCard*/}
          {/*  sm={12}*/}
          {/*  md={6}*/}
          {/*  lg={4}*/}
          {/*  iconModule="Messages"*/}
          {/*  count={4}*/}
          {/*  label="Unread Messages"*/}
          {/*/>*/}
          <StatCard
            sm={12}
            md={6}
            lg={4}
            iconModule="Leads"
            count={21}
            label="Leads"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <SalesPipelineCard />
      </Grid>
    </Grid>
  );
};
