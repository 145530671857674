import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ModuleIcon, Table } from 'components/Common';
import { ToolbarTitle } from 'components/Common/Table/TableToolbar/styled';
import { useGetNotesQuery } from 'redux/rtk-query';
import { activityFiltersSelector } from 'redux/selectors';
import { Cells } from './config';

export const NotesActivityTable = ({ noTitle = false }) => {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState('');

  const activityFilters = useSelector(activityFiltersSelector);

  const listFilterValues =
    activityFilters.filterType === 'lists'
      ? activityFilters?.filterValues?.map((list) => list.value).join(',')
      : '';

  const { data, isFetching } = useGetNotesQuery({
    search: search,
    listIds: listFilterValues,
    page: page,
    limit: rowsPerPage,
    orderBy: orderBy,
    order: order.toUpperCase(),
  });
  const [loading, setLoading] = React.useState(true);
  const totalPages = data?.data?.meta.totalPages;
  const totalCount = data?.data?.meta.total;
  const rows = data?.data?.items;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Table
        title={
          noTitle ? null : (
            <ToolbarTitle>
              <ModuleIcon marginRight={10} module={'Notes'} />
              Notes
            </ToolbarTitle>
          )
        }
        handleSearch={setSearch}
        search={search}
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

NotesActivityTable.propTypes = {
  noTitle: PropTypes.bool,
};
