import React from 'react';
import PropTypes from 'prop-types';
import {
  faFolder,
  faFolderOpen,
  faFolderCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconComp } from 'components/Common';
import { transactionColors } from 'consts';

export const TransactionFolderIcon = ({ model, opened = false }) => {
  const iconColor = transactionColors[model.status];
  const closedFolder =
    model.status === 'Sold' || model.status === 'Closed Sale (12 mo.)'
      ? faFolderCheck
      : faFolder;
  return (
    <FontAwesomeIconComp
      icon={opened ? faFolderOpen : closedFolder}
      color={iconColor}
    />
  );
};

TransactionFolderIcon.propTypes = {
  model: PropTypes.object.isRequired,
  opened: PropTypes.bool,
};
