import React from 'react';
import PropTypes from 'prop-types';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Name, SettingsIcon, DetailsBox } from 'components/Styled';
import { conversion } from 'helpers';
import { ContactAvatar } from 'pages/ContactsPages/DetailsContactPage/components/ContactDetails/components/TopCard/components';
import { HeadshotAvatar } from 'pages/SettingsPages/DetailsSettingPage/components/HeadshotCard/components/HeadshotAvatar';
import { Username, FieldBox, FieldName, FieldValue, Divider } from './styled';

const ICON_COLOR = '#969696';

export const UserDetails = ({
  user: { uuid, name, username, contact, setting },
}) => {
  return (
    <DetailsBox>
      <Link to={`/admin/users/edit/${uuid}`}>
        <SettingsIcon icon={faGear} color={ICON_COLOR} />
      </Link>
      {/*<Avatar alt={name} src={avatar} />*/}
      {setting?.headshotFile ? (
        <HeadshotAvatar size={100} contactInfo={setting} />
      ) : (
        <ContactAvatar contactInfo={contact} />
      )}
      <Name>{name}</Name>
      <Username>{username}</Username>
      <FieldBox>
        <FieldName>Contact</FieldName>
        <FieldValue>{conversion.getName(contact)}</FieldValue>
      </FieldBox>
      <Divider />
      <FieldBox>
        <FieldName>Settings</FieldName>
        <FieldValue>{setting.name}</FieldValue>
      </FieldBox>
    </DetailsBox>
  );
};

UserDetails.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    contact: PropTypes.object,
    setting: PropTypes.object,
  }),
};
