import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem } from '@mui/material';
import { ColorChip } from 'components/Common';
import {
  DocumentItemName,
  DocumentItemBox,
} from 'components/ModelViews/Documents/DocumentsList/styled';
import { REPRESENTING_OPTIONS, VIEW_OFFER } from 'consts';
import { conversion, getColorByStatus } from 'helpers';
import { useOpenDrawer } from 'hooks';

export const OfferRow = ({ offer }) => {
  const label = offer.transactionType.replace(' Offer', '');
  // const isActive = offer.transactionType === TRANSACTION_TYPES.ACTIVE_OFFER;
  const openDrawer = useOpenDrawer();

  return (
    <ListItem>
      <DocumentItemBox
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          openDrawer(VIEW_OFFER(offer.id));
        }}
      >
        <DocumentItemName className={'document-name'}>
          <Typography variant="body1" component={'span'}>
            {offer.representing === REPRESENTING_OPTIONS.SELLER.value
              ? conversion.getCustomersString(offer.rolesStr, 'Buyer')
              : offer.name}
          </Typography>
          <Typography variant="body2" component={'span'}>
            {` - ${conversion.getCustomersString(offer.roles, 'Cooperating Agent')}`}
          </Typography>
        </DocumentItemName>
        <ColorChip
          size={'small'}
          label={label}
          variant={'contained'}
          chipColor={getColorByStatus(offer.transactionType)}
          sx={{ ml: 1 }}
        />
      </DocumentItemBox>
    </ListItem>
  );
};

OfferRow.propTypes = {
  offer: PropTypes.object.isRequired,
};
