import React, { useRef, useState, useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Grid, IconButton } from '@mui/material';
import { useGetRecentlyViewedTransactionsQuery } from 'redux/rtk-query';
import { PropertyFlowCard } from './PropertyFlowCard'; // Adjust import path as needed
import { PropertyFlowCardSkeleton } from './PropertyFlowCardSkeleton';

const StaticPropertyFlowCards = () => {
  const { data, isLoading } = useGetRecentlyViewedTransactionsQuery();
  const containerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const updateScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft + container.clientWidth < container.scrollWidth,
      );
    }
  };

  useEffect(() => {
    updateScrollButtons();
  }, [data]);

  const scrollByAmount = (amount) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: amount,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    updateScrollButtons();
  };

  const handleMouseLeaveContainer = () => {
    setHoveredIndex(0);
  };

  return (
    <Grid item xs={12} sx={{ position: 'relative', overflow: 'visible' }}>
      <Box
        sx={{ position: 'relative', overflow: 'visible' }}
        onMouseLeave={handleMouseLeaveContainer}
      >
        {canScrollLeft && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              paddingLeft: 1,
              top: 0,
              bottom: 0,
              width: 50,
              background: 'linear-gradient(to right, white, transparent)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() =>
                scrollByAmount(-containerRef.current.clientWidth / 2)
              }
              sx={{
                position: 'absolute',
                left: 8,
                zIndex: 2,
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'grey.200' },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        )}

        {canScrollRight && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              paddingRight: 1,
              top: 0,
              bottom: 0,
              width: 50,
              background: 'linear-gradient(to left, white, transparent)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              onClick={() =>
                scrollByAmount(containerRef.current.clientWidth / 2)
              }
              sx={{
                zIndex: 2,
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'grey.200' },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}

        <Box
          ref={containerRef}
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            scrollBehavior: 'smooth',
            width: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {isLoading
            ? Array.from(new Array(10)).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: '0 0 25%',
                    mr: -1,
                    ml: index === 0 ? 0 : -1,
                    overflow: 'visible',
                  }}
                >
                  <PropertyFlowCardSkeleton firstCard={index === 0} />
                </Box>
              ))
            : data?.data.map((row, index) => (
                <Box
                  key={row.id}
                  onMouseEnter={() => setHoveredIndex(index)}
                  sx={{
                    flex: '0 0 25%',
                    mr: -1,
                    ml: index === 0 ? 0 : -1,
                    overflow: 'visible',
                  }}
                >
                  <PropertyFlowCard
                    row={row?.transaction}
                    hovered={hoveredIndex === index}
                  />
                </Box>
              ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default StaticPropertyFlowCards;
