import React from 'react';
import { Grid } from '@mui/material';
import { STATUS_OPTIONS } from 'consts';
import { PipelineStatusCard } from 'pages/HomePages/HomePage/components/SalesPipelineCard/components/PipelineStatusCard';

export const SalesPipelineCard = () => {
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const today = new Date();
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setFullYear(today.getFullYear() - 1);

  return (
    // <AbeCard title={'Sales Pipeline'}>
    //   <Divider />
    //   <TableContainer>
    //     <PipelineTable>
    //       <SalesPipelineHeader />
    //       <SalesPipelineSection status={'Listed'} data={listedData} />
    //       <SalesPipelineSection status={'Pending'} data={pendingData} />
    //       <SalesPipelineSection status={'Draft'} data={draftData} />
    //       <SalesPipelineSection
    //         title={'Closing in Progress'}
    //         status={'Sold'}
    //         data={soldData}
    //       />
    //       <SalesPipelineSection
    //         hideTable
    //         title={'Closed Sale'}
    //         extraText={'(12 mo.)'}
    //         status={'Sold'}
    //         data={{}}
    //       />
    //     </PipelineTable>
    //   </TableContainer>
    // </AbeCard>
    <Grid container spacing={2}>
      <PipelineStatusCard
        label="Listed"
        params={{
          representing: 'Seller,Seller & Buyer,Buyer',
          status: STATUS_OPTIONS.LISTED.value,
        }}
      />
      <PipelineStatusCard
        label="Pending"
        params={{
          status: STATUS_OPTIONS.PENDING.value,
        }}
      />
      <PipelineStatusCard
        label="Draft"
        params={{
          representing: 'Seller,Seller & Buyer,Buyer,Referral',
          status: STATUS_OPTIONS.DRAFT.value,
          closedApproved: false,
        }}
      />
      <PipelineStatusCard
        label="Closing In Progress"
        params={{
          representing: 'Seller,Seller & Buyer,Buyer,Referral',
          status: STATUS_OPTIONS.SOLD.value,
          closedApproved: false,
        }}
      />
      <PipelineStatusCard
        startClosed
        label="Closed Sale (12 mo.)"
        params={{
          representing: 'Seller,Seller & Buyer,Buyer,Referral',
          status: STATUS_OPTIONS.SOLD.value,
          closedApproved: true,
          //expectedCloseDate is array of 2 dates in DD-MM-YYYY between today and 12 months ago
          expectedCloseDate: [formatDate(twelveMonthsAgo), formatDate(today)],
        }}
      />
      {/*<PipelineStatusCard*/}
      {/*  label="Closing In Progress"*/}
      {/*  statusValue={STATUS_OPTIONS.SOLD.value}*/}
      {/*  data={closingInProgressData}*/}
      {/*/>*/}
      {/*<PipelineStatusCard*/}
      {/*  startClosed*/}
      {/*  label="Sold"*/}
      {/*  statusValue={STATUS_OPTIONS.SOLD.value}*/}
      {/*  data={soldData}*/}
      {/*/>*/}
    </Grid>
  );
};

SalesPipelineCard.propTypes = {};
