import {
  faThumbsUp,
  faThumbsDown,
  faHeart,
  faComment,
} from '@fortawesome/pro-solid-svg-icons';

const FB_LIKE_COLOR = '#74B72E';
const FB_LOVE_COLOR = '#f75386';
const FB_DISLIKE_COLOR = '#48484a';
const FB_COMMENT_COLOR = '#0088CC';

export const REACTIONS = {
  LOVE: 'love',
  LIKE: 'like',
  DISLIKE: 'dislike',
  COMMENT: 'comment',
};

export const iconMap = {
  [REACTIONS.LOVE]: { icon: faHeart, color: FB_LOVE_COLOR },
  [REACTIONS.LIKE]: { icon: faThumbsUp, color: FB_LIKE_COLOR },
  [REACTIONS.DISLIKE]: { icon: faThumbsDown, color: FB_DISLIKE_COLOR },
};

export const commentIconMap = {
  [REACTIONS.COMMENT]: { icon: faComment, color: FB_COMMENT_COLOR },
};
