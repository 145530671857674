import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { useForm, useFormState } from 'react-final-form';
import { ReactSortable } from 'react-sortablejs';
import { Alert } from '@mui/lab';
import { Button, Box } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { getLastPathName } from 'helpers/report';
import Column from 'pages/ReportPages/CreateReportPage/ReportColumnsPage/Column';
import ReportFiltersTree from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersTree';

const ReportColumnsPage = ({ input }) => {
  const [showColumnDrawer, setShowColumnDrawer] = React.useState(false);
  const fieldName = input.name;
  const form = useForm();
  const { submitError } = useFormState();
  const columnsState = form.getState().values[fieldName] || [];
  const reportType = form.getState().values.reportType;

  const onHandleSelect = (selectedColumn) => {
    const id = Date.now().toString(); // Or use a UUID generator
    const label = getLastPathName(selectedColumn);
    const newColumn = {
      id,
      name: label,
      path: selectedColumn.label.split(' -> '),
      visualPath: selectedColumn.label.split(' -> '),
      order: columnsState.length,
      groupingFunction: selectedColumn.groupingFunction,
    };
    const updatedColumns = [...columnsState, newColumn];
    form.change(fieldName, updatedColumns);
    form.mutators.clearSubmitError();
    setShowColumnDrawer(false);
  };

  const handleColumnsSort = (newColumns) => {
    const updatedColumns = newColumns.map((column, index) => ({
      ...column,
      order: index,
    }));
    form.change(fieldName, updatedColumns);
  };

  const removeColumn = (index) => () => {
    const updatedColumns = columnsState.filter((_, i) => i !== index);
    const reorderedColumns = updatedColumns.map((column, index) => ({
      ...column,
      order: index,
    }));
    form.change(fieldName, reorderedColumns);
  };

  const treeType =
    fieldName === 'drilldowns'
      ? 'drilldown'
      : reportType === 'chart'
        ? 'groupedColumns'
        : 'columns';

  return (
    <>
      <div>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <ReactSortable
          list={columnsState}
          setList={handleColumnsSort}
          animation={200}
        >
          {columnsState.map((column, index) => (
            <Box key={column.id} sx={{ px: 3, pb: 2 }}>
              <Column
                name={`${fieldName}[${index}]`}
                removeColumn={removeColumn(index)}
              />
            </Box>
          ))}
        </ReactSortable>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowColumnDrawer(true)}
            sx={{ ml: 2 }}
          >
            Add Column
          </Button>
        </Box>
      </div>
      <SideDrawer
        width="400px"
        isOpen={showColumnDrawer}
        closeDrawer={() => setShowColumnDrawer(false)}
        closeOnClickAway={() => setShowColumnDrawer(false)}
      >
        <HeadingSection>
          <Header variant="h4">Select Column</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowColumnDrawer(false)}
          />
        </HeadingSection>
        <ReportFiltersTree onSelect={onHandleSelect} type={treeType} />
      </SideDrawer>
    </>
  );
};

export default ReportColumnsPage;

ReportColumnsPage.propTypes = {
  input: PropTypes.object,
};
