import React from 'react';
import PropTypes from 'prop-types';
import { faFire, faSnowflake } from '@fortawesome/pro-regular-svg-icons';
import { useParams } from 'react-router-dom';
import { Rating } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { colors } from 'consts';
import { useUpdateContactMutation } from 'redux/rtk-query';

const hotIcons = {
  1: {
    icon: <FontAwesomeIconComp icon={faFire} />,
    label: 'Cold',
  },
  2: {
    icon: <FontAwesomeIconComp icon={faFire} />,
    label: 'Warm',
  },
  3: {
    icon: <FontAwesomeIconComp icon={faFire} />,
    label: 'Hot',
  },
  4: {
    icon: <FontAwesomeIconComp icon={faFire} />,
    label: 'Hott!',
  },
  5: {
    icon: <FontAwesomeIconComp icon={faFire} />,
    label: 'Smokkkin!!',
  },
};

function IconContainerHot(props) {
  const { value, ...other } = props;
  return <span {...other}>{hotIcons[value].icon}</span>;
}

IconContainerHot.propTypes = {
  value: PropTypes.number.isRequired,
};

export const CustomerRating = ({ customerScore }) => {
  let { contactDetailsId } = useParams();

  const [updateContact] = useUpdateContactMutation();

  const handleRatingClick = async (value) => {
    await updateContact({
      id: parseInt(contactDetailsId),
      customerScore: value,
    });
  };

  return (
    <span>
      <Rating
        onChange={() => {
          handleRatingClick(0);
        }}
        sx={{ fontSize: 16, '& label > .MuiRating-icon': { paddingRight: 1 } }}
        name="customized-color"
        defaultValue={0}
        value={customerScore === 0 ? 1 : 0}
        precision={1}
        max={1}
        icon={
          <FontAwesomeIconComp icon={faSnowflake} color={colors.LIGHT_BLUE_1} />
        }
        emptyIcon={<FontAwesomeIconComp icon={faSnowflake} />}
      />
      <Rating
        onChange={(event, newValue) => {
          handleRatingClick(newValue);
        }}
        IconContainerComponent={IconContainerHot}
        getLabelText={(value) => hotIcons[value].label}
        sx={{
          fontSize: 16,
          marginRight: -1,
          marginLeft: 1,
          '& label > .MuiRating-icon': { paddingRight: 1 },
        }}
        name="customized-color"
        defaultValue={0}
        value={customerScore}
        precision={1}
        max={5}
      />
    </span>
  );
};

CustomerRating.propTypes = {
  customerScore: PropTypes.string,
};
