import React from 'react';
import { Checkbox } from '@mui/material';
import { conversion } from 'helpers/conversion';

export const pathArrayToPathLabel = (pathArray) => {
  return (
    pathArray &&
    pathArray.length > 0 &&
    pathArray.map((item) => conversion.camelCaseToWords(item)).join(' > ')
  );
};
export const getLastPathName = (obj, showGrouping = false) => {
  const visualPathValue = obj.visualPath || obj.path || [];
  const lastItem = visualPathValue[visualPathValue.length - 1];
  const groupingFunctionValue = obj.groupingFunction || '';
  // {conversion.camelCaseToWords(lastItem)}
  // {groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''}};
  return `${conversion.camelCaseToWords(lastItem)}${
    showGrouping && groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''
  }`;
};

export const getNestedValue = (obj, column) => {
  const pathArray = column.visualPath || column.path;
  if (!Array.isArray(pathArray) || pathArray.length === 0) {
    return '';
  }

  let value = '';

  if (column.groupingFunction) {
    // Construct the key using the grouping function and the last element of the path
    const key = `${column.groupingFunction}_${pathArray[pathArray.length - 1]}`;
    value = obj[key];
  } else {
    // Traverse the object using the pathArray
    value = pathArray.reduce(
      (acc, key) => (acc && acc[key] !== null ? acc[key] : ''),
      obj,
    );
  }

  return formatValue(value, column.type);
};

export const getGroupingUrlParams = (obj, column) => {
  const groupingValue = getNestedValue(obj, column);
  const pathArray = column.visualPath || column.path;
  const path = pathArray.join('.');
  return { [path]: groupingValue };
};

export const getGroupingNestedValue = (modelType, obj, column) => {
  let pathArray = column.visualPath || column.path;
  if (!Array.isArray(pathArray) || pathArray.length === 0) {
    return '';
  }
  pathArray = pathArray.filter((item) => item !== modelType);
  let value = pathArray.reduce(
    (acc, key) => (acc && acc[key] !== null ? acc[key] : ''),
    obj,
  );

  return formatValue(value, column.type);
};

const formatValue = (value, formatType) => {
  switch (formatType) {
    case 'currency':
      return conversion.formatCurrency(value);
    case 'percentage':
      return conversion.formatPercentage(value);
    case 'date':
      return conversion.formatDate(value, 'M/d/yy');
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'checkbox':
      return value ? (
        <Checkbox sx={{ p: 0 }} size="small" checked disabled />
      ) : (
        <Checkbox sx={{ p: 0 }} size="small" disabled />
      );
    default:
      return typeof value === 'object' ? JSON.stringify(value) : value;
  }
};
