import queryString from 'query-string';
import { endpoints } from 'consts';

export const groupsUrlBuilder = {
  getFunctionalActions: function () {
    return `${endpoints.groups}/modules`;
  },
  getGroups: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.groups}?${urlParams}`;
  },
  getGroup: function (groupId) {
    return `${endpoints.groups}/${groupId}`;
  },
  getGroupUsers: function ({ id, ...params }) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.groups}/${id}/users?${urlParams}`;
  },
  createGroup: function () {
    return endpoints.groups;
  },
  updateGroup: function () {
    return endpoints.groups;
  },
};
