import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import { Box, Grid, ButtonGroup, Button, Typography } from '@mui/material';
import ColumnsLayoutButton from 'pages/ReportPages/ReportPage/ColumnsLayoutButton';
import { useDashboard } from 'pages/ReportPages/ReportPage/context/DashboardContext';
import Column from './Column';

const Row = ({ row, dashboard }) => {
  const { updateDashboard, setActiveDashboard, isEditMode, setIsEditMode } =
    useDashboard();
  const [isHovered, setIsHovered] = useState(false);

  // Update columns count
  const handleSetColumns = (columnCount) => {
    if (columnCount < 1 || columnCount > 3) return;
    let updatedColumns = [...row.columns];

    if (updatedColumns.length > columnCount) {
      // Remove extra columns
      updatedColumns = updatedColumns.slice(0, columnCount);
    } else {
      // Add new columns
      while (updatedColumns.length < columnCount) {
        updatedColumns.push({
          id: Date.now() + updatedColumns.length,
          report: null,
        });
      }
    }

    const updatedRows = dashboard.rows.map((r) =>
      r.id === row.id ? { ...r, columns: updatedColumns } : r,
    );

    updateDashboard(dashboard.id, { ...dashboard, rows: updatedRows });
    setActiveDashboard({ ...dashboard, rows: updatedRows });
  };

  // Delete the entire row
  const handleDeleteRow = () => {
    const updatedRows = dashboard.rows.filter((r) => r.id !== row.id);
    updateDashboard(dashboard.id, { ...dashboard, rows: updatedRows });
    setActiveDashboard({ ...dashboard, rows: updatedRows });
  };

  // Handler for re-ordering columns
  const handleReorderColumns = (newColumns) => {
    const updatedRows = dashboard.rows.map((r) =>
      r.id === row.id ? { ...r, columns: newColumns } : r,
    );
    updateDashboard(dashboard.id, { ...dashboard, rows: updatedRows });
    setActiveDashboard({ ...dashboard, rows: updatedRows });
  };

  useEffect(() => {
    if (row.columns.length === 0) {
      setIsEditMode(true);
    }
  }, [row?.columns]);

  return (
    <Grid
      container
      sx={{ position: 'relative', width: '100%' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* ✅ Row Layout Using MUI Grid */}
      <Grid
        container
        columnSpacing={0}
        alignItems="stretch"
        mb={2}
        sx={{
          outline:
            row.columns.length === 0 || (isHovered && isEditMode)
              ? '1px solid purple'
              : 'none',
          outlineOffset: 4,
          borderRadius: 2,
        }}
      >
        {/* ✅ Pass `handleSetColumns` to Columns Button */}
        {isEditMode && isHovered && (
          <ColumnsLayoutButton
            handleDeleteRow={handleDeleteRow}
            handleSetColumns={handleSetColumns}
          />
        )}
        <ReactSortable
          list={row.columns}
          setList={handleReorderColumns}
          animation={200}
          delayOnTouchStart
          delay={2}
          swapThreshold={0.5}
          disabled={!isEditMode}
          tag="div"
          handle={'.move-column'}
          style={{ display: 'flex', width: '100%' }}
        >
          {row.columns.length ? (
            row.columns.map((col, index) => (
              <Grid
                item
                xs={12 / row.columns.length}
                key={col.id}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  pr: index !== row.columns.length - 1 ? 2 : 0,
                }}
              >
                <Column col={col} row={row} dashboard={dashboard} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sx={{ display: 'flex', flexGrow: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100px',
                  borderRadius: '8px',
                }}
              >
                <Box
                  justifyItems={'center'}
                  alignItems={'center'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography mb={1} variant="h4">
                    Select # of Columns
                  </Typography>
                  <ButtonGroup>
                    <Button onClick={() => handleSetColumns(1)}>1</Button>
                    <Button onClick={() => handleSetColumns(2)}>2</Button>
                    <Button onClick={() => handleSetColumns(3)}>3</Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Grid>
          )}
        </ReactSortable>
      </Grid>
    </Grid>
  );
};

export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
};
