import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import { currentDocumentSelectorCM as currentDocumentSelector } from 'redux/selectors';
import {
  FileMenuGroup,
  ViewMenuGroup,
  ItemGroupMenu,
  AddItemsMenuGroup,
} from './menuItems';

export const PrimaryMenu = () => {
  const currentDocument = useSelector(currentDocumentSelector);
  return (
    <>
      <FileMenuGroup />
      <Divider />
      <ViewMenuGroup />
      <Divider />
      {currentDocument.cmBlockType !== 'Template' && <AddItemsMenuGroup />}
      {currentDocument.cmBlockType === 'Template' && <ItemGroupMenu />}
    </>
  );
};
