import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from 'redux/rtk-query';
import UserForm, { defaultValues } from '../UserForm';

export const CreateUserPage = () => {
  const [createUser, { isSuccess, isLoading }] = useCreateUserMutation();
  const navigate = useNavigate();

  const onSubmit = async ({
    contact,
    setting,
    roles,
    groups,
    companyStructureUnits,
    language,
    locale,
    timezone,
    currency,
    ...values
  }) => {
    const user = await createUser({
      ...values,
      contactId: contact ? contact.value : null,
      settingId: setting ? setting.value : null,
      roleIds: roles.map(({ value }) => value),
      groupIds: groups.map(({ value }) => value),
      companyStructureUnitsIds: companyStructureUnits.map(({ value }) => value),
      languageId: language ? language.value : null,
      localeId: locale ? locale.value : null,
      timezoneId: timezone ? timezone.value : null,
      currencyId: currency ? currency.value : null,
    });
    return user;
  };

  const afterSave = ({ data }) => {
    navigate(`/admin/users/${data?.data?.uuid}/`);
  };

  return (
    <UserForm
      initialValues={defaultValues}
      handleSubmit={onSubmit}
      title={'Create User'}
      isLoading={isLoading}
      isSuccess={isSuccess}
      afterSave={afterSave}
    />
  );
};
