// PositionedMediaImage.js
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PositionedImage from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields/components/PositionedImage';
import { useItemLabelValue } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useItemLabelValue';
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import { itemGroupsSelectorCM } from 'redux/selectors';

const PositionedMediaImage = ({ currentItem }) => {
  const labelValue = useItemLabelValue(currentItem);
  const itemGroups = useSelector(itemGroupsSelectorCM);

  // Extract the fileId (or uuid) from labelValue
  const fileUuid = useMemo(() => {
    if (labelValue && typeof labelValue === 'object') {
      return labelValue.uuid || labelValue.fileId || labelValue.mediaId || null;
    }
    return null;
  }, [labelValue]);

  // Fetch the image URL using the fileUuid
  const { data: fileData } = useGetMediaUrl({
    uuid: fileUuid,
  }); // Replace 'identifierValue' with actual value if needed

  const activeStyle =
    itemGroups?.[currentItem.page]?.[currentItem.modularType]?.[
      currentItem.templateId
    ]?.activeStyle;
  const croppingData = labelValue?.croppingData?.[activeStyle] || {};
  const imageUrl = fileData?.data?.url || ''; // Adjust based on the structure of fileData
  if (!imageUrl) return null; // Optionally, render a placeholder or nothing if no URL is available

  return (
    <PositionedImage
      src={imageUrl}
      alt={currentItem.alt || 'Media Image'}
      positionX={croppingData?.positionX}
      positionY={croppingData?.positionY}
      backgroundW={croppingData?.backgroundW}
      backgroundH={croppingData?.backgroundH}
      containerWidth={parseFloat(currentItem.w)}
      containerHeight={parseFloat(currentItem.h)}
      borderRadius={parseFloat(currentItem.style?.borderRadius || 0)}
      itemId={currentItem.id}
    />
  );
};

PositionedMediaImage.propTypes = {
  currentItem: PropTypes.object.isRequired,
  // Include any additional props that PositionedImage expects
};

export default PositionedMediaImage;
