import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import hexRgb from 'hex-rgb';
import { NumericFormat } from 'react-number-format';
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
} from '@mui/material';
import { ListPagination } from 'components/ListPagination';
import { DocumentProgress } from 'components/ModelViews';
import { PageLink } from 'components/Styled';
import { transactionColors, DETAILS_TRANSACTION } from 'consts';
import { useItemTotal } from 'contexts/ItemTotalProvider';
import { conversion } from 'helpers';
import { useTableData } from 'hooks';
import { useGetSalesPipelineQuery } from 'redux/rtk-query';

export const SalesPipelineSection = ({ params }) => {
  // Fetch Table Data (Ensure useTableData is optimized internally)
  const { setItemTotal } = useItemTotal();
  const status = params.status;
  const {
    page,
    setPage,
    rowsPerPage,
    totalPages,
    totalCount,
    rows,
    otherData,
  } = useTableData(useGetSalesPipelineQuery, [], 'updatedAt', 'asc', 5, params);

  // Memoized Background Color (Returns a String Instead of a Function)
  const bgColor = useMemo(() => {
    const color = transactionColors[status] || '#cccccc'; // Default to grey
    const { red, green, blue } = hexRgb(color, { format: 'object' });
    return `rgba(${red},${green},${blue},1)`;
  }, [status]);

  // Memoized Page Change Handler to Prevent Unnecessary Re-renders
  const handleChangePage = useCallback((pageNo) => setPage(pageNo), [setPage]);

  // Dynamic Cell Render Functions
  const getSecondCell = (row) => (
    <TableCell
      align={status === 'Property Mgmt' ? 'left' : 'right'}
      width="150px"
    >
      {status === 'Property Mgmt' ? (
        row.pmStatus
      ) : (
        <DocumentProgress documentProgress={row.documentProgress} />
      )}
    </TableCell>
  );

  const getThirdCell = (row) => (
    <TableCell align="right">
      {status === 'Property Mgmt' ? (
        row.period
      ) : status.includes('Lease') ? (
        row.status
      ) : (
        <Typography mr={1} variant="body1">
          {row.netCommission ? (
            <NumericFormat
              decimalScale={0}
              fixedDecimalScale
              value={row.netCommission}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          ) : (
            conversion.getName(row.owner)
          )}
        </Typography>
      )}
    </TableCell>
  );

  const getFourthCell = (row) =>
    status === 'Property Mgmt' && (
      <TableCell align="right">
        <Typography mr={1} variant="body1">
          {row.netCommission ? (
            <NumericFormat
              decimalScale={0}
              fixedDecimalScale
              value={row.netCommission}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          ) : (
            conversion.getName(row.owner)
          )}
        </Typography>
      </TableCell>
    );

  useEffect(() => {
    if (otherData?.netCommissionTotal) {
      setItemTotal(otherData.netCommissionTotal);
    }
  }, [otherData]);
  return (
    <Table>
      <TableBody
        sx={{
          borderBottom: '1px solid lightgrey',
          '& .MuiTableCell-root': { borderBottom: 'none', p: 1 },
        }}
      >
        {rows.map((row) => (
          <TableRow
            key={row.id || `${row.name}-${row.representing}`} // Ensure Unique Key
            sx={{ borderLeft: `4px solid ${bgColor}` }}
            className="data-rows"
          >
            <TableCell width="auto">
              <Typography variant="body1" noWrap fontWeight={500}>
                <PageLink to={`/${DETAILS_TRANSACTION(row.id)}`}>
                  <div> {conversion.getName(row.name)} </div>
                </PageLink>
              </Typography>
            </TableCell>
            <TableCell width="auto">
              <Typography variant="body1" noWrap fontWeight={500}>
                {row.representing}
              </Typography>
            </TableCell>
            {getSecondCell(row)}
            {getThirdCell(row)}
            {getFourthCell(row)}
          </TableRow>
        ))}
        {totalPages > 1 && (
          <TableRow>
            <TableCell
              colSpan={5}
              align="center"
              sx={{ p: 1, borderLeft: `4px solid ${bgColor}` }}
            >
              <ListPagination
                totalCount={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage} // Memoized Function
                rowsPerPageOptions={[5]}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

SalesPipelineSection.propTypes = {
  params: PropTypes.object.isRequired,
};
