import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { reportUrlBuilder } from './urlBuilder/reports';

export const reportsAPI = createApi({
  reducerPath: 'reportsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: [
    'Reports',
    'Filters',
    'Columns',
    'Groupings',
    'Sortings',
    'Charts',
  ],
  endpoints: (build) => ({
    getReportModules: build.query({
      query: () => ({
        url: reportUrlBuilder.getReportModules(),
        method: 'GET',
      }),
    }),
    createReport: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createReport(),
        method: 'POST',
        data: {
          data: data,
        },
      }),
      invalidatesTags: ['Reports'],
    }),
    deleteReport: build.mutation({
      query: (id) => ({
        url: reportUrlBuilder.deleteReport(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Reports'],
    }),
    getReportDetails: build.mutation({
      query: ({ options = {}, ...params }) => ({
        url: reportUrlBuilder.getReportDetails(params),
        method: 'POST',
        data: {
          ...options,
        },
      }),
      providesTags: ['Reports', 'Groupings', 'Sortings', 'Columns', 'Filters'],
    }),
    getReportSummationDetails: build.mutation({
      query: ({ options = {}, ...params }) => ({
        url: reportUrlBuilder.getReportSummationDetails(params),
        method: 'POST',
        data: {
          ...options,
        },
      }),
      providesTags: ['Reports', 'Groupings', 'Sortings', 'Columns', 'Filters'],
    }),
    reportGroupingDetails: build.mutation({
      query: ({ options = {}, ...data }) => ({
        url: reportUrlBuilder.getReportGroupingDetails(data),
        method: 'POST',
        data: {
          ...options,
        },
      }),
      providesTags: ['Reports', 'Groupings', 'Sortings', 'Columns', 'Filters'],
    }),
    createFilters: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createFilters(data),
        method: 'POST',
        data: {
          data: data,
        },
      }),
      invalidatesTags: ['Filters'],
    }),
    createColumns: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createColumns(data),
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Columns'],
    }),
    updateColumn: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.updateColumn(data),
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: ['Columns'],
    }),
    deleteColumn: build.mutation({
      query: (id) => ({
        url: reportUrlBuilder.deleteColumn(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Columns'],
    }),
    getColumns: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getColumns(id),
        method: 'GET',
      }),
      providesTags: ['Columns'],
    }),
    getGroupings: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getGroupings(id),
        method: 'GET',
      }),
      providesTags: ['Groupings'],
    }),
    createGroupings: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createGroupings(data),
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Groupings'],
    }),
    updateGrouping: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.updateGrouping(data),
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: ['Groupings'],
    }),
    deleteGrouping: build.mutation({
      query: (id) => ({
        url: reportUrlBuilder.deleteGrouping(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Groupings'],
    }),
    updateSorting: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.updateSorting(data),
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: ['Sortings'],
    }),
    deleteSorting: build.mutation({
      query: (id) => ({
        url: reportUrlBuilder.deleteSorting(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Sortings'],
    }),
    getSortings: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getSortings(id),
        method: 'GET',
      }),
      providesTags: ['Sortings'],
    }),
    createSortings: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createSortings(data),
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Sortings'],
    }),
    getFilters: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getFilters(id),
        method: 'GET',
      }),
      providesTags: ['Filters'],
    }),
    updateReport: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.updateReport(data),
        method: 'PUT',
        data: {
          data: data,
        },
      }),
      invalidatesTags: [
        'Reports',
        'Groupings',
        'Sortings',
        'Columns',
        'Filters',
        'Charts',
      ],
    }),
    getReport: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getReport(id),
        method: 'GET',
      }),
      providesTags: ['Reports'],
    }),
    getReportsList: build.query({
      query: (args) => ({
        url: reportUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Reports'],
    }),
    createChart: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createChart(data),
        method: 'POST',
        data: {
          data: data,
        },
      }),
      invalidatesTags: ['Charts'],
    }),
    updateChart: build.mutation({
      query: (data) => ({
        url: reportUrlBuilder.createChart(data),
        method: 'PUT',
        data: {
          data: data,
        },
      }),
      invalidatesTags: ['Charts'],
    }),
    getChartList: build.query({
      query: (id) => ({
        url: reportUrlBuilder.getCharts(id),
        method: 'GET',
      }),
      providesTags: ['Charts'],
    }),
    getChart: build.query({
      query: (data) => ({
        url: reportUrlBuilder.getChart(data),
        method: 'GET',
      }),
      providesTags: ['Charts'],
    }),
    deleteChart: build.mutation({
      query: (id) => ({
        url: reportUrlBuilder.deleteChart(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Charts'],
    }),
    getChartData: build.query({
      query: ({ options = {}, ...params }) => ({
        url: reportUrlBuilder.getChartData(params),
        method: 'POST',
        data: {
          ...options,
        },
      }),
      providesTags: ['Charts'],
    }),
    getReportCategories: build.query({
      query: (params) => ({
        url: reportUrlBuilder.getReportCategories(params),
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetReportModulesQuery,
  useGetReportDetailsMutation,
  useGetReportSummationDetailsMutation,
  useReportGroupingDetailsMutation,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useCreateFiltersMutation,
  useCreateColumnsMutation,
  useUpdateColumnMutation,
  useGetFiltersQuery,
  useGetColumnsQuery,
  useGetReportQuery,
  useGetReportsListQuery,
  useGetGroupingsQuery,
  useUpdateGroupingMutation,
  useCreateGroupingsMutation,
  useGetSortingsQuery,
  useCreateSortingsMutation,
  useUpdateSortingMutation,
  useDeleteSortingMutation,
  useCreateChartMutation,
  useUpdateChartMutation,
  useGetChartListQuery,
  useGetChartDataQuery,
  useGetChartQuery,
  useDeleteChartMutation,
  useDeleteColumnMutation,
  useDeleteGroupingMutation,
  useGetReportCategoriesQuery,
} = reportsAPI;
