import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ItemTotalContext = createContext({
  itemTotal: 0, // Default value
  setItemTotal: () => {}, // Default value
});

export const useItemTotal = () => useContext(ItemTotalContext);

export const ItemTotalProvider = ({ children }) => {
  const [itemTotal, setItemTotal] = useState(null);

  const handleSetItemTotal = (total) => {
    setItemTotal(total);
  };

  return (
    <ItemTotalContext.Provider
      value={{ itemTotal, setItemTotal: handleSetItemTotal }}
    >
      {children}
    </ItemTotalContext.Provider>
  );
};

ItemTotalProvider.propTypes = {
  children: PropTypes.node,
};
