import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  googleUserInfo: {},
  isAuthenticated: false,
  tokens: {
    access: '',
    refresh: '',
  },
};

export const userLoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUserLogin: (state, action) => {
      state.isAuthenticated = Boolean(action.payload.token);
      state.tokens.access = action.payload.token;
      state.tokens.refresh = action.payload.refresh;
    },
    setUserLogout: (state) => {
      state.isAuthenticated = false;
      state.tokens.access = '';
      state.tokens.refresh = '';
      state.googleUserInfo = {};
    },
    setGoogleUserInfo: (state, action) => {
      state.googleUserInfo = action.payload.Av;
    },
  },
});

export const { setUserLogin, setUserLogout, setGoogleUserInfo } =
  userLoginSlice.actions;
export const userLoginSliceReducer = userLoginSlice.reducer;
