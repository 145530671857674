import React from 'react';
import PropTypes from 'prop-types';
import { faContactCard, faPen } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { AgentName, DateCell, DateTimeCell } from 'components/CellComponents';
import { PageLink } from 'components/Styled';
import {
  columnTypes,
  DETAILS_TRANSACTION,
  STATUS_OPTIONS,
  UPDATE_TRANSACTION,
} from 'consts';
import { conversion } from 'helpers';
import { TransactionStatusChip } from '../../styled';

const TransactionName = ({ value }) => {
  return value ? (
    <PageLink to={`/${DETAILS_TRANSACTION(value.id)}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

TransactionName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

// function that take a var 'value' that has key of roles. return the first two roles contacts names that CONTAIN Buyer or Seller or Landlord or Tenant
const TransactionCustomers = ({ value }) => {
  const customersString = conversion.getCustomersString(value);
  return <div style={{ maxWidth: 200 }}>{customersString}</div>;
};

TransactionCustomers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const TransactionStatus = ({ value }) => {
  return <TransactionStatusChip light>{value}</TransactionStatusChip>;
};

TransactionStatus.propTypes = {
  value: PropTypes.string,
};

export const Cells = [
  {
    id: 'name',
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    static: true,
    isVisible: true,
    Wrapper: TransactionName,
  },
  {
    id: 'status',
    value: 'status',
    label: 'Status',
    filterable: true,
    isVisible: true,
    filterType: columnTypes.DROP_DOWN,
    filterOptions: [
      STATUS_OPTIONS.DRAFT,
      STATUS_OPTIONS.LISTED,
      STATUS_OPTIONS.PENDING,
      STATUS_OPTIONS.SOLD,
      STATUS_OPTIONS.LEASED,
      STATUS_OPTIONS.PROPERTY_MGMT,
    ],
    Wrapper: TransactionStatus,
  },
  {
    id: 'roles',
    value: 'roles',
    label: 'Customers',
    disableSort: true,
    isVisible: true,
    Wrapper: TransactionCustomers,
  },
  {
    id: 'modelAccess',
    value: 'modelAccess',
    label: 'Agent',
    isVisible: true,
    disableSort: true,
    Wrapper: AgentName,
  },
  {
    id: 'closeDate',
    value: 'closeDate',
    label: 'Close Image',
    isVisible: true,
    Wrapper: DateCell,
  },
  {
    id: 'createdAt',
    value: 'createdAt',
    label: 'Created',
    disableSort: false,
    Wrapper: DateTimeCell,
  },
  {
    id: 'updatedAt',
    value: 'updatedAt',
    label: 'Updated',
    disableSort: false,
    Wrapper: DateTimeCell,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Edit',
      icon: faPen,
      onClick: (item) => {
        navigate(UPDATE_TRANSACTION(item.id));
      },
    },
    {
      name: 'Details',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`/${DETAILS_TRANSACTION(item.id)}`);
      },
    },
  ];
};
