import React, { useCallback } from 'react';
import { faSearchPlus, faSearchMinus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Slider, Grid, useTheme } from '@mui/material';
import { CollapsableMenuGroup } from 'components/Common';
import { scaleSelectorCM } from 'redux/selectors';
import { setScaleCM } from 'redux/slices';

const ZoomMenuItem = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // Get the current zoom level from Redux store
  const zoomLevel = useSelector(scaleSelectorCM);

  // Handler functions
  const handleZoomOutClick = useCallback(() => {
    const newZoom = Math.max(0.01, zoomLevel - 0.1);
    dispatch(setScaleCM(newZoom));
  }, [dispatch, zoomLevel]);

  const handleZoomInClick = useCallback(() => {
    const newZoom = Math.min(2, zoomLevel + 0.1);
    dispatch(setScaleCM(newZoom));
  }, [dispatch, zoomLevel]);

  const handleZoomChange = useCallback(
    (event, value) => {
      dispatch(setScaleCM(value / 100));
    },
    [dispatch],
  );

  return (
    <CollapsableMenuGroup
      hideChevron
      label="Zoom"
      icon={<FontAwesomeIcon icon={faSearchPlus} fontSize={18} />}
    >
      <div style={{ padding: '12px' }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton size="small" onClick={handleZoomOutClick}>
              <FontAwesomeIcon
                icon={faSearchMinus}
                style={{
                  fontWeight: 500,
                  fontSize: '15px',
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Slider
              value={Math.ceil(zoomLevel * 100)}
              defaultValue={100}
              step={1}
              marks
              track="inverted"
              min={1}
              max={200}
              onChange={handleZoomChange}
              valueLabelDisplay="auto"
            />
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={handleZoomInClick}>
              <FontAwesomeIcon
                icon={faSearchPlus}
                style={{
                  fontWeight: 500,
                  fontSize: '15px',
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </CollapsableMenuGroup>
  );
};

export default ZoomMenuItem;
