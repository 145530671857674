import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  Box,
  IconButton,
} from '@mui/material';
import { useTableData } from 'hooks';
import {
  useGetReportDetailsQuery,
  useGetColumnsQuery,
  useGetGroupingsQuery,
} from 'redux/rtk-query';
import ReportTableHead from './ReportTableHead';
import ReportTableRow from './ReportTableRow';

const ReportTable = ({ reportId }) => {
  const {
    rows,
    setPage,
    totalCount,
    loading,
    order,
    orderBy,
    filterValues,
    search,
  } = useTableData(
    useGetReportDetailsQuery,
    [],
    'createdAt',
    'asc',
    10,
    { reportId },
    {},
  );

  const { data: columnsData } = useGetColumnsQuery(reportId, {
    skip: !reportId,
  });
  const { data: groupingsData } = useGetGroupingsQuery(reportId, {
    skip: !reportId,
  });

  const hasGroupings = groupingsData?.meta?.total > 0;

  const columns = columnsData?.data || [];
  const groupingPaths =
    groupingsData?.data.map((group) => JSON.stringify(group.visualPath)) || [];

  const tableColumns = columns
    .filter(
      (column) =>
        column.groupingFunction ||
        groupingPaths.includes(JSON.stringify(column.visualPath)),
    )
    .sort((a, b) => a.order - b.order);

  const drillDownColumns = columns
    .filter(
      (column) =>
        !column.groupingFunction &&
        !groupingPaths.includes(JSON.stringify(column.visualPath)),
    )
    .sort((a, b) => a.order - b.order);

  const [displayedRows, setDisplayedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  // Keep track of previous filters, sorting, and search to detect changes
  const [prevFilters, setPrevFilters] = useState({
    filterValues,
    order,
    orderBy,
    search,
  });

  // Accumulate rows when page changes, reset when filters or sorting change
  useEffect(() => {
    const filtersChanged =
      JSON.stringify(filterValues) !==
        JSON.stringify(prevFilters.filterValues) ||
      order !== prevFilters.order ||
      orderBy !== prevFilters.orderBy ||
      search !== prevFilters.search;

    if (filtersChanged) {
      // Reset displayedRows when filters, sorting, or search change
      setDisplayedRows(rows);
      setPrevFilters({ filterValues, order, orderBy, search });
    } else {
      // Accumulate rows when page increases
      setDisplayedRows((prevRows) => {
        const newRows = [...prevRows, ...rows];
        // Remove duplicates based on unique 'id'
        const uniqueRows = Array.from(
          new Map(newRows.map((item) => [item.id, item])).values(),
        );
        return uniqueRows;
      });
    }
  }, [rows, filterValues, order, orderBy, search]);

  // Toggle the expansion of rows in case of grouped data
  const toggleRowExpansion = (index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Handler for "See More" - increments the page to fetch next set of rows
  const handleSeeMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        mb: 4,
      }}
    >
      <Table
        aria-label="dynamic report table"
        sx={{
          '& .MuiTableCell-root': {
            py: 1,
            px: 2,
            border: 'none',
          },
          '& .group-details-row > .MuiTableCell-root': {
            mx: 2,
            px: 0,
          },
          '& .MuiTableCell-paddingCheckbox + .MuiTableCell-root': {
            pl: 0,
          },
        }}
      >
        <ReportTableHead
          mainHeader
          columns={hasGroupings ? tableColumns : drillDownColumns}
          hasGroupings={hasGroupings}
        />
        <TableBody>
          {displayedRows.map((row, index) => (
            <ReportTableRow
              key={row.id || index}
              row={row}
              tableColumns={hasGroupings ? tableColumns : drillDownColumns}
              drillDownColumns={hasGroupings ? drillDownColumns : []}
              isExpanded={expandedRows[index]}
              onToggleExpand={() => toggleRowExpansion(index)}
              reportId={reportId}
            />
          ))}
        </TableBody>
      </Table>
      {!loading && displayedRows.length < totalCount && (
        <Box textAlign="center">
          <IconButton variant="contained" onClick={handleSeeMore}>
            <FontAwesomeIcon icon={faChevronDown} />
          </IconButton>
        </Box>
      )}
    </TableContainer>
  );
};

ReportTable.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default ReportTable;
