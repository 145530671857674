import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import { MenuButton } from 'components/Common';

const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const sizes = {
  Square: '1:1',
  Letter: '8.5:11',
  'Letter Landscape': '11:8.5',
  Story: '9:16',
  'Cover Photo': '42:20',
};

export const SizesDropDown = ({ selectedSizes, setSizes }) => {
  return (
    <Box textAlign={'right'}>
      <MenuButton sx={{ padding: 0 }} size={'x-small'} title={'All Sizes'}>
        <StyledBox>
          {Object.entries(sizes).map(([key, value]) => (
            <Button
              key={key}
              onClick={() => {
                if (selectedSizes.includes(value)) {
                  setSizes(selectedSizes.filter((size) => size !== value));
                } else {
                  setSizes([...selectedSizes, value]);
                }
              }}
              variant={'text'}
              color={selectedSizes.includes(value) ? 'primary' : 'inherit'}
            >
              {key}
            </Button>
          ))}
        </StyledBox>
      </MenuButton>
    </Box>
  );
};

SizesDropDown.propTypes = {
  selectedSizes: PropTypes.array,
  setSizes: PropTypes.func,
};
