import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { DetailsLayout } from 'components/Layouts';
import { useAccessDeniedHandler } from 'hooks';
import { useGetTransactionQuery } from 'redux/rtk-query';
import { TransactionDetails } from './components';
import { TransactionDashboard } from './components/TransactionDashboard';
import { TransactionOptionsButton } from './components/TransactionOptionsButton';

export const DetailsTransactionPage = () => {
  let { transactionDetailsId } = useParams();

  const [transactionInfo, setTransactionInfo] = useState(null);
  const {
    data: transactionData,
    isSuccess,
    isFetching,
    error,
  } = useGetTransactionQuery(
    { id: transactionDetailsId },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (isSuccess) {
      document.title = `${transactionData.data?.name} - Transactions`;
      setTransactionInfo(transactionData.data);
    }
  }, [isFetching, isSuccess, transactionDetailsId]);

  const accessDeniedMessage = useAccessDeniedHandler(error);
  if (accessDeniedMessage) {
    return <div>{accessDeniedMessage}</div>;
  }

  return (
    <>
      {transactionInfo && (
        <>
          <DetailsLayout
            equalWidth
            header={
              <>
                <span>{transactionInfo.name}</span>
                <TransactionOptionsButton />
              </>
            }
            leftContent={
              <TransactionDetails transactionInfo={transactionInfo} />
            }
            rightContent={<TransactionDashboard id={transactionDetailsId} />}
          />
          <Outlet />
        </>
      )}
    </>
  );
};
