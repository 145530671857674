export const getPixelSize = (currentDocument, minSize = 600) => {
  // Get size array from splitting currentDocument.aspectRatio, e.g., "1:1"
  if (!currentDocument || !currentDocument.aspectRatio)
    return [minSize, minSize];
  const sizeArray = currentDocument.aspectRatio.split(':');

  if (parseFloat(sizeArray[0]) > parseFloat(sizeArray[1])) {
    let newSize = parseFloat(sizeArray[0]) * 20;
    while (newSize < minSize) {
      newSize *= 2;
    }
    const ratio = newSize / parseFloat(sizeArray[0]);
    let height = parseFloat(sizeArray[1]) * ratio;
    return [parseInt(newSize), parseInt(height)];
  } else {
    let newSize = parseFloat(sizeArray[1]) * 20;
    while (newSize < minSize) {
      newSize *= 2;
    }
    const ratio = newSize / parseFloat(sizeArray[1]);
    let width = parseFloat(sizeArray[0]) * ratio;
    return [parseInt(width), parseInt(newSize)];
  }
};
