import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faEdit, faHeart } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Divider, IconButton, Box } from '@mui/material';
import { SelectTransactionButton } from 'components/ModelViews/Documents/ClickMarketingViewer/SelectTransactionButton';
import { EDIT_DOCUMENT, colors, CM_ITEM_TYPES } from 'consts';
import { useOpenDrawer } from 'hooks';
import {
  useCreateDocumentMutation,
  useGetTransactionQuery,
} from 'redux/rtk-query';
import { currentDocumentSelectorCM } from 'redux/selectors';

const PreviewToolbar = ({ onClose }) => {
  const currentDocument = useSelector(currentDocumentSelectorCM);
  const [createDocument] = useCreateDocumentMutation();
  const openDrawer = useOpenDrawer();
  const routeParams = useParams();

  // We manage transaction in local state.
  // If a Transaction was provided via URL params you could pre–set this state.
  const [transaction, setTransaction] = useState(null);

  // Optionally, if transaction is already set, fetch its latest data.
  const { data: transactionData } = useGetTransactionQuery(
    { id: transaction?.id },
    { skip: !transaction?.id },
  );

  console.log('transaction', transaction);

  const toggleHeart = () => {
    // Your heart toggle logic
  };

  const createClickMarketing = async () => {
    await createDocument({
      name: currentDocument.name,
      status: 'Draft',
      fileType: 'Click Marketing',
      documentTemplateId: currentDocument.id,
      transactionId: transaction?.id ? parseInt(transaction.id) : null,
    }).then((res) => {
      if (res.data) {
        onClose();
        openDrawer(EDIT_DOCUMENT(res.data?.data?.id), routeParams['*']);
      }
    });
  };

  // An array of autofill field keys
  const propertyAutofils = [
    'fullAddress',
    'streetAddress',
    'cityStateZip',
    'beds',
    'baths',
    'sqft',
    'listPrice',
  ];

  const hasPropertyField = useMemo(
    () =>
      currentDocument?.htmlTemplate?.items?.some(
        (item) =>
          propertyAutofils.includes(item.autofill) ||
          item.type === CM_ITEM_TYPES.image.property,
      ),
    [currentDocument],
  );

  // showEditButton indicates that the Edit action should be available.
  // In this example, if there is no property field, or if there is one and a Transaction is selected.
  const showEditButton =
    !hasPropertyField || (hasPropertyField && transaction?.id);

  // Update local Transaction if we have new data from the query.
  useEffect(() => {
    if (transactionData?.data) {
      setTransaction(transactionData.data);
    }
  }, [transactionData]);

  useEffect(() => {
    if (transaction) {
      console.log('transaction', transaction);
    }
  }, [transaction]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={2}
        justifyContent="space-between"
      >
        <Typography variant="h4">{currentDocument.name}</Typography>
        <Box display="flex" gap={1} alignItems="center">
          {showEditButton ? (
            <IconButton
              sx={{ border: `1px ${colors.LIGHT_BLACK} solid` }}
              onClick={createClickMarketing}
            >
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </IconButton>
          ) : transaction ? (
            // When a Transaction is selected, display its address or name.
            <Typography variant="body1">{transaction.label}</Typography>
          ) : (
            // Otherwise, let the user select one.
            <SelectTransactionButton
              setTransaction={setTransaction}
              transaction={transaction}
            />
          )}
          <IconButton
            sx={{ border: `1px ${colors.LIGHT_BLACK} solid` }}
            onClick={toggleHeart}
          >
            <FontAwesomeIcon icon={faHeart} size="1x" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

PreviewToolbar.propTypes = {
  onClose: PropTypes.func,
};

export default PreviewToolbar;
