import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  faBell,
  faCircleXmark,
  faSignHanging,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Box,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  FontAwesomeIconComp,
  PopperButton,
  SideDrawer,
} from 'components/Common';
import AbeNavLottie from 'components/Layouts/components/TopNavbar/AbeNavLottie';
import { SwitchStateAutocomplete } from 'components/Layouts/components/TopNavbar/components/SwitchStateAutocomplete';
import { Navbar } from 'components/SearchInput/Navbar';
import { Header, HeadingSection } from 'components/Styled';
import {
  ADMIN_ROUTES,
  DETAILS_CONTACT,
  DETAILS_SETTING,
  HOME_ROUTE,
} from 'consts';
import { isAdmin } from 'helpers';
import AbeLogo from 'images/abe-logo.png';
import {
  useGetProfileQuery,
  useSwitchBackMutation,
  useGetStateListQuery,
  useSetStateMutation,
} from 'redux/rtk-query';
import { isAuthenticatedSelector } from 'redux/selectors';
import { setUserLogin, setUserLogout } from 'redux/slices';
import { gapiLibInit } from 'utils/authService';
import { invalidateAllTags } from 'utils/invalidateTags';
import { QuickCreateMenu } from './components/QuickCreateMenu';
import { SwitchUserAutocomplete } from './components/SwitchUserAutocomplete';
import {
  AppBar,
  NavbarWrapper,
  UserMenuButtonAndAvatar,
  UserMenuWrapper,
} from './styled';

const SmallBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    height: '16px',
    minWidth: '16px',
    padding: '0 4px',
    fontSize: '1rem',
    borderRadius: '8px',
  },
}));

export const TopNavbar = () => {
  const { data: { data } = {}, refetch } = useGetProfileQuery();
  const [showSwitchUserDrawer, setShowSwitchUserDrawer] = useState(false);
  const [showSwitchStateDrawer, setShowSwitchStateDrawer] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: stateList } = useGetStateListQuery();
  const [switchBack] = useSwitchBackMutation();
  const [switchState] = useSetStateMutation();
  const isAuthenticated = useSelector((state) =>
    isAuthenticatedSelector(state),
  );

  const { logout } = useAuth0();

  const handleSignOut = () => {
    dispatch(setUserLogout());
    invalidateAllTags(dispatch);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleSwitchUser = async () => {
    if (data?.isSwitchedUser) {
      const response = await switchBack();
      dispatch(setUserLogin(response.data.data));
      window.location.reload();
    } else {
      setShowSwitchUserDrawer(true);
    }
  };

  const handleSwitchState = () => {
    setShowSwitchStateDrawer(true);
  };

  const ProfileMenu = [
    {
      name: 'Admin',
      icon: false,
      divider: true,
      shouldRender: () => isAdmin(data?.permissions),
      onClick: () => {
        navigate(ADMIN_ROUTES.USERS.path);
      },
    },
    {
      name: 'My Contact',
      icon: false,
      shouldRender: () => !!data?.contact,
      onClick: () => {
        navigate(`/${DETAILS_CONTACT(data?.contact.id)}`);
      },
    },
    {
      name: 'My Settings',
      icon: false,
      divider: true,
      shouldRender: () => !!data?.setting,
      onClick: () => {
        navigate(`/${DETAILS_SETTING(data?.setting.id)}`);
      },
    },
    {
      name: data?.isSwitchedUser ? 'Switch Back' : 'Switch User',
      icon: false,
      menuSx: {
        backgroundColor: data?.isSwitchedUser ? '#ff9494' : null,
        '&:not(:hover) .MuiTypography-root': {
          color: data?.isSwitchedUser ? '#ffffff' : null,
        },
        '&:hover .MuiTypography-root': {
          color: data?.isSwitchedUser ? '#ff9494' : null,
        },
      },
      onClick: handleSwitchUser,
    },
    {
      name: 'Switch State',
      icon: false,
      divider: true,
      onClick: handleSwitchState,
    },
    {
      name: 'Feedback',
      icon: false,
      onClick: () => {},
    },
    {
      name: 'Log Out',
      icon: false,
      onClick: handleSignOut,
    },
  ];

  const handleHome = () => navigate(HOME_ROUTE);

  useEffect(() => {
    gapiLibInit();
  }, []);

  useEffect(() => {
    refetch();
  }, [isAuthenticated]);

  useEffect(() => {
    if (data && !data?.state && stateList?.data?.length === 1) {
      switchState({ stateId: stateList?.data?.[0].id });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [data, stateList]);

  return (
    <AppBar position="fixed">
      <NavbarWrapper sx={{ px: { xs: 1, sm: 0 } }} disableGutters>
        <Box display="flex" alignItems="center">
          <Box onClick={handleHome} sx={{ cursor: 'pointer' }}>
            {data === undefined ? (
              <AbeNavLottie
                style={{ width: 65, top: 6, position: 'relative' }}
                speed={3}
              />
            ) : (
              <Box
                component="img"
                src={AbeLogo}
                alt="Abe logo"
                sx={{
                  height: 'auto',
                  width: '65px',
                  padding: '0 11px',
                }}
              />
            )}
          </Box>
          <Navbar />
        </Box>
        <UserMenuWrapper>
          <QuickCreateMenu />
          <Box pr={2}>
            <IconButton>
              <SmallBadge badgeContent={79} color="error">
                <FontAwesomeIconComp
                  fontSize="2rem"
                  icon={faSignHanging}
                  color="grey"
                />
              </SmallBadge>
            </IconButton>
          </Box>
          <Box pr={2}>
            <IconButton>
              <SmallBadge badgeContent={4} color="error">
                <FontAwesomeIconComp
                  fontSize="2rem"
                  icon={faBell}
                  color="grey"
                />
              </SmallBadge>
            </IconButton>
          </Box>
          <Divider sx={{ m: 0.5 }} orientation="vertical" flexItem />
          <Box sx={{ flexGrow: 0 }}>
            <PopperButton
              size="x-small"
              menuItems={ProfileMenu}
              sx={{
                outline: data?.isSwitchedUser ? '4px solid #ff9494' : null,
              }}
            >
              <UserMenuButtonAndAvatar component="div">
                {data?.headshot?.url && (
                  <Avatar
                    alt="User Data"
                    src={data?.headshot?.url}
                    sx={{ height: '28px', width: '28px', mr: 1 }}
                  />
                )}
                <Typography>
                  {data?.firstName.trim().length
                    ? data?.firstName
                    : data?.lastName}
                </Typography>
                <FontAwesomeIconComp
                  icon={faChevronDown}
                  fontSize="1.4rem"
                  style={{ paddingLeft: '10px' }}
                  color="grey"
                />
              </UserMenuButtonAndAvatar>
            </PopperButton>
          </Box>
        </UserMenuWrapper>
      </NavbarWrapper>
      <SideDrawer width="40%" isOpen={showSwitchUserDrawer}>
        <HeadingSection>
          <Header variant="h4">Switch User</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowSwitchUserDrawer(false)}
          />
        </HeadingSection>
        <DialogContent>
          <SwitchUserAutocomplete
            onSuccess={() => setShowSwitchUserDrawer(false)}
          />
        </DialogContent>
      </SideDrawer>
      <SideDrawer width="40%" isOpen={showSwitchStateDrawer}>
        <HeadingSection>
          <Header variant="h4">Select State to View As</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowSwitchStateDrawer(false)}
          />
        </HeadingSection>
        <DialogContent>
          <SwitchStateAutocomplete
            onSuccess={() => setShowSwitchStateDrawer(false)}
          />
        </DialogContent>
      </SideDrawer>
    </AppBar>
  );
};
