import React from 'react';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModuleIcon, FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { colors } from 'consts';
import { useDrawer } from 'hooks';
import { TasksActivityTable } from 'pages/ActivityPages/ActivityPage/components';

const TimeframeHomeCard = () => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false);
  const iconFontSize = '2.5rem';
  const iconModule = 'Timeframes';
  const count = 12;
  const label = 'Timeframes';

  return (
    <>
      <Grid item lg={6} sm={12} xs={12}>
        <Card
          onClick={openDrawer}
          elevation={1}
          sx={{
            height: 130,
            cursor: 'pointer',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: 3,
            },
          }}
        >
          <Grid container py={2}>
            {/* Left side: Count and Label */}
            <Grid
              item
              xs={5}
              py={2}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h2" display="flex" alignItems="center">
                <ModuleIcon
                  marginRight={10}
                  fontSize={iconFontSize}
                  module={iconModule}
                />
                {count}
              </Typography>
              <Typography variant="h4" sx={{ mt: 1, whiteSpace: 'pre' }}>
                {label}
              </Typography>
            </Grid>

            {/* Right side: Aligned icon, number, text in columns */}
            <Grid
              item
              xs={7}
              container
              direction="column"
              justifyContent="center"
              sx={{
                borderLeft: '1px solid lightgrey',
                p: 2,
              }}
            >
              {/* Row 1: "1 Past Due" */}
              <Grid
                sx={{
                  maxWidth: '150px !important',
                  alignSelf: 'center',
                }}
                container
                item
                columns={12}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    fontSize=".8rem"
                    icon={faCircle}
                    color={colors.RED}
                  />
                </Grid>
                <Grid item xs={3} justifyItems={'center'}>
                  <Typography variant="h5">1</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h5">Past Due</Typography>
                </Grid>
              </Grid>

              {/* Row 2: "11 Upcoming" */}
              <Grid
                sx={{
                  maxWidth: '150px !important',
                  alignSelf: 'center',
                }}
                container
                item
                columns={12}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <FontAwesomeIcon
                    fontSize=".8rem"
                    icon={faCircle}
                    color={colors.ORANGE}
                  />
                </Grid>
                <Grid item xs={3} justifyItems={'center'}>
                  <Typography variant="h5">11</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h5">Upcoming</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <SideDrawer width={'60%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <HeadingSection>
          <Header variant="h4">Timeframes</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => closeDrawer()}
          />
        </HeadingSection>
        <Box px={2}>
          <TasksActivityTable timeframes />
        </Box>
      </SideDrawer>
    </>
  );
};

export default TimeframeHomeCard;
