import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { TableHead, TableRow, TableCell, Table } from '@mui/material';

const ReportTableHead = ({ columns, hasGroupings, mainHeader = false }) => {
  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [tableRect, setTableRect] = useState(null);
  const [offsetTop, setOffsetTop] = useState(64);
  const [columnWidths, setColumnWidths] = useState([]);

  // Calculate the offsetTop dynamically based on the application header bar
  useEffect(() => {
    const headerBar = document.querySelector('header.MuiAppBar-root');
    if (headerBar) {
      const rect = headerBar.getBoundingClientRect();
      if (mainHeader) {
        setOffsetTop(rect.bottom);
      } else {
        const mainHeader = document.querySelector('.main-header');
        if (mainHeader) {
          setOffsetTop(rect.bottom + mainHeader.clientHeight);
        }
      }
    }
  }, []);

  // Updated IntersectionObserver to use offsetPlusHeight
  useEffect(() => {
    if (!headerRef.current) return;

    const offsetPlusHeight = offsetTop + headerRef.current.clientHeight;

    const observerOptions = {
      root: null,
      rootMargin: `-${offsetPlusHeight}px 0px 0px 0px`,
      threshold: 0,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        console.log(entry, offsetTop);
        if (entry.boundingClientRect.top <= offsetTop) {
          // Header has scrolled off the top
          setIsSticky(true);
        } else {
          // Header is still visible
          setIsSticky(false);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    observer.observe(headerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [offsetTop]);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const scrollContainer = headerRef.current.closest(
          '.MuiTableContainer-root',
        );
        if (scrollContainer) {
          setScrollLeft(scrollContainer.scrollLeft);
        }
      }
    };

    const scrollContainer = headerRef.current?.closest(
      '.MuiTableContainer-root',
    );
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (headerRef.current) {
        const rect = headerRef.current.parentElement.getBoundingClientRect();
        setTableRect(rect);

        // Measure column widths
        const headerCells = headerRef.current.querySelectorAll('th');
        const widths = Array.from(headerCells).map(
          (cell) => cell.getBoundingClientRect().width,
        );
        setColumnWidths(widths);
      }
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [columns]);

  // Styles common to both headers
  const headerStyles = {
    backgroundColor: '#fafafa',
    '& .MuiTableCell-root': {
      fontWeight: 700,
      fontSize: '0.875rem',
      color: '#333333',
      borderBottom: '1px solid #E0E0E0',
      padding: '0.5rem 1rem',
      boxSizing: 'border-box',
    },
  };

  // Render the sticky header into the body using a portal
  const stickyHeader =
    isSticky && tableRect
      ? createPortal(
          <div
            className={mainHeader ? 'main-header' : 'sub-header'}
            style={{
              position: 'fixed',
              top: offsetTop,
              left: tableRect.left - scrollLeft,
              width: tableRect.width,
              zIndex: 999,
              backgroundColor: '#fafafa',
            }}
          >
            <Table
              aria-label="dynamic report table"
              sx={{
                '& .MuiTableCell-root': {
                  py: 1,
                  px: 2,
                  border: 'none',
                },
                '& .group-details-row > .MuiTableCell-root': {
                  mx: 2,
                  px: 0,
                },
                '& .MuiTableCell-paddingCheckbox + .MuiTableCell-root': {
                  pl: 0,
                },
              }}
            >
              <TableHead>
                <TableRow sx={headerStyles}>
                  {hasGroupings && (
                    <TableCell
                      padding="checkbox"
                      style={{
                        width: columnWidths[0],
                        boxSizing: 'border-box',
                      }}
                    />
                  )}
                  {columns.map((column, index) => (
                    <TableCell
                      key={column.id}
                      style={{
                        width: columnWidths[hasGroupings ? index + 1 : index],
                        boxSizing: 'border-box',
                      }}
                    >
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </div>,
          document.body,
        )
      : null;

  return (
    <>
      {stickyHeader}

      {/* Original header */}
      <TableHead ref={headerRef}>
        <TableRow sx={headerStyles}>
          {hasGroupings && <TableCell padding="checkbox" />}
          {columns.map((column) => (
            <TableCell key={column.id}>{column.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};

ReportTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  hasGroupings: PropTypes.bool.isRequired,
  mainHeader: PropTypes.bool,
};

export default ReportTableHead;
