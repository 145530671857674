import React from 'react';
import { Field, useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CheckboxField,
  MlsListing,
  Parcel,
  ParcelLookupAddress,
  Select,
  TextInput,
} from 'components/Fields';
import { FormCondition } from 'components/Forms';
import {
  PM_FILE_OPTIONS,
  REPRESENTING_OPTIONS,
  STATUS_OPTIONS,
  TRANSACTION_TYPES,
} from 'consts';
import { isAdmin } from 'helpers';
import { useTransactionOptions } from 'hooks';
import { useActiveOffer } from 'hooks/transaction/useActiveOffer';
import { StatusField, CloseDateField, RepresentingField } from './fields';

export const OverviewPanel = () => {
  const form = useForm();
  const isAdminUser = isAdmin();
  const { saleTypeOptions, pmFileOptions } = useTransactionOptions(form);
  const hasActiveOffer = useActiveOffer(form.getState().values.id);
  const isPrimary =
    form.getState().values.transactionType === TRANSACTION_TYPES.PRIMARY;
  const hasActiveOrIsPrimary = hasActiveOffer || isPrimary;

  const showStatus = isPrimary;

  const showCloseDate = () => {
    return (
      form.getState().values.status === STATUS_OPTIONS.LEASED.value ||
      form.getState().values.status === STATUS_OPTIONS.SOLD.value ||
      form.getState().values.status === STATUS_OPTIONS.REFERRAL.value ||
      form.getState().values.status ===
        STATUS_OPTIONS.EXPIRED_WITHDRAWN.value ||
      form.getState().values.status === STATUS_OPTIONS.EXPIRED.value ||
      form.getState().values.status === STATUS_OPTIONS.WITHDRAWN.value ||
      form.getState().values.status === STATUS_OPTIONS.CANCELLED.value
    );
  };

  const showAddress =
    (isPrimary &&
      form
        .getState()
        .values.representing?.includes(REPRESENTING_OPTIONS.SELLER.value)) ||
    (!isPrimary &&
      form
        .getState()
        .values.representing?.includes(REPRESENTING_OPTIONS.BUYER.value)) ||
    hasActiveOffer;

  return (
    <FormPanel label={'Overview'}>
      {showStatus && <StatusField />}
      {showCloseDate() && <CloseDateField />}
      <RepresentingField />
      {(!hasActiveOrIsPrimary || form.getState().values.representing) && (
        <>
          <Grid item xs={12}>
            <FormCondition
              when="status"
              operator="doesNotEqual"
              is={STATUS_OPTIONS.PROPERTY_MGMT.value}
            >
              <FormCondition
                when="representing"
                operator="contains"
                is={'Landlord'}
              >
                <Field
                  label="Management"
                  name={'pmFile'}
                  component={Select}
                  options={pmFileOptions}
                  tooltip={{
                    title: (
                      <>
                        <strong>Property Mgmt:</strong> The account is managed
                        on a monthly basis. <br />{' '}
                        <strong> Lease for Property Mgmt:</strong> The lease
                        account is related to a property under management.{' '}
                        <br /> <strong>Lease Only:</strong> The account is not
                        managed monthly by the company.
                      </>
                    ),
                  }}
                />
              </FormCondition>
            </FormCondition>
          </Grid>
          <Grid item xs={12}>
            <FormCondition
              when="pmFile"
              operator="equals"
              is={PM_FILE_OPTIONS.LEASE_FOR_PROPERTY_MGMT.value}
            >
              <Field
                label="Transaction"
                name={'account'}
                component={TextInput}
                tooltip={{
                  title:
                    'Select the address of the management account. This will link this lease to the management account.',
                }}
              />
            </FormCondition>
          </Grid>
          {showAddress && (
            <Grid item xs={12}>
              <Field
                label={'Address'}
                name={'address'}
                component={ParcelLookupAddress}
              />
            </Grid>
          )}
        </>
      )}
      <FormCondition when="addressConfirmed" operator="equals" is={true}>
        <Grid item xs={12}>
          <Field label="Parcel" name={'parcel'} component={Parcel} />
        </Grid>
        <FormCondition
          when="status"
          operator="doesNotEqual"
          is={STATUS_OPTIONS.PROPERTY_MGMT.value}
        >
          <FormCondition
            when="pmFile"
            operator="doesNotEqual"
            is={STATUS_OPTIONS.PROPERTY_MGMT.value}
          >
            <Grid item xs={12}>
              <Field
                label="MLS Listing"
                name={'mlsListing'}
                component={MlsListing}
              />
            </Grid>
          </FormCondition>
        </FormCondition>
        {isPrimary && (
          <Grid item xs={12}>
            <FormCondition
              when="representing"
              operator="oneOf"
              is={[
                REPRESENTING_OPTIONS.SELLER.value,
                REPRESENTING_OPTIONS.BUYER.value,
                REPRESENTING_OPTIONS.SELLER_AND_BUYER.value,
              ]}
            >
              <Field
                label="Sale Type"
                name={'saleType'}
                component={Select}
                multiple
                options={saleTypeOptions}
              />
            </FormCondition>
          </Grid>
        )}
        {isAdminUser && (
          <Grid item xs={12}>
            <Field
              name="closedApproved"
              label={'Closed Approved'}
              component={CheckboxField}
            />
          </Grid>
        )}
      </FormCondition>
    </FormPanel>
  );
};
