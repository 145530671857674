import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { TRANSACTION_TYPES, DETAILS_TRANSACTION } from 'consts';
import { useDrawer } from 'hooks';
import {
  useCreateTransactionMutation,
  useGetProfileQuery,
} from 'redux/rtk-query';
import { defaultValues } from '../TransactionForm/defaultValues';
import { processFormData } from '../TransactionForm/formData';
import { TransactionForm } from '../TransactionForm/TransactionForm';

export const CreateTransactionPage = ({ path }) => {
  const [initialValues, setInitialValues] = useState({});
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const [createTransaction] = useCreateTransactionMutation();
  const { data } = useGetProfileQuery();

  const navigate = useNavigate();

  useEffect(() => {
    setInitialValues({
      ...defaultValues,
      transactionType: TRANSACTION_TYPES.PRIMARY,
      agent: data?.data,
      team: [
        {
          teamMember: data?.data?.contact,
          percent: 100,
          order: 1,
        },
      ],
    });
  }, [0, data]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    return await createTransaction(formData);
  };

  const afterSave = (response) => {
    closeDrawer();
    navigate(`/${DETAILS_TRANSACTION(response?.data?.data?.id)}`);
  };

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <TransactionForm
        title={'Create Transaction'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

CreateTransactionPage.propTypes = {
  path: PropTypes.string,
};
