// src/contexts/ExtraParamOptionsContext.js
import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const ExtraParamOptionsContext = createContext({
  extraParamOptions: {},
  setExtraParamOptions: () => {},
});

export const ExtraParamOptionsProvider = ({ children, initialReportId }) => {
  const [extraParamOptions, setExtraParamOptions] = useState({
    reportId: initialReportId,
    options: {
      editableFilters: [],
    },
  });

  // Memoize the context value so it doesn't change unless extraParamOptions changes.
  const value = useMemo(
    () => ({ extraParamOptions, setExtraParamOptions }),
    [extraParamOptions],
  );

  return (
    <ExtraParamOptionsContext.Provider value={value}>
      {children}
    </ExtraParamOptionsContext.Provider>
  );
};

ExtraParamOptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialReportId: PropTypes.string.isRequired,
};
