import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Common';
import { Header, HeadingSection } from 'components/Styled';
import { getMlsParams } from 'helpers';
import { useGetMlsListingsQuery } from 'redux/rtk-query';
import { Cells } from './config';

const RowsPerPageOptions = [15, 25, 50];

export const MlsListingsTable = ({ onRowClick }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState('');
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const formValues = useForm().getState().values;

  const rows = useMemo(() => data?.data || [], [data]);
  const totalPages = useMemo(() => data?.meta?.totalPages, [data]);
  const MenuItems = useMemo(
    () => [
      {
        name: 'View',
        icon: faEye,
        onClick: ({ uuid }) => navigate(`/admin/users/${uuid}`),
      },
    ],
    [navigate],
  );

  const mlsParams = useMemo(() => {
    return getMlsParams(formValues);
  }, [formValues.parcel]);

  const {
    data: mlsData,
    isFetching,
    isSuccess,
  } = useGetMlsListingsQuery({
    limit: 10,
    page: 1,
    search,
    ...mlsParams,
  });

  useEffect(() => {
    if (!isFetching && isSuccess) {
      setData(mlsData);
    }
  }, [mlsData, isFetching, isSuccess]);

  return (
    <>
      <HeadingSection sx={{ mb: 1 }}>
        <Header variant="h4">MLS Listings</Header>
      </HeadingSection>
      <Table
        onRowClick={onRowClick}
        handleSearch={setSearch}
        search={search}
        isLoading={data === null}
        rows={rows}
        totalPages={totalPages}
        menuItems={MenuItems}
        cells={Cells}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={RowsPerPageOptions}
        setPage={setPage}
        setRowsPerPage={setLimit}
      />
    </>
  );
};

MlsListingsTable.propTypes = {
  onRowClick: PropTypes.func,
};
