import React from 'react';
import PropTypes from 'prop-types';
import { Status } from 'components/Common/Table/components';
import { PageLink } from 'components/Styled';

const UserName = ({ value }) => {
  return value ? (
    <PageLink to={`/admin/users/${value.uuid}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

UserName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'name',
    value: ['name', 'uuid'],
    disablePadding: true,
    label: 'Name',
    Wrapper: UserName,
  },
  {
    id: 'username',
    value: 'username',
    disablePadding: false,
    label: 'Username',
  },
  {
    id: 'status',
    value: 'status',
    disablePadding: false,
    label: 'Status',
    Wrapper: Status,
  },
];
