import {
  DETAILS_TRANSACTION,
  DETAILS_CONTACT,
  DETAILS_COMMISSION,
} from 'consts';

export const getHref = (lastOfPath, id) => {
  if (lastOfPath === 'COMMISSIONS_NAME') {
    return DETAILS_COMMISSION(id);
  } else if (lastOfPath === 'TRANSACTION_NAME') {
    return DETAILS_TRANSACTION(id);
  } else if (lastOfPath === 'CONTACT_NAME') {
    return DETAILS_CONTACT(id);
  }
};
