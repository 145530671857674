import React from 'react';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard, FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { ModelLink } from 'components/Common/ModelLink';
import { DETAILS_CONTACT, DETAILS_TRANSACTION, STATUS_OPTIONS } from 'consts';
import { conversion } from 'helpers';
import {
  useGetRelatedTransactionsQuery,
  useGetTransactionQuery,
} from 'redux/rtk-query';

const GridItem = ({ label, value }) => (
  <Grid container>
    <Grid item xs={4}>
      <Typography variant="body2">{label}:</Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography variant="body2">{value}</Typography>
    </Grid>
  </Grid>
);

GridItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const RelatedTransactionsGridItem = ({
  relatedTransaction,
  isPropertyMgmt = false,
}) => {
  const tenants = conversion.getCustomers(relatedTransaction?.roles, 'Tenant');

  const tenantsNode = tenants.map((tenant, index) => (
    <>
      <ModelLink
        key={index}
        href={`/${DETAILS_CONTACT(tenant.id)}`}
        label={conversion.getName(tenant)}
      />
      {index < tenants.length - 1 ? ', ' : ''}
    </>
  ));

  return (
    <Box width={'100%'} px={2} py={1}>
      <Grid item pb={0.5} xs={12}>
        <ModelLink
          href={`/${DETAILS_TRANSACTION(relatedTransaction.id)}`}
          label={relatedTransaction?.name}
        />
      </Grid>
      {isPropertyMgmt && (
        <>
          <GridItem key={'Tenants'} label="Tenants" value={tenantsNode} />
          <GridItem
            key={'Lease Begins'}
            label="Lease Begins"
            value={conversion.formatDate(relatedTransaction?.leaseBegins)}
          />
          <GridItem
            key={'Lease Ends'}
            label="Lease Ends"
            value={conversion.formatDate(relatedTransaction?.leaseEnds)}
          />
          <GridItem
            key={'Rent'}
            label="Rent"
            value={conversion.formatCurrency(relatedTransaction?.rentAmount)}
          />
        </>
      )}
    </Box>
  );
};
RelatedTransactionsGridItem.propTypes = {
  relatedTransaction: PropTypes.object,
  isPropertyMgmt: PropTypes.bool,
};

export const RelatedTransactionsCard = () => {
  const { transactionDetailsId } = useParams();
  const [itemLimit, setItemLimit] = React.useState(1);

  const { data: relatedTransactionData } = useGetRelatedTransactionsQuery(
    {
      id: transactionDetailsId,
      orderBy: 'leaseEnds',
      order: 'DESC',
      limit: itemLimit,
    },
    { skip: !transactionDetailsId },
  );

  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionDetailsId },
    { skip: !transactionDetailsId },
  );

  const transaction = transactionData?.data || {};
  const relatedTransactions = relatedTransactionData?.data?.items || [];
  const relatedTransactionCount =
    relatedTransactionData?.data?.meta?.total || 0;

  const title =
    transaction?.status === STATUS_OPTIONS.PROPERTY_MGMT.value
      ? 'Leases'
      : 'Mgmt Transactions';

  return (
    <AbeCard
      icon={
        <ModuleIcon
          module={'Transactions'}
          type={
            transaction?.status === STATUS_OPTIONS.PROPERTY_MGMT.value
              ? null
              : STATUS_OPTIONS.PROPERTY_MGMT.value
          }
        />
      }
      title={title}
    >
      <Divider />
      <Grid container>
        {relatedTransactions.map((relatedTransaction) => (
          <Box width={'100%'} key={relatedTransaction.uuid}>
            <RelatedTransactionsGridItem
              relatedTransaction={relatedTransaction}
              isPropertyMgmt={
                transaction?.status === STATUS_OPTIONS.PROPERTY_MGMT.value
              }
            />
            <Divider />
          </Box>
        ))}
        {relatedTransactionCount > itemLimit && (
          <Box width={'100%'} textAlign="center">
            <IconButton
              size={'x-small'}
              onClick={() => {
                setItemLimit(itemLimit + 1);
              }}
            >
              <FontAwesomeIconComp fontSize={14} icon={faChevronDown} />
            </IconButton>
          </Box>
        )}
      </Grid>
    </AbeCard>
  );
};
