import { styled } from '@mui/system';
import { PageContainer as Container } from 'components/Styled';

export const PageContainer = styled(Container)(({ theme }) => ({
  borderRadius: '24px',
  overflow: 'hidden',
  marginLeft: 65,
  width: 'calc(100% - 85px)',
  boxShadow: '0px 0px 10px 4px rgb(0 0 0 / 9%) inset',
  marginTop: 68,
  height: 'auto',
  position: 'relative',
  bottom: 20,
  padding: 16,
  minHeight: 'calc(100vh - 70px)',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    borderRadius: 0,
  },
}));
