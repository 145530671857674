import React from 'react';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { MarketingList } from '../MarketingList';

export const MarketingCard = (props) => {
  return (
    <AbeCard {...props} title={'Marketing'}>
      <Divider />
      <MarketingList />
    </AbeCard>
  );
};

MarketingCard.defaultProps = {};
