import React, { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { CreateListModal } from 'components/ModelViews/Lists';
import { SectionHeader } from 'components/Styled';
import { AllCustomersName, AllLeadsName, AllListsName } from 'consts';
import { useTableData } from 'hooks';
import { useGetContactsQuery, useGetListsQuery } from 'redux/rtk-query';
import { editingListSelector } from 'redux/selectors';
import { setEditingList } from 'redux/slices';
import { ListsAccordeonItem } from './components';
import { ListSectionHead, ListSectionWrapper } from './styled';

export const ListsSection = () => {
  const editingList = useSelector(editingListSelector);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: allContacts } = useGetContactsQuery({
    page: 1,
    limit: 1,
  });
  const { data: allCustomers } = useGetContactsQuery({
    page: 1,
    limit: 2,
    type: 'Customer',
  });
  const { data: allLeads } = useGetContactsQuery({
    page: 1,
    limit: 2,
    type: 'Lead',
  });

  const {
    loading: otherListsLoading,
    page: otherListsPage,
    rowsPerPage: otherListsRowsPerPage,
    setPage: setOtherListsPage,
    setRowsPerPage: setOtherListsRowsPerPage,
    totalPages: otherListsTotalPages,
    totalCount: otherListsTotalCount,
    rows: otherLists,
  } = useTableData(useGetListsQuery, [], 'updatedAt', 'asc', 5, {
    search: '',
    justFavorite: 0,
    skipFavorite: 1,
  });

  const {
    loading: favoritesListsLoading,
    page: favoritesListsPage,
    rowsPerPage: favoritesListsRowsPerPage,
    setPage: setFavoritesListsPage,
    setRowsPerPage: setFavoritesListsRowsPerPage,
    totalPages: favoritesListsTotalPages,
    totalCount: favoritesListsTotalCount,
    rows: favoriteLists,
  } = useTableData(useGetListsQuery, [], 'updatedAt', 'asc', 5, {
    search: '',
    justFavorite: 1,
    skipFavorite: 0,
  });

  const contactCount = allContacts?.data?.meta?.total;
  const customerCount = allCustomers?.data?.meta?.total;
  const leadCount = allLeads?.data?.meta?.total;

  const QuickLists = [
    { ...AllListsName, totalContactsCount: contactCount },
    { ...AllCustomersName, totalContactsCount: customerCount },
    { ...AllLeadsName, totalContactsCount: leadCount },
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(setEditingList(false));
  };

  useEffect(() => {
    if (editingList) {
      setIsModalOpen(true);
    }
  }, [editingList]);

  return (
    <>
      <CreateListModal closeHandler={handleModalClose} open={isModalOpen} />
      <ListSectionWrapper>
        <ListSectionHead>
          <SectionHeader variant="h5">LISTS</SectionHeader>
          <Button
            variant={'text'}
            size={'small'}
            onClick={() => setIsModalOpen(true)}
            endIcon={<FontAwesomeIconComp icon={faPlus} />}
          >
            New
          </Button>
        </ListSectionHead>
        <ListsAccordeonItem list={QuickLists} title="QUICK" />
        {!favoritesListsLoading && (
          <ListsAccordeonItem
            list={favoriteLists}
            title="FAVORITE"
            page={favoritesListsPage}
            rowsPerPage={favoritesListsRowsPerPage}
            setPage={setFavoritesListsPage}
            setRowsPerPage={setFavoritesListsRowsPerPage}
            totalPages={favoritesListsTotalPages}
            totalCount={favoritesListsTotalCount}
          />
        )}
        {!otherListsLoading && (
          <ListsAccordeonItem
            list={otherLists}
            title="OTHER"
            page={otherListsPage}
            rowsPerPage={otherListsRowsPerPage}
            setPage={setOtherListsPage}
            setRowsPerPage={setOtherListsRowsPerPage}
            totalPages={otherListsTotalPages}
            totalCount={otherListsTotalCount}
          />
        )}
      </ListSectionWrapper>
    </>
  );
};
