import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableWrapper } from 'components/Styled';
import { TaskSectionTable } from './components/TaskSectionTable';

export const TasksActivityTable = ({ timeframes = false }) => {
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = React.useState('');

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Grid container mt={1}>
      <Grid item xs={12}>
        <TableWrapper
          sx={{
            '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 },
          }}
        >
          <TaskSectionTable
            timeframes={timeframes}
            search={search}
            sectionType={'PAST_DUE'}
          />
          <TaskSectionTable
            timeframes={timeframes}
            search={search}
            sectionType={'DUE_TODAY'}
          />
          <TaskSectionTable
            timeframes={timeframes}
            search={search}
            sectionType={'UPCOMING'}
          />
          <TaskSectionTable
            timeframes={timeframes}
            search={search}
            sectionType={'NO_DUE_DATE'}
          />
          <TaskSectionTable
            startClosed
            timeframes={timeframes}
            search={search}
            sectionType={'COMPLETED'}
          />
        </TableWrapper>
      </Grid>
    </Grid>
  );
};

TasksActivityTable.propTypes = {
  timeframes: PropTypes.bool,
};
