// BarChartStacked.jsx
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { shortNumber } from 'helpers/report';

const BarChartStacked = ({ data, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      // Force update after mount
      chart.update();
    }
  }, [data, options]);

  const stackedOptions = {
    ...options,
    scales: {
      x: {
        stacked: true,
        ...options.scales?.x,
      },
      y: {
        stacked: true,
        ...options.scales?.y,
      },
    },
    animation: {
      duration: 100,
      onComplete: () => {
        const chart = chartRef.current;
        if (chart) {
          chart.update();
        }
      },
    },
    plugins: {
      ...options.plugins,
      // tooltip: {
      //   ...options.plugins?.tooltip,
      //   callbacks: {
      //     // The title typically gets the bar's "label" from the dataset
      //     title: (tooltipItems) => {
      //       if (!tooltipItems.length) return '';
      //       // For a bar chart, tooltipItems[0].label is the “X axis label” from Chart.js
      //       console.log('tooltipItems', tooltipItems);
      //       const xLabel = tooltipItems[0].label;
      //       return `${xLabel}`; // or whatever field your xDefs[0] represents
      //     },
      //     label: (tooltipItem) => {
      //       console.log();
      //       // For each dataset, you get a line in the tooltip
      //       console.log('tooltipItems', tooltipItem);
      //       const dsLabel = tooltipItem.dataset.label; // e.g. "Patrick Pixley"
      //       const val = tooltipItem.raw; // numeric value
      //       return `${dsLabel}: ${shortNumber(val)}`;
      //     },
      //   },
      // },
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#ffffff',
        formatter: (value) => shortNumber(value),
        display: (ctx) => {
          const { chart, dataIndex, datasetIndex } = ctx;
          const meta = chart.getDatasetMeta(datasetIndex);
          const bar = meta.data[dataIndex];
          if (!bar) return false;

          // 1) Check the actual value
          const dataset = chart.data.datasets[datasetIndex];
          const value = dataset.data[dataIndex];
          // If 0 (or falsy), hide the label
          if (!value) return false;

          // 2) Check the bar's width in pixels
          const barWidth = Math.abs(bar.base - bar.x);
          // 3) Check the bar's height in pixels.
          // If the bar doesn't have a height property, compute it as the difference between top and bottom.
          const barHeight = bar.height || Math.abs(bar.bottom - bar.top);

          // Only display the label if both the width and height are large enough (e.g. > 15px)
          return barWidth > 30 && barHeight > 9;
        },
      },
    },
  };

  return <Bar ref={chartRef} data={data} options={stackedOptions} />;
};

BarChartStacked.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default BarChartStacked;
