import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Table } from 'components/Common/Table';
import { FullName } from 'components/Fields/Contact/components/ContactsTable/config';
import { BaseDialogWrapper } from 'components/ModelViews/styled';
import { Header, HeadingSection } from 'components/Styled';
import { useTableData } from 'hooks';

export const UsersModal = ({ currentItem, setCurrentItem, useGetList }) => {
  // Use the current item's uuid (or id) as a parameter.
  const identifier = currentItem.uuid ? currentItem.uuid : currentItem.id;

  // Build query parameters. Include the status if provided.
  const queryParams = { id: identifier };
  if (currentItem.status) {
    queryParams.status = currentItem.status;
  }
  if (currentItem.active) {
    queryParams.active = currentItem.active;
  }

  const { page, setPage, totalPages, totalCount, rows } = useTableData(
    useGetList,
    [],
    'name',
    'asc',
    10,
    queryParams,
  );

  const cells = useMemo(
    () => [
      {
        id: 'firstName',
        value: ['firstName', 'middleName', 'lastName', 'companyName', 'id'],
        label: 'Name',
        isVisible: true,
        Wrapper: FullName,
      },
      {
        id: 'username',
        label: 'Username',
        isVisible: true,
        value: 'username',
      },
    ],
    [],
  );

  const handleClose = () => setCurrentItem(false);

  return (
    <BaseDialogWrapper
      maxWidth={'lg'}
      open={!!currentItem}
      onClose={handleClose}
    >
      <Box>
        <HeadingSection>
          <Header variant="h4">
            {queryParams.status === 'Active' ? 'Active Users' : 'Total Users'}
          </Header>
        </HeadingSection>
        {rows.length > 0 ? (
          <Table
            rows={rows}
            cells={cells}
            page={page}
            setPage={setPage}
            rowsPerPage={10}
            totalPages={totalPages}
            totalCount={totalCount}
          />
        ) : (
          <Box>Empty list</Box>
        )}
      </Box>
    </BaseDialogWrapper>
  );
};

UsersModal.propTypes = {
  currentItem: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    active: PropTypes.bool,
  }),
  setCurrentItem: PropTypes.func,
  useGetList: PropTypes.func,
};

export default UsersModal;
