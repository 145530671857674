import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useGetChartDataQuery, useGetChartQuery } from 'redux/rtk-query';

export const ReportChart = ({ reportId, chartId }) => {
  const { data: chart } = useGetChartQuery({
    reportId,
    chartId,
  });
  const { data: chartData } = useGetChartDataQuery({
    reportId,
    chartId,
    limit: 100,
  });

  if (!chart || !chartData) {
    return <div>Loading...</div>;
  }

  // Extract the chart type and datasets
  const chartType = chart.data.type;
  const datasets = chart.data.datasets;

  // Map the axes from the datasets
  const xAxisColumn = datasets.find((d) => d.axis === 'X')?.column;
  const yAxisColumn = datasets.find((d) => d.axis === 'Y')?.column;

  // Prepare the data
  const data = chartData.data.map((item) => ({
    [xAxisColumn.name]: item[xAxisColumn.name],
    [yAxisColumn.name]: parseFloat(item[yAxisColumn.name]),
  }));

  // Decide which chart to render based on chartType
  const renderChart = () => {
    switch (chartType) {
      case 'Bar Chart Horizontal':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey={xAxisColumn.name} type="category" />
              <Tooltip />
              <Legend />
              <Bar dataKey={yAxisColumn.name} fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        );
      case 'Bar Chart Vertical':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxisColumn.name} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey={yAxisColumn.name} fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        );
      case 'Line Chart':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={xAxisColumn.name} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={yAxisColumn.name}
                stroke="#8884d8"
              />
            </LineChart>
          </ResponsiveContainer>
        );
      // Add cases for other chart types as needed
      default:
        return <div>Chart type not supported.</div>;
    }
  };

  return <div>{renderChart()}</div>;
};

ReportChart.propTypes = {
  reportId: PropTypes.string.isRequired,
  chartId: PropTypes.string.isRequired,
};
