// ClickMarketingViewer.js

import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import AutofillDataProvider from 'components/ModelViews/Documents/ClickMarketingViewer/AutofillDataProvider';
import { CM_ITEM_TYPES } from 'consts';
import {
  scaleSelectorCM as scaleSelector,
  pixelSizeSelector,
} from 'redux/selectors';
import {
  setScaleCM as setScale,
  setDocumentDataCM as setDocumentData,
  reset,
} from 'redux/slices';
import { loadItemGroupsCM } from 'redux/slices/clickMarketing/thunk';
import DesignItems from './DesignItems';
import './css/styles.css';

const debounce = (func, delay) => {
  let inDebounce;
  return function (...args) {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(this, args), delay);
  };
};

const ClickMarketingPreview = ({ documentData, templateView = false }) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const scale = useSelector(scaleSelector);

  const [width, height] = useSelector(pixelSizeSelector);

  // Handle Resize function
  const handleResize = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.clientWidth; // 20px buffer each side
      const containerHeight = container.clientHeight; // 20px buffer each side

      const widthScale = containerWidth / width;
      const heightScale = containerHeight / height;

      const newScale = Math.min(widthScale, heightScale);

      dispatch(setScale(newScale));
    }
  }, [width, height, dispatch]);

  // Debounced version of handleResize
  const debouncedHandleResize = useCallback(debounce(handleResize, 250), [
    handleResize,
  ]);

  // Use ResizeObserver to monitor containerRef size changes
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // Call handleResize initially
    handleResize();

    const resizeObserver = new ResizeObserver(() => {
      debouncedHandleResize();
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
      resizeObserver.disconnect();
    };
  }, [containerRef, debouncedHandleResize, handleResize]);

  useEffect(() => {
    if (documentData) {
      const documentDataCopy = JSON.parse(JSON.stringify(documentData));
      documentDataCopy.templateView = templateView;
      if (documentDataCopy.cmBlockType === CM_ITEM_TYPES.modular.Template) {
        documentDataCopy.htmlTemplate.items = [];
      }
      dispatch(setDocumentData(documentDataCopy));
      dispatch(loadItemGroupsCM());
    }
  }, [documentData, templateView, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset(null));
    };
  }, [dispatch]);

  return (
    <>
      <AutofillDataProvider />
      <Grid
        item
        xs={8}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          flex={2}
          p={0}
          sx={{
            backgroundColor: '#6c6a6a',
            maxWidth: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            ref={containerRef}
            position="relative"
            sx={{
              pointerEvents: 'none',
              transform: `scale(${scale})`,
              width: `${width}px`,
              height: `${height}px`,
              background: 'white',
            }}
          >
            <DesignItems preview />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

ClickMarketingPreview.propTypes = {
  documentData: PropTypes.object.isRequired,
  templateView: PropTypes.bool,
};

export default ClickMarketingPreview;
