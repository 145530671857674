import React from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { Button } from '@mui/material';
import { FormPanel } from 'components/Common';
import { FormCondition } from 'components/Forms';
import {
  CONTACT_TYPES,
  REPRESENTING_OPTIONS,
  STATUS_OPTIONS,
  TRANSACTION_TYPES,
} from 'consts';
import { TeamMembers } from '../TeamShareAndReferralPanel/TeamMembers';
import {
  CustomerRolesField,
  OpposingCustomerRolesField,
  RolesField,
} from './components';
import { OtherRoleSelection } from './components/OtherRoleSelection';
import { OtherRolesFields } from './components/OtherRolesFields';

export const RolesPanel = () => {
  const [addExtraRole, setAddExtraRole] = React.useState(false);
  let representingValue = useField('representing').input.value;
  let statusValue = useField('state').input.value;
  const form = useForm();

  const shouldShowCoopAndLender =
    representingValue !== REPRESENTING_OPTIONS.SELLER.value ||
    (representingValue === REPRESENTING_OPTIONS.SELLER.value &&
      statusValue !== STATUS_OPTIONS.DRAFT.value);

  const handleAddExtraRole = () => {
    setAddExtraRole(true);
  };

  return (
    <>
      {(form.getState().values.transactionType !== TRANSACTION_TYPES.PRIMARY ||
        form.getState().values.representing) && (
        <>
          <FormPanel
            startOpen
            label={'Roles'}
            toolbar={
              <Button
                size={'x-small'}
                sx={{ minWidth: 26, padding: '0px 6px', ml: 1 }}
                color={'primary'}
                variant={'outlined'}
                onClick={handleAddExtraRole}
              >
                + Add Role
              </Button>
            }
          >
            <Field label="Roles" name={'roles'}>
              {(props) => (
                <>
                  <CustomerRolesField
                    {...props}
                    customerType={'customersType1'}
                    createType={'Customer'}
                  />
                  <CustomerRolesField
                    {...props}
                    customerType={'customersType2'}
                    createType={'Customer'}
                  />
                </>
              )}
            </Field>
            <TeamMembers />
            {form.getState().values.transactionType !==
              TRANSACTION_TYPES.PRIMARY && (
              <Field label="Roles" name={'rolesStr'}>
                {(props) => (
                  <>
                    <OpposingCustomerRolesField {...props} />
                  </>
                )}
              </Field>
            )}
            <Field label="Roles" name={'roles'}>
              {(props) => (
                <>
                  {shouldShowCoopAndLender && (
                    <>
                      {form.getState().values.transactionType !==
                        TRANSACTION_TYPES.PRIMARY && (
                        <RolesField
                          {...props}
                          role={'Cooperating Agent'}
                          contactType={CONTACT_TYPES.COOPERATING_AGENT.value}
                          createType={CONTACT_TYPES.COOPERATING_AGENT.value}
                        />
                      )}
                      <FormCondition
                        when="financingType"
                        operator="doesNotEqual"
                        is={'CASH'}
                      >
                        <RolesField
                          {...props}
                          role={'Lender'}
                          contactType={CONTACT_TYPES.LENDER.value}
                          createType={CONTACT_TYPES.LENDER.value}
                        />
                      </FormCondition>
                    </>
                  )}
                  <FormCondition
                    when="representing"
                    operator="oneOf"
                    is={[
                      REPRESENTING_OPTIONS.SELLER.value,
                      REPRESENTING_OPTIONS.BUYER.value,
                      REPRESENTING_OPTIONS.SELLER_AND_BUYER.value,
                    ]}
                  >
                    <RolesField
                      {...props}
                      role={'Escrow Agent'}
                      contactType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                      createType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                    />
                    <RolesField
                      {...props}
                      role={'Closing Agent'}
                      contactType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                      createType={CONTACT_TYPES.CLOSING_ESCROW_AGENT.value}
                    />
                  </FormCondition>
                  <OtherRolesFields {...props} />
                </>
              )}
            </Field>
          </FormPanel>
        </>
      )}
      <OtherRoleSelection
        isOpen={addExtraRole}
        handleClose={() => {
          setAddExtraRole(false);
        }}
      />
    </>
  );
};

RolesPanel.propTypes = {};
