import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ChartView } from 'pages/ReportPages/DetailsReportPage/ChartView';
import LiveFilters from 'pages/ReportPages/DetailsReportPage/LiveFilters';
import { useGetReportQuery } from 'redux/rtk-query';

const ReportConfig = ({ reportId }) => {
  const { data: reportData } = useGetReportQuery(reportId, { skip: !reportId });

  const ChartReportConfig = () => (
    <>
      <Grid item xs={12}>
        <LiveFilters reportId={reportId} />
        <ChartView reportId={reportId} />
      </Grid>
    </>
  );

  return (
    <Grid container>
      {reportData?.data?.reportType === 'chart' && <ChartReportConfig />}
    </Grid>
  );
};

export default ReportConfig;

ReportConfig.propTypes = {
  reportId: PropTypes.string.isRequired,
};
