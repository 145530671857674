import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import { Autocomplete, Select, TextInput } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { UNIT_TYPES } from 'consts';
import { useLoadHierarchyOptions } from 'hooks';
import { useGetCompanyUnitsQuery } from 'redux/rtk-query';

const CompanyUnitForm = (props) => {
  const units = useLoadHierarchyOptions(useGetCompanyUnitsQuery);

  const renderFields = () => (
    <FormPanel startOpen label={'Overview'}>
      <Grid container>
        <Grid item xs={6}>
          <Field name="name" label="Name" component={TextInput} />
          <Field
            name="type"
            label="Unit Type"
            options={UNIT_TYPES}
            component={Select}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="parent"
            label="Parent Unit"
            options={units}
            component={Autocomplete}
          />
        </Grid>
      </Grid>
    </FormPanel>
  );

  return (
    <AbeForm
      headerColor={props.headerColor}
      handleSubmitFunc={props.handleSubmit}
      {...props}
    >
      {renderFields}
    </AbeForm>
  );
};

export default CompanyUnitForm;
CompanyUnitForm.propTypes = {
  submitError: PropTypes.string,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  afterSave: PropTypes.func,
  headerColor: PropTypes.string,
};
