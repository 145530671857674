import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { SideDrawer, Table } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { useTableData } from 'hooks';
import { ChartView } from 'pages/ReportPages/DetailsReportPage/ChartView';
import { useGetReportsListQuery } from 'redux/rtk-query';

// eslint-disable-next-line no-unused-vars
const ReportDrawer = ({ open, onClose, onSelectReport, handleChartSelect }) => {
  // eslint-disable-next-line no-unused-vars
  const [reportId, setReportId] = React.useState(null);
  const ReportName = ({ value }) => {
    return value ? (
      <Box
        onClick={() => {
          setReportId(value.id);
          onSelectReport(value); // ✅ Set selected report
        }}
      >
        <div>{value.name} </div>
      </Box>
    ) : (
      <></>
    );
  };

  ReportName.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  const cells = [
    {
      id: 'name',
      value: ['name', 'id'],
      numeric: false,
      disablePadding: true,
      label: 'Name',
      Wrapper: ReportName,
    },
    {
      id: 'reportType',
      value: ['reportType'],
      numeric: false,
      disablePadding: true,
      label: 'Report Type',
    },
  ];

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    search,
    totalCount,
    loading,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    rows,
  } = useTableData(useGetReportsListQuery, cells, 'updatedAt', 'desc', 10);

  return (
    <SideDrawer
      closeOnClickAway={onClose}
      width={'60%'}
      isOpen={open}
      closeDrawer={onClose}
    >
      <HeadingSection>
        <Header variant="h4">
          {reportId ? 'Select Chart' : 'Select Report'}
        </Header>
      </HeadingSection>
      {!reportId ? (
        <Table
          handleSearch={setSearch}
          search={search}
          isLoading={loading}
          rows={rows}
          totalPages={totalPages}
          totalCount={totalCount}
          order={order}
          cells={cells}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRequestSort={handleRequestSort}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      ) : (
        <Box p={2}>
          <ChartView
            reportId={reportId}
            handleChartSelect={(id) => {
              handleChartSelect(id);
              setReportId(null);
              onClose();
            }}
          />
        </Box>
      )}
    </SideDrawer>
  );
};

export default ReportDrawer;

ReportDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectReport: PropTypes.func.isRequired,
  handleChartSelect: PropTypes.func.isRequired,
};
