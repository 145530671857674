import { DateTime } from 'luxon';

export const conversion = {
  formatCurrency: function (value) {
    value = parseFloat(value);
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: !Number.isInteger(value) ? 2 : 0, // remove cents if they are zero
    });
  },
  formatNumberWithCommas: function (value) {
    value = parseFloat(value);
    return value.toLocaleString('en-US');
  },
  formatPercentage: function (value) {
    value = parseFloat(value);
    return `${value}%`;
  },
  formatDate: function (value, format = DateTime.DATE_MED) {
    const dateTime = DateTime.fromISO(value);

    // Return empty string if the date is invalid
    if (!dateTime.isValid) {
      return '';
    }

    if (typeof format === 'string') {
      return dateTime.toFormat(format);
    } else {
      return dateTime.toLocaleString(format);
    }
  },
  ucFirst: function (str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  },
  getTransactionName: function ({ address, roles }, streetOnly = false) {
    if (address?.street1) {
      if (streetOnly) {
        return address.street1;
      }
      return this.addressToString(address);
    } else {
      return this.getCustomersString(roles);
    }
  },
  getNestedValue: function (obj, path) {
    if (!obj || !path) return null;
    let pathArray = path.split('.');
    let value = obj;
    for (let i = 0; i < pathArray.length; i++) {
      value = value?.[pathArray[i]];
      if (value === undefined) return null;
    }
    return value;
  },
  getName: function (contact, short = true) {
    if (typeof contact === 'string') {
      return contact;
    }
    if (contact && contact.transaction) {
      return this.getTransactionName(contact);
    }
    if (
      !contact ||
      !Object.keys(contact).length ||
      (!contact.lastName && !contact.companyName)
    ) {
      let fallback = typeof contact === 'string' ? contact : '';
      return contact && contact.name ? contact.name : fallback;
    }
    console.log('contact', contact);
    const firstName = contact.firstName;
    const nickName = contact.nickname;
    const middleName = contact.middleName;
    const lastName = contact.lastName;
    if (short) {
      return `${firstName ? this.ucFirst(firstName) : ''} ${nickName ? `"${this.ucFirst(nickName)}" ` : ''}${
        lastName ? this.ucFirst(lastName) : ''
      }`;
    }
    return !firstName && !lastName && !middleName
      ? contact.companyName
      : `${firstName ? this.ucFirst(firstName) : ''}
      ${nickName ? `"${this.ucFirst(nickName)}"` : ''}
      ${
        middleName ? ` ${this.ucFirst(middleName)}` : ''
      } ${lastName ? this.ucFirst(lastName) : ''}`;
  },
  getCustomers: function (roles, rolesType = null) {
    let customers = [];
    if (roles) {
      if (Array.isArray(roles)) {
        roles.forEach((role) => {
          if (
            (rolesType &&
              role.role.toLowerCase().includes(rolesType.toLowerCase())) ||
            (!rolesType &&
              (role.role.toLowerCase().includes('buyer') ||
                role.role.toLowerCase().includes('seller') ||
                role.role.toLowerCase().includes('landlord') ||
                role.role.toLowerCase().includes('tenant')))
          ) {
            if (role.contact) {
              customers.push(role.contact);
            } else {
              customers.push(role.contactName);
            }
          }
        });
      } else if (typeof roles === 'object') {
        Object.keys(roles).forEach((key) => {
          let role = roles[key];
          if (
            (rolesType &&
              key.toLowerCase().includes(rolesType.toLowerCase())) ||
            (!rolesType &&
              (key.toLowerCase().includes('buyer') ||
                key.toLowerCase().includes('seller') ||
                key.toLowerCase().includes('landlord') ||
                key.toLowerCase().includes('tenant')))
          ) {
            if (role[0].contact) {
              customers.push(role[0].contact);
            } else {
              customers.push(role[0].contactName);
            }
          }
        });
      }
    }
    return customers;
  },
  getCustomerRoles: function (roles) {
    let customers = [];
    if (roles) {
      if (Array.isArray(roles)) {
        roles.forEach((role) => {
          if (
            role.role.toLowerCase().includes('buyer') ||
            role.role.toLowerCase().includes('seller') ||
            role.role.toLowerCase().includes('landlord') ||
            role.role.toLowerCase().includes('tenant')
          ) {
            customers.push(role);
          }
        });
      } else if (typeof roles === 'object') {
        Object.keys(roles).forEach((key) => {
          let role = roles[key];
          if (
            key.toLowerCase().includes('buyer') ||
            key.toLowerCase().includes('seller') ||
            key.toLowerCase().includes('landlord') ||
            key.toLowerCase().includes('tenant')
          ) {
            customers.push(role[0]);
          }
        });
      }
    }
    return customers;
  },
  getCustomersString: function (roles, rolesType = null) {
    let customersString = '';
    let customers = this.getCustomers(roles, rolesType);
    if (customers.length > 0) {
      customersString = conversion.getName(customers[0]);
      if (customers.length > 1) {
        customersString =
          customersString + ' & ' + conversion.getName(customers[1]);
      }
    }
    return customersString;
  },
  getAllCustomersString: function (roles, rolesType) {
    let customersString = '';
    let matchedRoles = roles.filter((role) =>
      role.role.toLowerCase().includes(rolesType.toLowerCase()),
    );
    matchedRoles.map((role, index) => {
      let contactName = role.contact
        ? conversion.getName(role.contact)
        : role.contactName;
      if (!contactName) return;
      if (index === 0) {
        customersString = contactName;
      } else {
        customersString = customersString + ' & ' + contactName;
      }
    });

    return customersString;
  },
  titleCase: function (s) {
    return s
      .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
      .replace(/[_-]+/g, ' ') // split snake_case and lisp-case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }) // title case words
      .replace(/\s+/g, ' ') // collapse repeated whitespace
      .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
  },
  addressToString: function (address, returnFull = false) {
    if (!address) return '';
    const street1 = address?.street1 ? address?.street1 : '';
    const street2 = address?.street2 ? ` ${address?.street2}` : '';
    const city = address?.city ? ` ${address?.city}` : '';
    const state = address?.state ? ` ${address?.state}` : '';
    const zipCode = address?.zipCode ? ` ${address?.zipCode}` : '';
    if (returnFull) {
      return `${street1}${street2},${city},${state}${zipCode}`;
    } else {
      return `${street1}${street2}${city}`;
    }
  },
  addressToParcelAddress: function (address) {
    if (!address) {
      return '';
    }
    let parcelAddress = address.toUpperCase();
    let dirList = {
      ' northeast': ' ne ',
      ' southeast': ' se ',
      ' southwest': ' sw ',
      ' northwest': ' nw ',
      ' north': ' n ',
      ' east': ' e ',
      ' south': ' s ',
      ' west': ' w ',
    };
    Object.keys(dirList).map((dir) => {
      parcelAddress = parcelAddress.toLowerCase().replace(dir, dirList[dir]);
    });
    parcelAddress = parcelAddress.toUpperCase();
    let sepPos = parcelAddress.lastIndexOf(' ');
    let addressMinusSuffix = parcelAddress.substring(0, sepPos);
    let addressSuffix = parcelAddress.slice(sepPos);

    let suffixList = {
      drive: 'dr',
      circle: 'cir',
      street: 'st',
      avenue: 'ave',
      boulevard: 'blvd',
      court: 'ct',
      parkway: 'pky',
      point: 'pt',
      place: 'pl',
      road: 'rd',
      terrace: 'ter',
      trail: 'tr',
      lane: 'ln',
      alley: 'aly',
      cove: 'cv',
    };

    Object.keys(suffixList).map((suffix) => {
      addressSuffix = addressSuffix
        .toLowerCase()
        .replace(suffix, suffixList[suffix]);
    });
    parcelAddress = `${addressMinusSuffix} ${addressSuffix}`;
    parcelAddress = parcelAddress.replace('  ', ' ');
    parcelAddress = parcelAddress.replace('  ', ' ');

    return parcelAddress.toUpperCase();
  },
  formatPhoneNumber: function (str) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return str;
  },
  spaceNumberFromString: function (input) {
    let output = input.replace(/([A-Z])/g, ' $1');
    output = output.charAt(0).toUpperCase() + output.slice(1);
    let lastCharacters = output.charAt(output.length - 2);
    if (!isNaN(lastCharacters)) {
      output = output.substring(0, output.length - 2) + ' ' + lastCharacters;
    } else {
      let lastCharacter = output.charAt(output.length - 1);
      if (!isNaN(lastCharacter)) {
        output = output.substring(0, output.length - 1) + ' ' + lastCharacter;
      }
    }
    return output;
  },
  camelCaseToWords: function (input) {
    let output = '';
    if (!input) return output;
    let parts = input.split('_');
    parts.map((part) => {
      output = output + ' ' + this.spaceNumberFromString(part);
    });
    return output.trim();
  },
  wordsToCamelCase: function (input) {
    let output = '';
    if (!input) return output;
    let parts = input.split(' ');
    parts.map((part, index) => {
      if (index === 0) {
        output = output + part.toLowerCase();
      } else {
        output = output + this.ucFirst(part.toLowerCase());
      }
    });
    return output;
  },
  dbEnumToArray: function (string) {
    let str = String(string);
    str = str.replace('{', '');
    str = str.replace('}', '');
    if (str.includes(',')) {
      return str.split(',');
    }
    return null;
  },
};
