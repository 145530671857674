import {
  styled,
  Table as MUITable,
  TableRow as MUITableRow,
  TableCell as MUITableCell,
  Pagination as MUIPagination,
  TablePagination as MUITablePagination,
  Box,
  Divider,
  Paper,
  IconButton as MUIIconButton,
  Skeleton,
  TableHead,
} from '@mui/material';

export const Table = styled(MUITable)({
  minWidth: '750',
  width: '100%',
});

Table.defaultProps = {
  size: 'small',
};

export const TableWrapper = styled(Box)({
  width: '100%',
  borderRadius: '8px',
});

export const TablePaper = styled(Paper)({
  boxShadow: '0px 0px 8px 2px rgb(0 0 0 / 4%)',
  borderRadius: '16px',
});

export const MaterialTableHead = styled(TableHead)({
  '& .MuiDivider-root': {
    top: 5,
    bottom: 5,
    height: 'calc(100% - 10px)',
  },
});

export const TableRow = styled(MUITableRow)({
  height: '35px',
  '.MuiTableCell-root:first-of-type': {
    hr: {
      display: 'none',
    },
  },
});

export const TableCell = styled(MUITableCell)({
  padding: '0 15px',
  position: 'relative',
  borderColor: '#E8E8E8',
  color: '#565656',
  fontWeight: '500',
  lineHeight: '24px',
  '.MuiTableRow-root &': {},
  '.MuiTableRow-head &': {
    color: '#969696',
  },
});

export const TableCellLoader = styled(Skeleton)({
  margin: '5px 20px',
});

export const TableCellCollapse = styled(TableCell)({
  padding: 0,
  color: 'red',
  '.MuiTableRow-root:last-child .MuiTableCell-root': {
    border: 'none',
  },
  '.MuiTableRow-root .MuiTableCell-root': {
    fontWeight: '500',
    color: '#565656',
  },
});

export const TablePagination = styled(MUIPagination)({
  '& .MuiButtonBase-root.MuiPaginationItem-root': {
    minWidth: 20,
    height: 30,
  },
});

export const TablePaginationSelect = styled(MUITablePagination)({
  '& .MuiToolbar-root': {
    minHeight: 25,
    paddingLeft: 5,
  },
  '& .MuiTablePagination-select': {
    padding: '3px 20px 3px 9px',
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
    padding: 0,
    margin: 0,
    fontWeight: '500',
    lineHeight: '24px',
    color: '#565656',
  },
  '.MuiInputBase-root': {
    marginRight: '8px',
    borderBottom: '1px solid #d2d2d2',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#565656',
  },
  '.MuiTablePagination-actions': {
    display: 'none',
  },
});

TablePaginationSelect.defaultProps = {
  SelectProps: { size: 'small' },
};

export const FooterWrapper = styled(Box)({
  padding: '8px 14px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TableHeadDivider = styled(Divider)({
  borderRightWidth: 0,
  borderLeftWidth: 'thin',
  // height: "24px",
  margin: 0,
  width: '1px',
  borderColor: '#E8E8E8',
});

TableHeadDivider.defaultProps = {
  orientation: 'vertical',
  variant: 'middle',
  absolute: true,
};

export const IconButton = styled(MUIIconButton)({
  '&:disabled svg path': {
    fill: '#E8E8E8',
  },
});
