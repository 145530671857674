import _ from 'lodash';

/**
 * Returns true if the role (or any of its children) has active users.
 * (Here we assume that each role has an `activeUsers` property with a count.)
 */
const hasActiveUsers = (role) => {
  console.log('role', role);
  if (role.activeUsersCount && role.activeUsersCount > 0) {
    return true;
  }
  if (role.children && role.children.length > 0) {
    return role.children.some((child) => hasActiveUsers(child));
  }
  return false;
};

/**
 * Filters an array of roles according to the search term,
 * but only returns roles that have active users (or have at least one child with active users).
 */
const filter = (array, search) => {
  return _.filter(array, (role) => {
    // First, if neither the role nor any of its children have active users, skip it.
    if (!hasActiveUsers(role)) {
      return false;
    }
    // Now, if there are children, recursively filter them.
    if (role.children && role.children.length) {
      const filteredChildren = filter(role.children, search);
      // Return the role if either it matches the search text
      // or if any of its children (after filtering) do.
      return (
        filteredChildren.length > 0 ||
        role.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    // If no children, simply check if the role's name includes the search term.
    return role.name.toLowerCase().includes(search.toLowerCase());
  });
};

/**
 * Recursively maps the filtered data so that children are processed in the same way.
 */
export const filterMap = (array, search) => {
  return filter(array, search).flatMap((role) => ({
    ...role,
    children: filterMap(role.children, search),
  }));
};

/**
 * A helper function to filter roles based on both search and active user requirements.
 */
export const filterChildren = (array, search) => {
  return filterMap(array, search, true);
};
