import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import Pluralize from 'pluralize';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { conversion } from 'helpers';
import { useDebounce } from 'hooks';
import { useGlobalSearchQuery } from 'redux/rtk-query';
import { Search, SearchIconWrapper, StyledInputBase } from './styled';

const ContactItem = ({ contact }) => (
  <Box>
    <Typography variant="body2">
      {conversion.getName(contact)} - {contact.type}
    </Typography>
    <Typography variant="body2">{contact.email}</Typography>
  </Box>
);

ContactItem.propTypes = {
  contact: PropTypes.object.isRequired,
};

const SettingItem = ({ setting }) => (
  <>
    <Typography variant="body2">{setting.name}</Typography>
  </>
);

SettingItem.propTypes = {
  setting: PropTypes.object.isRequired,
};

const TransactionItem = ({ transaction }) => (
  <Box>
    <Typography variant="body2">{transaction.name}</Typography>
    <Typography variant="body2">
      {transaction.status}
      {transaction.closeDate &&
        ` - ${DateTime.fromISO(transaction.closeDate, {
          zone: 'utc',
        }).toLocaleString(DateTime.DATE_SHORT)}`}
      {` - ${conversion.getName(transaction.owner)}`}
    </Typography>
  </Box>
);

TransactionItem.propTypes = {
  transaction: PropTypes.object.isRequired,
};

const ResultItem = ({ result, showDivider, navigateToModel }) => {
  const entity = Object.keys(result)[0];
  const module = Pluralize(entity);
  const data = result[entity];
  const typeOrStatus = entity === 'transaction' ? data.status : data.type;

  return (
    <>
      <Box
        p={1}
        sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f7f7f7' } }}
      >
        <Grid
          onClick={() => {
            navigateToModel(module, data.id);
          }}
          container
          alignItems={'center'}
        >
          <Grid item width={30}>
            <ModuleIcon
              marginRight={10}
              module={entity}
              type={typeOrStatus}
              closedApproved={data.closedApproved}
            />
          </Grid>
          <Grid item>
            {entity === 'contact' && <ContactItem contact={data} />}
            {entity === 'setting' && <SettingItem setting={data} />}
            {entity === 'transaction' && <TransactionItem transaction={data} />}
          </Grid>
        </Grid>
      </Box>
      {showDivider && <Divider />}
    </>
  );
};

ResultItem.propTypes = {
  result: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
  navigateToModel: PropTypes.func,
};

export const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const debouncedValue = useDebounce(searchTerm, 500);
  const { data, error } = useGlobalSearchQuery(debouncedValue, {
    skip: !debouncedValue,
  });

  const navigateToModel = (module, id) => {
    navigate(`/${module}/details/${id}`);
    setSearchTerm('');
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // No need to manually trigger the RTK query since it will re-run when searchTerm changes
  };

  return (
    <Box position={'relative'}>
      <form onSubmit={handleSubmit}>
        <Search>
          <SearchIconWrapper>
            <FontAwesomeIconComp icon={faMagnifyingGlass} color="#969696" />
          </SearchIconWrapper>
          <StyledInputBase
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      </form>
      <Box position={'absolute'} top={40} left={0}>
        <Paper>
          {error && <div>Error: {error.message}</div>}
          {data?.items?.map((result, index) => (
            <ResultItem
              key={index}
              result={result}
              showDivider={index < data.items.length - 1}
              navigateToModel={navigateToModel}
            />
          ))}
        </Paper>
      </Box>
    </Box>
  );
};
