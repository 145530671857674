import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { DrawerContextProvider } from './context/DrawerContext';

export function SideDrawer({
  children,
  isOpen,
  width,
  closeOnClickAway,
  zIndex,
  closeDrawer,
}) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <DrawerContextProvider closeDrawer={closeDrawer}>
      <Drawer
        style={{
          zIndex: zIndex ? zIndex : 1201,
          position: 'relative',
        }}
        PaperProps={{
          style: {
            width: isSm ? '90%' : width,
            backgroundColor: '#F7F7F7',
          },
        }}
        anchor={'right'}
        open={isOpen}
        ModalProps={{
          onBackdropClick: closeOnClickAway ? closeOnClickAway : null,
        }}
      >
        {children}
      </Drawer>
    </DrawerContextProvider>
  );
}

SideDrawer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  closeOnClickAway: PropTypes.func,
  width: PropTypes.string,
  zIndex: PropTypes.number,
  closeDrawer: PropTypes.func,
};
