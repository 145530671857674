import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box } from '@mui/system';
import { MobileDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LabeledOutline } from 'components/Common';
import { ErrorMsg, BoxInput, TextInputField } from 'components/Styled';

export const DateTimePicker = ({
  label,
  size = 'small',
  // eslint-disable-next-line no-unused-vars
  inputFormat = 'MM/dd/yyyy hh:mm a',
  input,
  meta,
}) => {
  // Parse the initial value (if available) or default to now.
  const initialDateTime = input.value
    ? DateTime.fromISO(input.value)
    : DateTime.now();

  // Separate states for date and time.
  const [selectedDate, setSelectedDate] = useState(initialDateTime);
  const [selectedTime, setSelectedTime] = useState(initialDateTime);

  // If the external value changes, update the internal states.
  useEffect(() => {
    if (input.value) {
      const dt = DateTime.fromISO(input.value);
      if (!dt.equals(selectedDate)) {
        setSelectedDate(dt);
      }
      if (!dt.equals(selectedTime)) {
        setSelectedTime(dt);
      }
    }
  }, [input.value]);

  // Combine the chosen date and time into one DateTime and notify the change.
  const updateDateTime = (date, time) => {
    // Only update if both date and time are valid Luxon DateTime objects.
    if (!date || !time || !date.isValid || !time.isValid) {
      return;
    }
    const combined = date.set({
      hour: time.hour,
      minute: time.minute,
      second: time.second,
    });
    input.onChange(combined.toISO());
  };

  const handleTimeChange = (newTime) => {
    // If newTime is not valid, just update the state without calling updateDateTime.
    if (!newTime || !newTime.isValid) {
      setSelectedTime(newTime);
      return;
    }
    setSelectedTime(newTime);
    updateDateTime(selectedDate, newTime);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    updateDateTime(newDate, selectedTime);
  };

  return (
    <BoxInput>
      <LabeledOutline large label={label}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '& .MuiFormControl-root': { width: 100 },
              '& .MuiFormControl-root input': { paddingRight: 0 },
            }}
          >
            <MobileDatePicker
              value={selectedDate}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextInputField size={size} {...params} />
              )}
              inputFormat="MM/dd/yyyy"
            />
            <TimePicker
              value={selectedTime}
              disableOpenPicker
              onChange={handleTimeChange}
              renderInput={(params) => (
                <TextInputField size={size} {...params} />
              )}
            />
          </Box>
        </LocalizationProvider>
        {meta.error && meta.touched && (
          <ErrorMsg component={'span'}>{meta.error}</ErrorMsg>
        )}
      </LabeledOutline>
    </BoxInput>
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  inputFormat: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};
