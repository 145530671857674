import React from 'react';
import { faBullseyePointer } from '@fortawesome/pro-solid-svg-icons';
import { Outlet } from 'react-router-dom';
import { Grid, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { MarketingCreateView } from 'components/ModelViews/Marketing/MarketingCreateView';
import { PageMainContent } from 'components/Styled';

export const MarketingPage = () => {
  const [createType, setCreateType] = React.useState(null);

  return (
    <>
      <LayoutHeader
        title={
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant="h5">Marketing</Typography>
            <Button
              variant={'outlined'}
              startIcon={
                <FontAwesomeIconComp
                  icon={faBullseyePointer}
                  fontSize={'10px'}
                />
              }
              onClick={() => {
                setCreateType('Click Marketing');
              }}
            >
              Click Marketing
            </Button>
          </Box>
        }
      ></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container flexDirection="column"></Grid>
      </PageMainContent>
      <Outlet />
      <MarketingCreateView
        onClose={() => setCreateType(null)}
        createType={createType}
      />
    </>
  );
};
