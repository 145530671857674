import React from 'react';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Table, CreateButton } from 'components/Common';
import ReportCategoriesDrawer from 'components/ModelViews/Reports/ReportCategoriesDrawer/ReportCategoriesDrawer';
import { REPORT_CREATE } from 'consts';
import { useTableData, useDrawer } from 'hooks';
import { useGetReportsListQuery } from 'redux/rtk-query';
import { Cells, MenuItems } from './config';

export const ReportsTable = () => {
  const { isOpen, closeDrawer, openDrawer } = useDrawer();
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    search,
    totalCount,
    loading,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    totalPages,
    rows,
  } = useTableData(useGetReportsListQuery, Cells, 'updatedAt', 'desc', 10);

  return (
    <>
      <Table
        afterSearch={
          <Box gap={1} display="flex">
            <CreateButton path={`/${REPORT_CREATE}`} title="Create" />
            <Button onClick={openDrawer} variant="outlined">
              Categories
            </Button>
          </Box>
        }
        handleSearch={setSearch}
        search={search}
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <ReportCategoriesDrawer
        isOpen={isOpen}
        closeDrawer={closeDrawer}
        openDrawer={openDrawer}
      />
    </>
  );
};
