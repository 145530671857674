import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Avatar } from 'components/Styled/DetailsPage';
import { contactColors } from 'consts/colors';
import useGetMediaUrl from 'hooks/useGetMediaUrl';

export const HeadshotAvatar = ({ contactInfo: settingInfo, size = 130 }) => {
  const { data: fileData } = useGetMediaUrl(
    settingInfo?.headshotFile,
    'id',
    settingInfo?.id,
  );
  const url = fileData?.data?.url;

  if (url) {
    return (
      <Avatar
        alt="User Date"
        sx={{
          width: size,
          height: size,
          backgroundColor: 'white',
          borderRadius: '100% 100% 100% 100%',
          border: '1px solid #d1d1d1',
        }}
        src={url}
      />
    );
  } else {
    let firstInitial = '';
    let lastInitial = '';
    if (settingInfo.lastName) {
      firstInitial = settingInfo.firstName?.charAt(0) || '';
      lastInitial = settingInfo.lastName.charAt(0);
    } else if (settingInfo.companyName) {
      firstInitial = settingInfo.companyName.charAt(0);
    } else {
      const name = settingInfo.name.split(' ');
      firstInitial = name[0].charAt(0);
      lastInitial = name[1].charAt(0);
    }
    return (
      <Stack direction="row" spacing={2} p={2} justifyContent={'center'}>
        <Avatar sx={{ bgcolor: contactColors['Company Agent'] }}>
          {firstInitial + lastInitial}
        </Avatar>
      </Stack>
    );
  }
};

HeadshotAvatar.propTypes = {
  contactInfo: PropTypes.object,
  size: PropTypes.number,
};
