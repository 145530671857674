import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ImageToolbar } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu/ImageToolbar';
import {
  GroupToolbar,
  TextToolbar,
} from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu/index';
import { ShapeToolbar } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu/ShapeToolbar';
import CloseMenuButton from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/CloseMenuButton';
import { textTypes, shapeTypes, imageTypes } from 'consts';
import { conversion } from 'helpers';
import { selectCurrentItem, activeFieldsSelector } from 'redux/selectors';

export const ActiveFieldMenu = () => {
  const activeField = useSelector(selectCurrentItem);
  const activeFields = useSelector(activeFieldsSelector);
  const showTextToolbar = textTypes.includes(activeField?.type);
  const showImageToolbar = imageTypes.includes(activeField?.type);
  const showShapeToolbar = shapeTypes.includes(activeField?.type);

  return (
    <>
      <Box px={2} py={1} display="flex" alignItems="center">
        <Typography variant="h5">
          {activeField?.textLabel ||
            conversion.camelCaseToWords(activeField?.type)}
        </Typography>
        <CloseMenuButton />
      </Box>
      <Divider />
      {showTextToolbar && <TextToolbar />}
      {showShapeToolbar && <ShapeToolbar />}
      {showImageToolbar && <ImageToolbar />}
      {activeFields.length > 1 && <GroupToolbar />}
    </>
  );
};
