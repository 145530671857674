import React, { useState, useEffect, useRef } from 'react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, IconButton } from '@mui/material';
import { ListPagination } from 'components/ListPagination';
import { SectionHeader } from 'components/Styled';
import {
  ListAccordionSum,
  ExpandIcon,
  ListAccordionDetHeader,
  ListAccordionDetails,
  ListAccordion,
  DividerStyled,
} from 'pages/ContactsPages/ContactsPage/components/ListsSection/components/ListAccordeonItem/styled';
import {
  ListTabText,
  ListTab,
  ListSectionHead,
  ListSectionWrapper,
} from 'pages/ContactsPages/ContactsPage/components/ListsSection/styled';
import { useDashboard } from 'pages/ReportPages/ReportPage/context/DashboardContext';

const DashboardSidebar = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const inputRef = useRef(null); // 🔥 Track input value without causing re-renders
  const navigate = useNavigate();
  const params = useParams();
  const paramName = Object.keys(params)[0];
  const view = params[paramName];

  const {
    dashboards,
    addDashboard,
    activeDashboard,
    setActiveDashboard,
    setIsEditMode,
    isEditMode,
    updateDashboard,
    deleteDashboard,
  } = useDashboard();

  const paginatedDashboards = dashboards.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  // Sync inputRef with activeDashboard name instantly
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = activeDashboard?.name || '';
    }
  }, [activeDashboard]);

  // Update pagination details
  useEffect(() => {
    setTotalCount(dashboards.length);
    setTotalPages(Math.ceil(dashboards.length / rowsPerPage));
  }, [dashboards, rowsPerPage]);

  const handleSaveName = () => {
    if (
      activeDashboard &&
      inputRef.current?.value.trim() !== activeDashboard.name.trim()
    ) {
      updateDashboard(activeDashboard.id, {
        ...activeDashboard,
        name: inputRef.current.value,
      });
    }
  };

  return (
    <Box sx={{ width: 250 }}>
      <ListSectionWrapper>
        <ListSectionHead>
          <SectionHeader variant="h4">Reports</SectionHeader>
          <Button
            variant="text"
            size="small"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? 'Save' : 'Edit'}
          </Button>
        </ListSectionHead>
        <DividerStyled />

        {/* Reports List Tab */}
        <ListTab
          isSelected={view === 'list'}
          onClick={() => {
            navigate('/reports/list');
            setActiveDashboard(null);
          }}
        >
          <ListTabText
            sx={{
              width: '100%',
              color: view === 'list' ? 'white' : 'primary.main',
            }}
          >
            Reports List
          </ListTabText>
        </ListTab>

        {/* Dashboards Accordion */}
        <ListAccordion defaultExpanded>
          <ListAccordionSum
            expandIcon={<ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListAccordionDetHeader sx={{ fontSize: '1.4rem' }}>
              Dashboards
            </ListAccordionDetHeader>
          </ListAccordionSum>
          <ListAccordionDetails>
            {paginatedDashboards.map((db) => (
              <ListTab
                key={db.id}
                isSelected={activeDashboard?.id === db.id}
                onClick={() => {
                  setActiveDashboard(db);
                  navigate(`/reports/dashboard/${db.id}`);
                }}
              >
                <ListTabText
                  sx={{
                    width: '100%',
                    color:
                      activeDashboard?.id === db.id ? 'white' : 'primary.main',
                  }}
                >
                  {isEditMode && activeDashboard?.id === db.id ? (
                    <TextField
                      sx={{ ml: '-7px' }}
                      fullWidth
                      defaultValue={activeDashboard.name} // ✅ Controlled directly from activeDashboard
                      inputRef={inputRef} // 🔥 Track edits without causing re-renders
                      onBlur={handleSaveName}
                      onKeyDown={(e) => e.key === 'Enter' && handleSaveName()}
                      size="small"
                      InputProps={{
                        sx: {
                          fontSize: '1.4rem',
                          '& input': { padding: '4px 8px' },
                        },
                      }}
                    />
                  ) : (
                    db.name
                  )}
                </ListTabText>
                {isEditMode && activeDashboard?.id === db.id && (
                  <IconButton onClick={() => deleteDashboard(db.id)}>
                    <FontAwesomeIcon icon={faTrash} color="white" />
                  </IconButton>
                )}
              </ListTab>
            ))}
          </ListAccordionDetails>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <Box display="flex" justifyContent="space-between" p={1}>
              <ListPagination
                hideText
                totalCount={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={setPage}
                handleChangeRowsPerPage={(event) =>
                  setRowsPerPage(parseInt(event.target.value, 10))
                }
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          )}

          {/* Add Dashboard Button */}
          {isEditMode && (
            <Button
              color="info"
              sx={{
                position: 'relative',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                width: '100%',
              }}
              variant="outlined"
              onClick={addDashboard}
            >
              + Add Dashboard
            </Button>
          )}
        </ListAccordion>
      </ListSectionWrapper>
    </Box>
  );
};

export default DashboardSidebar;
