// PipelineStatusCard.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard } from 'components/Common';
import { TransactionFolderIcon } from 'components/Icons';
import { StyledBadge } from 'components/Styled';
import { shortNumber } from 'helpers/report';
import { SalesPipelineSection } from 'pages/HomePages/HomePage/components/SalesPipelineCard/components';

export const PipelineStatusCard = ({
  label,
  statusValue,
  data,
  startClosed = false,
}) => {
  return (
    <Grid item xs={12}>
      <AbeCard
        startClosed={startClosed}
        hideArrow
        icon={<TransactionFolderIcon model={{ status: label }} />}
        title={
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
            <Typography variant="h4" display={'flex'} alignItems={'center'}>
              {label}
              <StyledBadge
                sx={{ ml: 0.5 }}
                color={'info'}
                badgeContent={data?.totalItemCount}
              />
            </Typography>
            <Typography
              mr={0.4}
              variant="h4"
              display={'flex'}
              alignItems={'center'}
            >
              ${shortNumber(data?.total || 0)}
            </Typography>
          </Box>
        }
      >
        <Divider />
        <SalesPipelineSection status={statusValue} data={data} />
      </AbeCard>
    </Grid>
  );
};

PipelineStatusCard.propTypes = {
  label: PropTypes.string.isRequired,
  statusValue: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  showSection: PropTypes.bool,
  startClosed: PropTypes.bool,
};
