import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { TRANSACTION_TYPES, REPRESENTING_OPTIONS, CREATE_OFFER } from 'consts';
import { getRolesByType } from 'helpers/roles';
import { useDrawer } from 'hooks';
import {
  useCreateTransactionMutation,
  useGetProfileQuery,
  useGetTransactionQuery,
} from 'redux/rtk-query';
import { defaultValues } from '../TransactionForm/defaultValues';
import { processFormData } from '../TransactionForm/formData';
import { TransactionForm } from '../TransactionForm/TransactionForm';

export const CreateOfferTransactionPage = () => {
  const [initialValues, setInitialValues] = useState({});
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false, CREATE_OFFER);

  let { transactionDetailsId } = useParams();
  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionDetailsId },
    { skip: !transactionDetailsId },
  );

  const [createTransaction] = useCreateTransactionMutation();
  const { data } = useGetProfileQuery();

  const getRolesForOffer = () => {
    if (
      transactionData?.data?.representing === REPRESENTING_OPTIONS.SELLER.value
    ) {
      return getRolesByType(transactionData?.data?.roles, 'Seller');
    }
    if (
      transactionData?.data?.representing === REPRESENTING_OPTIONS.BUYER.value
    ) {
      return getRolesByType(transactionData?.data?.roles, 'Buyer');
    }
  };

  useEffect(() => {
    if (transactionData?.data) {
      setInitialValues({
        ...defaultValues,
        representing: transactionData?.data?.representing,
        address:
          transactionData?.data?.representing ===
          REPRESENTING_OPTIONS.SELLER.value
            ? {
                street1: transactionData?.data?.address?.street1,
                street2: transactionData?.data?.address?.street2,
                city: transactionData?.data?.address?.city,
                state: transactionData?.data?.address?.state,
                zipCode: transactionData?.data?.address?.zipCode,
                country: transactionData?.data?.address?.country,
                latitude: transactionData?.data?.address?.latitude,
                longitude: transactionData?.data?.address?.longitude,
              }
            : {},
        modelAccess: transactionData?.data?.modelAccess,
        parcel:
          transactionData?.data?.representing ===
          REPRESENTING_OPTIONS.SELLER.value
            ? transactionData?.data?.parcel
            : null,
        mlsListing:
          transactionData?.data?.representing ===
          REPRESENTING_OPTIONS.SELLER.value
            ? transactionData?.data?.mlsListing
            : null,
        roles: getRolesForOffer(),
        addressConfirmed:
          transactionData?.data?.representing ===
          REPRESENTING_OPTIONS.SELLER.value,
        transactionType: TRANSACTION_TYPES.DRAFT_OFFER,
        primaryTransaction: transactionData?.data,
        agent: data?.data,
        team: transactionData?.data?.team,
      });
      openDrawer();
    }
  }, [0, data, transactionData]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    return await createTransaction(formData);
  };

  const afterSave = () => {
    closeDrawer();
  };

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <TransactionForm
        title={'Create Offer'}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};
