import React from 'react';
import PropTypes from 'prop-types';
import { faEdit, faCopy } from '@fortawesome/pro-light-svg-icons';
import {
  faChartBar,
  faContactCard,
  faTrash,
  faFlask,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Typography, Tooltip } from '@mui/material';
import { DeleteConfirmMenuItem } from 'components/Common/DeleteConfirmMenuItem/DeleteConfirmMenuItem';
import { PageLink } from 'components/Styled';
import { REPORT_UPDATE, REPORT_DETAILS, colors, REPORT_CLONE } from 'consts';
import { useDeleteReportMutation } from 'redux/rtk-query';

const ReportName = ({ value }) => {
  return value ? (
    <PageLink to={`/${REPORT_DETAILS(value.id)}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

ReportName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const StatusWrapper = ({ value }) => {
  const testModeIcon = value.testMode ? (
    <Tooltip title="Test Mode">
      <FontAwesomeIcon icon={faFlask} style={{ marginLeft: 5 }} />
    </Tooltip>
  ) : (
    <></>
  );
  return value ? (
    <Typography>
      {value.status}
      {testModeIcon}
    </Typography>
  ) : (
    <></>
  );
};

StatusWrapper.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const ReportTypeWrapper = ({ value }) => {
  const label = value.reportType === 'chart' ? 'Summation' : 'Rows & Columns';
  const chartIcon = value.chartCount ? (
    <Tooltip title={'Chart'}>
      <FontAwesomeIcon icon={faChartBar} style={{ marginLeft: 5 }} />
    </Tooltip>
  ) : (
    <></>
  );
  return (
    <Typography>
      {label}
      {chartIcon}
    </Typography>
  );
};

ReportTypeWrapper.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'name',
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    Wrapper: ReportName,
  },
  {
    id: 'status',
    value: ['status', 'testMode'],
    numeric: false,
    disablePadding: true,
    label: 'Status',
    Wrapper: StatusWrapper,
  },
  {
    id: 'module',
    value: ['module'],
    numeric: false,
    disablePadding: true,
    label: 'Module',
  },
  {
    id: 'reportType',
    value: ['reportType', 'chartCount'],
    numeric: false,
    disablePadding: true,
    label: 'Report Type',
    Wrapper: ReportTypeWrapper,
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  const [deleteReport] = useDeleteReportMutation();

  return [
    {
      name: 'Edit',
      icon: faEdit,
      onClick: (item) => {
        navigate(`/${REPORT_UPDATE(item.id)}`);
      },
    },
    {
      name: 'Details',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`/${REPORT_DETAILS(item.id)}`);
      },
    },
    {
      name: 'Clone',
      icon: faCopy,
      onClick: (item) => {
        navigate(`/${REPORT_CLONE(item.id)}`);
      },
    },
    {
      name: 'Delete',
      icon: faTrash,
      color: colors.RED,
      content: ({ onBackToMenu, item }) => {
        return (
          <DeleteConfirmMenuItem
            onConfirm={() => {
              deleteReport(item.id);
              if (onBackToMenu) {
                onBackToMenu();
              }
            }}
            onBackToMenu={onBackToMenu}
          />
        );
      },
    },
  ];
};
