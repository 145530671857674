import React, { useRef, useState, useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Grid, IconButton } from '@mui/material';
import { useTableData } from 'hooks';
import { useGetTransactionsQuery } from 'redux/rtk-query';
import { PropertyFlowCard } from './PropertyFlowCard'; // Adjust import path as needed

const StaticPropertyFlowCards = () => {
  // Fetch up to 10 transactions
  const { rows } = useTableData(
    useGetTransactionsQuery,
    [],
    'updatedAt',
    'desc',
    10,
  );
  const containerRef = useRef(null);

  // For horizontal scrolling arrow controls
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  // Track which card index is “hovered” in React state
  // Default to 0 (the first card)
  const [hoveredIndex, setHoveredIndex] = useState(0);

  // Check if the container can scroll left/right
  const updateScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft + container.clientWidth < container.scrollWidth,
      );
    }
  };

  useEffect(() => {
    updateScrollButtons();
  }, [rows]);

  const scrollByAmount = (amount) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: amount,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    updateScrollButtons();
  };

  // If the user leaves the entire container, revert to the first card
  const handleMouseLeaveContainer = () => {
    setHoveredIndex(0);
  };

  return (
    <Grid item xs={12} sx={{ position: 'relative', overflow: 'visible' }}>
      {/* Outer container: overflow: visible so children can “lift” without clipping */}
      <Box
        sx={{ position: 'relative', overflow: 'visible' }}
        onMouseLeave={handleMouseLeaveContainer}
      >
        {/* Left Gradient Overlay */}
        {canScrollLeft && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              paddingLeft: 1,
              top: 0,
              bottom: 0,
              width: 50,
              background: 'linear-gradient(to right, white, transparent)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() =>
                scrollByAmount(-containerRef.current.clientWidth / 2)
              }
              sx={{
                position: 'absolute',
                left: 8,
                zIndex: 2,
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'grey.200' },
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        )}

        {/* Right Gradient Overlay */}
        {canScrollRight && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              paddingRight: 1,
              top: 0,
              bottom: 0,
              width: 50,
              background: 'linear-gradient(to left, white, transparent)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              onClick={() =>
                scrollByAmount(containerRef.current.clientWidth / 2)
              }
              sx={{
                zIndex: 2,
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'grey.200' },
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}

        {/* Inner container: horizontal scroll only */}
        <Box
          ref={containerRef}
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto', // horizontal scrolling
            overflowY: 'hidden', // no vertical clipping
            scrollBehavior: 'smooth',
            width: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {rows.map((row, index) => (
            <Box
              key={row.id}
              // When mouse enters this Box, we set that card as hovered
              onMouseEnter={() => setHoveredIndex(index)}
              sx={{
                flex: '0 0 25%', // or 20% if you want 5 visible, etc.
                mr: -1,
                ml: index === 0 ? 0 : -1, // add margin to the left of all but the first card
                overflow: 'visible',
              }}
            >
              {/* Pass hovered prop to the card if it's the hoveredIndex */}
              <PropertyFlowCard row={row} hovered={hoveredIndex === index} />
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default StaticPropertyFlowCards;
