import React from 'react';
import PropTypes from 'prop-types';
import { faSquareEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField } from 'react-final-form';
import { Typography, Tooltip } from '@mui/material';
import { pathArrayToPathLabel } from 'helpers/report';

export const Path = ({ name }) => {
  const groupingFunctionValue = useField(`${name}.groupingFunction`).input
    .value;
  const dateSeparationValue = useField(`${name}.dateSeparation`).input.value;
  return (
    <Field name={`${name}.visualPath`}>
      {({ input }) => {
        const pathArray = input.value || [];
        const fullLabel = pathArrayToPathLabel(pathArray);
        const shortenedLabel = getShortenedLabel(pathArray);
        const isTruncated = pathArray.length > 2;

        return (
          <Tooltip title={isTruncated ? fullLabel : ''}>
            <Typography component="div" sx={{ whiteSpace: 'nowrap' }}>
              {shortenedLabel}
              {groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''}
              {dateSeparationValue ? ` - (${dateSeparationValue})` : ''}
            </Typography>
          </Tooltip>
        );
      }}
    </Field>
  );
};

Path.propTypes = {
  name: PropTypes.string.isRequired,
};

// Helper to produce a shortened label if there are more than 2 segments
function getShortenedLabel(pathArray) {
  if (!pathArray || pathArray.length === 0) {
    return '';
  }

  // If 2 or fewer segments, just show them all
  if (pathArray.length <= 2) {
    return pathArrayToPathLabel(pathArray);
  }

  // Otherwise, show "... -> lastSegment -> secondLastSegment"
  const lastTwo = pathArray.slice(-2);
  return (
    <>
      <FontAwesomeIcon style={{ marginRight: 8 }} icon={faSquareEllipsis} />
      {pathArrayToPathLabel(lastTwo)}
    </>
  );
}
