import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { EDIT_DOCUMENT } from 'consts';
import { useOpenDrawer } from 'hooks';
import { MainContent, MainSummary } from './styled';

export const MarketingItem = ({ marketing }) => {
  const openDrawer = useOpenDrawer();
  const routeParams = useParams();
  const handleEdit = () => {
    openDrawer(EDIT_DOCUMENT(marketing.id), routeParams['*']);
  };

  return (
    <MainContent>
      <MainSummary onClick={handleEdit}>
        {/*<MarketingItemIcon type={marketing.type} />*/}
        {marketing.name}
      </MainSummary>
    </MainContent>
  );
};

MarketingItem.propTypes = {
  marketing: PropTypes.object,
};
