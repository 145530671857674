import React from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

export const PropertyFlowCardSkeleton = ({ firstCard = false }) => (
  <Card
    sx={{
      width: 200,
      height: 200,
      transform: firstCard ? 'scale(.95)' : 'scale(0.85)',
      transition: 'transform 0.3s, box-shadow 0.3s',
      boxShadow: firstCard ? 3 : 'none',
      cursor: 'pointer',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        height: '40%',
        width: '100%',
        padding: 2,
      }}
    >
      <Skeleton width="100%" height="20px" />
      <Skeleton width="50%" height="20px" />
    </Box>
  </Card>
);

PropertyFlowCardSkeleton.propTypes = {
  firstCard: PropTypes.bool,
};
