import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmMenuItem } from 'components/Common';
import { STATUSES } from 'consts';
import { useUpdateDocumentMutation } from 'redux/rtk-query';

export const CancelEsignComponent = ({
  item,
  onBackToMenu,
  handleCloseMenu,
}) => {
  const [updateDocument] = useUpdateDocumentMutation();

  const onConfirm = () => {
    updateDocument({
      id: item.id,
      status: STATUSES.DRAFT,
      closedApproved: true,
    });
    handleCloseMenu(true);
  };

  return (
    <ConfirmMenuItem
      title={'Cancel E-sign?'}
      message={'Are you sure you want to cancel e-signing for this document?'}
      onConfirm={onConfirm}
      onCancel={onBackToMenu}
      confirmText={'Confirm'}
      confirmColor={'error'}
      cancelText={'Go Back'}
    />
  );
};

CancelEsignComponent.propTypes = {
  onBackToMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
  item: PropTypes.object,
};
