import React from 'react';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { Card, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModuleIcon, FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { useDrawer } from 'hooks';
import { NotesActivityTable } from 'pages/ActivityPages/ActivityPage/components';

const NotesHomeCard = () => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false);
  const iconFontSize = '2.5rem';
  const iconModule = 'Notes';
  const count = 5;
  const label = 'New Notes';

  return (
    <>
      <Grid item sm={12} md={6} lg={4}>
        <Card
          onClick={openDrawer}
          elevation={1}
          sx={{
            cursor: 'pointer',
            transition: 'transform 0.3s, boxShadow 0.3s',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: 3,
            },
          }}
        >
          <Box px={2} py={4} display="flex" justifyContent={'center'}>
            {/* Left side: Icon */}

            {/* Right side: count + label */}
            <Box
              p={1}
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h2">
                <ModuleIcon
                  fontSize={iconFontSize}
                  marginRight={10}
                  module={iconModule}
                />
                {count}
              </Typography>
              <Typography sx={{ whiteSpace: 'pre', mt: 1 }} variant="h4">
                {label}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <SideDrawer width={'60%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <HeadingSection>
          <Header variant="h4">Notes</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => closeDrawer()}
          />
        </HeadingSection>
        <Box p={2}>
          <NotesActivityTable noTitle />
        </Box>
      </SideDrawer>
    </>
  );
};

export default NotesHomeCard;
