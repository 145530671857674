// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Quicksand&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

.pac-container {
  z-index: 1300 !important;
}

html, body {
  font-size: 62.5%;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: "Quicksand", sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

div[data-lastpass-icon-root] {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;AAAF;;AAGA;EACE,wBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,YAAA;EACA,kBAAA;EACA,oCAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Quicksand&display=swap');\n\n* {\n  box-sizing: border-box;\n}\n\n.pac-container {\n  z-index: 1300 !important;\n}\n\nhtml, body {\n  font-size: 62.5%;\n}\n\nbody {\n  width: 100vw;\n  overflow-x: hidden;\n  font-family: 'Quicksand', sans-serif;\n}\n\n#root {\n  height: 100%;\n  width: 100%;\n}\n\ndiv[data-lastpass-icon-root] {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
