import React from 'react';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIconComp, MenuButton } from 'components/Common';
import { DocumentListFilters, DocumentListRefresh } from './components';

function DocumentCardToolbar() {
  return (
    <span>
      <DocumentListRefresh />
      <MenuButton
        size={'x-small'}
        title={<FontAwesomeIconComp fontSize={12} icon={faFilter} />}
      >
        <DocumentListFilters />
      </MenuButton>
    </span>
  );
}

export default DocumentCardToolbar;
