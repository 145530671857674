import React from 'react';
import PropTypes from 'prop-types';
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import { Tooltip } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { STATUSES, colors } from 'consts';
import { getStatus } from 'helpers';
import {
  DocumentItemBox,
  DocumentItemName,
  DocumentRowMenuWrapper,
  DocumentTreeItemBox,
} from '../styled';
import { AddDocumentButton } from './AddDocumentButton';
import { DocumentItemIcon } from './DocumentItemIcon';
import { EditDocumentButton } from './EditDocumentButton';

export function DocumentTreeItem({
  item,
  setSelected,
  onIconClick,
  expanded,
  ...other
}) {
  const FolderIcon = () => (
    <span onClick={() => onIconClick(`document-${item.id}`)}>
      {expanded ? (
        <FontAwesomeIconComp color={colors.GREY} icon={faFolderOpen} />
      ) : (
        <FontAwesomeIconComp color={colors.GREY} icon={faFolder} />
      )}
    </span>
  );

  const getDisplayName = () => {
    const hasCustomName = item.documentTemplate.categories.find(
      (category) =>
        category.name === 'Custom Name Optional' ||
        category.name === 'Custom Name Required',
    );
    if (hasCustomName) {
      return item.name;
    }
    if (item.documentTemplate.status === STATUSES.VOIDED) {
      const dateStr = item.documentTemplate.revisionDate;
      const date = DateTime.fromISO(dateStr, { zone: 'utc' });
      const formattedDate = date.toFormat('M/yy');
      return `${item.documentTemplate.name} (Rev ${formattedDate})`;
    }
    return item.documentTemplate.name;
  };

  return (
    <DocumentTreeItemBox
      icon={
        item.documentTemplate.type === 'Folder' ? (
          <FolderIcon />
        ) : (
          <Tooltip arrow title={getStatus(item)}>
            <span
              onClick={() => {
                onIconClick(`document-${item.id}`);
              }}
            >
              <DocumentItemIcon item={item} />
            </span>
          </Tooltip>
        )
      }
      label={
        <span
          onClick={() => {
            setSelected(`document-${item.id}`);
          }}
        >
          <DocumentItemBox>
            <DocumentItemName className={'document-name'}>
              {getDisplayName()}
              {item.documentTemplate.type === 'Folder' && (
                <AddDocumentButton item={item} />
              )}
            </DocumentItemName>
            {item.documentTemplate.type === 'File' && (
              <DocumentRowMenuWrapper className={'document-row-menu'}>
                <EditDocumentButton setSelected={setSelected} item={item} />
              </DocumentRowMenuWrapper>
            )}
          </DocumentItemBox>
        </span>
      }
      {...other}
    />
  );
}

DocumentTreeItem.propTypes = {
  item: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};
