import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import {
  Box,
  IconButton,
  Paper,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { ClickMarketingEditorDrawer } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/ClickMarketingEditorDrawer';
import {
  ButtonSection,
  Header,
  HeaderSubtitle,
  HeadingSection,
} from 'components/Styled';
import { conversion } from 'helpers';
import { useDrawer } from 'hooks';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { useGetDocumentQuery } from 'redux/rtk-query';
import DocumentEsignEditor from './components/DocumentEsignEditor';

export function DocumentEditorDrawer({ params, path }) {
  const { isOpen, closeDrawer } = useDrawer(true, path);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headerRef = React.useRef(null);
  const routeParams = useParams();
  const relatedModelType = routeParams.contactDetailsId
    ? 'contact'
    : 'transaction';

  const paramName = Object.keys(params)[0];
  const { data: documentData } = useGetDocumentQuery(
    {
      id: params[paramName],
      defaults: true,
    },
    {
      skip: !params[paramName],
    },
  );

  useResizeObserver(headerRef, (height) => {
    setHeaderHeight(height);
  });

  return (
    <SideDrawer
      width={'95%'}
      isOpen={isOpen}
      closeDrawer={closeDrawer}
      onOpened={() => {
        if (headerRef.current) {
          setHeaderHeight(headerRef.current.offsetHeight);
        }
      }}
    >
      <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div ref={headerRef}>
          <HeadingSection shadow backgroundColor={'#F7F7F7'}>
            <Box>
              <Header variant="h4">
                {documentData?.data?.name || <Skeleton width={300} />}
              </Header>
              <HeaderSubtitle>
                {conversion.getName(documentData?.data?.[relatedModelType]) || (
                  <Skeleton width={150} />
                )}
              </HeaderSubtitle>
            </Box>
            <ButtonSection>
              <IconButton onClick={closeDrawer}>
                <FontAwesomeIconComp size="xl" icon={faTimesCircle} />
              </IconButton>
            </ButtonSection>
          </HeadingSection>
        </div>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            maxHeight: `calc(100% - ${headerHeight}px)`,
            position: 'relative',
          }}
        >
          {documentData?.data ? (
            <>
              {documentData?.data?.documentTemplate?.fileType ===
              'Click Marketing' ? (
                <ClickMarketingEditorDrawer
                  headerHeight={headerHeight}
                  documentData={documentData?.data}
                />
              ) : (
                <DocumentEsignEditor
                  headerHeight={headerHeight}
                  documentData={documentData?.data}
                />
              )}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                bgcolor: 'white',
              }}
            >
              <CircularProgress thickness={2} size={'8rem'} />
            </Box>
          )}
        </Box>
      </Paper>
    </SideDrawer>
  );
}

DocumentEditorDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string,
};
