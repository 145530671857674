import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Divider, Fade } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard } from 'components/Common';
import { REPORT_DETAILS } from 'consts';
import { ReportChart } from 'pages/ReportPages/DetailsReportPage/ReportChart';
import { useDashboard } from 'pages/ReportPages/ReportPage/context/DashboardContext';
import ReportDrawer from 'pages/ReportPages/ReportPage/ReportDrawer';

const Column = ({ col, row, dashboard }) => {
  const navigate = useNavigate();
  const { updateDashboard, setActiveDashboard, isEditMode } = useDashboard();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedReport, setSelectedReport] = useState(col.report || null);
  const [selectedChart, setSelectedChart] = useState(col.chartId || null);
  const [isHovered, setIsHovered] = useState(false); // ✅ Track hover state

  // ✅ Assign report to the correct column
  const handleSelectReport = (report) => {
    setSelectedReport(report);
  };

  // ✅ Assign chart to the correct column
  const handleChartSelect = (chartId) => {
    setSelectedChart(chartId);

    // Update the dashboard state
    const updatedRows = dashboard.rows.map((r) =>
      r.id === row.id
        ? {
            ...r,
            columns: r.columns.map((c) =>
              c.id === col.id ? { ...c, report: selectedReport, chartId } : c,
            ),
          }
        : r,
    );

    updateDashboard(dashboard.id, { ...dashboard, rows: updatedRows });
    setActiveDashboard({ ...dashboard, rows: updatedRows });
    setOpenDrawer(false);
  };

  return (
    <Box
      sx={{ position: 'relative', width: '100%' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* ✅ Floating Buttons (Appear on Hover) */}
      {isEditMode && (
        <Fade in={isHovered}>
          <Box
            className={'move-column'}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              width: '100%',
              height: '100%',
              gap: 1, // ✅ Ensures buttons are side by side
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              padding: '4px 8px',
              borderRadius: '8px',
              zIndex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Change Report Button */}
            <Button
              sx={{
                height: '50px',
              }}
              startIcon={<EditIcon />}
              onClick={() => setOpenDrawer(true)}
              variant={'outlined'}
            >
              {selectedChart ? 'Change' : 'Select'} Chart
            </Button>
          </Box>
        </Fade>
      )}

      {/* Render the selected chart */}
      <AbeCard
        forceOpen
        hideArrow
        title={
          !selectedReport ? (
            <>Select Report</>
          ) : (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(`/${REPORT_DETAILS(selectedReport.id)}`);
                setActiveDashboard(null);
              }}
            >
              {selectedReport.name}
            </Box>
          )
        }
      >
        <Divider />
        <Box
          p={2}
          sx={{
            width: '100%',
            minWidth: 0, // <-- Key: allows child to shrink
            flexShrink: '1', // or flexGrow: 1
            position: 'relative',
          }}
        >
          {selectedReport && selectedChart ? (
            <ReportChart reportId={selectedReport.id} chartId={selectedChart} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <p>Select a report and chart to display</p>
            </Box>
          )}
        </Box>
      </AbeCard>

      {/* Drawer for selecting report & charts */}
      <ReportDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onSelectReport={handleSelectReport}
        handleChartSelect={handleChartSelect}
      />
    </Box>
  );
};

export default Column;

Column.propTypes = {
  col: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
};
