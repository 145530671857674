import React from 'react';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { CollapsableMenuGroup, FontAwesomeIconComp } from 'components/Common';
import ShowGridMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ShowGridMenuItem';
import ZoomMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/ZoomMenuItem';

export const ViewMenuGroup = () => {
  return (
    <CollapsableMenuGroup
      label={'View'}
      hideChevron
      icon={<FontAwesomeIconComp fontSize={18} icon={faEye} />}
    >
      <ZoomMenuItem />
      <ShowGridMenuItem />
    </CollapsableMenuGroup>
  );
};
