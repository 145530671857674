import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CardWithEdit, DetailsPanels } from 'components/Common';
import { UPDATE_SETTING } from 'consts';
import { useGetSettingQuery } from 'redux/rtk-query';
import { ProfileBulletListDisplay } from './components/ProfileBulletListDisplay';

const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '175px',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '4em',
    background: `linear-gradient(to bottom, rgba(255,255,255,0), ${theme.palette.background.paper} 100%)`,
  },
}));

function Bio({ bio }) {
  const sanitizedBio = DOMPurify.sanitize(bio);

  return (
    <StyledBox>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{ __html: sanitizedBio }}
      />
    </StyledBox>
  );
}

Bio.propTypes = {
  bio: PropTypes.string,
};

export const SettingProfile = () => {
  let { settingDetailsId } = useParams();

  const { data: settingData } = useGetSettingQuery({ id: settingDetailsId });

  const bio = settingData?.data?.bio;
  const achievements = settingData?.data?.achievements;
  const designations = settingData?.data?.designations;
  const communityInvolvement = settingData?.data?.communityInvolvement;

  return (
    <Grid item xs={6}>
      <CardWithEdit link={UPDATE_SETTING(settingDetailsId, 'Profile')}>
        <DetailsPanels
          data={settingData?.data}
          panels={{
            Profile: [
              'title',
              'emailAddress',
              'phoneNumber',
              'officeNumber',
              'officeLocation',
            ],
          }}
        />
        <Box px={2}>
          <Typography fontWeight={700} variant="subtitle2">
            Bio:
          </Typography>
          <Bio bio={bio} />
          <Typography fontWeight={700} variant="subtitle2">
            Achievements:
          </Typography>
          <ProfileBulletListDisplay list={achievements} />
          <Typography fontWeight={700} variant="subtitle2">
            Designations:
          </Typography>
          <ProfileBulletListDisplay list={designations} />
          <Typography fontWeight={700} variant="subtitle2">
            Community Involvements:
          </Typography>
          <ProfileBulletListDisplay list={communityInvolvement} />
        </Box>
      </CardWithEdit>
    </Grid>
  );
};
