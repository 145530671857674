import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { useForm, useFormState } from 'react-final-form';
import { ReactSortable } from 'react-sortablejs';
import { Alert } from '@mui/lab';
import { Button, Box } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { getLastPathName } from 'helpers/report';
import Column from 'pages/ReportPages/CreateReportPage/ReportColumnsPage/Column';
import ReportFiltersTree from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersTree';

const ReportColumnsPage = ({ input }) => {
  const [showColumnDrawer, setShowColumnDrawer] = React.useState(false);
  const fieldName = input.name;
  const form = useForm();
  const { submitError } = useFormState();
  const columnsState = form.getState().values[fieldName] || [];
  const reportType = form.getState().values.reportType;

  // 1) Grab the "groupings" from the form. Adjust the path if it's different in your data.
  const groupings = form.getState().values.groupings || [];

  // 2) useEffect to copy groupings => columns if columns are empty on mount
  React.useEffect(() => {
    if (columnsState.length === 0 && groupings.length > 0) {
      // Map each grouping into a column structure
      const newColumns = groupings.map((grp, idx) => {
        // For example, if grp.path is an array of field names
        // or if grp has a "name" property
        const label = grp.name || getLastPathName(grp);
        console.log('grp', grp);
        return {
          id: Date.now().toString() + '-' + idx,
          name: label,
          path: grp.path || [],
          visualPath: grp.path || [],
          order: idx,
          groupingFunction: grp.groupingFunction || null,
          dateSeparation: grp.dateSeparation || null,
        };
      });

      form.change(fieldName, newColumns);
      // optionally clear submit error
      form.mutators?.clearSubmitError?.();
    }
  }, []);
  // empty dependency so it only runs once on mount

  const onHandleSelect = (selectedColumn) => {
    const id = Date.now().toString(); // Or use a UUID generator
    const label = getLastPathName(selectedColumn);
    const newColumn = {
      id,
      name: label,
      path: selectedColumn.label.split(' -> '),
      visualPath: selectedColumn.label.split(' -> '),
      order: columnsState.length,
      groupingFunction: selectedColumn.groupingFunction,
      dateSeparation: selectedColumn.dateSeparation,
    };
    const updatedColumns = [...columnsState, newColumn];
    form.change(fieldName, updatedColumns);
    form.mutators.clearSubmitError();
  };

  const handleColumnsSort = (newColumns) => {
    const updatedColumns = newColumns.map((column, index) => ({
      ...column,
      order: index,
    }));
    form.change(fieldName, updatedColumns);
  };

  const removeColumn = (index) => () => {
    const updatedColumns = columnsState.filter((_, i) => i !== index);
    const reorderedColumns = updatedColumns.map((column, idx) => ({
      ...column,
      order: idx,
    }));
    form.change(fieldName, reorderedColumns);
  };

  const treeType =
    fieldName === 'drilldowns'
      ? 'drilldown'
      : reportType === 'chart'
        ? 'groupedColumns'
        : 'columns';

  return (
    <>
      <div>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <ReactSortable
          list={columnsState}
          setList={handleColumnsSort}
          animation={200}
        >
          {columnsState.map((column, index) => (
            <Box key={column.id} sx={{ px: 3, pb: 2 }}>
              <Column
                name={`${fieldName}[${index}]`}
                removeColumn={removeColumn(index)}
              />
            </Box>
          ))}
        </ReactSortable>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowColumnDrawer(true)}
            sx={{ ml: 2 }}
          >
            Add Column
          </Button>
        </Box>
      </div>
      <SideDrawer
        width="400px"
        isOpen={showColumnDrawer}
        closeDrawer={() => setShowColumnDrawer(false)}
        closeOnClickAway={() => setShowColumnDrawer(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
        }}
      >
        <HeadingSection>
          <Header variant="h4">Select Column</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowColumnDrawer(false)}
          />
        </HeadingSection>
        <ReportFiltersTree onSelect={onHandleSelect} type={treeType} />
      </SideDrawer>
    </>
  );
};

ReportColumnsPage.propTypes = {
  input: PropTypes.object,
};

export default ReportColumnsPage;
