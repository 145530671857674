import React from 'react';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, MenuItemButton } from 'components/Common';
import { usePreviewMode } from 'components/ModelViews/Documents/DocumentPdfViewer/hooks/usePreviewMode';
import { manageTemplate, setMenu } from 'redux/slices';
import { BottomMenuButtons } from '../BottomMenuButtons';

export function FieldManagerMenu() {
  const dispatch = useDispatch();
  const [action, setAction] = React.useState(null);
  const [includeTextCheckboxes, setIncludeTextCheckboxes] =
    React.useState(false);
  const [includeSignatureInitials, setIncludeSignatureInitials] =
    React.useState(false);
  usePreviewMode();

  const confirmDisabled =
    !action || (!includeTextCheckboxes && !includeSignatureInitials);

  return (
    <>
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faAlignCenter} />}
        label={'Field Manager'}
        isSelected
      />
      <Box p={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant="h6">Step 1</Typography>
          </FormLabel>
          <Typography variant="body2">
            Choose if you want to add or remove.
          </Typography>
          <div
            style={{
              marginTop: '8px',
            }}
          >
            <ButtonGroup fullWidth>
              <Button
                onClick={() => {
                  setAction('removeFields');
                }}
                variant={action === 'removeFields' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
              >
                Remove Fields
              </Button>
              <Button
                onClick={() => {
                  setAction('addFields');
                }}
                variant={action === 'addFields' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
              >
                Add Fields
              </Button>
            </ButtonGroup>
          </div>
        </FormControl>
      </Box>
      <Divider />
      <Box p={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant="h6">Step 2</Typography>
          </FormLabel>
          <Typography variant="body2">
            Choose the fields that you want to add or remove.
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeTextCheckboxes}
                  onChange={(e, checked) => {
                    setIncludeTextCheckboxes(checked);
                  }}
                  color="primary"
                />
              }
              label="Text & Checkbox Fields"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeSignatureInitials}
                  onChange={(e, checked) => {
                    setIncludeSignatureInitials(checked);
                  }}
                  color="primary"
                />
              }
              label="Signature & Initial Fields"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <BottomMenuButtons>
        <Tooltip
          placement={'top'}
          arrow
          title={
            confirmDisabled && (
              <div
                style={{
                  color: 'white',
                }}
              >
                Select an option from Step 1 and Step 2
              </div>
            )
          }
        >
          <span style={{ backgroundColor: 'transparent' }}>
            <Button
              size="medium"
              variant="outlined"
              disabled={confirmDisabled}
              style={{ marginRight: '3px' }}
              onClick={() => {
                dispatch(
                  manageTemplate({
                    action,
                    includeTextCheckboxes,
                    includeSignatureInitials,
                  }),
                );
                dispatch(setMenu('primary'));
              }}
            >
              Confirm
            </Button>
          </span>
        </Tooltip>
        <Button
          size="medium"
          variant="outlined"
          style={{ marginRight: '3px' }}
          onClick={() => {
            dispatch(setMenu('primary'));
          }}
        >
          Cancel
        </Button>
      </BottomMenuButtons>
    </>
  );
}
