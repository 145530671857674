import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { RoleHeadIcon } from 'components/Icons';
import { DETAILS_CONTACT } from 'consts';
import { conversion } from 'helpers';

export const RoleRow = ({ role, showOrder }) => {
  const contactName = role.contact
    ? conversion.getName(role.contact)
    : role.contactName;

  return (
    <Box px={2} py={0.5}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <RoleHeadIcon role={role} />
        </Grid>
        <Grid item xs>
          {role.contact && role.contact.id ? (
            <Typography
              variant="subtitle2"
              component={Link}
              href={`/${DETAILS_CONTACT(role.contact.id)}`}
              underline={'hover'}
              target="_blank"
            >
              {contactName}
            </Typography>
          ) : (
            <Typography variant="subtitle2">{contactName}</Typography>
          )}
        </Grid>
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Typography variant="subtitle2" color="textSecondary">
            {role.role} {showOrder ? role.order : ''}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

RoleRow.propTypes = {
  role: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  showOrder: PropTypes.bool,
};
