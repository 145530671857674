import React, { useEffect } from 'react';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-final-form';
import { ReactSortable } from 'react-sortablejs';
import { Button, Box } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { conversion } from 'helpers';
import ReportFiltersTree from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersTree';
import Sorting from 'pages/ReportPages/CreateReportPage/ReportSortingsPage/Sorting';

const ReportSortingsPage = () => {
  const [showSortingDrawer, setShowSortingDrawer] = React.useState(false);
  const [sortingsState, setSortingsState] = React.useState([]);
  const form = useForm();

  const sortings = form.getState().values['sortings'] || [];

  console.log('sortings', sortings);
  useEffect(() => {
    if (sortings) {
      setSortingsState(sortings);
    }
  }, [sortings]);

  const onHandleSelect = (selectedSorting) => {
    const id = Date.now().toString(); // Or use a UUID generator
    const newSorting = {
      id,
      name: conversion.camelCaseToWords(selectedSorting.name),
      path: selectedSorting.label.split(' -> '),
      visualPath: selectedSorting.label.split(' -> '),
      order: sortingsState.length,
      groupingFunction: selectedSorting.groupingFunction,
      dateSeparation: selectedSorting.dateSeparation,
      direction: 'ASC',
    };
    const updatedSortings = [...sortingsState, newSorting];
    setSortingsState(updatedSortings);
    form.change('sortings', updatedSortings);
  };

  const handleSortingsSort = (newSortings) => {
    if (!newSortings.length) return;
    const updatedSortings = newSortings.map((sorting, index) => ({
      ...sorting,
      order: index,
    }));
    setSortingsState(updatedSortings);
    form.change('sortings', updatedSortings);
  };

  const removeSorting = (index) => () => {
    const updatedSortings = sortingsState.filter((_, i) => i !== index);
    const reorderedSortings = updatedSortings.map((sorting, index) => ({
      ...sorting,
      order: index,
    }));
    setSortingsState(reorderedSortings);
    form.change('sortings', reorderedSortings);
  };

  return (
    <>
      <div>
        <ReactSortable
          list={sortingsState}
          setList={handleSortingsSort}
          animation={200}
        >
          {sortingsState.map((sorting, index) => (
            <Box key={sorting.id} sx={{ px: 3 }}>
              <Sorting
                name={`sortings[${index}]`}
                removeSorting={removeSorting(index)}
              />
            </Box>
          ))}
        </ReactSortable>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowSortingDrawer(true)}
            sx={{ ml: 2 }}
          >
            Add Sorting
          </Button>
        </Box>
      </div>
      <SideDrawer
        width="400px"
        isOpen={showSortingDrawer}
        closeDrawer={() => setShowSortingDrawer(false)}
        closeOnClickAway={() => setShowSortingDrawer(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
        }}
      >
        <HeadingSection>
          <Header variant="h4">Select Sorting</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowSortingDrawer(false)}
          />
        </HeadingSection>
        <ReportFiltersTree type={'sortings'} onSelect={onHandleSelect} />
      </SideDrawer>
    </>
  );
};

export default ReportSortingsPage;
