// ConditionGroup.js
import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Button, MenuItem, Select, IconButton, Grid } from '@mui/material';
import { SideDrawer, FontAwesomeIconComp } from 'components/Common';
import HoverReveal from 'components/Common/HoverReveal/HoverReveal';
import { Header, HeadingSection } from 'components/Styled';
import { conversion } from 'helpers';
import { FilterCompareCondition } from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters/Trigger';
import Trigger from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters/Trigger/Trigger';
import ReportFiltersTree from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersTree';

const ConditionGroup = ({ name, showGroupCondition, onRemoveGroup }) => {
  const form = useForm();
  const [showFilterDrawer, setShowFilterDrawer] = React.useState(false);

  const handleFilterSelect = (selectedFilter) => {
    form.mutators.push(`${name}.filters`, {
      name: conversion.camelCaseToWords(selectedFilter.name),
      path: selectedFilter.label.split(' -> '),
      condition: 'Equals',
      value: '',
      type: selectedFilter.type,
      visualPath: selectedFilter.label.split(' -> '),
      enum: selectedFilter.enum,
    });
  };

  return (
    <>
      <HoverReveal
        hoverContent={
          <IconButton color="error" onClick={onRemoveGroup}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        sx={{
          flexDirection: 'column',
        }}
      >
        <Box
          className="condition-group"
          sx={{
            display: 'flex',
            flexWrap: 'wrap', // Allow wrapping to prevent overflow
            alignItems: 'center',
            padding: 2,
            border: '1px solid #ddd',
            borderRadius: 2,
            backgroundColor: '#f5f5f5',
            position: 'relative',
            marginBottom: 3,
          }}
        >
          {showGroupCondition && (
            <Box
              sx={{
                position: 'absolute',
                top: '-32px',
                left: 0,
              }}
            >
              <Field name={`${name}.groupingCondition`} defaultValue="And">
                {({ input }) => (
                  <Select
                    {...input}
                    IconComponent={() => null}
                    sx={{
                      marginLeft: '16px',
                      padding: '8px',
                      fontSize: '18px',
                      fontWeight: 600,
                      background: 'white',
                      borderRadius: 8,
                      borderWidth: 2,
                      zIndex: 7,
                      minWidth: '76px',
                      textAlign: 'center',
                      '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input':
                        {
                          padding: '2px',
                          paddingRight: '2px',
                        },
                    }}
                  >
                    <MenuItem value="And">AND</MenuItem>
                    <MenuItem value="Or">OR</MenuItem>
                  </Select>
                )}
              </Field>
            </Box>
          )}

          {/* Filters */}
          <FieldArray name={`${name}.filters`}>
            {({ fields }) => (
              <Grid container spacing={[3, 3, 3, 0]}>
                {fields.map((filterName, index) => (
                  <Grid
                    item
                    key={filterName}
                    xs={12}
                    sm="auto"
                    sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
                  >
                    {index > 0 && <FilterCompareCondition name={filterName} />}
                    <Trigger
                      name={filterName}
                      showCondition={index > 0}
                      onRemove={() => fields.remove(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </FieldArray>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              mr: 4,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowFilterDrawer(true)}
            >
              Add Filter
            </Button>
          </Box>
        </Box>
      </HoverReveal>

      {/* SideDrawer for selecting filters */}
      <SideDrawer
        width={'400px'}
        isOpen={showFilterDrawer}
        closeDrawer={() => setShowFilterDrawer(false)}
        closeOnClickAway={() => setShowFilterDrawer(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
        }}
      >
        <HeadingSection>
          <Header variant="h4">Select Filter</Header>
          <FontAwesomeIconComp
            icon={faTimes}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowFilterDrawer(false)}
          />
        </HeadingSection>
        <ReportFiltersTree type={'filters'} onSelect={handleFilterSelect} />
      </SideDrawer>
    </>
  );
};

ConditionGroup.propTypes = {
  name: PropTypes.string.isRequired,
  showGroupCondition: PropTypes.bool.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
};

export default ConditionGroup;
