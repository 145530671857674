import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
  useCallback,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  Box,
  IconButton,
} from '@mui/material';
import { useTableData } from 'hooks';
import { ExtraParamOptionsContext } from 'pages/ReportPages/ReportPage/context/ExtraParamOptionsContext';
import {
  useGetReportDetailsMutation,
  useGetColumnsQuery,
  useGetGroupingsQuery,
  useGetReportSummationDetailsMutation,
} from 'redux/rtk-query';
import ReportTableHead from './ReportTableHead';
import ReportTableRow from './ReportTableRow';

const ReportTable = ({ reportId }) => {
  const getReportDetails = useGetReportDetailsMutation();
  // (Unused summationRows state remains, but you may remove it if unnecessary)
  // eslint-disable-next-line no-unused-vars
  const [summationRows, setSummationRows] = useState([]);
  const [getReportSummationDetails] = useGetReportSummationDetailsMutation();
  const { extraParamOptions } = useContext(ExtraParamOptionsContext);

  // Memoize extraParamOptions so that its reference only changes when its content changes.
  const memoizedExtraParamOptions = useMemo(
    () => extraParamOptions,
    [JSON.stringify(extraParamOptions)],
  );

  const {
    rows,
    setPage,
    totalCount,
    loading,
    order,
    orderBy,
    filterValues,
    search,
  } = useTableData(
    getReportDetails,
    [],
    'createdAt',
    'asc',
    10,
    memoizedExtraParamOptions,
    {},
  );

  const { data: columnsData } = useGetColumnsQuery(reportId, {
    skip: !reportId,
  });
  const { data: groupingsData } = useGetGroupingsQuery(reportId, {
    skip: !reportId,
  });

  // Memoize the boolean flag for groupings.
  const hasGroupings = useMemo(() => {
    return groupingsData?.meta?.total > 0;
  }, [groupingsData?.meta?.total]);

  // Memoize columns and groupingPaths so that their references are stable.
  const columns = useMemo(() => columnsData?.data || [], [columnsData?.data]);
  const groupingPaths = useMemo(() => {
    return groupingsData?.data
      ? groupingsData.data.map((group) => JSON.stringify(group.visualPath))
      : [];
  }, [groupingsData?.data]);

  // Create memoized versions of the table and drill-down columns.
  const tableColumns = useMemo(() => {
    return columns
      .filter(
        (column) =>
          column.groupingFunction ||
          column.dateSeparation ||
          groupingPaths.includes(JSON.stringify(column.visualPath)),
      )
      .sort((a, b) => a.order - b.order);
  }, [columns, groupingPaths]);

  const drillDownColumns = useMemo(() => {
    return columns
      .filter(
        (column) =>
          !column.groupingFunction &&
          !column.dateSeparation &&
          !groupingPaths.includes(JSON.stringify(column.visualPath)),
      )
      .sort((a, b) => a.order - b.order);
  }, [columns, groupingPaths]);

  const [displayedRows, setDisplayedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  // Use a ref to track previous filter values rather than state.
  const prevFiltersRef = useRef({ filterValues, order, orderBy, search });

  useEffect(() => {
    const currentFilters = prevFiltersRef.current;
    const filtersChanged =
      JSON.stringify(filterValues) !==
        JSON.stringify(currentFilters.filterValues) ||
      order !== currentFilters.order ||
      orderBy !== currentFilters.orderBy ||
      search !== currentFilters.search;

    if (filtersChanged) {
      // Reset displayedRows when filters, sorting, or search change.
      setDisplayedRows(rows);
      prevFiltersRef.current = { filterValues, order, orderBy, search };
    } else {
      // Accumulate rows when the page increases.
      setDisplayedRows((prevRows) => [...prevRows, ...rows]);
    }
  }, [rows, filterValues, order, orderBy, search]);

  useEffect(() => {
    // Reset displayed rows if extra parameters change.
    setDisplayedRows([]);
  }, [memoizedExtraParamOptions]);

  // Memoize the callback for toggling row expansion.
  const toggleRowExpansion = useCallback((index) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }, []);

  // Memoize the "see more" callback.
  const handleSeeMore = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, [setPage]);

  // Trigger the report summation details effect when reportId changes.
  useEffect(() => {
    const handleGetReportSummationDetails = async () => {
      const reportSummationDetails = await getReportSummationDetails({
        reportId,
      });
      console.log('reportSummationDetails', reportSummationDetails);
    };
    handleGetReportSummationDetails();
  }, [reportId, getReportSummationDetails]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        mb: 4,
        borderRadius: 3,
      }}
    >
      <Table
        aria-label="dynamic report table"
        sx={{
          '& .MuiTableCell-root': {
            py: 1,
            px: 2,
            border: 'none',
          },
          '& .group-details-row > .MuiTableCell-root': {
            mx: 2,
            px: 0,
          },
          '& .MuiTableCell-paddingCheckbox + .MuiTableCell-root': {
            pl: 0,
          },
        }}
      >
        <ReportTableHead
          mainHeader
          columns={hasGroupings ? tableColumns : drillDownColumns}
          hasGroupings={hasGroupings && drillDownColumns.length > 0}
        />
        <TableBody>
          {displayedRows.map((row, index) => (
            <ReportTableRow
              key={row.id || index}
              row={row}
              tableColumns={hasGroupings ? tableColumns : drillDownColumns}
              drillDownColumns={hasGroupings ? drillDownColumns : []}
              isExpanded={expandedRows[index]}
              onToggleExpand={() => toggleRowExpansion(index)}
              reportId={reportId}
            />
          ))}
        </TableBody>
      </Table>
      {!loading && displayedRows.length < totalCount && (
        <Box textAlign="center">
          <IconButton variant="contained" onClick={handleSeeMore}>
            <FontAwesomeIcon icon={faChevronDown} />
          </IconButton>
        </Box>
      )}
    </TableContainer>
  );
};

ReportTable.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default memo(ReportTable);
