import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { ModuleIcon } from 'components/Common';
import { selectedActivitiesSelector } from 'redux/selectors';
import { setSelectedActivity } from 'redux/slices';
import { ActivityCenterToolbarWrapper } from '../../styled';
import { ActivityFilters } from '../ActivityFilters';

const activities = [
  { name: 'Tasks', module: 'Tasks' },
  { name: 'Notes', module: 'Notes' },
  { name: 'Emails', module: 'Emails' },
];

export const ActivityCenterToolbar = () => {
  const selectedActivity = useSelector(selectedActivitiesSelector);
  const dispatch = useDispatch();

  const handleSelectActivity = (activity) => {
    dispatch(setSelectedActivity(activity));
  };

  return (
    <ActivityCenterToolbarWrapper>
      <Grid item xs={6}>
        {activities.map((activity) => (
          <Button
            key={activity.name}
            sx={{ mr: 1 }}
            className={selectedActivity === activity.name ? 'selected' : ''}
            onClick={() => handleSelectActivity(activity.name)}
            variant="text"
          >
            <ModuleIcon module={activity.module} />
            {activity.name}
          </Button>
        ))}
      </Grid>
      <Grid item textAlign={'right'} xs={6}>
        <ActivityFilters />
      </Grid>
    </ActivityCenterToolbarWrapper>
  );
};
