import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { CardWithEdit, DetailsPanels } from 'components/Common';
import { DetailsBox, DetailsWrapper } from 'components/Styled';
import { UPDATE_SETTING } from 'consts';
import { useGetSettingQuery } from 'redux/rtk-query';

const formatTypes = {
  hireDate: 'date',
  licenseDate: 'date',
  dehireDate: 'date',
  hireAnniversaryDate: 'date',
  officeSetting: 'model',
  teamSetting: 'model',
  assistantSetting: 'model',
  brokerSetting: 'model',
  complianceCoordinatorSetting: 'model',
  officeAdminSetting: 'model',
  marketingCoordinatorSetting: 'model',
};

export const SettingsOverview = () => {
  let { settingDetailsId } = useParams();
  const { data: settingData } = useGetSettingQuery({ id: settingDetailsId });

  return (
    <DetailsWrapper>
      <CardWithEdit link={UPDATE_SETTING(settingDetailsId, 'Overview')}>
        <DetailsBox>
          <Box width={'100%'}>
            <DetailsPanels
              formatTypes={formatTypes}
              data={settingData?.data}
              panels={{
                Overview: [
                  'name',
                  'settingType',
                  'officeSetting',
                  'teamSetting',
                  'agentStatus',
                  'hireDate',
                  'licenseDate',
                  'assistantSetting',
                  'dehireDate',
                  'hireAnniversaryDate',
                  'rankAs',
                  'licenseNumber',
                  'brokerSetting',
                  'complianceCoordinatorSetting',
                  'officeAdminSetting',
                  'marketingCoordinatorSetting',
                ],
              }}
            />
          </Box>
        </DetailsBox>
      </CardWithEdit>
    </DetailsWrapper>
  );
};

SettingsOverview.propTypes = {};
