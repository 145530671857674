import React from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';

export const CreateButton = ({
  path,
  size = 'small',
  title,
  onClick,
  icon = <FontAwesomeIconComp icon={faPlus} />,
  noIcon = false,
  ...rest
}) => {
  return (
    <Button
      size={size}
      variant="outlined"
      endIcon={noIcon ? null : icon}
      component={Link}
      to={path ? path : null}
      onClick={onClick}
      {...rest}
    >
      {title}
    </Button>
  );
};

CreateButton.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  size: PropTypes.string,
  noIcon: PropTypes.bool,
};
