import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ChartForm from 'pages/ReportPages/DetailsReportPage/ChartForm';
import { ChartView } from 'pages/ReportPages/DetailsReportPage/ChartView';

const ReportConfig = ({ reportId }) => {
  // const [addChart, setAddChart] = React.useState(false);
  return (
    <Grid container>
      <Grid item xs={6}>
        <ChartForm reportId={reportId} />
      </Grid>
      <Grid item xs={6}>
        <ChartView reportId={reportId} />
      </Grid>
    </Grid>
  );
};

export default ReportConfig;

ReportConfig.propTypes = {
  reportId: PropTypes.string.isRequired,
};
