import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { IconButton, createFilterOptions } from '@mui/material';
import AutocompleteField from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { Required, ColorChip } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { BoxInput, ErrorMsg, TextInputField } from 'components/Styled';
import { conversion, hasValue } from 'helpers';
import { useFormValidationProcess, useIsRequired } from 'hooks';

export const Autocomplete = ({
  label,
  size,
  creatable,
  placeholder,
  variant,
  options,
  input,
  meta,
  isLoading,
  emptyButton,
  loadMoreResults,
  onOpen,
  handleSearch,
  autoFocus,
  tooltip,
  readOnly,
  notClearable,
  endAdornment,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [selectedValue, setSelectedValue] = useState(input.value || null);
  const filter = createFilterOptions();

  const error = meta.error || meta.submitError;
  const isFilled = hasValue(input.value);
  let isRequired = (useIsRequired(input.name) && !isFilled) || required;
  const validateFormData = useFormValidationProcess(); // Use the custom hook

  if (input.name) {
    let form = useForm();
    const field = form.getFieldState(input.name);
    if (field && field.data && field.data.tooltip) {
      tooltip = field.data.tooltip;
    }
  }

  useEffect(() => {
    if ((input.value && !selectedValue) || selectedValue?.length === 0) {
      setSelectedValue(input.value);
    }
  }, [input.value, selectedValue]);

  useEffect(() => {
    // Update currentOptions to ensure selectedValue is included
    if (selectedValue) {
      const isValuePresent = options.some(
        (option) => option.value === selectedValue.value,
      );

      if (!isValuePresent) {
        setCurrentOptions([selectedValue, ...options]);
      } else {
        setCurrentOptions(options);
      }
    } else {
      setCurrentOptions(options);
    }
  }, [options, selectedValue]);

  const getOptionLabel = (option, forTag = false) => {
    if (typeof option === 'string') {
      return option;
    } else if (!forTag && option.addLabel) {
      return option.addLabel;
    } else if (option.inputValue) {
      return option.inputValue;
    } else if (option.label) {
      return option.label;
    } else if (option.name) {
      return option.name;
    } else if (option.lastName || option.companyName) {
      return conversion.getName(option);
    } else if (Object.keys(option).length === 0) {
      return '';
    } else if (option.value) {
      return option.value;
    }
    return '';
  };

  const getEndAdornment = (input) => {
    let hasValue =
      input.value &&
      input.value.id &&
      input.value.id.toString().length > 0 &&
      parseInt(input.value.id) >= 0;
    let clearIcon =
      hasValue && !notClearable ? (
        <span>
          <IconButton
            onClick={() => {
              setSelectedValue(null); // Clear selected value
              input.onChange({ id: '', name: '' });
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            edge="end"
          >
            <i className={'fal fa-times'} style={{ color: '#c9232d' }} />
          </IconButton>
        </span>
      ) : (
        <></>
      );
    return (
      <InputAdornment position="end">
        {endAdornment ? (
          <>
            {endAdornment}
            {clearIcon}
          </>
        ) : (
          <>{clearIcon}</>
        )}
      </InputAdornment>
    );
  };

  return (
    <BoxInput>
      <AutocompleteField
        {...input}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <ColorChip
              key={option.key || index}
              chipColor={option.color}
              variant="outlined"
              label={getOptionLabel(option, true)}
              {...getTagProps({ index })}
            />
          ))
        }
        open={isOpen}
        options={currentOptions}
        value={selectedValue}
        onBlur={() => {
          setIsOpen(false);
        }}
        onChange={(event, newValue) => {
          setSelectedValue(newValue); // Update the state with the selected value
          if (typeof newValue === 'string') {
            input.onChange({
              value: null,
              label: newValue,
            });
          } else if (newValue?.id) {
            input.onChange({
              value: newValue.value,
              label: newValue.label,
              id: newValue.id,
            });
          } else if (newValue?.inputValue) {
            input.onChange({
              value: newValue.inputValue,
              label: newValue.inputValue,
            });
          } else if (newValue?.value) {
            input.onChange({
              value: newValue.value,
              label: newValue.label,
            });
          } else {
            input.onChange(newValue);
          }
          validateFormData();
          if (!input.multiple) {
            setIsOpen(false);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value; // Ensure equality check based on unique property
        }}
        forcePopupIcon={false}
        size={size}
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title,
          );
          if (creatable && inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              value: inputValue,
              addLabel: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        onOpen={onOpen}
        loading={isLoading}
        getOptionLabel={(option) => {
          return getOptionLabel(option);
        }}
        ListboxProps={
          loadMoreResults
            ? {
                onScroll: (event) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight >=
                    listboxNode.scrollHeight
                  ) {
                    loadMoreResults();
                  }
                },
              }
            : null
        }
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => {
          return (
            <TextInputField
              {...params}
              error={!!error}
              className={isFilled ? 'filled' : ''}
              value={
                params.value && params.value.id
                  ? params.value.id.toString()
                  : params.value
              }
              label={
                <>
                  {label}
                  {isRequired ? <Required /> : ''}
                </>
              }
              autoFocus={autoFocus}
              size={size}
              variant={variant}
              placeholder={placeholder}
              fullWidth
              onChange={(e) => {
                if (handleSearch) {
                  handleSearch(e.target.value);
                }
                if (params.inputProps.value === '') {
                  setIsOpen(false);
                } else {
                  setIsOpen(true);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment:
                  emptyButton && params.inputProps.value === ''
                    ? emptyButton
                    : getEndAdornment(input),
                readOnly: readOnly,
              }}
              inputProps={{ ...params.inputProps, 'data-lpignore': 'true' }}
            />
          );
        }}
      />
      {tooltip && <FieldTooltip tooltip={tooltip} />}
      {error && meta.touched && <ErrorMsg component={'span'}>{error}</ErrorMsg>}
    </BoxInput>
  );
};

Autocomplete.defaultProps = {
  onOpen: null,
  isLoading: false,
  size: 'small',
};

Autocomplete.propTypes = {
  required: PropTypes.bool,
  endAdornment: PropTypes.any,
  readOnly: PropTypes.bool,
  notClearable: PropTypes.bool,
  emptyButton: PropTypes.node,
  label: PropTypes.string,
  tooltip: PropTypes.object,
  staticList: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  autoFocus: PropTypes.bool,
  creatable: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  iconStart: PropTypes.node,
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitError: PropTypes.string,
    touched: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  handleSearch: PropTypes.func,
};
