import React from 'react';
import PropTypes from 'prop-types';
import { ReportChart } from 'pages/ReportPages/DetailsReportPage/ReportChart';
import { useGetChartListQuery } from 'redux/rtk-query';

export const ChartView = ({ reportId }) => {
  const [viewChart, setViewChart] = React.useState(false);
  const { data: charts } = useGetChartListQuery(reportId);

  return (
    <>
      {!viewChart ? (
        <div>
          {charts?.data.map((chart) => (
            <div key={chart.id} onClick={() => setViewChart(chart.id)}>
              {chart.type}
            </div>
          ))}
        </div>
      ) : (
        <ReportChart reportId={reportId} chartId={viewChart} />
      )}
    </>
  );
};

ChartView.propTypes = {
  reportId: PropTypes.string.isRequired,
};
