import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, IconButton } from '@mui/material';
import { ListPagination } from 'components/ListPagination';
import { SectionHeader } from 'components/Styled';
import {
  ListAccordionSum,
  ExpandIcon,
  ListAccordionDetHeader,
  ListAccordionDetails,
  ListAccordion,
  DividerStyled,
} from 'pages/ContactsPages/ContactsPage/components/ListsSection/components/ListAccordeonItem/styled';
import {
  ListTabText,
  ListTab,
  ListSectionHead,
  ListSectionWrapper,
} from 'pages/ContactsPages/ContactsPage/components/ListsSection/styled';
import { useDashboard } from 'pages/ReportPages/ReportPage/context/DashboardContext';

const DashboardSidebar = ({
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  totalPages,
  totalCount,
}) => {
  const navigate = useNavigate();
  const params = useParams(); // Get current view from URL
  const paramName = Object.keys(params)[0];

  const view = params[paramName];

  const {
    dashboards,
    addDashboard,
    activeDashboard,
    setActiveDashboard,
    setIsEditMode,
    isEditMode,
    updateDashboard,
    deleteDashboard,
  } = useDashboard();

  const [dashboardName, setDashboardName] = useState(
    activeDashboard?.name || '',
  );

  const paginatedDashboards = dashboards.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  const handleSaveName = () => {
    if (activeDashboard) {
      updateDashboard(activeDashboard.id, {
        ...activeDashboard,
        name: dashboardName,
      });
    }
  };

  useEffect(() => {
    setDashboardName(activeDashboard?.name || '');
  }, [activeDashboard]);

  return (
    <Box sx={{ width: 250 }}>
      <ListSectionWrapper>
        <ListSectionHead>
          <SectionHeader variant="h4">Reports</SectionHeader>
          <Button
            variant="text"
            size="small"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? 'Save' : 'Edit'}
          </Button>
        </ListSectionHead>
        <DividerStyled />

        <ListTab
          isSelected={view === 'list'}
          onClick={() => {
            navigate('/reports/list');
            setActiveDashboard(null);
          }}
        >
          <ListTabText
            sx={{
              width: '100%',
              color: view === 'list' ? 'white' : 'primary.main',
            }}
          >
            Reports List
          </ListTabText>
        </ListTab>

        <ListAccordion defaultExpanded>
          <ListAccordionSum
            expandIcon={<ExpandIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListAccordionDetHeader
              sx={{
                fontSize: '1.4rem',
              }}
            >
              Dashboards
            </ListAccordionDetHeader>
          </ListAccordionSum>
          <ListAccordionDetails>
            {paginatedDashboards.map((db) => (
              <ListTab
                key={db.id}
                isSelected={activeDashboard?.id === db.id}
                onClick={() => {
                  setActiveDashboard(db);
                  navigate(`/reports/dashboard/${db.id}`);
                }}
              >
                <ListTabText
                  sx={{
                    width: '100%',
                    color:
                      activeDashboard?.id === db.id ? 'white' : 'primary.main',
                  }}
                >
                  {isEditMode && activeDashboard?.id === db.id ? (
                    <TextField
                      sx={{ ml: '-7px' }}
                      fullWidth
                      value={dashboardName}
                      onChange={(e) => setDashboardName(e.target.value)}
                      onBlur={handleSaveName}
                      onKeyDown={(e) => e.key === 'Enter' && handleSaveName()}
                      size="small"
                      InputProps={{
                        sx: {
                          fontSize: '1.4rem',
                          '& input': { padding: '4px 8px' },
                        },
                      }}
                    />
                  ) : (
                    db.name
                  )}
                </ListTabText>
                {isEditMode && activeDashboard?.id === db.id && (
                  <IconButton onClick={() => deleteDashboard(db.id)}>
                    <FontAwesomeIcon icon={faTrash} color="white" />
                  </IconButton>
                )}
              </ListTab>
            ))}
            {isEditMode && (
              <Button
                color={'info'}
                sx={{
                  position: 'relative',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '100%',
                }}
                variant="outlined"
                onClick={addDashboard}
              >
                + Add Dashboard
              </Button>
            )}
          </ListAccordionDetails>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <Box display="flex" justifyContent="space-between" p={1}>
              <ListPagination
                hideText
                totalCount={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={(newPage) => setPage(newPage)}
                handleChangeRowsPerPage={(event) =>
                  setRowsPerPage(parseInt(event.target.value, 10))
                }
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          )}
        </ListAccordion>
      </ListSectionWrapper>
    </Box>
  );
};

DashboardSidebar.propTypes = {
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default DashboardSidebar;
