import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { Field, useField, useForm } from 'react-final-form';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIconComp, MenuButton } from 'components/Common';
import {
  CompanyUnitsAutocomplete,
  Select,
  UserAutocomplete,
} from 'components/Fields';
import { RIGHT_OPTIONS } from 'consts';
import { getSelectOptionsAlt, isAdmin } from 'helpers';
import { useGetProfileQuery } from 'redux/rtk-query';
import { Label, OptionBox } from './styled';

export const RightsPermissions = () => {
  const [showPermissions, setShowPermissions] = useState(false);

  const isUserAdmin = isAdmin();
  const { getState, change } = useForm();
  const { data: currentUser } = useGetProfileQuery();
  const data = getState().values;
  const shareWithOptionInput = useField('shareWithOption');

  const rightOptions = getSelectOptionsAlt(RIGHT_OPTIONS);

  useEffect(() => {
    if (data.modelAccess) {
      const data = getState().values;
      const shareWithOption = data.modelAccess.everyone
        ? RIGHT_OPTIONS.everyone.value
        : data.modelAccess.sharedWithUnits.length > 0
          ? RIGHT_OPTIONS.sharedWithUnits.value
          : data.modelAccess.sharedWithUsers.length > 0
            ? RIGHT_OPTIONS.sharedWithUsers.value
            : RIGHT_OPTIONS.agentOnly.value;
      shareWithOptionInput.input.onChange(shareWithOption);
    }
  }, [
    getState().values.modelAccess?.sharedWithUsers?.length,
    getState().values.modelAccess?.sharedWithUnits?.length,
  ]);

  ///if shareWithOptionInput.input.value === RIGHT_OPTIONS.everyone.value then set modelAccess.everyone to true
  useEffect(() => {
    if (data.modelAccess) {
      if (shareWithOptionInput.input.value === RIGHT_OPTIONS.everyone.value) {
        change('modelAccess.everyone', true);
      } else {
        change('modelAccess.everyone', false);
      }
    }
  }, [shareWithOptionInput.input.value]);

  //if modelAccess.owner is null then set modelAccess.owner to the current user
  useEffect(() => {
    if (data.modelAccess && !data.modelAccess?.owner && currentUser) {
      change('modelAccess.owner', currentUser.data);
    }
  }, [currentUser, data.modelAccess?.owner]);

  return isUserAdmin && data.modelAccess ? (
    <>
      <Box alignSelf={'center'} display={'inline-block'}>
        <MenuButton
          title={
            <Tooltip title={'Rights and Permissions'}>
              <IconButton
                component={'span'}
                size="small"
                onClick={() => setShowPermissions(!showPermissions)}
                color="primary"
              >
                <FontAwesomeIconComp icon={faUserPlus} />
              </IconButton>
            </Tooltip>
          }
        >
          <Box minWidth={200}>
            <Box>
              <Label>Owner</Label>
              <Field name="modelAccess.owner" component={UserAutocomplete} />
            </Box>
            <OptionBox>
              <Box>
                <Label>Shared With:</Label>
                <Field
                  name="shareWithOption"
                  options={rightOptions}
                  component={Select}
                />
              </Box>
              {shareWithOptionInput.input.value ===
              RIGHT_OPTIONS.sharedWithUsers.value ? (
                <Field
                  name="modelAccess.sharedWithUsers"
                  label="Agents"
                  multiple
                  component={UserAutocomplete}
                />
              ) : null}
              {shareWithOptionInput.input.value ===
              RIGHT_OPTIONS.sharedWithUnits.value ? (
                <Field
                  component={CompanyUnitsAutocomplete}
                  name="modelAccess.sharedWithUnits"
                  label={'Share with Units'}
                  multiple
                />
              ) : null}
            </OptionBox>
          </Box>
        </MenuButton>
      </Box>
    </>
  ) : null;
};

RightsPermissions.propTypes = {
  defaultValue: PropTypes.string,
};
