import React from 'react';
import { Field } from 'react-final-form';
import { Box, Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CheckboxField,
  DatePicker,
  DocumentTemplateAutocomplete,
  Select,
  TextInput,
} from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { MODULE_CLASS_NAMES, NAME_FORMATS, STATUSES, TYPES } from 'consts';
import { getSelectOptions } from 'helpers/form';
import { ModuleRelationType } from './components/ModuleRelationType';

export const DocumentTemplateForm = ({ ...props }) => {
  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Grid item xs={12}>
          <Field label="Name" name={'name'} component={TextInput} />
          <Field
            name="type"
            label="Type"
            options={getSelectOptions(TYPES)}
            component={Select}
          />
          <Field
            name="status"
            label="Status"
            options={getSelectOptions(STATUSES)}
            component={Select}
          />
          <Field
            name="moduleClassName"
            label="Module Name"
            options={getSelectOptions(MODULE_CLASS_NAMES)}
            component={Select}
          />
          <ModuleRelationType />
          <Field
            name="nameFormat"
            label="Name Format"
            options={getSelectOptions(NAME_FORMATS)}
            component={Select}
          />
          <Field
            name={'parentTemplate'}
            label={'Parent Template'}
            component={DocumentTemplateAutocomplete}
          />
          <Field
            name={'aliasDocument'}
            label={'Alias Document'}
            component={DocumentTemplateAutocomplete}
          />
          <Field
            name="revisionDate"
            label={'Revision Image'}
            component={DatePicker}
          />
          <Field
            name="multiplesAllowed"
            label={'Multiples Allowed'}
            component={CheckboxField}
          />
          <Field name="required" label={'Required'} component={CheckboxField} />
          <Field
            name="closedApproved"
            label={'Closed Approved'}
            component={CheckboxField}
          />
        </Grid>
      </FormPanel>
    </Box>
  );

  return <AbeForm {...props}>{renderFields}</AbeForm>;
};
