import React, { useState } from 'react';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { CREATE_CONTACT, CREATE_TRANSACTION } from 'consts';

export const QuickCreateMenu = () => {
  const QUICK_LINKS = [
    {
      label: 'Transaction',
      moduleName: 'Transactions',
      link: CREATE_TRANSACTION,
    },
    {
      label: 'Contact',
      moduleName: 'Contacts',
      link: CREATE_CONTACT,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box pr={2}>
        <IconButton onClick={handleClick}>
          <FontAwesomeIconComp
            fontSize={20}
            icon={faCirclePlus}
            color={'grey'}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {QUICK_LINKS.map(({ label, moduleName, link }) => (
          <MenuItem key={moduleName}>
            <Link
              to={`${pathname}${link}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ModuleIcon marginRight={10} module={moduleName} />
              {label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
