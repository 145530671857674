import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { ItemCountProvider } from 'contexts';
import { conversion } from 'helpers';
import TaskSectionTableContent from 'pages/ActivityPages/ActivityPage/components/TasksActivityTable/components/TaskSectionTable/TaskSectionTableContent';

export const TaskSectionTable = ({
  // eslint-disable-next-line no-unused-vars
  search,
  sectionType,
  timeframes = 'false',
  startClosed = false,
}) => {
  return (
    <ItemCountProvider>
      <AbeCard
        hideOnEmpty
        startClosed={startClosed}
        title={conversion.titleCase(sectionType)}
      >
        <Divider />
        <TaskSectionTableContent
          sectionType={sectionType}
          timeframes={timeframes}
        />
      </AbeCard>
    </ItemCountProvider>
  );
};

TaskSectionTable.propTypes = {
  search: PropTypes.string,
  sectionType: PropTypes.string,
  timeframes: PropTypes.bool,
  startClosed: PropTypes.bool,
};
