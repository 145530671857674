export const listedData = {
  totalItemCount: 2,
  items: [
    {
      id: 9222,
      name: '260 Spring Forest Drive New Smyrna Beach',
      pmStatus: '',
      status: 'Listed',
      period: '11/30/00-1',
      netCommission: '27000',
      representing: 'Seller',
      documentProgress: {
        Draft: 2,
        'In Progress': 2,
        Completed: 2,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 12581,
      name: '111 North 12th Street 1605 Tampa',
      representing: 'Seller',
      pmStatus: '',
      status: 'Listed',
      period: '11/30/00-1',
      netCommission: '13000',
      documentProgress: {
        Draft: 3,
        'In Progress': 1,
        Completed: 1,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
  ],
  total: '40000',
};

export const pendingData = {
  totalItemCount: 6,
  items: [
    {
      id: 8434,
      representing: 'Buyer',
      name: '1835 2nd Street South St. Petersburg',
      pmStatus: '',
      status: 'Pending',
      period: '11/30/00-1',
      netCommission: '10320',
      documentProgress: {
        Draft: 10,
        'In Progress': 2,
        Completed: 0,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 12582,
      representing: 'Seller',
      name: '10636 Gretna Green Drive Tampa',
      pmStatus: '',
      status: 'Pending',
      period: '11/30/00-1',
      netCommission: '17700',
      documentProgress: {
        Draft: 0,
        'In Progress': 1,
        Completed: 6,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 12599,
      representing: 'Seller & Buyer',
      name: '5229 Butler Ridge Drive Windermere',
      pmStatus: '',
      status: 'Pending',
      period: '11/30/00-1',
      netCommission: '22800',
      documentProgress: {
        Draft: 3,
        'In Progress': 2,
        Completed: 3,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 7676,
      name: '705 East Pine Street Orlando',
      representing: 'Seller & Buyer',
      pmStatus: '',
      status: 'Pending',
      period: '11/30/00-1',
      netCommission: '11720',
      documentProgress: {
        Draft: 7,
        'In Progress': 2,
        Completed: 5,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 13363,
      name: '8704 Pinter Street Orlando',
      representing: 'Seller',
      pmStatus: '',
      status: 'Pending',
      period: '11/30/00-1',
      netCommission: '18000',
      documentProgress: {
        Draft: 5,
        'In Progress': 2,
        Completed: 1,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
  ],
  total: '80540',
};

export const draftData = {
  totalItemCount: 10,
  items: [
    {
      id: 13648,
      name: '1125 West Yale Street Orlando',
      pmStatus: null,
      status: 'Draft',
      representing: 'Seller',
      period: null,
      netCommission: '0',
      documentProgress: {
        Draft: 4,
        'In Progress': 0,
        Completed: 0,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 13607,
      name: '1811 Antilles Place Orlando',
      representing: 'Buyer',
      pmStatus: '',
      status: 'Draft',
      period: '11/30/00-1',
      netCommission: '0',
      documentProgress: {
        Draft: 5,
        'In Progress': 0,
        Completed: 0,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 13301,
      name: '1522 East Jefferson Street Orlando',
      pmStatus: null,
      status: 'Draft',
      representing: 'Buyer',
      period: null,
      netCommission: '800',
      documentProgress: {
        Draft: 5,
        'In Progress': 0,
        Completed: 0,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 11942,
      name: '24 Minnehaha Circle Maitland',
      pmStatus: '',
      status: 'Draft',
      period: '11/30/00-1',
      netCommission: '16680',
      representing: 'Buyer',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
    {
      id: 12637,
      name: '1234 North Orange Avenue Winter Park',
      pmStatus: '',
      status: 'Draft',
      representing: 'Seller & Buyer',
      period: '11/30/00-1',
      netCommission: '7200',
      documentProgress: {
        Draft: 4,
        'In Progress': 1,
        Completed: 0,
        Revise: 0,
      },
      agent: 'Kylo Holmes',
    },
  ],
  total: '80340',
};

export const soldData = {
  totalItemCount: 458,
  items: [
    {
      id: 15449,
      name: '2000 North Bayshore Drive 515 Miami',
      pmStatus: '',
      status: 'Closed Sale',
      period: '11/30/00-1',
      representing: 'Buyer',
      netCommission: '13400',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 18,
        Revise: 0,
      },
      agent: 'Val Scinto',
    },
    {
      id: 15992,
      name: '213 Cypress Hills Way DeLand',
      pmStatus: '',
      representing: 'Buyer',
      status: 'Closed Sale',
      period: '11/30/00-1',
      netCommission: '21300',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 19,
        Revise: 0,
      },
      agent: 'Justin Dalrymple',
    },
    {
      id: 16033,
      name: '2084 Switch Grass Circle Ocoee',
      pmStatus: '',
      representing: 'Seller',
      status: 'Closed Sale',
      period: '11/30/00-1',
      netCommission: '11400',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 15,
        Revise: 0,
      },
      agent: 'Melissa Vance',
    },
    {
      id: 16071,
      name: '250 Homewood Avenue 11a DeBary',
      pmStatus: '',
      representing: 'Seller & Buyer',
      status: 'Closed Sale',
      period: '11/30/00-1',
      netCommission: '35123.43',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 17,
        Revise: 0,
      },
      agent: 'Rocki Sanchez',
    },
    {
      id: 16223,
      name: '5671 Maddie Drive Haines City',
      pmStatus: '',
      representing: 'Buyer',
      status: 'Closed Sale',
      period: '11/30/00-1',
      netCommission: '21123.90',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 9,
        Revise: 0,
      },
      agent: 'Jessica Polanco',
    },
  ],
  total: '2080340',
};

export const closingInProgressData = {
  totalItemCount: 4,
  items: [
    {
      id: 15449,
      name: '2000 North Bayshore Drive 515 Miami',
      pmStatus: '',
      status: 'Closed Sale',
      period: '11/30/00-1',
      netCommission: '9123.20',
      representing: 'Seller',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 18,
        Revise: 0,
      },
      agent: 'Val Scinto',
    },
    {
      id: 15992,
      name: '213 Cypress Hills Way DeLand',
      pmStatus: '',
      status: 'Closed Sale',
      representing: 'Seller',
      period: '11/30/00-1',
      netCommission: '8233.20',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 19,
        Revise: 0,
      },
      agent: 'Justin Dalrymple',
    },
    {
      id: 16033,
      name: '2084 Switch Grass Circle Ocoee',
      pmStatus: '',
      status: 'Closed Sale',
      period: '11/30/00-1',
      representing: 'Seller & Buyer',
      netCommission: '6721.11',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 15,
        Revise: 0,
      },
      agent: 'Melissa Vance',
    },
    {
      id: 16071,
      name: '250 Homewood Avenue 11a DeBary',
      pmStatus: '',
      status: 'Closed Sale',
      period: '11/30/00-1',
      representing: 'Buyer',
      netCommission: '4726.23',
      documentProgress: {
        Draft: 0,
        'In Progress': 0,
        Completed: 0,
        Approved: 17,
        Revise: 0,
      },
      agent: 'Rocki Sanchez',
    },
  ],
  total: '120345',
};
