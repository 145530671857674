import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, FontAwesomeIconComp, PopperButton } from 'components/Common';
import { mapArrayValues } from 'components/Common/Table/utils';
import {
  IconButton,
  TableCell,
  TableRow as MUITableRow,
} from 'components/Styled';
import { TableChildRow } from './TableChildRow';

export const TableRow = ({
  search,
  isCollapsible,
  menuItems,
  row,
  cells,
  setSelected,
  selected,
  onRowClick,
  handleRowExpand,
  expandedRows,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isCollapsible) {
      setOpen(!!search && !!row.children?.length);
    }
  }, [search]);

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
    handleRowExpand(row.id);
  };

  return (
    <>
      <MUITableRow
        hover
        key={row.id}
        onClick={
          onRowClick
            ? (e) => {
                onRowClick(row, e);
              }
            : null
        }
      >
        {setSelected && selected && (
          <TableCell align={'center'} width={'1%'}>
            <Checkbox
              onClick={() => {
                setSelected(row.id, row);
              }}
              checked={selected.includes(row.id)}
            />
          </TableCell>
        )}
        {cells.map(
          ({
            width,
            value,
            id,
            cellAlign,
            isCollapsibleCell,
            Wrapper,
            getRowClickHandler,
            sx,
          }) => (
            <TableCell
              key={id}
              width={width}
              align={cellAlign ? cellAlign : 'inherit'}
              sx={sx}
            >
              {isCollapsible && isCollapsibleCell ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={handleExpandClick}
                  disabled={!row.children?.length && row?.hasChildren === 0}
                >
                  <FontAwesomeIconComp
                    icon={open ? faCaretDown : faCaretRight}
                    style={{ margin: '5px 10px' }}
                    color="#969696"
                  />
                </IconButton>
              ) : null}
              {Wrapper ? (
                <Wrapper
                  value={
                    Array.isArray(value)
                      ? mapArrayValues(row, value)
                      : row[value]
                  }
                  item={row}
                  onClick={getRowClickHandler ? getRowClickHandler(row) : null}
                />
              ) : (
                row[value]
              )}
            </TableCell>
          ),
        )}
        {menuItems ? (
          <TableCell align="right" width={90}>
            <PopperButton menuItems={menuItems} item={row} />
          </TableCell>
        ) : null}
      </MUITableRow>
      {isCollapsible && open && (row.children || expandedRows[row.id]) ? (
        <TableChildRow
          number={0}
          open={open}
          rows={row.children || expandedRows[row.id]}
          menuItems={menuItems}
          cells={cells}
        />
      ) : null}
    </>
  );
};

TableRow.defaultProps = {
  menuItems: null,
  isCollapsible: false,
};

TableRow.propTypes = {
  search: PropTypes.string,
  isCollapsible: PropTypes.bool,
  row: PropTypes.object.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      icon: PropTypes.object,
    }),
  ),
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      width: PropTypes.number,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      isCollapsibleCell: PropTypes.bool,
    }),
  ),
  onRowClick: PropTypes.func,
  setSelected: PropTypes.func,
  getClickHandler: PropTypes.func,
  selected: PropTypes.array,
  handleRowExpand: PropTypes.func,
  expandedRows: PropTypes.array,
};
