import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import { Form } from 'react-final-form';
import { Box } from '@mui/system';
import { RightsPermissions } from 'components/Fields';
import { FormValidator, ToggleFieldsButton } from 'components/Forms';
import { CreateLayoutHeader } from 'components/Layouts';
import { CreateBox } from 'components/Styled';
import { useFormSubmitHandler } from 'hooks';
import { useGetProfileQuery } from 'redux/rtk-query';

export const AbeForm = ({
  title,
  subTitle,
  nameButton,
  afterSave,
  isLoading,
  formId,
  handleSubmitFunc,
  initialValues,
  mutators,
  children,
  showAllFieldsButton = true,
}) => {
  const onSubmit = useFormSubmitHandler(handleSubmitFunc, afterSave);
  const { data: profileData } = useGetProfileQuery();
  const permissions = profileData?.data?.permissions || [];
  const [showAllFields, setShowAllFields] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const form = formRef.current;
    if (!form) return;

    const handleKeyPress = (event) => {
      const tagName = event.target.tagName;
      const type = event.target.type;

      // Allow Enter key for textareas and buttons
      if (
        tagName === 'TEXTAREA' ||
        (tagName === 'BUTTON' && type === 'submit')
      ) {
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent form submission for other elements
      }
    };

    form.addEventListener('keypress', handleKeyPress);

    return () => {
      form.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ setFieldData, ...arrayMutators, ...mutators }}
      render={({ handleSubmit, form, values }) => (
        <CreateBox>
          <form id={formId} ref={formRef} onSubmit={handleSubmit}>
            <CreateLayoutHeader
              title={title}
              subTitle={subTitle}
              nameButton={nameButton}
              afterSave={afterSave}
            >
              <Box>
                {showAllFieldsButton && (
                  <ToggleFieldsButton
                    showAllFields={showAllFields}
                    setShowAllFields={setShowAllFields}
                    permissions={permissions}
                  />
                )}
                <RightsPermissions />
              </Box>
            </CreateLayoutHeader>
            {isLoading ? 'Loading...' : children(form, values, showAllFields)}
            <FormValidator />
          </form>
        </CreateBox>
      )}
    />
  );
};

AbeForm.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  formId: PropTypes.string,
  nameButton: PropTypes.string,
  afterSave: PropTypes.func,
  isLoading: PropTypes.bool,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  mutators: PropTypes.object,
  children: PropTypes.func.isRequired,
  showAllFieldsButton: PropTypes.bool,
};
