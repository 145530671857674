import React from 'react';
import { faSendBackward } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemButton, FontAwesomeIconComp } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { moveToBackCM } from 'redux/slices';

const MoveToBackMenuItem = () => {
  const dispatch = useDispatch();
  const activeField = useSelector(selectCurrentItem);

  const handleMoveToBackClick = () => {
    dispatch(moveToBackCM(activeField.id));
  };

  return (
    <MenuItemButton
      icon={<FontAwesomeIconComp fontSize={18} icon={faSendBackward} />}
      label={'Send Backward'}
      onClick={handleMoveToBackClick}
    />
  );
};

export default MoveToBackMenuItem;
