import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, IconButton, Paper } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { ClickMarketingEditorDrawer } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/ClickMarketingEditorDrawer';
import DocumentEsignEditor from 'components/ModelViews/Documents/DocumentEditorDrawer/components/DocumentEsignEditor';
import { ButtonSection, Header, HeadingSection } from 'components/Styled';
import { useDrawer } from 'hooks';
import { useGetDocumentTemplateQuery } from 'redux/rtk-query';

export function DocumentTemplateEditorDrawer({ params, path }) {
  const { isOpen, closeDrawer } = useDrawer(true, path);
  const [headerHeight, setHeaderHeight] = useState(0);
  const resizeObserverRef = useRef(null);

  const paramName = Object.keys(params)[0];
  const { data: documentData } = useGetDocumentTemplateQuery({
    id: params[paramName],
  });

  const headerRef = useCallback(
    (node) => {
      if (node !== null) {
        const updateHeaderHeight = () => {
          setHeaderHeight(node.offsetHeight);
        };

        updateHeaderHeight();

        resizeObserverRef.current = new ResizeObserver(() => {
          updateHeaderHeight();
        });
        resizeObserverRef.current.observe(node);
      } else {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
      }
    },
    [documentData],
  );

  useLayoutEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    updateHeaderHeight();
  }, [headerRef, documentData]);

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box ref={headerRef}>
          <HeadingSection shadow backgroundColor={'#F7F7F7'}>
            <Box>
              <Header variant="h4">{documentData?.data?.name}</Header>
            </Box>
            <ButtonSection>
              <IconButton onClick={closeDrawer}>
                <FontAwesomeIconComp icon={faTimesCircle} />
              </IconButton>
            </ButtonSection>
          </HeadingSection>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            maxHeight: `calc(100% - ${headerHeight}px)`,
            position: 'relative',
          }}
        >
          {documentData?.data && (
            <>
              {documentData?.data?.fileType === 'Click Marketing' ? (
                <ClickMarketingEditorDrawer
                  headerHeight={headerHeight}
                  documentData={documentData?.data}
                />
              ) : (
                <DocumentEsignEditor
                  templateView
                  headerHeight={headerHeight}
                  documentData={documentData?.data}
                />
              )}
            </>
          )}
        </Box>
      </Paper>
    </SideDrawer>
  );
}

DocumentTemplateEditorDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string,
};
