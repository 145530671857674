import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

const DashboardContext = createContext(null);

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};

export const DashboardProvider = ({ children }) => {
  const navigate = useNavigate();

  const sortDashboards = (dashboardsArray) =>
    dashboardsArray.slice().sort((a, b) => a.id - b.id);

  const [dashboards, setDashboards] = useState(() => {
    const stored = JSON.parse(localStorage.getItem('dashboards')) || [];
    return sortDashboards(stored);
  });
  const params = useParams();

  const hasNoIdOrReportId =
    !params.id && !params.reportId && params['*'] !== 'list';

  const [activeDashboard, setActiveDashboard] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const prevEditModeRef = useRef(isEditMode);

  const addDashboard = () => {
    const newDashboard = {
      id: Date.now(),
      name: `Dashboard ${dashboards.length + 1}`,
      rows: [
        {
          id: Date.now(),
          columns: [],
        },
      ],
    };
    setDashboards((prevDashboards) =>
      sortDashboards([...prevDashboards, newDashboard]),
    );
    setActiveDashboard(newDashboard);
    navigate(`/reports/dashboard/${newDashboard.id}`);
  };

  const updateDashboard = (id, updatedData) => {
    setDashboards((prevDashboards) =>
      sortDashboards(
        prevDashboards.map((db) => (db.id === id ? updatedData : db)),
      ),
    );

    if (activeDashboard?.id === id) {
      setActiveDashboard(updatedData);
    }
  };

  const deleteDashboard = (id) => {
    setDashboards((prevDashboards) => {
      const updatedDashboards = prevDashboards.filter((db) => db.id !== id);
      const sortedDashboards = sortDashboards(updatedDashboards);

      const newActive =
        sortedDashboards.length > 0 ? sortedDashboards[0] : null;

      setTimeout(() => {
        if (newActive) {
          setActiveDashboard(newActive);
          // navigate(`/reports/dashboard/${newActive.id}`);
        } else {
          setActiveDashboard(newActive);
          // navigate('/reports/list'); // Fallback to reports list if none exist
        }
      }, 100);

      return sortedDashboards;
    });
  };

  useEffect(() => {
    if (activeDashboard?.id) {
      navigate(`/reports/dashboard/${activeDashboard.id}`);
    }
  }, [activeDashboard]);

  useEffect(() => {
    if (hasNoIdOrReportId && dashboards.length > 0) {
      setActiveDashboard(dashboards[0]);
    }
    if (params?.id !== activeDashboard?.id) {
      const foundDashboard = dashboards.find((db) => db.id === +params.id);
      if (foundDashboard) {
        setActiveDashboard(foundDashboard);
      }
    }
  }, [hasNoIdOrReportId, dashboards, params?.id, activeDashboard]);

  useEffect(() => {
    // If we just switched to "false" and we have an activeDashboard
    if (!isEditMode && prevEditModeRef.current === true && activeDashboard) {
      // Filter out columns that have no "chart"
      const cleanedRows = activeDashboard.rows.map((row) => {
        const filteredCols = row.columns.filter(
          (col) => col.report && col.chartId,
        );
        return { ...row, columns: filteredCols };
      });

      // Build the updated dashboard
      const updated = { ...activeDashboard, rows: cleanedRows };
      // Update it in state
      updateDashboard(activeDashboard.id, updated);
    }
  }, [isEditMode, activeDashboard]);

  useEffect(() => {
    prevEditModeRef.current = isEditMode;
  }, [isEditMode]);

  useEffect(() => {
    localStorage.setItem('dashboards', JSON.stringify(dashboards));
  }, [dashboards]);

  return (
    <DashboardContext.Provider
      value={{
        dashboards,
        activeDashboard,
        setActiveDashboard,
        addDashboard,
        updateDashboard,
        deleteDashboard,
        isEditMode,
        setIsEditMode,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
