import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { PageMainContent } from 'components/Styled';
import { DashboardProvider } from 'pages/ReportPages/ReportPage/context/DashboardContext';
import DashboardSidebar from 'pages/ReportPages/ReportPage/DashboardSidebar';

export const ReportPage = () => {
  return (
    <PageMainContent maxWidth={false} component="main" disableGutters>
      <DashboardProvider>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <DashboardSidebar />

          <Box sx={{ flexGrow: 1, pl: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </DashboardProvider>
    </PageMainContent>
  );
};
