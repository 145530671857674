import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { DetailsLayout } from 'components/Layouts';
import { CommissionSummary } from 'components/ModelViews';
import { UPDATE_CAPPING } from 'consts';
import { useGetCommissionQuery } from 'redux/rtk-query';

export const DetailsCommissionPage = () => {
  let { commissionDetailsId } = useParams();
  const navigate = useNavigate();
  const { data: commissionData } = useGetCommissionQuery({
    id: commissionDetailsId,
  });

  return (
    <>
      {commissionData && (
        <>
          <DetailsLayout
            twoColumns
            header={
              <>
                <span>{commissionData.data.name}</span>
                <Button
                  size={'small'}
                  onClick={() => {
                    navigate(UPDATE_CAPPING(commissionDetailsId));
                  }}
                >
                  Edit
                </Button>
              </>
            }
            leftContent={
              <Card>
                <CommissionSummary commission={commissionData.data} overview />
              </Card>
            }
            rightContent={<></>}
          />
          <Outlet />
        </>
      )}
    </>
  );
};
