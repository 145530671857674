import { useCallback, useEffect, useState } from 'react';

export function useTableData(
  dataFetchHook,
  initialColumns,
  initialOrderBy,
  initialOrder = 'asc',
  initialRowsPerPage = 10,
  extraParams = {},
  dataHookOptions = {},
) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [filterValues, setFilterValues] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [search, setSearch] = useState('');
  const [visibleColumns, setVisibleColumns] = useState(
    initialColumns.map((cell) => ({
      ...cell,
      isVisible: cell.isVisible ?? false,
    })),
  );

  const toggleColumnVisibility = (columnId) => {
    setVisibleColumns(
      visibleColumns.map((col) =>
        col.id === columnId ? { ...col, isVisible: !col.isVisible } : col,
      ),
    );
  };

  const resetColumns = () => {
    setVisibleColumns(initialColumns);
  };

  const queryParams = {
    page,
    limit: rowsPerPage,
    search,
    order: order.toUpperCase(),
    orderBy,
    column: visibleColumns.filter((col) => col.isVisible).map((col) => col.id),
    ...filterValues,
    ...extraParams,
  };

  const { data, isFetching, ...otherFetchHookProps } = dataFetchHook(
    queryParams,
    dataHookOptions,
  );

  const totalPages = data?.meta
    ? data?.meta?.totalPages
    : data?.data?.meta?.totalPages;
  const totalCount = data?.meta ? data?.meta?.total : data?.data?.meta?.total;
  const rows = data?.data?.items || data?.data || [];
  const otherData = data?.meta
    ? data?.meta?.otherData
    : data?.data?.meta?.otherData;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (search) {
      setPage(1);
    }
  }, [search]);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  return {
    order,
    orderBy,
    filterValues,
    page,
    rowsPerPage,
    search,
    totalPages,
    totalCount,
    rows,
    otherData,
    visibleColumns,
    toggleColumnVisibility,
    resetColumns,
    loading,
    setFilterValues,
    setPage,
    setRowsPerPage,
    setSearch,
    handleRequestSort,
    ...otherFetchHookProps,
  };
}
