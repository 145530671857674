import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { FontAwesomeIconComp, SkeletonSummary } from 'components/Common';
import { TYPES, colors } from 'consts';
import { useGetDocumentsListQuery } from 'redux/rtk-query';
import { DocumentCardContext } from '../DocumentCard/context/DocumentCardContext';
import { FileTree } from './components/FileTree';

export const DocumentsList = ({ transactionId }) => {
  const { statusFilter, setRefetchDocuments, setTopFolder } =
    useContext(DocumentCardContext);
  const [documentTreeData, setDocumentTreeData] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const params = useParams();
  const relatedModelId =
    transactionId || params.contactDetailsId || params.transactionDetailsId;
  const relatedModelType = params.contactDetailsId ? 'contact' : 'transaction';

  const { data, isSuccess, refetch, isFetching } = useGetDocumentsListQuery({
    page: 1,
    limit: 100,
    status: statusFilter,
    fileType: 'E-sign',
    checkTemplateChildren: true,
    [`${relatedModelType}Id`]: parseInt(relatedModelId),
  });

  const handleIconClick = (nodeId) => {
    if (expanded.includes(nodeId)) {
      setExpanded(expanded.filter((id) => id !== nodeId));
    } else {
      setExpanded([...expanded, nodeId]);
    }
  };

  const findParent = (file, folders) => {
    let parent = null;
    for (let i = 0; i < folders.length; i++) {
      let possibleParent = folders[i];
      if (
        possibleParent.documentTemplate.id ===
          file.documentTemplate?.parentTemplate.id ||
        (file.documentTemplate?.parentTemplate.type === TYPES.FILE &&
          possibleParent.documentTemplate.id ===
            file.documentTemplate?.parentTemplate?.parentTemplate.id) ||
        (file.documentTemplate?.parentTemplate.type === TYPES.FILE &&
          possibleParent.documentTemplate?.aliasDocument?.id ===
            file.documentTemplate?.parentTemplate?.parentTemplate.id) ||
        possibleParent.documentTemplate.aliasDocument?.id ===
          file.documentTemplate?.parentTemplate.id
      ) {
        parent = possibleParent;
        break;
      }
      let possibleArchiveChild = [9, 10, 11, 284, 286];
      if (
        possibleParent.documentType === 'Archive Folder' &&
        possibleArchiveChild.includes(file.documentTemplate.id)
      ) {
        parent = possibleParent;
        break;
      }
    }
    if (parent) {
      let parentIsContractFolder =
        parent.aliasDocumentName === 'Sales Folder Group';
      if (parentIsContractFolder) {
        if (file.status === 'Voided' && file.expirationDate) {
          let cancelledContractsFolder = null;
          for (let i = 0; i < folders.length; i++) {
            let possibleFolder = folders[i];
            if (possibleFolder.displayName === 'Cancelled Contracts') {
              if (possibleFolder.expirationDate !== null) {
                if (
                  possibleFolder.expirationDate.trim() ===
                  file.expirationDate.trim()
                ) {
                  cancelledContractsFolder = possibleFolder;
                  break;
                }
              }
            }
          }
          if (cancelledContractsFolder) {
            parent = cancelledContractsFolder;
          }
        }
      }
    }
    return parent;
  };

  const mapDocumentsToTreeData = (documents) => {
    const sortedDocuments = [...documents].sort((a, b) => a.id - b.id);

    const folders = sortedDocuments
      .filter((doc) => doc.documentTemplate.type === TYPES.FOLDER)
      .map((folder) => ({ ...folder, children: [] }));

    const files = sortedDocuments
      .filter((doc) => doc.documentTemplate.type === TYPES.FILE)
      .map((file) => ({ ...file }));

    const folderMap = folders.map((acc, folder) => {
      acc[folder.id] = folder;
      return acc;
    }, {});

    folders.forEach((folder) => {
      if (
        folder.documentTemplate.name === 'Marketing' ||
        folder.documentTemplate.name === '4. Miscellaneous' ||
        folder.documentTemplate.name === 'Showings'
      ) {
        return;
      }
      const parent = findParent(folder, folderMap);
      if (parent) {
        parent.children.push(folder);
      }
    });

    files.forEach((file) => {
      const parent = findParent(file, folderMap);
      if (parent) {
        parent.children.push(file);
      }
    });

    return folders.filter((folder) => !findParent(folder, folderMap));
  };

  useEffect(() => {
    if (isSuccess && data?.data) {
      if (data?.data.length > 0) {
        let treeData = mapDocumentsToTreeData(data.data);

        setDocumentTreeData(treeData);
        setTopFolder(treeData[0]);

        const allFolderIds = getAllFolderIds(treeData);
        setExpanded(allFolderIds);
      } else {
        setDocumentTreeData([{ children: [] }]);
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setRefetchDocuments(() => refetch);
  }, [refetch, setRefetchDocuments]);

  const getAllFolderIds = (folders) => {
    let folderIds = [];
    folders.forEach((folder) => {
      folderIds.push(`document-${folder.id}`);
      if (folder.children && folder.children.length > 0) {
        folderIds = folderIds.concat(getAllFolderIds(folder.children));
      }
    });
    return folderIds;
  };

  if (isFetching || !documentTreeData) return <SkeletonSummary />;

  return (
    <>
      <TreeView
        multiSelect
        defaultCollapseIcon={
          <FontAwesomeIconComp color={colors.GREY} icon={faFolderOpen} />
        }
        defaultExpandIcon={
          <FontAwesomeIconComp color={colors.GREY} icon={faFolder} />
        }
        expanded={expanded}
        selected={selected}
        sx={{
          padding: '6px 4px',
        }}
      >
        {documentTreeData && (
          <FileTree
            skipTopLevel
            documentData={documentTreeData}
            setSelected={setSelected}
            onIconClick={handleIconClick}
            expanded={expanded}
          />
        )}
      </TreeView>
    </>
  );
};

DocumentsList.propTypes = {
  statusFilter: PropTypes.array,
  transactionId: PropTypes.string,
};
