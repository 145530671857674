import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line no-unused-vars
import { Field, useField } from 'react-final-form';
import { IconButton, Card, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MenuButton } from 'components/Common';
import HoverReveal from 'components/Common/HoverReveal/HoverReveal';
// eslint-disable-next-line no-unused-vars
import { CheckboxField, TextInput } from 'components/Fields';
import { Path, Condition, Value } from './';

const Trigger = ({ name, onRemove }) => {
  // Subscribe to the `isEditable` field so we can conditionally render the TextInput.
  const {
    // eslint-disable-next-line no-unused-vars
    input: { value: isEditable },
  } = useField(`${name}.isEditable`, { subscription: { value: true } });

  return (
    <Grid item xs position="relative">
      <HoverReveal
        hoverContent={
          <IconButton color="error" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        sx={{
          flexDirection: 'column',
        }}
      >
        <Card
          sx={{
            px: 2,
            py: 1,
            // '& .hover-reveal-content': {
            //   display: isEditable ? 'flex' : 'none',
            // },
            // '&:hover .hover-reveal-content': { display: 'flex' },
          }}
        >
          <Box display="flex" alignItems="center" sx={{ mr: 3 }}>
            <Path name={name} />
            <Condition name={name} />
            <Value name={name} />
          </Box>
          <Box
            // className={'hover-reveal-content'}
            position="absolute"
            bottom={-14}
            left={0}
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
          >
            <MenuButton
              sx={{
                padding: 0,
                minWidth: 10,
                bgcolor: 'white',
                '&:hover': {
                  bgcolor: 'white',
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              title={
                <Typography variant={'body2'}>
                  <Field
                    name={`${name}.isEditable`}
                    label="Live Filter"
                    size="small"
                    component={CheckboxField}
                    sx={{ mr: 2 }}
                  />
                </Typography>
              }
            >
              <Box display="flex" alignItems="center" sx={{ mr: 3 }}>
                {isEditable && (
                  <Field
                    name={`${name}.name`}
                    label="Name"
                    component={TextInput}
                  />
                )}
              </Box>
            </MenuButton>
          </Box>
        </Card>
      </HoverReveal>
    </Grid>
  );
};

Trigger.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Trigger;
