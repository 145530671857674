import React, { useMemo, useState } from 'react';
import setFieldData from 'final-form-set-field-data';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { MenuButton } from 'components/Common';
import { UserAutocomplete } from 'components/Fields';
import { activityFiltersSelector } from 'redux/selectors';
import { setActivityFilters } from 'redux/slices';
import { CurrentActivityFilters } from '../CurrentActivityFilters';

export const ActivityFilters = () => {
  const [filterType, setFilterType] = useState('lists');
  // eslint-disable-next-line no-unused-vars
  const [filterValue, setFilterValue] = useState('');

  const activityFilters = useSelector(activityFiltersSelector);

  const dispatch = useDispatch();

  const memoizedFilterValues = useMemo(() => {
    return {
      lists: activityFilters.lists || [],
      owners: activityFilters.owners || [],
    };
  }, [activityFilters]);

  const [formValues, setFormValues] = useState({
    lists: memoizedFilterValues.lists,
    owners: memoizedFilterValues.owners,
  });
  // eslint-disable-next-line no-unused-vars
  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    setFilterValue('');
  };
  // eslint-disable-next-line no-unused-vars
  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };

  const applyFilters = (values) => {
    dispatch(
      setActivityFilters({
        filterType,
        filterValues: values[filterType],
      }),
    );
    setFormValues(values);
  };

  return (
    <>
      <CurrentActivityFilters />
      <MenuButton
        title={
          <Button component={'p'} size={'small'} variant="contained">
            Activity Filters
          </Button>
        }
      >
        <Box width={200}>
          <Form
            mutators={{ setFieldData }}
            initialValues={formValues}
            onSubmit={applyFilters}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="owners"
                  label="Owners"
                  multiple
                  component={UserAutocomplete}
                />
                <Button type="submit" variant="contained">
                  Apply
                </Button>
              </form>
            )}
          />
        </Box>
      </MenuButton>
    </>
  );
};

ActivityFilters.propTypes = {};
