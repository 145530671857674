import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from 'consts';
import { useCreateCompanyUnitMutation } from 'redux/rtk-query';
import CompanyUnitForm, { defaultValues } from '../CompanyUnitForm';

export const CreateCompanyUnitPage = () => {
  const [createCompanyUnit] = useCreateCompanyUnitMutation();
  const navigate = useNavigate();

  const onSubmit = ({ parent, ...values }) => {
    createCompanyUnit({ ...values, parentId: parent ? parent.value : null });
  };

  const afterSave = () => {
    navigate(ADMIN_ROUTES.COMPANY_UNITS.path);
  };

  return (
    <CompanyUnitForm
      headerColor={'white'}
      initialValues={defaultValues}
      handleSubmit={onSubmit}
      title={'Create Company Unit'}
      afterSave={afterSave}
    />
  );
};
