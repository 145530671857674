import queryString from 'query-string';
import { endpoints } from 'consts';

export const roleUrlBuilder = {
  getList: function () {
    return endpoints.rolesList;
  },
  getRole: function (id) {
    return `${endpoints.roles}/${id}`;
  },
  getRoleUsers: function ({ id, ...params }) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.roles}/${id}/users?${urlParams}`;
  },
  createRole: function () {
    return endpoints.roles;
  },
  editRole: function () {
    return endpoints.roles;
  },
  deleteRole: function (id) {
    return `${endpoints.roles}/${id}`;
  },
};
