import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { Header, HeadingSection, ButtonSection } from 'components/Styled';
import { getCappingType, getModuleIcon } from 'helpers';
import { useDrawer } from 'hooks';
import { CappingsTable } from 'pages/CappingPages/CappingsPage/components';
import {
  useGetTransactionQuery,
  useGetDefaultCappingQuery,
  useUpdateCommissionMutation,
  transactionsAPI,
} from 'redux/rtk-query';

export const ChangeCompensationButton = ({ commission }) => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false);

  let { transactionDetailsId } = useParams();
  const dispatch = useDispatch();
  const [updateCommission] = useUpdateCommissionMutation();

  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionDetailsId },
    { skip: !transactionDetailsId },
  );

  const cappingType = getCappingType(transactionData?.data);

  const { data: cappingData } = useGetDefaultCappingQuery(
    {
      userId: commission.modelAccess.owner.id,
      cappingType,
    },
    { skip: !cappingType },
  );

  const defaultCapping = cappingData?.data;

  const onRowClick = async (capping) => {
    await updateCommission({
      id: commission.id,
      activeCappingId: capping.id,
    }).then(() => {
      dispatch(transactionsAPI.util.invalidateTags(['Commissions']));
      closeDrawer();
    });
  };

  const handleUpdateClick = async () => {
    if (currentOrDefaultCapping === 'Active') {
      await updateCommission({
        id: commission.id,
        activeCappingId: null,
      }).then(() => {
        dispatch(transactionsAPI.util.invalidateTags(['Commissions']));
        closeDrawer();
      });
    } else {
      openDrawer();
    }
  };

  const activeCapping = commission.activeCapping || defaultCapping;
  const currentOrDefaultCapping = commission.activeCapping
    ? 'Active'
    : 'Default';
  return (
    <>
      <Tooltip
        title={
          <Box>
            {currentOrDefaultCapping === 'Active'
              ? 'Revert to Default Compensation'
              : 'Change Compensation'}
          </Box>
        }
      >
        <Box display={'flex'}>
          <Box>
            {activeCapping ? activeCapping?.name : 'Change Compensation'}
          </Box>
          <IconButton sx={{ ml: 1 }} size="x-small" onClick={handleUpdateClick}>
            <FontAwesomeIconComp
              fontSize={14}
              icon={getModuleIcon('Compensation').icon}
            />
          </IconButton>
        </Box>
      </Tooltip>
      <SideDrawer width={'40%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <HeadingSection backgroundColor={'#F7F7F7'}>
          <Header variant="h4">Select Compensation</Header>
          <ButtonSection>
            <IconButton onClick={closeDrawer}>
              <FontAwesomeIconComp size={'xl'} icon={faTimesCircle} />
            </IconButton>
          </ButtonSection>
        </HeadingSection>
        <CappingsTable onRowClick={onRowClick} />
      </SideDrawer>
    </>
  );
};

ChangeCompensationButton.propTypes = {
  commission: PropTypes.object,
};
