import React from 'react';
import PropTypes from 'prop-types';
import { faTrash, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Box, Button, Tooltip, IconButton } from '@mui/material';
import { ConfirmationButton } from 'components/Common/ConfirmationButton';
import { colors } from 'consts';
import ChartForm from 'pages/ReportPages/DetailsReportPage/ChartForm';
import { ReportChart } from 'pages/ReportPages/DetailsReportPage/ReportChart';
import {
  useGetChartListQuery,
  useDeleteChartMutation,
  useGetChartQuery,
} from 'redux/rtk-query';

export const ChartView = ({ reportId, handleChartSelect }) => {
  const [addChart, setAddChart] = React.useState(false);
  const [editingChartId, setEditingChartId] = React.useState(null);

  const { data: charts } = useGetChartListQuery(reportId);
  const { data: editingChartData } = useGetChartQuery(
    { reportId, chartId: editingChartId },
    { skip: !editingChartId },
  );

  const [deleteChart] = useDeleteChartMutation();

  const chartList = charts?.data || [];
  const totalCharts = chartList.length + (addChart ? 1 : 0);

  const getGridSize = () => {
    if (totalCharts === 1) return 12;
    if (totalCharts === 2) return 6;
    return 4;
  };

  const handleDeleteChart = (id) => {
    deleteChart({ reportId, chartId: id });
  };

  return (
    <>
      <Grid container spacing={2}>
        {chartList.map((chart) => (
          <Grid item key={chart.id} xs={12} sm={getGridSize()}>
            {editingChartId === chart.id ? (
              <ChartForm
                reportId={reportId}
                chartData={editingChartData?.data}
                afterSave={() => setEditingChartId(null)}
              />
            ) : (
              <Card
                onClick={() => handleChartSelect && handleChartSelect(chart.id)}
                sx={{ p: 2, cursor: 'pointer', position: 'relative' }}
              >
                <ReportChart reportId={reportId} chartId={chart.id} />
                {!handleChartSelect && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 4,
                      left: 4,
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <IconButton
                      onClick={() => setEditingChartId(chart.id)}
                      size="small"
                    >
                      <Tooltip title={'Edit Chart'}>
                        <FontAwesomeIcon color={colors.BLUE} icon={faEdit} />
                      </Tooltip>
                    </IconButton>
                    <ConfirmationButton
                      sx={{ minWidth: 'auto' }}
                      variant={'text'}
                      label={
                        <Tooltip title={'Delete Chart'}>
                          <FontAwesomeIcon color={colors.RED} icon={faTrash} />
                        </Tooltip>
                      }
                      confirmTitle={'Remove Chart'}
                      confirmMessage={
                        'Are you sure you want to delete this chart?'
                      }
                      handleConfirm={() => handleDeleteChart(chart.id)}
                    />
                  </Box>
                )}
              </Card>
            )}
          </Grid>
        ))}

        {addChart && (
          <Grid item xs={12} sm={getGridSize()}>
            <ChartForm
              reportId={reportId}
              afterSave={() => setAddChart(false)}
            />
          </Grid>
        )}

        {!addChart && (
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: '13px 20px',
              zIndex: 10,
            }}
          >
            <Button variant="outlined" onClick={() => setAddChart(true)}>
              + Add Chart
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );
};

ChartView.propTypes = {
  reportId: PropTypes.string.isRequired,
  handleChartSelect: PropTypes.func,
};
