import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  faBookUser,
  faFolder,
  faTimesCircle,
  faStar,
  faBusinessFront,
  faHeart,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Box, IconButton, Divider, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import { MenuItemButton } from 'components/Common';
import { SearchInput } from 'components/SearchInput';
import { HeadingSection, Header, ButtonSection } from 'components/Styled';
import { CM_ITEM_TYPES } from 'consts'; // Adjust the import path as needed
import useOrderedPhotos from 'hooks/transaction/useOrderedPhotos';
import {
  useGetProfileQuery,
  useGetFavoritesQuery,
  useGetMediaLibraryQuery,
} from 'redux/rtk-query';
import { currentDocumentSelectorCM, selectCurrentItem } from 'redux/selectors';
import GalleryImages from './GalleryImages';

const FiltersContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  overflowY: 'auto',
  width: 250,
  backgroundColor: theme.palette.background.paper,
}));

const MediaLibrary = ({ onClose }) => {
  const currentDocument = useSelector(currentDocumentSelectorCM);
  const activeItem = useSelector(selectCurrentItem);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [view, setView] = useState(
    activeItem?.type === CM_ITEM_TYPES.image.property
      ? 'transactions'
      : 'recommended',
  );
  const [files, setFiles] = useState([]);
  const [viewingTransaction, setViewingTransaction] = useState(false);
  const { orderedPhotos } = useOrderedPhotos(viewingTransaction);

  const { data: userData } = useGetProfileQuery();

  const { data: favorites } = useGetFavoritesQuery(
    {
      userId: view === 'recommended' ? null : userData?.data?.id,
      documentTemplateId:
        currentDocument?.documentTemplate?.id || currentDocument?.id,
    },
    {
      skip: !(view === 'recommended' || view === 'favorites'),
    },
  );

  const { data: library } = useGetMediaLibraryQuery(
    {
      svg: activeItem?.type === CM_ITEM_TYPES.image.svgImage,
      limit: 12,
      page: page + 1,
      type: view,
      search,
    },
    {
      skip: !(view === 'companyLibrary' || view === 'myLibrary'),
    },
  );

  const totalCount =
    view === 'recommended'
      ? favorites?.data?.meta?.total
      : library?.data?.meta?.total;

  useEffect(() => {
    setPage(0);
  }, [view]);

  useEffect(() => {
    if (view === 'recommended' || view === 'favorites') {
      setFiles(favorites?.data?.items || []);
    } else if (view === 'companyLibrary' || view === 'myLibrary') {
      setFiles(library?.data?.items || []);
    } else if (view === 'transactions') {
      setFiles([]);
      setViewingTransaction(currentDocument?.transaction?.id);
    }
  }, [view, favorites, library, currentDocument?.transaction?.id]);

  useEffect(() => {
    if (view === 'transactions') {
      setFiles(orderedPhotos);
    }
  }, [orderedPhotos, view]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* Header */}
      <HeadingSection>
        <Box display="flex">
          <Header width="100%" variant="h4">
            Media Library
          </Header>
          <SearchInput changeHandler={setSearch} />
        </Box>
        <ButtonSection>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon size="xl" icon={faTimesCircle} />
          </IconButton>
        </ButtonSection>
      </HeadingSection>

      {/* Main Content */}
      <Box display="flex" flexGrow={1} minHeight={0}>
        {/* Sidebar Filters */}
        <FiltersContainer>
          <MenuItemButton
            label="Recommended"
            icon={faStar}
            isSelected={view === 'recommended'}
            onClick={() => setView('recommended')}
          />
          <MenuItemButton
            label="Favorites"
            icon={faHeart}
            isSelected={view === 'favorites'}
            onClick={() => setView('favorites')}
          />
          <MenuItemButton
            label="Company Library"
            icon={faBusinessFront}
            isSelected={view === 'companyLibrary'}
            onClick={() => setView('companyLibrary')}
          />
          <MenuItemButton
            label="My Library"
            icon={faBookUser}
            isSelected={view === 'myLibrary'}
            onClick={() => setView('myLibrary')}
          />
          <MenuItemButton
            label="Transactions"
            icon={faFolder}
            isSelected={view === 'transactions'}
            onClick={() => setView('transactions')}
          />
        </FiltersContainer>

        {/* Gallery and Pagination */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          {/* GalleryImages Component */}
          <Box sx={{ flexGrow: 1, minHeight: 0, overflowY: 'auto' }}>
            <GalleryImages files={files} onClose={onClose} />
          </Box>

          {/* Pagination */}
          <Divider />
          {view !== 'transactions' && totalCount > 0 && (
            <Box sx={{ padding: '0px', flexShrink: 0 }}>
              <TablePagination
                component="div"
                count={totalCount}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={12}
                rowsPerPageOptions={[12]}
                labelRowsPerPage=""
                sx={{ justifyContent: 'center' }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

MediaLibrary.propTypes = {
  onClose: PropTypes.func,
};

export default MediaLibrary;
