import React from 'react';
import PropTypes from 'prop-types';
import { faColumns } from '@fortawesome/pro-regular-svg-icons';
import { faTrash, faUpDownLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, ButtonGroup, Button } from '@mui/material';
import { Box } from '@mui/system';
import { MenuButton } from 'components/Common';
import { ConfirmationButton } from 'components/Common/ConfirmationButton';

const ColumnsLayoutButton = ({ handleSetColumns, handleDeleteRow }) => {
  return (
    <Box
      sx={{
        pointer: 'cursor',
        position: 'absolute',
        top: -4,
        right: -4,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        bgcolor: 'purple',
        zIndex: 10,
      }}
    >
      <Button className={'handle'}>
        <FontAwesomeIcon color={'white'} icon={faUpDownLeftRight} />
      </Button>
      <MenuButton
        size={'x-small'}
        title={
          <Tooltip title={'Number of Columns'}>
            <FontAwesomeIcon color={'white'} size={'lg'} icon={faColumns} />
          </Tooltip>
        }
        sx={{ minWidth: 'auto' }}
      >
        <Box>
          <ButtonGroup>
            <Button onClick={() => handleSetColumns(1)}>1</Button>
            <Button onClick={() => handleSetColumns(2)}>2</Button>
            <Button onClick={() => handleSetColumns(3)}>3</Button>
          </ButtonGroup>
        </Box>
      </MenuButton>

      <ConfirmationButton
        sx={{ minWidth: 'auto' }}
        variant={'text'}
        label={
          <Tooltip title={'Remove Row'}>
            <FontAwesomeIcon color={'white'} icon={faTrash} />
          </Tooltip>
        }
        confirmTitle={'Remove Row'}
        confirmMessage={'Are you sure you want to remove this row?'}
        handleConfirm={handleDeleteRow}
      />
    </Box>
  );
};

export default ColumnsLayoutButton;

ColumnsLayoutButton.propTypes = {
  handleSetColumns: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};
