import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'consts/colors';

export const PanelName = styled(Typography)({
  marginBottom: 8,
  fontWeight: 700,
  fontSize: 14,
  display: 'inline-block',
});

PanelName.defaultProps = {
  variant: 'h7',
  display: 'block',
};

export const PanelLabel = styled(Typography)({
  display: 'inline-flex',
});

PanelLabel.defaultProps = {
  component: 'div',
  variant: 'body2',
  fontSize: 14,
  display: 'inline-block',
};

export const AttributeName = styled(PanelLabel)({
  width: '100%',
});

export const AttributeValue = styled(PanelLabel)({
  // marginLeft: 5,
  fontWeight: 500,
  color: colors.BLACK,
  flexGrow: 1,
});

export const InfoBox = styled(Box)({
  width: '100%',
});
