import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'react-final-form';
import { Typography, Grid, IconButton, Card } from '@mui/material';
import { colors } from 'consts';
import { pathArrayToPathLabel } from 'helpers/report';

const Grouping = ({ name, removeGrouping }) => {
  const visualPathValue = useField(`${name}.visualPath`).input.value;
  const groupingFunctionValue = useField(`${name}.groupingFunction`).input
    .value;
  const dateSeparationValue = useField(`${name}.dateSeparation`).input.value;

  return (
    <Card
      display="flex"
      alignItems="center"
      sx={{
        px: 2,
        py: 1,
        borderRadius: '8px',
        mb: 1,
      }}
    >
      <Grid container alignItems={'center'}>
        <Grid
          item
          xs={12}
          display={'inline-flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="body1" color={colors.BLACK}>
            {pathArrayToPathLabel(visualPathValue)}
            {groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''}
            {dateSeparationValue ? ` - (${dateSeparationValue})` : ''}
          </Typography>
          <IconButton onClick={removeGrouping}>
            <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

Grouping.propTypes = {
  name: PropTypes.string.isRequired,
  removeGrouping: PropTypes.func.isRequired,
};

export default Grouping;
