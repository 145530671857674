import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import {
  SideDrawer,
  FontAwesomeIconComp,
  DetailsPanels,
} from 'components/Common';
import { InvoiceForm } from 'components/ModelViews/Invoices/InvoiceForm';
import { defaultValues } from 'components/ModelViews/Invoices/InvoiceForm/defaultValues';
import { Header, HeadingSection } from 'components/Styled';
import { useDrawer } from 'hooks';
import {
  contactsAPI,
  transactionsAPI,
  useCreateInvoiceMutation,
  useGetInvoiceQuery,
} from 'redux/rtk-query';
import { processFormData } from '../InvoiceForm/formData';

export const EditInvoiceModal = ({ params, path }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const paramName = Object.keys(params)[0];

  const [createInvoice] = useCreateInvoiceMutation();
  const { isOpen, openDrawer, closeDrawer } = useDrawer(true, path);

  const { data: invoiceData } = useGetInvoiceQuery({
    id: params[paramName],
  });

  useEffect(() => {
    if (invoiceData?.data) {
      setInitialValues({
        ...defaultValues,
        ...invoiceData.data,
      });
      openDrawer();
    }
  }, [invoiceData]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    return await createInvoice(formData).then(() => {
      dispatch(contactsAPI.util.invalidateTags(['Invoices']));
      dispatch(transactionsAPI.util.invalidateTags(['Invoices']));
    });
  };

  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      {invoiceData?.data.closedApproved ? (
        <>
          <HeadingSection>
            <Header variant="h4">{invoiceData?.data?.name}</Header>
            <FontAwesomeIconComp
              icon={faCircleXmark}
              style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
              onClick={() => closeDrawer()}
            />
          </HeadingSection>
          <DetailsPanels
            data={invoiceData?.data}
            panels={{
              '': [
                'name',
                'status',
                'date',
                'priceFrequency',
                'price',
                'invoiceNumber',
                'files',
                'paidFrom',
                'paidTo',
                'description',
                'closedApproved',
              ],
            }}
            formatTypes={{
              cost: 'currency',
              paidFrom: 'model',
              paidTo: 'model',
              categories: 'modelArray',
              attachments: 'attachmentArray',
              closedApproved: 'boolean',
            }}
          />
        </>
      ) : (
        <InvoiceForm
          title={invoiceData?.data?.name}
          initialValues={initialValues}
          afterSave={closeDrawer}
          handleSubmitFunc={handleSubmitFunc}
        />
      )}
    </SideDrawer>
  );
};

EditInvoiceModal.propTypes = {
  path: PropTypes.string,
  params: PropTypes.object,
};
