import React from 'react';
import { Outlet, useParams, Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { REPORT_UPDATE } from 'consts';
import ReportConfig from 'pages/ReportPages/DetailsReportPage/ReportConfig';
import { ExtraParamOptionsProvider } from 'pages/ReportPages/ReportPage/context/ExtraParamOptionsContext';
import { useGetReportQuery } from 'redux/rtk-query';
import ReportTable from './ReportTable';

export const DetailsReportPage = () => {
  const { reportId } = useParams();
  const { data: reportData } = useGetReportQuery(reportId, { skip: !reportId });

  return (
    <ExtraParamOptionsProvider initialReportId={reportId}>
      <LayoutHeader
        title={reportData?.data?.name}
        afterTitle={
          <Button
            sx={{ ml: 1 }}
            to={`/${REPORT_UPDATE(reportId)}`}
            component={Link}
          >
            Edit
          </Button>
        }
      ></LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReportConfig reportId={reportId} />
          </Grid>
          <Grid item xs={12}>
            <ReportTable reportId={reportId} />
          </Grid>
        </Grid>
      </PageMainContent>
      <Outlet />
    </ExtraParamOptionsProvider>
  );
};
