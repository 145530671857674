import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, Box, Typography } from '@mui/material';
import { TransactionFolderIcon } from 'components/Icons';
import { DETAILS_TRANSACTION } from 'consts';
import { conversion } from 'helpers';
import { TransactionPlaceholderImage } from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDetails/components/TopCard/components';
import { useGetMlsListingQuery } from 'redux/rtk-query';

export const PropertyFlowCard = ({ row, hovered }) => {
  const { data: mlsListingData } = useGetMlsListingQuery(
    { id: row.mlsListing },
    { skip: typeof row.mlsListing !== 'string' },
  );
  const navigate = useNavigate();
  const mlsListingImageUrl = mlsListingData?.data?.image;

  return (
    <Card
      onClick={() => navigate(`/${DETAILS_TRANSACTION(row.id)}`)}
      sx={{
        width: 200,
        height: 200,
        backgroundImage: mlsListingImageUrl
          ? `url(${mlsListingImageUrl})`
          : 'none',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        transform: hovered ? 'scale(.95)' : 'scale(0.85)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        boxShadow: hovered ? 3 : 'none',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          borderRadius: 2,
          background:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ' +
            'rgba(0, 0, 0, 0.0) 50%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.6) 100%)',
          p: 2,
        }}
      >
        {!row.mlsListing && <TransactionPlaceholderImage />}
        <Typography variant="h5" sx={{ color: 'white', fontWeight: 700 }}>
          {conversion.getTransactionName(row, true)}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" sx={{ color: 'white', fontWeight: 700 }}>
            {row.address?.city}
          </Typography>
          <Typography variant="body1" sx={{ color: 'white', fontWeight: 700 }}>
            <Box display="inline-block" mr={1}>
              <TransactionFolderIcon model={{ status: row.status }} />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

PropertyFlowCard.propTypes = {
  row: PropTypes.object.isRequired,
  hovered: PropTypes.bool, // Add this prop
};
