import React from 'react';
import PropTypes from 'prop-types';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { RowButtonWrapper } from 'components/Styled';
import { MarketingItemIcon } from '../MarketingItemIcon';
import { MainContent, MainSummary } from './styled';

export const MarketingItem = ({ marketing }) => {
  const handleEdit = () => {};

  return (
    <MainContent>
      <MainSummary>
        <MarketingItemIcon type={marketing.type} />
        {marketing.name}
      </MainSummary>
      <RowButtonWrapper className={'showOnHover'}>
        <IconButton onClick={handleEdit} size={'small'}>
          <FontAwesomeIconComp icon={faEdit} size="sm" />
        </IconButton>
      </RowButtonWrapper>
    </MainContent>
  );
};

MarketingItem.propTypes = {
  marketing: PropTypes.object,
};
