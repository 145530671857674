import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, Typography, Button, Divider, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard } from 'components/Common';
import { AbeForm } from 'components/Forms';
import {
  Value,
  Condition,
  conditionOptions,
} from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters/Trigger';
import { ExtraParamOptionsContext } from 'pages/ReportPages/ReportPage/context/ExtraParamOptionsContext';
import { useGetFiltersQuery } from 'redux/rtk-query';

const LiveFilters = ({ reportId }) => {
  const [editingFilters, setEditingFilters] = useState(false);
  const { data: filtersData } = useGetFiltersQuery(reportId, {
    skip: !reportId,
  });
  const { setExtraParamOptions, extraParamOptions } = useContext(
    ExtraParamOptionsContext,
  );

  // Recursively flatten the filters from groups
  const getAllFilters = useCallback((group) => {
    let filters = [];
    if (group.filters && Array.isArray(group.filters)) {
      filters = filters.concat(group.filters);
    }
    if (group.childGroups && Array.isArray(group.childGroups)) {
      group.childGroups.forEach((childGroup) => {
        filters = filters.concat(getAllFilters(childGroup));
      });
    }
    return filters;
  }, []);

  const flattenedFilters = useMemo(
    () => (filtersData?.data ? getAllFilters(filtersData.data) : []),
    [filtersData, getAllFilters],
  );

  // Get only the filters that can be edited
  const editableFilters = useMemo(
    () => flattenedFilters.filter((filter) => filter.isEditable),
    [flattenedFilters],
  );

  // Set form initial values based on editable filters
  const [formInitialValues, setFormInitialValues] = useState({
    filters: editableFilters,
  });
  useEffect(() => {
    setFormInitialValues({ filters: editableFilters });
  }, [editableFilters]);

  // Update extraParamOptions and form state on submit
  const handleSubmit = useCallback(
    (values) => {
      setExtraParamOptions((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          editableFilters: values.filters.map((filter) => ({
            filterId: filter.id,
            value: filter.value,
            condition: filter.condition,
          })),
        },
      }));
      setFormInitialValues({ filters: values.filters });
      setEditingFilters(false);
    },
    [setExtraParamOptions],
  );

  // Reset filters to the original editable values
  const handleResetFilters = useCallback(() => {
    setFormInitialValues({ filters: editableFilters });
    setExtraParamOptions((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        editableFilters: editableFilters.map((filter) => ({
          filterId: filter.id,
          value: filter.value,
          condition: filter.condition,
        })),
      },
    }));
    setEditingFilters(false);
  }, [editableFilters, setExtraParamOptions]);

  // Helper to determine which value/condition to display
  const getDisplayData = (filter) => {
    const editedFilter =
      extraParamOptions.options.editableFilters.find(
        (f) => f.filterId === filter.id,
      ) || {};
    return {
      name: filter.name,
      condition: editedFilter.condition || filter.condition,
      value:
        editedFilter.value !== undefined ? editedFilter.value : filter.value,
    };
  };

  // Render form fields for editing filters
  const renderEditFields = useCallback(
    () => (
      <>
        <Grid container wrap="wrap" alignItems="center">
          <FieldArray name="filters">
            {({ fields }) =>
              fields.map((filterName) => (
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', mr: 2 }}
                  key={filterName}
                >
                  <Field name={`${filterName}.name`}>
                    {({ input }) => (
                      <Typography
                        variant="body1"
                        sx={{ whiteSpace: 'nowrap', mr: 1, minWidth: 100 }}
                      >
                        {input.value}
                      </Typography>
                    )}
                  </Field>
                  <Condition name={filterName} />
                  <Value name={filterName} />
                </Grid>
              ))
            }
          </FieldArray>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            justifyContent: 'flex-end',
          }}
        >
          <Grid item>
            <Button variant="outlined" type="submit">
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </Grid>
        </Grid>
      </>
    ),
    [handleResetFilters],
  );

  // Render the filters in display (non-edit) mode
  const renderDisplayFilters = () => (
    <Box display="inline-flex" justifyContent="space-between" width="100%">
      <Box display="inline-flex">
        {editableFilters.map((filter, index) => {
          const { name, condition, value } = getDisplayData(filter);
          return (
            <React.Fragment key={filter.id}>
              {index > 0 && (
                <Divider sx={{ mx: 1 }} flexItem orientation="vertical" />
              )}
              <Box display="inline-flex" alignItems="center">
                <Typography
                  variant="body1"
                  mr={1}
                  fontWeight={800}
                  color="info.main"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {name}:{' '}
                </Typography>
                {conditionOptions.date.includes(filter.condition) && (
                  <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                    {condition}
                  </Typography>
                )}
                <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                  {Array.isArray(value) ? (
                    <Tooltip
                      slotProps={{ tooltip: { sx: { maxWidth: 500 } } }}
                      title={value.join(', ')}
                    >
                      <span>{value.length} Selected</span>
                    </Tooltip>
                  ) : (
                    value
                  )}
                </Typography>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <Button variant="outlined" onClick={() => setEditingFilters(true)}>
        Edit Filters
      </Button>
    </Box>
  );

  return (
    <Grid container mb={2}>
      {editableFilters.length > 0 && (
        <Grid item xs={12}>
          <AbeCard noTitle>
            <Box px={2} py={1} position="relative">
              {editingFilters ? (
                <AbeForm
                  noGutter
                  handleSubmitFunc={handleSubmit}
                  initialValues={formInitialValues}
                >
                  {renderEditFields}
                </AbeForm>
              ) : (
                renderDisplayFilters()
              )}
            </Box>
          </AbeCard>
        </Grid>
      )}
    </Grid>
  );
};

LiveFilters.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default React.memo(LiveFilters);
