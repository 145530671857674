import React, { useEffect } from 'react';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { useForm } from 'react-final-form';
import { ReactSortable } from 'react-sortablejs';
import { Button, Box } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { conversion } from 'helpers';
import ReportFiltersTree from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersTree';
import Grouping from 'pages/ReportPages/CreateReportPage/ReportGroupingsPage/Grouping';

const ReportGroupingsPage = () => {
  const [showGroupingDrawer, setShowGroupingDrawer] = React.useState(false);
  const [groupingsState, setGroupingsState] = React.useState([]);
  const form = useForm();

  const groupings = form.getState().values['groupings'] || [];

  useEffect(() => {
    if (groupings.length) {
      const groupingsCopy = [...groupings];
      const processedGroupings = groupingsCopy.map((grouping) => ({
        ...grouping,
        path: grouping.visualPath,
      }));
      processedGroupings.sort((a, b) => a.order - b.order);
      setGroupingsState(processedGroupings);
    }
  }, [groupings]);

  const onHandleSelect = (selectedGrouping) => {
    const id = Date.now().toString(); // Or use a UUID generator
    const newGrouping = {
      id,
      name: conversion.camelCaseToWords(selectedGrouping.name),
      path: selectedGrouping.label.split(' -> '),
      visualPath: selectedGrouping.label.split(' -> '),
      order: groupingsState.length,
      groupingFunction: selectedGrouping.groupingFunction,
      dateSeparation: selectedGrouping.dateSeparation,
    };
    const updatedGroupings = [...groupingsState, newGrouping];
    setGroupingsState(updatedGroupings);
    form.change('groupings', updatedGroupings);
  };

  const handleGroupingsSort = (newGroupings) => {
    if (!newGroupings.length) return;
    const updatedGroupings = newGroupings.map((grouping, index) => ({
      ...grouping,
      order: index,
    }));
    setGroupingsState(updatedGroupings);
    form.change('groupings', updatedGroupings);
  };

  const removeGrouping = (index) => () => {
    const updatedGroupings = groupingsState.filter((_, i) => i !== index);
    const reorderedGroupings = updatedGroupings.map((grouping, index) => ({
      ...grouping,
      order: index,
    }));
    setGroupingsState(reorderedGroupings);
    form.change('groupings', reorderedGroupings);
  };

  return (
    <>
      <div>
        <ReactSortable
          list={groupingsState}
          setList={handleGroupingsSort}
          animation={200}
        >
          {groupingsState.map((grouping, index) => (
            <Box key={grouping.id} sx={{ px: 3 }}>
              <Grouping
                name={`groupings[${index}]`}
                removeGrouping={removeGrouping(index)}
              />
            </Box>
          ))}
        </ReactSortable>
        <Box sx={{ p: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowGroupingDrawer(true)}
            sx={{ ml: 2 }}
          >
            Add Grouping
          </Button>
        </Box>
      </div>
      <SideDrawer
        width="400px"
        isOpen={showGroupingDrawer}
        closeDrawer={() => setShowGroupingDrawer(false)}
        closeOnClickAway={() => setShowGroupingDrawer(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'none',
          },
        }}
      >
        <HeadingSection>
          <Header variant="h4">Select Grouping</Header>
          <FontAwesomeIconComp
            icon={faCircleXmark}
            style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
            onClick={() => setShowGroupingDrawer(false)}
          />
        </HeadingSection>
        <ReportFiltersTree type={'groupings'} onSelect={onHandleSelect} />
      </SideDrawer>
    </>
  );
};

export default ReportGroupingsPage;
