import React from 'react';
import { faSignHanging, faComment } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModuleIcon } from 'components/Common';
import { colors } from 'consts';
import { iconMap, REACTIONS } from 'consts/properties';

const PropertyUpdatesCard = () => {
  const iconFontSize = '2.5rem';
  const iconModule = 'Properties';
  const count = 79;
  const label = 'Property Updates';

  return (
    <Grid item xs={12}>
      <Card
        elevation={1}
        sx={{
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'translateY(-3px)',
            boxShadow: 3,
          },
        }}
      >
        <Grid container py={2}>
          {/* Middle Column: Big Number & Label */}
          <Grid
            item
            xs={5}
            py={2}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRight: '1px solid lightgrey',
            }}
          >
            <Typography variant="h2" display="flex" alignItems="center">
              <ModuleIcon
                marginRight={10}
                fontSize={iconFontSize}
                module={iconModule}
              />
              {count}
            </Typography>
            <Typography variant="h4" sx={{ mt: 1, whiteSpace: 'pre' }}>
              {label}
            </Typography>
          </Grid>

          {/* Right Column */}
          <Grid
            item
            xs={7}
            container
            direction="column"
            justifyContent="center"
            sx={{
              p: 2,
            }}
          >
            {/* Row 1: 3 Past Due */}
            <Grid
              sx={{
                maxWidth: '210px !important',
                alignSelf: 'center',
              }}
              container
              item
              columns={12}
              alignItems="center"
            >
              <Grid item xs={2}>
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                >
                  {/* LOVE icon circle */}
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      backgroundColor: iconMap[REACTIONS.LOVE].color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mr: -1, // negative margin to overlap the next icon
                      zIndex: 2, // ensure this circle is on top
                    }}
                  >
                    <FontAwesomeIcon
                      icon={iconMap[REACTIONS.LOVE].icon}
                      color="#fff"
                      fontSize="1rem"
                    />
                  </Box>

                  {/* LIKE icon circle */}
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      backgroundColor: iconMap[REACTIONS.LIKE].color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 1,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={iconMap[REACTIONS.LIKE].icon}
                      color="#fff"
                      fontSize="1rem"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} justifyItems={'center'}>
                <Typography variant="h5">6</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">Reactions</Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                maxWidth: '210px !important',
                alignSelf: 'center',
              }}
              container
              item
              columns={12}
              alignItems="center"
            >
              <Grid item xs={2} justifyItems={'center'}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: colors.BLUE_1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faComment}
                    color="#fff"
                    fontSize="1rem"
                  />
                </Box>
              </Grid>
              <Grid justifyItems={'center'} item xs={2}>
                <Typography variant="h5">3</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">Comments</Typography>
              </Grid>
            </Grid>
            {/* Row 2: 26 Search Updates */}
            <Grid
              sx={{
                maxWidth: '210px !important',
                alignSelf: 'center',
              }}
              container
              item
              columns={12}
              alignItems="center"
            >
              <Grid item xs={2} justifyItems={'center'}>
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: colors.PURPLE,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSignHanging}
                    color="#fff"
                    fontSize="1rem"
                  />
                </Box>
              </Grid>
              <Grid justifyItems={'center'} item xs={2}>
                <Typography variant="h5">26</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">Listing Updates</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default PropertyUpdatesCard;
