import React from 'react';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { alpha } from '@mui/material/styles';
import { FontAwesomeIconComp } from 'components/Common';
import { getModuleIcon } from 'helpers';

export const ModuleIcon = ({
  module,
  type = null,
  closedApproved = false,
  fontSize = '1.8rem',
  marginRight = 0,
  maxWidth = '100%',
}) => {
  const iconInfo = getModuleIcon(module, type);
  if (!iconInfo.icon) return null;
  const color = iconInfo.color ? alpha(iconInfo.color, 0.75) : null;

  return (
    <>
      {closedApproved ? (
        <span className="fa-layers">
          <FontAwesomeIconComp
            className="fa-stack-1x"
            transform="left-1"
            icon={iconInfo.icon}
            fontSize={fontSize}
            color={color}
          />
          <FontAwesomeIconComp
            className="fa-stack-1x"
            icon={faCheck}
            fontSize={fontSize}
            transform="shrink-7 down-1 left-1"
            color={color}
          />
        </span>
      ) : (
        <FontAwesomeIconComp
          style={{ marginRight: marginRight, maxWidth: maxWidth }}
          icon={iconInfo.icon}
          fontSize={fontSize}
          color={color}
        />
      )}
    </>
  );
};

ModuleIcon.propTypes = {
  module: PropTypes.string.isRequired,
  type: PropTypes.string,
  closedApproved: PropTypes.bool,
  fontSize: PropTypes.string,
  marginRight: PropTypes.number,
  maxWidth: PropTypes.string,
};
