import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ConfirmDialog } from 'components/Common';

export const ConfirmationButton = ({
  label,
  confirmTitle,
  confirmMessage,
  handleConfirm,
  color,
  variant = 'contained',
  sx = {},
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        sx={{ mx: 1, height: 32, color: 'white', ...sx }}
        variant={variant}
        color={color}
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        {label}
      </Button>
      <ConfirmDialog
        title={confirmTitle}
        message={confirmMessage}
        open={open}
        setOpen={setOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
};

ConfirmationButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  color: PropTypes.string,
  confirmTitle: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  variant: PropTypes.string,
  sx: PropTypes.object,
};
