// Image.js
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { hexToRgb } from '@mui/system';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import PositionedMediaImage from 'components/Common/AbeFileImage/PositionedMediaImage';
import PositionedImage from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields/components/PositionedImage';
import { SvgImageComponent } from 'components/ModelViews/Documents/ClickMarketingViewer/components/DocumentItem/components/fields/components/SvgImageComponent';
import { useCurrentItem } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useCurrentItem';
import { useItemLabelValue } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useItemLabelValue';
import { logoComponents } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { CM_ITEM_TYPES } from 'consts';
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import {
  currentDocumentSelectorCM,
  autofillDataSelectorCM,
} from 'redux/selectors';

export const Image = React.memo(({ itemId }) => {
  const currentDocument = useSelector(currentDocumentSelectorCM);
  const currentItem = useCurrentItem(itemId);
  const labelValue = useItemLabelValue(currentItem);
  const autoFillData = useSelector(autofillDataSelectorCM);
  const { data: fileData } = useGetMediaUrl(
    autoFillData.agentIcon,
    'id',
    autoFillData.agentSettingId,
  );
  const { data: labelValueUrlData } = useGetMediaUrl({
    uuid: labelValue?.fileId,
  });

  if (!currentItem) return null;

  const computeBackgroundStyle = (item, autoFillData) => {
    let backgroundStyle = item?.style?.background;
    if (item?.style?.backgroundThemeColor) {
      const themeColor =
        autoFillData.customColor === 'mainframeTheme'
          ? '#FFFFFF'
          : autoFillData.customColor;
      const rgbString = hexToRgb(themeColor);
      const rgbaMatch = item.style.background.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/,
      );
      if (rgbString && rgbaMatch) {
        backgroundStyle = `rgba(${rgbString}, ${rgbaMatch[4]})`;
      }
    }
    return backgroundStyle;
  };

  const backgroundStyle = computeBackgroundStyle(currentItem, autoFillData);

  const imageComponent = useMemo(() => {
    const { type, style } = currentItem;
    const fillColor =
      style?.svgColor === 'customColor' ? style.customColor : style?.svgColor;

    switch (type) {
      case CM_ITEM_TYPES.image.logo.svgLogo:
      case CM_ITEM_TYPES.image.logo.svgIcon:
        return (
          <SvgImageComponent
            SvgComponent={logoComponents[type]}
            fillColor={fillColor}
          />
        );
      case CM_ITEM_TYPES.image.svgImage:
        return (
          <SvgImageComponent
            SvgComponent={labelValueUrlData?.data?.url || logoComponents[type]}
            fillColor={fillColor}
          />
        );

      case CM_ITEM_TYPES.image.attachment: {
        if (currentDocument.cmBlockType === CM_ITEM_TYPES.modular.Template) {
          return (
            <PositionedMediaImage
              currentItem={currentItem}
              containerWidth={parseFloat(currentItem.w)}
              containerHeight={parseFloat(currentItem.h)}
              borderRadius={parseFloat(currentItem.style?.borderRadius || 0)}
            />
          );
        } else {
          return (
            <div
              style={{
                pointerEvents: 'none',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                padding: 10,
                backgroundColor: 'grey',
                zIndex: -2,
                position: 'relative',
              }}
            />
          );
        }
      }

      case CM_ITEM_TYPES.image.agentIcon:
        if (fileData?.data?.url) {
          return (
            <SvgImageComponent
              SvgComponent={fileData.data.url}
              fillColor={fillColor}
            />
          );
        }
        return null;

      case CM_ITEM_TYPES.image.agent:
      case CM_ITEM_TYPES.image.agent2: {
        const agentImage =
          (type === CM_ITEM_TYPES.image.agent && autoFillData.agentHeadshot) ||
          (type === CM_ITEM_TYPES.image.agent2 && autoFillData.agent2Headshot);

        if (agentImage) {
          return (
            <AbeFileImage
              style={{
                pointerEvents: 'none',
              }}
              file={agentImage}
              identifierValue={autoFillData.agentSettingId}
              identifier="id"
            />
          );
        }
        return null;
      }

      case CM_ITEM_TYPES.image.property: {
        const imageSrc =
          autoFillData.propertyPhotos?.[Number(currentItem.imageNumber)];
        return imageSrc ? (
          <PositionedImage
            src={imageSrc}
            alt={`Property Photo ${currentItem.imageNumber}`}
            positionX={parseFloat(currentItem.positionX)}
            positionY={parseFloat(currentItem.positionY)}
            backgroundW={parseFloat(currentItem.backgroundW)}
            backgroundH={parseFloat(currentItem.backgroundH)}
            containerWidth={parseFloat(currentItem.w)}
            containerHeight={parseFloat(currentItem.h)}
            borderRadius={parseFloat(currentItem.style?.borderRadius || 0)}
            itemId={currentItem.id}
          />
        ) : (
          <div>No Image Available</div>
        );
      }

      default:
        return (
          <img
            src={currentItem.src}
            alt={currentItem.alt}
            style={{ width: '100%', height: '100%' }}
          />
        );
    }
  }, [currentItem, autoFillData, fileData, currentDocument.cmBlockType]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {currentItem.style?.background && (
        <div
          style={{
            background: backgroundStyle,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
      {imageComponent}
    </Box>
  );
});

Image.propTypes = {
  itemId: PropTypes.string.isRequired,
};

Image.displayName = 'Image';
