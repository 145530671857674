import React from 'react';
import PropTypes from 'prop-types';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common/FontAwesomeIconComp';
import { colors } from 'consts';

export function StarToggle({ handleToggleStar, isStarred }) {
  return (
    <IconButton onClick={handleToggleStar}>
      <FontAwesomeIconComp
        icon={faStar}
        fontSize={'18px'}
        color={isStarred ? colors.YELLOW : colors.LIGHT_GREY}
      />
    </IconButton>
  );
}

StarToggle.propTypes = {
  handleToggleStar: PropTypes.func.isRequired,
  isStarred: PropTypes.bool.isRequired,
};
