import queryString from 'query-string';
import { endpoints } from 'consts';

export const contactUrlBuilder = {
  createList: function () {
    return endpoints.contactList;
  },
  updateList: function () {
    return endpoints.contactList;
  },
  getList: function ({ id }) {
    return `${endpoints.contactList}/${id}`;
  },
  getNotes: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.contacts}/${uuid}/notes?${urlParams}`;
  },
  getTasks: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.contacts}/${uuid}/tasks?${urlParams}`;
  },
  getContactLists: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.contacts}/${uuid}/lists?${urlParams}`;
  },
  getContactTags: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.contacts}/${uuid}/tags?${urlParams}`;
  },
  deleteList: function ({ uuid }) {
    return `${endpoints.contactList}/${uuid}`;
  },
  getLists: function ({
    page = 1,
    limit = 15,
    search,
    justFavorite = 0,
    skipFavorite = 0,
  } = {}) {
    return `${endpoints.contactList}?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }&just_favourite=${justFavorite}&skip_favourite=${skipFavorite}`;
  },
  getContacts: ({ id, ...other } = {}) => {
    const urlParams = queryString.stringify(other, { skipNull: true });
    if (id) {
      return `${endpoints.contactList}/${id}/contacts?${urlParams}`;
    }
    return `${endpoints.contacts}` + `?${urlParams}`;
  },

  addToFavourite: function () {
    return `${endpoints.contactList}/favourite/add`;
  },
  removeFromFavorite: function () {
    return `${endpoints.contactList}/favourite/remove`;
  },
  addToList: function () {
    return `${endpoints.contactList}/link`;
  },
  removeFromList: function () {
    return `${endpoints.contactList}/unlink`;
  },
  validateContact: function () {
    return `${endpoints.contacts}/validate`;
  },
  createContact: function () {
    return endpoints.contacts;
  },
  updateContact: function () {
    return endpoints.contacts;
  },
  getContact: ({ id }) => {
    return `${endpoints.contacts}/${id}/details`;
  },
  getAuditLogs: ({ uuid }) => {
    return `${endpoints.contacts}/${uuid}/logs`;
  },
};
