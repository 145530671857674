import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'react-final-form';
import { Typography, Grid, IconButton, Card } from '@mui/material';
import { colors } from 'consts';
import { pathArrayToPathLabel } from 'helpers/report';

const Grouping = ({ name, removeGrouping }) => {
  const visualPathValue = useField(`${name}.visualPath`).input.value;
  const groupingFunctionValue = useField(`${name}.groupingFunction`).input
    .value;

  return (
    <Card
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: '#f5f5f5',
        px: '16px',
        py: '8px',
        borderRadius: '8px',
        mb: 2,
      }}
    >
      <Grid container alignItems={'center'}>
        {/* Display the visualPath */}
        <Grid item width={300}>
          <Typography variant="body1" color={colors.BLACK}>
            {pathArrayToPathLabel(visualPathValue)}
            {groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''}
          </Typography>
        </Grid>
        <IconButton onClick={removeGrouping}>
          <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
        </IconButton>
      </Grid>
    </Card>
  );
};

Grouping.propTypes = {
  name: PropTypes.string.isRequired,
  removeGrouping: PropTypes.func.isRequired,
};

export default Grouping;
