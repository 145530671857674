import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { ClickAwayListener } from '@mui/material';
import AutocompleteField from '@mui/material/Autocomplete';
import { Icon } from 'components/Fields/TextInput/styled';
import { TextInputField } from 'components/Styled';
import { useLoadMoreOptions } from 'hooks';
import {
  ToolbarAction,
  ToolbarButton,
  AutocompleteBox,
} from 'pages/TransactionsPages/TransactionsPage/components/TransactionsTable/components/TableToolbar/styled';
import { useGetTransactionsQuery } from 'redux/rtk-query';

export const SelectTransactionButton = ({ transaction, setTransaction }) => {
  const [open, setOpen] = useState(false);
  const contacts = useLoadMoreOptions(useGetTransactionsQuery, null, {
    selectField: true,
  });

  // newValue is the selected Transaction object from the options.
  const handleChange = (e, newValue) => {
    if (newValue) {
      setTransaction(newValue);
      setOpen(false);
    }
  };

  return (
    <>
      {!open ? (
        <ToolbarAction
          onClick={() => {
            setOpen(true);
            contacts.handleOpen();
          }}
        >
          <ToolbarButton variant="outlined" size="medium">
            Select Transaction
          </ToolbarButton>
        </ToolbarAction>
      ) : (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <AutocompleteBox>
            <AutocompleteField
              autoFocus
              open={true}
              // Make the autocomplete controlled by providing the current value.
              value={transaction || null}
              name="contact"
              placeholder="Search for a Contact"
              size="small"
              variant="standard"
              options={contacts.options}
              onOpen={contacts.handleOpen}
              loadMoreResults={contacts.handleMore}
              isLoading={contacts.isFetching}
              iconStart={<Icon icon={faMagnifyingGlass} />}
              onChange={handleChange}
              renderInput={(params) => (
                <TextInputField
                  {...params}
                  autoFocus
                  onChange={(e) => contacts.handleSearch(e.target.value)}
                />
              )}
            />
          </AutocompleteBox>
        </ClickAwayListener>
      )}
    </>
  );
};

SelectTransactionButton.propTypes = {
  transaction: PropTypes.object,
  setTransaction: PropTypes.func.isRequired,
};
