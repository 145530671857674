import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableRow as MuiTableRow, TableBody } from '@mui/material';
import { TableRow } from 'components/Common';
import { ListPagination } from 'components/ListPagination';
import { TableCell, TableCellLoader } from 'components/Styled';
import { useItemCount } from 'contexts';
import { taskColor } from 'helpers';
import { Cells } from 'pages/ActivityPages/ActivityPage/components/TasksActivityTable/config';
import { useGetTasksQuery, useUpdateTaskMutation } from 'redux/rtk-query';
import { activityFiltersSelector } from 'redux/selectors';

const TaskSectionTableContent = ({ sectionType, timeframes }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const activityFilters = useSelector(activityFiltersSelector);

  const { setItemCount } = useItemCount();

  const listFilterValues =
    activityFilters.filterType === 'lists'
      ? activityFilters?.filterValues?.map((list) => list.value).join(',')
      : '';

  const { data, isFetching } = useGetTasksQuery({
    taskType: timeframes ? 'Timeframe' : 'Task',
    type: sectionType,
    // search: search,
    page: page,
    limit: rowsPerPage,
    listIds: listFilterValues,
    status: sectionType !== 'COMPLETED' ? ['New', 'In Progress'] : null,
    sortAttribute: sectionType === 'COMPLETED' ? 'updatedAt' : 'dueOn',
    sortOrder: sectionType === 'COMPLETED' ? 'DESC' : 'ASC',
  });
  const [updateTask] = useUpdateTaskMutation();

  const [loading, setLoading] = React.useState(true);
  const totalPages = data?.data?.meta.totalPages;
  const rows = data?.data?.items;
  const totalCount = data?.data?.meta.total;

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleCheckClick = (id, row) => async () => {
    let status = row.status === 'Completed' ? 'New' : 'Completed';
    await updateTask({
      status,
      taskId: id,
    });
  };

  useEffect(() => {
    setItemCount(totalCount);
  }, [totalCount]);
  return (
    <TableBody sx={{ borderLeft: `4px solid ${taskColor(sectionType)}` }}>
      <>
        {loading ? (
          <tr>
            <TableCell />
            {Cells?.map((cell, index) => {
              return (
                <TableCell key={index}>
                  <TableCellLoader />
                </TableCell>
              );
            })}
          </tr>
        ) : (
          <>
            {rows?.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  setSelected={handleCheckClick}
                  selected={[]}
                  // search={search}
                  key={labelId}
                  row={row}
                  cells={Cells}
                  labelId={labelId}
                  firstCellSx={{
                    borderLeft: `4px solid ${taskColor(sectionType)}`,
                  }}
                />
              );
            })}
            {totalPages > 1 ? (
              <MuiTableRow>
                <TableCell
                  style={{
                    textAlign: 'center',
                    padding: 3,
                    borderLeft: `4px solid ${taskColor(sectionType)}`,
                  }}
                  colSpan={7}
                >
                  <ListPagination
                    totalCount={totalCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    rowsPerPageOptions={[5]}
                  />
                </TableCell>
              </MuiTableRow>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    </TableBody>
  );
};

export default TaskSectionTableContent;

TaskSectionTableContent.propTypes = {
  sectionType: PropTypes.string,
  timeframes: PropTypes.bool,
};
