import queryString from 'query-string';
import { endpoints } from 'consts';

export const transactionUrlBuilder = {
  validateTransaction: function () {
    return `${endpoints.transactions}/validate`;
  },
  getSalesPipeline: function (params) {
    console.log('params', params);
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.transactions}/sales-pipeline?${urlParams}`;
  },
  createTransaction: function () {
    return endpoints.transactions;
  },
  updateTransaction: function () {
    return endpoints.transactions;
  },
  updateTransactionType: function (id) {
    return `${endpoints.transactions}/${id}/transaction-type`;
  },
  getTransactions: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.transactions}?${urlParams}`;
  },
  getTransaction: function ({ id }) {
    return `${endpoints.transactions}/${id}`;
  },
  getTransactionRoles: function ({ id }) {
    return `${endpoints.transactions}/${id}/roles`;
  },
  getTasks: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.transactions}/${uuid}/tasks?${urlParams}`;
  },
  getNotes: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.transactions}/${uuid}/notes?${urlParams}`;
  },
  getCommissions: function ({ uuid, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.transactions}/${uuid}/commissions?${urlParams}`;
  },
  getInvoices: function ({ id, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.transactions}/${id}/invoices?${urlParams}`;
  },
  getTransactionTimeframes: function ({ uuid, ...params }) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.transactions}/${uuid}/timeframes?${urlParams}`;
  },
  getRelatedTransactions: function ({ id, ...other }) {
    const urlParams = queryString.stringify(other, { skipNull: true });
    return `${endpoints.transactions}/related/${id}?${urlParams}`;
  },
  toggleStarTransaction: function (uuid) {
    return `${endpoints.transactions}/${uuid}/favourite`;
  },
  getTransactionClosingStatement: function ({ id }) {
    return `${endpoints.transactions}/${id}/closing-instructions`;
  },
  getOffers: function ({ id }) {
    return `${endpoints.transactions}/${id}/offers`;
  },
  getAuditLogs: ({ uuid }) => {
    return `${endpoints.transactions}/${uuid}/logs`;
  },
};
