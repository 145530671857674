import { ReactComponent as MainframeLogo } from '../assets/MainframeLogo.svg';
import { ReactComponent as Micon } from '../assets/Micon.svg';
import { ReactComponent as SvgImage } from '../assets/SvgImage.svg';

export const logoComponents = {
  svgImage: SvgImage,
  svgLogo: MainframeLogo,
  svgIcon: Micon,
};

export const fontOptions = {
  'Company Fonts': {
    Quicksand: ['Light', 'Regular', 'Medium', 'SemiBold', 'Bold'],
    Lato: ['Light', 'Regular', 'Italic', 'Bold'],
  },
  Script: [
    'MS Madi',
    'Rouge Script',
    'Send Flowers',
    'Dancing Script',
    'Clicker Script',
    'Pacifico',
    'Damion',
  ],
  Serif: [
    'Abril Fatface',
    'Jacques Francois Shadow',
    'Josefin Slab',
    'Roboto Slab',
    'Vast Shadow',
  ],
  'Sans Serif': [
    'Josefin Sans',
    'Zen Kurenaido',
    'Bebas Neue',
    'Open Sans',
    'Teko',
  ],
};

export const getComputedBorderRadius = (item) => {
  let borderRadiusPercentage = item.style?.borderRadius
    ? parseFloat(item.style.borderRadius.replace('%', '')) / 100
    : 0;
  return (
    Math.min(parseFloat(item.w), parseFloat(item.h)) * borderRadiusPercentage
  );
};

export const getFontFamily = (fontFamily, header, autoFillData) => {
  if (fontFamily === 'themeFont') {
    const themeFont =
      autoFillData?.themeFonts[header ? 'headerFont' : 'bodyFont'];
    if (!themeFont || themeFont.includes('Quicksand')) {
      return 'Quicksand';
    }
    return themeFont;
  }
  if (fontFamily === 'scriptFont') {
    return autoFillData?.themeFonts['scriptFont'];
  }
  if (fontFamily.includes('Lato')) {
    return 'Lato';
  }
  if (fontFamily.includes('Quicksand')) {
    return 'Quicksand';
  }
  return fontFamily;
};

export const getFontStyle = (font) => {
  if (font.includes('Italic')) {
    return 'italic';
  }
};

export const getFontWeight = (fontFamily, header, autoFillData) => {
  if (fontFamily === 'themeFont') {
    fontFamily = autoFillData.themeFonts[header ? 'headerFont' : 'bodyFont'];
  }
  if (!fontFamily) {
    return 400;
  }
  const font =
    fontFamily.includes('Lato') || fontFamily.includes('Quicksand')
      ? fontFamily.substring(fontFamily.indexOf(' ') + 1)
      : fontFamily;

  switch (font) {
    case 'Light':
      return 300;
    case 'Regular':
      return 400;
    case 'Medium':
      return 500;
    case 'SemiBold':
      return 600;
    case 'Bold':
      return 700;
    default:
      return 400;
  }
};
