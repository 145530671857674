import { useCallback, useEffect, useMemo, useState } from 'react';

export function useTableData(
  dataFetchHookOrTuple, // Accepts either a query hook or a mutation tuple
  initialColumns,
  initialOrderBy,
  initialOrder = 'asc',
  initialRowsPerPage = 10,
  extraParams = {},
  dataHookOptions = {},
) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [filterValues, setFilterValues] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [search, setSearch] = useState('');
  const [visibleColumns, setVisibleColumns] = useState(
    initialColumns.map((cell) => ({
      ...cell,
      isVisible: cell.isVisible ?? false,
    })),
  );
  // New: local state to store fetched data so it stays stable
  const [fetchedData, setFetchedData] = useState(null);

  // Memoize extraParams and dataHookOptions to avoid unnecessary re-renders.
  const memoizedExtraParams = useMemo(
    () => extraParams,
    [JSON.stringify(extraParams)],
  );
  const memoizedDataHookOptions = useMemo(
    () => dataHookOptions,
    [JSON.stringify(dataHookOptions)],
  );

  const toggleColumnVisibility = useCallback((columnId) => {
    setVisibleColumns((cols) =>
      cols.map((col) =>
        col.id === columnId ? { ...col, isVisible: !col.isVisible } : col,
      ),
    );
  }, []);

  const resetColumns = useCallback(() => {
    setVisibleColumns(
      initialColumns.map((cell) => ({
        ...cell,
        isVisible: cell.isVisible ?? false,
      })),
    );
  }, [initialColumns]);

  // Memoize query parameters to avoid unnecessary re-renders.
  const queryParams = useMemo(
    () => ({
      page,
      limit: rowsPerPage,
      search,
      order: order.toUpperCase(),
      orderBy,
      column: visibleColumns
        .filter((col) => col.isVisible)
        .map((col) => col.id),
      ...filterValues,
      ...memoizedExtraParams,
    }),
    [
      page,
      rowsPerPage,
      search,
      order,
      orderBy,
      visibleColumns,
      filterValues,
      memoizedExtraParams,
    ],
  );

  // Determine if we’re handling a mutation or a query
  const isMutation = Array.isArray(dataFetchHookOrTuple);
  let trigger, result, queryResult;
  if (isMutation) {
    [trigger, result] = dataFetchHookOrTuple;
  } else {
    queryResult = dataFetchHookOrTuple(queryParams, memoizedDataHookOptions);
  }

  // For mutations, trigger the fetch whenever the query params change.
  useEffect(() => {
    if (isMutation && trigger) {
      trigger(queryParams, memoizedDataHookOptions);
    }
  }, [queryParams, memoizedDataHookOptions, isMutation, trigger]);

  // Update our fetchedData state when the hook returns new data.
  useEffect(() => {
    if (isMutation && result) {
      setFetchedData(result.data);
    }
  }, [result, isMutation]);

  useEffect(() => {
    if (!isMutation && queryResult) {
      setFetchedData(queryResult.data);
    }
  }, [queryResult?.data, isMutation]);

  // Derive table-specific data from the fetched data.
  const tableData = useMemo(() => {
    if (!fetchedData) {
      return { rows: [], totalPages: 0, totalCount: 0, otherData: null };
    }
    const totalPages = fetchedData?.meta
      ? fetchedData.meta.totalPages
      : fetchedData?.data?.meta?.totalPages;
    const totalCount = fetchedData?.meta
      ? fetchedData.meta.total
      : fetchedData?.data?.meta?.total;
    const rows =
      fetchedData?.data?.items || fetchedData?.data || fetchedData?.items || [];
    const otherData = fetchedData?.meta
      ? fetchedData.meta.otherData
      : fetchedData?.data?.meta?.otherData;
    return { rows, totalPages, totalCount, otherData };
  }, [fetchedData]);

  // Derive an isFetching flag based on whether we’re in mutation or query mode.
  const isFetching = useMemo(() => {
    if (isMutation) {
      return result?.isLoading || result?.isFetching;
    }
    return queryResult?.isLoading || queryResult?.isFetching;
  }, [isMutation, result, queryResult]);

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  // Reset page to 1 if the search query changes.
  useEffect(() => {
    if (search) {
      setPage(1);
    }
  }, [search]);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy],
  );

  // Return a memoized object containing all the table state and actions.
  return useMemo(
    () => ({
      order,
      orderBy,
      filterValues,
      page,
      rowsPerPage,
      search,
      totalPages: tableData.totalPages,
      totalCount: tableData.totalCount,
      rows: tableData.rows,
      otherData: tableData.otherData,
      visibleColumns,
      toggleColumnVisibility,
      resetColumns,
      loading,
      setFilterValues,
      setPage,
      setRowsPerPage,
      setSearch,
      handleRequestSort,
      // Also pass along any extra properties from the fetch hook (if needed)
      ...(isMutation ? result : queryResult),
    }),
    [
      order,
      orderBy,
      filterValues,
      page,
      rowsPerPage,
      search,
      tableData,
      visibleColumns,
      toggleColumnVisibility,
      resetColumns,
      loading,
      handleRequestSort,
      isMutation,
      result,
      queryResult,
    ],
  );
}
