import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Card, Button } from '@mui/material';
import { AbeForm } from 'components/Forms';
import { ButtonSection } from 'components/Styled';
import ChartTypeSelect from 'pages/ReportPages/DetailsReportPage/ChartTypeSelect';
import { DatasetsFields } from 'pages/ReportPages/DetailsReportPage/DatasetsFields';
import {
  useCreateChartMutation,
  useUpdateChartMutation,
} from 'redux/rtk-query';

const ChartForm = ({ reportId, afterSave, chartData }) => {
  const isEditMode = !!chartData;
  const [createChart] = useCreateChartMutation();
  const [updateChart] = useUpdateChartMutation();

  const initialValues = {
    ...chartData,
    reportId,
    datasets:
      chartData?.datasets.map((dataset) => ({
        axis: dataset.axis,
        columnId: dataset.column?.id,
      })) || [],
  } || {
    type: '',
    name: '',
    datasets: [],
    reportId: reportId,
  };

  const onSubmit = async (values) => {
    const { datasets } = values;
    const filteredDatasets = datasets.filter((dataset) => dataset.columnId);

    if (isEditMode) {
      await updateChart({
        ...values,
        datasets: filteredDatasets,
        reportId,
        chartId: chartData.id,
      }).unwrap();
    } else {
      await createChart({
        ...values,
        datasets: filteredDatasets,
        reportId,
      }).unwrap();
    }
    afterSave();
  };

  const renderFields = () => (
    <>
      <Field name={'type'} label={'Chart Type'} component={ChartTypeSelect} />
      <Field name={'datasets'} component={DatasetsFields} />
      <ButtonSection>
        <Button
          variant="outlined"
          color="info"
          onClick={() => {
            afterSave();
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" type="submit">
          Save
        </Button>
      </ButtonSection>
    </>
  );

  return (
    <Card sx={{ p: 2 }}>
      <AbeForm
        afterSave={afterSave}
        initialValues={initialValues}
        handleSubmitFunc={onSubmit}
        showAllFieldsButton={false}
      >
        {renderFields}
      </AbeForm>
    </Card>
  );
};

ChartForm.propTypes = {
  reportId: PropTypes.string.isRequired,
  afterSave: PropTypes.func,
  chartData: PropTypes.object,
};

export default ChartForm;
