import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { Grid, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { SideDrawer, FontAwesomeIconComp } from 'components/Common';
import { Header, StickyHeader } from 'components/Styled';
import { PhotoSetItem } from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDetails/components/PhotoSetItem';
import PhotoSetPlaceholderUpload from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDetails/components/PhotoSetPlaceholderUpload';
import { useDeleteFileMutation } from 'redux/rtk-query';

export const PhotoSetDrawer = ({ documentData, open = false, onClose }) => {
  const attachments = documentData?.attachments || [];
  const [unlinkFile] = useDeleteFileMutation();

  const handleUnlinkFile = async (fileId) => {
    // id, documentId, fileUUID
    await unlinkFile({
      id: documentData.id,
      documentId: documentData.id,
      fileUUID: fileId,
    });
  };
  return (
    <SideDrawer
      id={'photo-set-drawer'}
      width={'60%'}
      isOpen={open}
      closeDrawer={onClose}
    >
      <StickyHeader>
        <Header variant="h4">Photo Set</Header>
        <FontAwesomeIconComp
          icon={faCircleXmark}
          style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
          onClick={() => onClose()}
        />
      </StickyHeader>
      <Divider />
      <Box>
        <Grid container spacing={1} p={1}>
          <Grid item xs={3}>
            <PhotoSetPlaceholderUpload
              documentId={documentData?.id?.toString()}
            />
          </Grid>
          {attachments.map((attachment) => (
            <Grid key={attachment.id} item xs={3}>
              <PhotoSetItem
                attachment={attachment}
                handleUnlinkFile={handleUnlinkFile}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </SideDrawer>
  );
};

PhotoSetDrawer.propTypes = {
  documentData: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
