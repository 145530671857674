// DesignItems.js

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  htmlTemplateSelectorCM,
  activePageCM,
  cmBlockTypeSelectorCM,
  itemGroupsSelectorCM,
} from 'redux/selectors';
import DesignItem from './DesignItem';
import ItemGroup from './ItemGroup';

const DesignItems = ({ pageId, preview = false, scale = null }) => {
  const htmlTemplate = useSelector(htmlTemplateSelectorCM);
  const activePageFromStore = useSelector(activePageCM);
  const modularType = useSelector(cmBlockTypeSelectorCM);
  const itemGroups = useSelector(itemGroupsSelectorCM);

  // Determine the active page
  const activePage = useMemo(
    () => pageId || activePageFromStore,
    [pageId, activePageFromStore],
  );

  // Retrieve the page details
  const page = useMemo(
    () => htmlTemplate?.options?.pages?.[activePage],
    [htmlTemplate, activePage],
  );

  // Get elements and design items
  const elements = useMemo(() => page?.elements || [], [page]);
  const designItems = useMemo(() => htmlTemplate?.items || [], [htmlTemplate]);

  // Map items by ID for quick access
  const itemsById = useMemo(() => {
    const map = {};
    designItems.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  }, [designItems]);

  const itemGroupsForPage = useMemo(
    () => itemGroups?.[activePage] || {},
    [itemGroups, activePage],
  );

  if (modularType === 'Template') {
    return (
      <>
        {Object.entries(itemGroupsForPage).flatMap(
          ([modularElementId, itemGroupTemplates]) =>
            Object.entries(itemGroupTemplates).map(
              ([itemTemplateId, itemTemplate]) =>
                itemTemplate && itemTemplate.page === activePage ? (
                  <ItemGroup
                    key={itemTemplateId}
                    templateId={itemTemplateId}
                    modularType={modularElementId}
                    itemIds={itemTemplate.items} // Assuming itemTemplate.items is memoized
                    itemsById={itemsById} // Memoized object
                    preview={preview}
                    scale={scale}
                  />
                ) : null,
            ),
        )}
      </>
    );
  }

  return (
    <>
      {elements.map((id) => {
        const item = itemsById[id];
        if (!item) return null;
        return (
          <DesignItem key={id} item={item} preview={preview} scale={scale} />
        );
      })}
    </>
  );
};

DesignItems.propTypes = {
  pageId: PropTypes.string,
  preview: PropTypes.bool,
  scale: PropTypes.number,
};

// Custom comparison function for React.memo
const areEqual = (prevProps, nextProps) => {
  const {
    pageId: prevPageId,
    preview: prevPreview,
    scale: prevScale,
  } = prevProps;
  const {
    pageId: nextPageId,
    preview: nextPreview,
    scale: nextScale,
  } = nextProps;

  // Compare primitive props
  return (
    prevPageId === nextPageId &&
    prevPreview === nextPreview &&
    prevScale === nextScale
  );
};

export default React.memo(DesignItems, areEqual);
