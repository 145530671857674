import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel } from '@mui/material';
import { Checkbox } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { ErrorMsg } from 'components/Styled';

export const CheckboxField = ({ input, label, meta, tooltip }) => {
  const error =
    meta.submitError && !meta.dirtySinceLastSubmit && meta.submitError;
  // const isFilled = hasValue(input?.value);
  // const required = useIsRequired(input?.name) && !isFilled;

  return (
    <Box position="relative" ml={2}>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            sx={{ mr: 0 }}
            checked={input.value}
            error={error}
            // helperText={required && <Required />}
          />
        }
        type="checkbox"
        label={label}
      />
      {tooltip && <FieldTooltip tooltip={tooltip} />}
      {error && (
        <ErrorMsg className="error-message" component="span">
          {error}
        </ErrorMsg>
      )}
    </Box>
  );
};

CheckboxField.propTypes = {
  tooltip: PropTypes.object,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  size: PropTypes.string,
  isGrouped: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
};
