import PropTypes from 'prop-types';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { DateTimeCell, AgentName } from 'components/CellComponents';
import { hasValue } from 'helpers';

const AuditContent = ({ value }) => {
  const { content } = value;

  if (value.action === 'Offer Created') {
    return `Offer Created - ${content.transactionName} ${content.transactionId}`;
  }

  if (value.action !== 'UPDATE') {
    return value.action;
  }
  return (
    <Box>
      {Object.keys(content).map((key) => (
        <Grid container alignItems="center" spacing={1} key={key}>
          <Grid item>
            <Typography variant="body2" fontWeight="bold">
              {key}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {hasValue(content[key]?.before)
                ? content[key]?.before.toString()
                : '(empty)'}
            </Typography>
          </Grid>
          <Grid item>
            <FontAwesomeIcon icon={faArrowRight} size="sm" color="gray" />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary">
              {hasValue(content[key]?.after)
                ? content[key]?.after.toString()
                : '(empty)'}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

AuditContent.propTypes = {
  value: PropTypes.object,
};

export const Cells = [
  {
    id: 'createdAt',
    value: 'createdAt',
    label: 'Image Time',
    isVisible: true,
    Wrapper: DateTimeCell,
  },
  {
    id: 'agent',
    value: 'agent',
    label: 'Agent',
    isVisible: true,
    Wrapper: AgentName,
  },
  {
    id: 'content',
    value: ['content', 'action'],
    label: 'Content',
    isVisible: true,
    Wrapper: AuditContent,
  },
];
