import React, { useCallback } from 'react';
import { faCircle, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, InputAdornment, Grid } from '@mui/material';
import { MenuItemButton } from 'components/Common';
import { CM_ITEM_TYPES } from 'consts';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const LineHeightWidthMenuItem = () => {
  const dispatch = useDispatch();
  const item = useSelector(selectCurrentItem);

  const handleHeightChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            h: value,
          },
        }),
      );
    },
    [dispatch, item],
  );

  const handleWidthChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(
        updateItemCM({
          itemId: item.id,
          properties: {
            w: value,
          },
        }),
      );
    },
    [dispatch, item],
  );

  const handleRoundedToggle = useCallback(() => {
    dispatch(
      updateItemCM({
        itemId: item.id,
        properties: {
          roundedLine: !item.roundedLine,
        },
      }),
    );
  }, [dispatch, item]);

  if (item.type !== CM_ITEM_TYPES.shapes.line) return null;

  return (
    <>
      <MenuItemButton
        label={'Height'}
        rightAction={
          <TextField
            sx={{ width: '112px', '& input': { padding: '8px' } }}
            value={parseInt(item.h, 10) || ''}
            onChange={handleHeightChange}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">px</InputAdornment>,
              style: { padding: '0px 8px' },
            }}
            variant="outlined"
          />
        }
      />
      <MenuItemButton
        label={'Width'}
        rightAction={
          <TextField
            sx={{ width: '112px', '& input': { padding: '8px' } }}
            value={parseInt(item.w, 10) || ''}
            onChange={handleWidthChange}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">px</InputAdornment>,
              style: { padding: '0px 8px' },
            }}
            variant="outlined"
          />
        }
      />
      <MenuItemButton
        icon={<FontAwesomeIcon fontSize={14} icon={faCircle} />}
        onClick={handleRoundedToggle}
        label={
          <Grid container>
            <Grid item xs={11}>
              Rounded
            </Grid>
            <Grid item xs={1}>
              {item.roundedLine && (
                <FontAwesomeIcon fontSize={18} icon={faCheck} />
              )}
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default React.memo(LineHeightWidthMenuItem);
