import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import { FormControl } from '@mui/material';
import { Box } from '@mui/system';
import { Select } from 'components/Fields';
import { getSelectOptions } from 'helpers';

const conditionOptions = {
  date: [
    'Equals',
    'Before',
    'After',
    'Last Year',
    'This Year',
    'Last Month',
    'This Month',
    'Last 12 Months',
  ],
  string: ['Equals', 'Contains', 'Starts With', 'Ends With'],
  number: ['Equals', 'Less Than', 'Greater Than'],
  enum: ['Equals', 'Not Equals', 'One Of', 'Not One Of'],
};

export const Condition = ({ name }) => {
  const filterTypeField = useField(`${name}.type`);
  const filterType = filterTypeField.input.value;
  if (filterType === 'boolean') return null;
  return (
    <Box sx={{ marginLeft: 2 }}>
      <FormControl>
        <Field
          name={`${name}.condition`}
          defaultValue="Equals"
          component={Select}
          options={getSelectOptions(conditionOptions[filterType])}
        />
      </FormControl>
    </Box>
  );
};

Condition.propTypes = {
  name: PropTypes.string.isRequired,
};
