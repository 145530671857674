import React from 'react';
import {
  faTintSlash,
  faGameBoard,
  faSquareFull,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Slider, Grid, Typography, Button } from '@mui/material';
import { CollapsableMenuGroup } from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { updateItemCM } from 'redux/slices';

const OpacityMenuItem = () => {
  const dispatch = useDispatch();
  const currentItem = useSelector(selectCurrentItem);
  const [tempTransparency, setTempTransparency] = React.useState(
    currentItem?.style?.transparency || 100,
  );

  const handleTransparencyCommitted = (value) => {
    dispatch(
      updateItemCM({
        itemId: currentItem.id,
        properties: {
          style: {
            ...currentItem.style,
            transparency: value,
          },
        },
      }),
    );
  };

  return (
    <CollapsableMenuGroup
      hideChevron
      label="Opacity"
      icon={<FontAwesomeIcon icon={faTintSlash} fontSize={18} />}
    >
      <div style={{ padding: '12px' }}>
        <Grid container px={2} spacing={1} alignItems="center">
          <Typography variant="subtitle3" style={{ marginBottom: '8px' }}>
            This will adjust opacity for fill & border
          </Typography>
          <Slider
            color="primary"
            value={parseInt(tempTransparency * 100)}
            defaultValue={0}
            step={1}
            marks
            min={0}
            max={100}
            onChange={(e, value) => {
              setTempTransparency(value / 100);
            }}
            onChangeCommitted={(e, value) => {
              handleTransparencyCommitted(value / 100);
            }}
            valueLabelDisplay="auto"
          />

          <Grid container>
            <Grid item xs={11}>
              <Button
                size="small"
                variant="outlined"
                title="No Opacity"
                style={{
                  minWidth: '10px',
                  padding: '5px',
                }}
                onClick={() => {
                  setTempTransparency(0);
                  handleTransparencyCommitted(0);
                }}
              >
                <FontAwesomeIcon
                  icon={faGameBoard}
                  style={{ fontSize: '18px', color: 'grey' }}
                />
              </Button>
            </Grid>
            <Grid item xs={1} justify="flex-end" direction="row-reverse">
              <div>
                <Button
                  size="small"
                  variant="outlined"
                  title="Full Opacity"
                  style={{
                    minWidth: '10px',
                    padding: '5px',
                  }}
                  onClick={() => {
                    setTempTransparency(1);
                    handleTransparencyCommitted(1);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSquareFull}
                    style={{
                      fontSize: '18px',
                      fontWeight: '900',
                      color: currentItem.style.backgroundColor,
                    }}
                  />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CollapsableMenuGroup>
  );
};

export default OpacityMenuItem;
