import React from 'react';
import PropTypes from 'prop-types';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faContactCard } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PageLink } from 'components/Styled';
import { REPORT_UPDATE, REPORT_DETAILS } from 'consts';

const ReportName = ({ value }) => {
  return value ? (
    <PageLink to={`/${REPORT_DETAILS(value.id)}`}>
      <div>{value.name} </div>
    </PageLink>
  ) : (
    <></>
  );
};

ReportName.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export const Cells = [
  {
    id: 'name',
    value: ['name', 'id'],
    numeric: false,
    disablePadding: true,
    label: 'Name',
    Wrapper: ReportName,
  },
  {
    id: 'reportType',
    value: ['reportType'],
    numeric: false,
    disablePadding: true,
    label: 'Report Type',
  },
];

export const MenuItems = () => {
  let navigate = useNavigate();

  return [
    {
      name: 'Edit',
      icon: faEdit,
      onClick: (item) => {
        navigate(`/${REPORT_UPDATE(item.id)}`);
      },
    },
    {
      name: 'Details',
      icon: faContactCard,
      onClick: (item) => {
        navigate(`/${REPORT_DETAILS(item.id)}`);
      },
    },
  ];
};
