import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorMsg } from 'components/Styled';
import { HOME_ROUTE } from 'consts';
import AbeLogoImg from 'images/abe-logo.png';
import { isAuthenticatedSelector } from 'redux/selectors';
import {
  gapiLibInit,
  getTokenFromBackEndUserCreds,
  isAuthRedirect,
} from 'utils/authService';
import { AbeLogo, LogoBox } from '../SignInWithGoogle/styled';
import { EmailInput, PasswordInput } from './components';
import {
  FormButton,
  LoginForm,
  SignInContainer,
  SignInWithGoogleText,
} from './styled';

export const SignInWithEmailAndPass = ({ signInWith }) => {
  const isAuthenticated = useSelector((state) =>
    isAuthenticatedSelector(state),
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const targetUrl = location.state?.targetUrl;

  useEffect(() => {
    isAuthRedirect(isAuthenticated, navigate, targetUrl, HOME_ROUTE);
  }, [targetUrl, isAuthenticated]);

  useEffect(() => {
    gapiLibInit();
  }, []);

  const onSubmit = async (credentials) => {
    try {
      await getTokenFromBackEndUserCreds(credentials, dispatch);
    } catch {
      return { [FORM_ERROR]: 'Invalid user name and/or password' };
    }
  };

  return (
    <SignInContainer>
      <LogoBox>
        <AbeLogo component="img" alt="Abe logo" src={AbeLogoImg} />
        <div style={{ color: '#8a8a8a' }}>AUTOMATED BACK-END</div>
      </LogoBox>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError }) => (
          <LoginForm action="submit" onSubmit={handleSubmit}>
            <Field name="username" component={EmailInput} />
            <Field name="password" component={PasswordInput} />
            {submitError && <ErrorMsg component="span">{submitError}</ErrorMsg>}
            <FormButton component="button" type="submit">
              Sign In
            </FormButton>
          </LoginForm>
        )}
      />
      <SignInWithGoogleText variant="p" onClick={() => signInWith('Google')}>
        Sign in with Google.
      </SignInWithGoogleText>
    </SignInContainer>
  );
};

SignInWithEmailAndPass.propTypes = {
  signInWith: PropTypes.func.isRequired,
};
