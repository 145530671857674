import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, TableRow, TableCell, TableBody, Card } from '@mui/material';
import { getGroupingNestedValue, getGroupingUrlParams } from 'helpers/report';
import ReportTableHead from 'pages/ReportPages/DetailsReportPage/ReportTableHead';
import {
  useReportGroupingDetailsMutation,
  useGetGroupingsQuery,
} from 'redux/rtk-query';

export const GroupDetails = ({ reportId, row, columns }) => {
  const [groupingQueryParams, setGroupingQueryParams] = useState(null);
  const [groupingDetails, setGroupingDetails] = useState([]);
  const [reportGroupingDetails] = useReportGroupingDetailsMutation();
  const { data: groupingsData } = useGetGroupingsQuery(reportId);

  const modelType =
    columns[0].visualPath.length > 1 ? columns[0].visualPath[0] : null;

  useEffect(() => {
    if (!groupingsData) return;
    const urlParamObjs = {};
    groupingsData.data.forEach((group) => {
      const urlParams = getGroupingUrlParams(row, group);
      Object.assign(urlParamObjs, urlParams);
    });
    setGroupingQueryParams(urlParamObjs);
  }, [groupingsData]);

  useEffect(() => {
    const fetchGroupingDetails = async () => {
      const { data } = await reportGroupingDetails({
        reportId,
        groupBy: groupingQueryParams,
      });
      setGroupingDetails(data);
    };

    fetchGroupingDetails().then((r) => r);
  }, [reportId, groupingQueryParams, reportGroupingDetails]);

  return (
    <Card>
      <Table size="small" aria-label="group details">
        <ReportTableHead columns={columns} hasGroupings={false} />
        <TableBody>
          {groupingDetails?.data?.flatMap((group) =>
            group[modelType].map((model, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {getGroupingNestedValue(modelType, model, column)}
                  </TableCell>
                ))}
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

GroupDetails.propTypes = {
  reportId: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  columns: PropTypes.shape(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  ),
};
