import React from 'react';
import PropTypes from 'prop-types';
import {
  faBullhorn,
  faBullseyePointer,
  faCamera,
  faConciergeBell,
  faMoneyCheck,
  faPencilPaintbrush,
  faSign,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconComp } from 'components/Common';

function getIconByType(type) {
  switch (type) {
    case 'Marketing Services':
      return faConciergeBell;
    case 'Marketing Photos & Tours':
      return faCamera;
    case 'Marketing Yard Sign':
      return faSign;
    case 'Marketing Graphic Design':
      return faPencilPaintbrush;
    case 'Marketing Promo Item':
      return faBullhorn;
    case 'Marketing Other':
      return faMoneyCheck;
    case 'Click Marketing':
      return faBullseyePointer;
    case 'Photo Set':
      return faCamera;
    default:
      return '';
  }
}

function getColorByType(type) {
  switch (type) {
    case 'Draft':
    case 'Voided':
      return '#A9A7A4';
    case 'In Progress':
    case 'In Progress Completed':
      return '#F6C244';
    case 'Completed':
    case 'Approved':
      return '#4CAF50';
    default:
      return '#9b9b9b';
  }
}

export const MarketingItemIcon = ({ type }) => {
  let icon = getIconByType(type);
  let color = getColorByType(type);
  return (
    <FontAwesomeIconComp
      style={{ marginRight: 12 }}
      icon={icon}
      color={color}
    />
  );
};

MarketingItemIcon.propTypes = {
  type: PropTypes.string,
};
