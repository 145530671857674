import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/system';
import abeLottie from 'images/abelottie-notext.json';

// Animation states for clarity
const INITIAL_STATE = 'initial';
const HOVER_FORWARD = 'hoverForward';
const HOVER_BACKWARD = 'hoverBackward';

const AbeNavLottie = ({
  style = {},
  speed = 4,
  targetFrame = 130,
  ...props
}) => {
  // Ref for the Lottie player instance
  const playerRef = useRef(null);

  // Total frames in the Lottie animation, set once on load
  const [totalFrames, setTotalFrames] = useState(null);

  // Tracks our current “state machine” step
  const [animationState, setAnimationState] = useState(INITIAL_STATE);

  // Handle Lottie “load” and “complete” events
  const handleLottieEvent = useCallback(
    (eventName) => {
      const playerInstance = playerRef.current;
      if (!playerInstance) return;

      switch (eventName) {
        case 'load': {
          const frames = playerInstance.totalFrames;
          if (frames) {
            setTotalFrames(frames);
            setAnimationState(INITIAL_STATE);
            // Play from frame 0 to targetFrame once loaded
            playerInstance.playSegments([0, targetFrame], true);
          }
          break;
        }

        case 'complete': {
          if (animationState === INITIAL_STATE) {
            // After initial segment completes, force-stop at targetFrame
            requestAnimationFrame(() => {
              playerInstance.goToAndStop(targetFrame, true);
            });
          } else if (animationState === HOVER_FORWARD) {
            // After playing targetFrame → end, start backward segment
            setAnimationState(HOVER_BACKWARD);
            playerInstance.playSegments([0, targetFrame], true);
          } else if (animationState === HOVER_BACKWARD) {
            // After backward segment, lock at targetFrame again
            requestAnimationFrame(() => {
              playerInstance.goToAndStop(targetFrame, true);
            });
            setAnimationState(INITIAL_STATE);
          }
          break;
        }

        default:
          // For other events, do nothing
          break;
      }
    },
    [animationState, targetFrame],
  );

  // When hovering in, only trigger forward if we are locked at the target frame
  const handleMouseEnter = useCallback(() => {
    if (animationState === INITIAL_STATE && totalFrames && playerRef.current) {
      setAnimationState(HOVER_FORWARD);
      playerRef.current.playSegments([targetFrame, totalFrames], true);
    }
  }, [animationState, totalFrames, targetFrame]);

  // When leaving hover, if we’re in the backward cycle, replay 0 → target
  const handleMouseLeave = useCallback(() => {
    if (animationState === HOVER_BACKWARD && totalFrames && playerRef.current) {
      playerRef.current.playSegments([0, targetFrame], true);
    }
  }, [animationState, totalFrames, targetFrame]);

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Player
        // The ref to the Lottie player
        lottieRef={playerRef}
        src={abeLottie}
        // MUI `Box` style plus any custom style prop
        style={{ background: 'transparent', ...style }}
        speed={speed}
        loop={false}
        // Use a stable callback for Lottie events
        onEvent={(eventName) => handleLottieEvent(eventName)}
        {...props}
      />
    </Box>
  );
};

AbeNavLottie.propTypes = {
  /** Custom style object applied to the underlying player */
  style: PropTypes.object,
  /** Playback speed multiplier */
  speed: PropTypes.number,
  /** The frame at which the animation is “locked” by default */
  targetFrame: PropTypes.number,
};

export default AbeNavLottie;
