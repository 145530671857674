import React, { useState } from 'react';
import { SignInWithEmailAndPass } from './components/SignInWithEmailAndPass';
import { SignInWithGoogle } from './components/SignInWithGoogle';
import {
  FooterLink,
  Main,
  ContentWrapper,
  Footer,
  FooterContent,
  FooterText,
} from './styled';

export const LoginPage = () => {
  const [signInMethod, setSignInMethod] = useState('Google');

  return (
    <>
      <Main height={'100vh'} disableGutters maxWidth={false}>
        <ContentWrapper disableGutters>
          {signInMethod === 'Google' && (
            <SignInWithGoogle signInWith={setSignInMethod} />
          )}
          {signInMethod === 'Email' && (
            <SignInWithEmailAndPass signInWith={setSignInMethod} />
          )}
        </ContentWrapper>
      </Main>
      <Footer>
        <FooterContent>
          <FooterText>
            Copyright © Mainframe Real Estate, 2022. All rights reserved.{' '}
            <FooterLink
              target="_blank"
              href="https://mainframere.com/company/privacy-policy/"
              rel="noreferrer"
            >
              Privacy Policy
            </FooterLink>{' '}
            <FooterLink
              target="_blank"
              href="https://mainframere.com/company/terms-of-service/"
              rel="noreferrer"
            >
              Terms of Service
            </FooterLink>
          </FooterText>
        </FooterContent>
      </Footer>
    </>
  );
};
