import { Popper as MuiPoper } from '@mui/material';
import { styled } from '@mui/system';
import { contactColors } from 'consts';

export const Popper = styled(MuiPoper)({
  zIndex: 10,
  borderRadius: '8px',
});

const getChipColorByType = (type) => {
  switch (type) {
    case 'Company Agent':
      return {
        background:
          'linear-gradient(0deg, rgba(255, 59, 59, 0.1), rgba(255, 59, 59, 0.1)), #FFFFFF',
        color: contactColors[type],
      };
    case 'Brokerage':
      return {
        background:
          'linear-gradient(0deg, rgba(255, 59, 59, 0.1), rgba(255, 59, 59, 0.1)), #FFFFFF',
        color: contactColors[type],
      };
    case 'Vendor':
      return {
        background:
          'linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #FFFFFF',
        color: contactColors[type],
      };
    case 'Customer':
      return {
        background: 'rgba(56, 103, 214, 0.1)',
        color: contactColors[type],
      };
    case 'Lead':
      return {
        background:
          'linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #FFFFFF',
        color: contactColors[type],
      };
    case 'Lender':
      return {
        background:
          'linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), #FFFFFF',
        color: contactColors[type],
      };
    default:
      return {
        background:
          'linear-gradient(0deg, rgba(255, 150, 27, 0.1), rgba(255, 150, 27, 0.1)), #FFFFFF',
        color: '#F98600',
      };
  }
};

export const ContactTypeChip = styled('span')(({ children }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 6px',
  gap: '4px',
  height: '24px',
  borderRadius: '6px',
  flex: 'none',
  order: '0',
  flexGrow: '0',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '20px',
  textAlign: 'center',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  opacity: 0.8,
  ...getChipColorByType(children),
}));
