import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Divider } from '@mui/material';
import { FontAwesomeIconComp, SkeletonSummary } from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { ListPagination } from 'components/ListPagination';
import { TaskSummary } from 'components/ModelViews/Tasks/TasksList/components/TaskSummary';
import { SearchInput } from 'components/SearchInput';
import { NewButtonBox } from 'components/Styled';
import { CREATE_TIMEFRAME } from 'consts';
import { useItemCount } from 'contexts';
import { useDebounce } from 'hooks';
import { useGetTransactionsTimeframesQuery } from 'redux/rtk-query';

export const TimeframesList = ({ transaction }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchValue, setSearchValue] = React.useState('');
  const [showCompleted, setShowCompleted] = React.useState(false);
  const debouncedValue = useDebounce(searchValue, 500);
  const { setItemCount } = useItemCount();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: transactionTaskData } = useGetTransactionsTimeframesQuery(
    {
      uuid: transaction?.uuid,
      search: debouncedValue,
      status: showCompleted
        ? ['New', 'In Progress', 'Completed']
        : ['New', 'In Progress'],
      page: page,
      limit: rowsPerPage,
    },
    { skip: !transaction.uuid },
  );
  const taskData = transactionTaskData;

  const AllTasks = taskData?.data?.items;
  const totalPages = taskData?.data?.meta.totalPages;
  const totalCount = taskData?.data?.meta.total;
  const hasCompleted = taskData?.data?.meta?.otherData?.hasCompleted;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const createTimeframe = () => {
    navigate(`${pathname}${CREATE_TIMEFRAME('transaction', transaction.id)}`);
  };

  useEffect(() => {
    setItemCount(totalCount);
  }, [totalCount]);

  return (
    <div>
      <ToolbarRow width="100%" justifyContent="space-between">
        <SearchInput changeHandler={setSearchValue} />
        <NewButtonBox>
          <Button
            size={'small'}
            variant="text"
            onClick={createTimeframe}
            endIcon={<FontAwesomeIconComp size="sm" icon={faCirclePlus} />}
          >
            New
          </Button>
        </NewButtonBox>
      </ToolbarRow>
      <Divider />
      {!AllTasks ? (
        <SkeletonSummary />
      ) : (
        AllTasks &&
        AllTasks.length > 0 &&
        AllTasks.map((task) => (
          <React.Fragment key={task.id}>
            <TaskSummary task={task} />
          </React.Fragment>
        ))
      )}
      <Box style={{ textAlign: 'center' }}>
        {hasCompleted && (
          <Button
            fullWidth
            size="small"
            color="info"
            onClick={() => {
              setShowCompleted(!showCompleted);
            }}
          >
            <FontAwesomeIconComp icon={faClock} style={{ marginRight: 10 }} />
            {showCompleted ? 'Hide' : 'Show'} Completed
          </Button>
        )}
        {totalPages > 1 && (
          <ListPagination
            totalCount={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5]}
          />
        )}
      </Box>
    </div>
  );
};

TimeframesList.propTypes = {
  transaction: PropTypes.object,
};
