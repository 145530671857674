// PipelineStatusCard.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import { AbeCard } from 'components/Common';
import { TransactionFolderIcon } from 'components/Icons';
import { ItemCountProvider } from 'contexts';
import { ItemTotalProvider } from 'contexts/ItemTotalProvider';
import { SalesPipelineSection } from 'pages/HomePages/HomePage/components/SalesPipelineCard/components';

export const PipelineStatusCard = ({
  label,
  startClosed = false,
  params = {},
}) => {
  return (
    <ItemTotalProvider>
      <ItemCountProvider>
        <Grid item xs={12}>
          <AbeCard
            startClosed={startClosed}
            hideArrow
            icon={<TransactionFolderIcon model={{ status: label }} />}
            title={label}
          >
            <Divider />
            <SalesPipelineSection params={params} />
          </AbeCard>
        </Grid>
      </ItemCountProvider>
    </ItemTotalProvider>
  );
};

PipelineStatusCard.propTypes = {
  label: PropTypes.string.isRequired,
  showSection: PropTypes.bool,
  startClosed: PropTypes.bool,
  params: PropTypes.object,
};
