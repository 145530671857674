// ReportChart.jsx
import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { faMap } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IconButton, Box } from '@mui/material';
import { shortNumber, formatValue } from 'helpers/report';
import BarChartHorizontal from 'pages/ReportPages/DetailsReportPage/charts/BarChartHorizontal';
import BarChartStacked from 'pages/ReportPages/DetailsReportPage/charts/BarChartStacked';
import BarChartVertical from 'pages/ReportPages/DetailsReportPage/charts/BarChartVertical';
import PieChart from 'pages/ReportPages/DetailsReportPage/charts/PieChart';
import { ExtraParamOptionsContext } from 'pages/ReportPages/ReportPage/context/ExtraParamOptionsContext';
import { useGetChartDataQuery, useGetChartQuery } from 'redux/rtk-query';

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const colorPalette = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

function toOpaque(colorString) {
  if (!colorString) return 'rgba(0,0,0,1)';
  if (colorString.startsWith('#')) return colorString;
  const match = colorString.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/,
  );
  if (match) {
    const [, r, g, b] = match;
    return `rgba(${r}, ${g}, ${b}, 1)`;
  }
  return 'rgba(0,0,0,1)';
}

function hexToRgba(hexColor, alpha = 0.6) {
  const hex = hexColor.replace(/^#/, '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Parse a path, keeping double underscores __ together.
function parsePathStr(pathStr) {
  if (!pathStr) return [];
  const parts = [];
  let buffer = '';
  for (let i = 0; i < pathStr.length; i++) {
    const char = pathStr[i];
    const nextChar = pathStr[i + 1];
    if (char === '_' && nextChar === '_') {
      buffer += '__';
      i++; // skip next
    } else if (char === '_') {
      parts.push(buffer);
      buffer = '';
    } else {
      buffer += char;
    }
  }
  if (buffer) parts.push(buffer);
  return parts;
}

// Build field keys
const getFieldKey = (column) => {
  if (!column) return '';
  let fieldKey = column.visualPath.join('_');
  if (column.dateSeparation) {
    fieldKey += `__${column.dateSeparation}`;
  } else if (column.groupingFunction) {
    fieldKey += `__${column.groupingFunction}`;
  }
  return fieldKey;
};

function getNestedChartValue(obj, pathStr) {
  if (!obj || !pathStr) return undefined;
  const keys = parsePathStr(pathStr);
  return keys.reduce((acc, key) => {
    if (acc === null) return undefined;
    return acc[key];
  }, obj);
}

// --- Helpers for year-month
function parseYearMonth(ymStr) {
  // e.g. "2025-01" => new Date(2025, 0, 1)
  const [yyyy, mm] = ymStr.split('-');
  return new Date(+yyyy, +mm - 1, 1);
}

function formatAxisLabel(date) {
  // e.g. show the year only on January, or "Jan", "Feb", etc.
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = date.getFullYear();
  const month = date.getMonth();
  if (month === 0) {
    return `${year} ${monthNames[month]}`; // e.g. "2025 Jan"
  }
  return monthNames[month]; // "Feb", "Mar", etc.
}

function formatFullMonthYear(date) {
  // e.g. "January 2025"
  const fullMonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${fullMonthNames[date.getMonth()]} ${date.getFullYear()}`;
}

function getAllMonthsInRange(startDate, endDate) {
  const result = [];
  let current = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  while (current <= endDate) {
    result.push(new Date(current));
    current.setMonth(current.getMonth() + 1); // increment by 1 month
  }
  return result;
}

/** Pivot data for a 2D stacked bar: X-axis = xKey, stacked dimension = stackKey, measure = yKey. */
function pivotForStacked(rows, xColumn, stackColumn, yColumn, yColumn2) {
  const pivot = {};
  if (!rows) return pivot;

  rows.forEach((row) => {
    // 1) X axis value is taken from the stack column here
    const xValKey = getFieldKey(stackColumn);
    const xVal = getNestedChartValue(row, xValKey) || 'Unknown X';

    // 2) “Stack dimension” from the x column
    const stackValKey = getFieldKey(xColumn);
    const stackVal =
      formatValue(getNestedChartValue(row, stackValKey), xColumn.type) ||
      'Unknown Stack';

    // 3) First measure
    const yKey1 = getFieldKey(yColumn);
    const rawVal1 = getNestedChartValue(row, yKey1) || 0;
    const measure1 = parseFloat(rawVal1) || 0;

    // 4) Second measure (if present)
    let measure2 = 0;
    if (yColumn2) {
      const yKey2 = getFieldKey(yColumn2);
      const rawVal2 = getNestedChartValue(row, yKey2) || 0;
      measure2 = parseFloat(rawVal2) || 0;
    }

    // 5) Store in pivot
    if (!pivot[xVal]) {
      pivot[xVal] = {};
    }
    if (!pivot[xVal][stackVal]) {
      // We store an object with the two measures
      pivot[xVal][stackVal] = { measure1: 0, measure2: 0 };
    }
    pivot[xVal][stackVal].measure1 += measure1;
    pivot[xVal][stackVal].measure2 += measure2;
  });

  return pivot;
}

/**
 * Build Chart.js data object (labels + datasets) from the pivoted result.
 * If the original X field indicates a year&month formatting, we build a complete
 * list of months (with formatted labels and corresponding raw Date objects) and fill
 * missing values with zeros.
 */
function buildStackedData(pivot, originalXFieldKey) {
  let xLabels = Object.keys(pivot);
  let rawDates = null;
  let formattedLabels = xLabels.slice(); // default to unformatted
  let pivotToUse = pivot;

  if (originalXFieldKey && originalXFieldKey.includes('__Year&Month')) {
    // Check that every key matches "YYYY-MM"
    if (
      xLabels.length &&
      xLabels.every((label) => /^\d{4}-\d{2}$/.test(label))
    ) {
      // Convert each label to a Date object
      const dates = xLabels.map((label) => parseYearMonth(label));
      dates.sort((a, b) => a - b);
      const minDate = dates[0];
      const maxDate = dates[dates.length - 1];
      const allMonths = getAllMonthsInRange(minDate, maxDate);
      rawDates = allMonths; // keep the raw date objects for tooltips

      // Rebuild the pivot to include all months in the range
      const newPivot = {};
      allMonths.forEach((date) => {
        const ymKey = date.toISOString().slice(0, 7);
        newPivot[ymKey] = pivot[ymKey] || {};
      });
      pivotToUse = newPivot;
      xLabels = allMonths.map((date) => date.toISOString().slice(0, 7));
      formattedLabels = allMonths.map((date) => formatAxisLabel(date));
    }
  } else if (originalXFieldKey && originalXFieldKey.includes('__Month')) {
    // You could add similar handling for month-only formatting if needed.
    xLabels.sort((a, b) => a - b);
    formattedLabels = xLabels;
  } else {
    xLabels.sort(); // for non-date, sort alphabetically or numerically as needed
    formattedLabels = xLabels;
  }

  // Determine all unique stack keys from the (possibly rebuilt) pivot
  const stackSet = new Set();
  xLabels.forEach((xVal) => {
    const obj = pivotToUse[xVal];
    if (obj) {
      Object.keys(obj).forEach((s) => stackSet.add(s));
    }
  });
  const allStacks = [...stackSet];

  const datasets = allStacks.map((stackVal, idx) => {
    const color = colorPalette[idx % colorPalette.length];
    const dataArray = xLabels.map((xVal) => {
      const measures = pivotToUse[xVal] && pivotToUse[xVal][stackVal];
      return measures ? measures.measure1 : 0;
    });

    return {
      label: stackVal,
      data: dataArray,
      backgroundColor: hexToRgba(color, 0.7),
      stack: 'stack1',
    };
  });

  return {
    labels: formattedLabels,
    rawKeys: xLabels,
    datasets,
    rawDates, // will be non-null if we processed a year-month axis
  };
}

export const ReportChart = ({ reportId, chartId }) => {
  const [showLegend, setShowLegend] = useState(false);
  const { extraParamOptions } = useContext(ExtraParamOptionsContext);
  const memoizedExtraParamOptions = useMemo(
    () => extraParamOptions,
    [JSON.stringify(extraParamOptions)],
  );
  const handleToggleLegend = () => {
    setShowLegend((prev) => !prev);
  };
  const { data: chartResponse } = useGetChartQuery({ reportId, chartId });
  const { data: chartDataResponse } = useGetChartDataQuery({
    reportId,
    chartId,
    limit: 100,
    ...memoizedExtraParamOptions,
  });

  // Uncomment loading state as needed
  // if (!chartResponse || !chartDataResponse) {
  //   return <div>Loading...</div>;
  // }

  const chart = chartResponse?.data;
  const chartData = chartDataResponse?.data;
  const chartType = chart?.type;
  const datasets = chart?.datasets || [];

  useEffect(() => {
    if (chartType === 'Circle Chart Pie Charts') {
      setShowLegend(true);
    } else {
      setShowLegend(false);
    }
  }, [chartType]);

  if (!chartResponse || !chartDataResponse) {
    return <div>Loading...</div>;
  }

  const xAxisDataset = datasets.find((d) => d.axis === 'X');
  const yAxisDataset = datasets.find((d) => d.axis === 'Y');
  const xFieldKey = getFieldKey(xAxisDataset?.column);
  const yFieldKey = getFieldKey(yAxisDataset?.column);

  // 1) Gather raw pairs: { rawLabel: "2025-01", rawVal: 62000 } or just strings
  const rawPairs = chartData?.map((item) => {
    const rawLabel = getNestedChartValue(item, xFieldKey) || '';
    const rawVal = parseFloat(getNestedChartValue(item, yFieldKey) || 0);
    return { rawLabel, rawVal };
  });

  // 2) Check if it's a Year&Month scenario
  const isYearMonth = xFieldKey.includes('__Year&Month');

  let finalDates = []; // array of Date objects (for tooltip)
  let finalLabels = []; // array of strings for the axis
  let finalValues = [];

  if (isYearMonth) {
    // Filter valid "YYYY-MM"
    const validEntries = rawPairs?.filter((p) =>
      /^\d{4}-\d{2}$/.test(p.rawLabel),
    );
    if (validEntries.length === 0) {
      // fallback: just use raw label
      finalLabels = rawPairs.map((p, i) => p.rawLabel || `Unknown ${i}`);
      finalValues = rawPairs.map((p) => p.rawVal);
    } else {
      const dateVals = validEntries.map((e) => ({
        date: parseYearMonth(e.rawLabel),
        val: e.rawVal,
      }));
      // find min & max dates
      let minDate = dateVals[0].date;
      let maxDate = dateVals[0].date;
      for (let i = 1; i < dateVals.length; i++) {
        if (dateVals[i].date < minDate) minDate = dateVals[i].date;
        if (dateVals[i].date > maxDate) maxDate = dateVals[i].date;
      }
      // generate all months in range
      const allMonths = getAllMonthsInRange(minDate, maxDate);
      // build map => "YYYY-MM" => sum of values
      const mapMonthVals = {};
      dateVals.forEach((dv) => {
        const ymKey = dv.date.toISOString().slice(0, 7);
        if (!mapMonthVals[ymKey]) mapMonthVals[ymKey] = 0;
        mapMonthVals[ymKey] += dv.val;
      });
      finalDates = allMonths; // array of Date objects
      finalLabels = allMonths.map((d) => formatAxisLabel(d));
      finalValues = allMonths.map((d) => {
        const ymKey = d.toISOString().slice(0, 7);
        return mapMonthVals[ymKey] || 0;
      });
    }
  } else if (xFieldKey.includes('__Month')) {
    // Filter valid month entries: expecting "1" to "12" or "01" to "12"
    const monthRegex = /^(0?[1-9]|1[0-2])$/;
    const validEntries = rawPairs?.filter((p) => monthRegex.test(p.rawLabel));
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
    const mapMonthVals = {};
    validEntries.forEach((e) => {
      const m = parseInt(e.rawLabel, 10);
      if (!mapMonthVals[m]) mapMonthVals[m] = 0;
      mapMonthVals[m] += e.rawVal;
    });
    finalLabels = allMonths.map((m) => monthNames[m - 1]);
    finalValues = allMonths.map((m) => mapMonthVals[m] || 0);
    finalDates = finalLabels.map(() => null);
  } else {
    finalLabels = rawPairs.map((p) =>
      p.rawLabel.trim() === '' ? 'Unknown' : p.rawLabel.replace(/&nbsp;/g, ' '),
    );
    finalValues = rawPairs.map((p) => p.rawVal);
  }

  // Build colors for non-stacked charts
  const backgroundColors = finalLabels.map((_, i) => {
    const hex = colorPalette[i % colorPalette.length];
    return hexToRgba(hex, 0.7);
  });

  // Build data for non-stacked Chart.js charts
  const data = {
    labels: finalLabels,
    datasets: [
      {
        label: yAxisDataset?.column?.name || 'Value',
        data: finalValues,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        ...(chartType === 'Circle Chart Pie Charts' && {
          borderColor: '#fff',
          borderWidth: 1,
        }),
      },
    ],
  };

  // Chart options for non-stacked charts
  const baseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: chartType !== 'Circle Chart Pie Charts',
        ticks: {
          font: {
            family: 'Quicksand',
            size: 12,
            weight: '500',
          },
          ...(chartType === 'Bar Chart Horizontal' && {
            callback: (value) => shortNumber(value),
          }),
        },
      },
      y: {
        display: chartType !== 'Circle Chart Pie Charts',
        ticks: {
          font: {
            family: 'Quicksand',
            size: 12,
            weight: '500',
          },
          ...(chartType === 'Bar Chart Vertical' && {
            callback: (value) => shortNumber(value),
          }),
        },
      },
    },
    plugins: {
      legend: {
        display: showLegend,
        position: 'right',
        labels: {
          font: {
            family: 'Quicksand',
            size: 12,
            weight: '600',
          },
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      tooltip: {
        titleFont: {
          family: 'Quicksand',
          size: 14,
          weight: '700',
        },
        bodyFont: {
          family: 'Quicksand',
          size: 13,
          weight: '400',
        },
        footerFont: {
          family: 'Quicksand',
          size: 10,
          weight: '300',
        },
        position: 'nearest',
        backgroundColor: (ctx) => {
          const tooltip = ctx.tooltip;
          if (!tooltip || !tooltip.dataPoints?.length) return 'rgba(0,0,0,0.8)';
          const dp = tooltip.dataPoints[0];
          const sliceColor = dp.dataset.backgroundColor[dp.dataIndex];
          return toOpaque(sliceColor);
        },
        borderColor: '#fff',
        borderWidth: 2,
        titleColor: '#fff',
        bodyColor: '#fff',
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => {
            if (!tooltipItems.length) return '';
            const idx = tooltipItems[0].dataIndex;
            const d = finalDates[idx];
            if (!d) {
              return tooltipItems[0].label || '';
            }
            return formatFullMonthYear(d);
          },
          label: (tooltipItem) => {
            const rawValue = tooltipItem.raw;
            const formattedValue = shortNumber(rawValue);
            const tooltipLabel =
              yAxisDataset?.column?.groupingFunction === 'Count'
                ? 'Count'
                : yAxisDataset?.column?.name || 'Value';
            return `${tooltipLabel}: ${formattedValue}`;
          },
        },
      },
    },
  };

  // Separate the X vs. Y definitions from your chart config
  const xDefs = chart.datasets?.filter((d) => d.axis === 'X');
  const yDefs = chart.datasets?.filter((d) => d.axis === 'Y');

  // For a stacked bar chart with 2 "X" columns and at least 1 measure, use pivoting
  if (
    (chartType === 'Bar Chart Stacked' ||
      chartType === 'Bar Chart Stacked Horizontal') &&
    xDefs.length === 2 &&
    yDefs.length > 0
  ) {
    const yCol1 = yDefs[0].column;
    const yCol2 = yDefs[1]?.column;

    const measureLabel1 = yCol1.name;
    const measureLabel2 = yCol2?.name || '';

    const pivotObj = pivotForStacked(
      chartData,
      xDefs[0].column,
      xDefs[1].column,
      yDefs[0].column,
      yDefs?.[1]?.column,
    );
    // Use the second X definition’s field key to decide if we need date formatting

    const xFieldKeyForStacked = getFieldKey(xDefs[1].column);
    const stackedData = buildStackedData(pivotObj, xFieldKeyForStacked);
    const isHorizontal = chartType === 'Bar Chart Stacked Horizontal';

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: isHorizontal ? 'y' : 'x',
      scales: {
        x: {
          stacked: true,
          ...(isHorizontal
            ? {
                ticks: {
                  callback: (value) => shortNumber(value),
                },
              }
            : {
                ticks: {
                  font: {
                    family: 'Quicksand',
                    size: 12,
                    weight: '500',
                  },
                },
              }),
        },
        y: {
          stacked: true,
          ...(!isHorizontal
            ? {
                ticks: {
                  callback: (value) => shortNumber(value),
                },
              }
            : {
                ticks: {
                  font: {
                    family: 'Quicksand',
                    size: 12,
                    weight: '500',
                  },
                },
              }),
        },
      },
      plugins: {
        legend: {
          ...baseOptions.plugins.legend,
          display: showLegend,
          position: 'right',
        },
        tooltip: {
          displayColors: false,
          borderColor: '#fff',
          borderWidth: 2,
          backgroundColor: (ctx) => {
            const tooltip = ctx.tooltip;
            if (!tooltip || !tooltip.dataPoints?.length)
              return 'rgba(0,0,0,0.8)';
            const dp = tooltip.dataPoints[0];
            const bgArray = Array.isArray(dp.dataset.backgroundColor)
              ? dp.dataset.backgroundColor
              : [dp.dataset.backgroundColor];
            const sliceColor = bgArray[dp.dataIndex % bgArray.length] || '#000';
            return toOpaque(sliceColor);
          },
          callbacks: {
            // If rawDates are available (year-month scenario), use them to display a full date title.
            title: (tooltipItems) => {
              if (!tooltipItems.length) return '';
              const idx = tooltipItems[0].dataIndex;
              const secondLabel = tooltipItems[0].dataset.label || '';
              if (stackedData.rawDates && stackedData.rawDates[idx]) {
                return (
                  formatFullMonthYear(stackedData.rawDates[idx]) +
                  (secondLabel ? ` - ${secondLabel}` : '')
                );
              }
              // Otherwise, fallback to the dataset’s label
              return tooltipItems[0].dataset.label || '';
            },
            label: (tooltipItem) => {
              const dsLabel = tooltipItem.dataset.label;
              const xIndex = tooltipItem.dataIndex;
              // Note: use the original pivot object to get both measures

              const rawXVal = stackedData.rawKeys[xIndex]; // e.g. "2024-01"

              const measureObj = pivotObj[rawXVal]?.[dsLabel] || {
                measure1: 0,
                measure2: 0,
              };

              const line1 = `${measureLabel1}: ${shortNumber(measureObj.measure1)}`;
              const line2 = `${measureLabel2}: ${shortNumber(measureObj.measure2)}`;

              return yCol2 ? [line1, line2] : line1;
            },
          },
        },
      },
    };

    return (
      <Box sx={{ height: '300px', width: '100%', position: 'relative' }}>
        <IconButton
          onClick={() => setShowLegend((prev) => !prev)}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 10,
            backgroundColor: 'white',
          }}
        >
          <FontAwesomeIcon icon={faMap} />
        </IconButton>
        <BarChartStacked data={stackedData} options={options} />
      </Box>
    );
  }

  // Render the correct chart for non-stacked types
  const renderChart = () => {
    switch (chartType) {
      case 'Bar Chart Horizontal':
        return <BarChartHorizontal data={data} options={baseOptions} />;
      case 'Bar Chart Vertical':
        return <BarChartVertical data={data} options={baseOptions} />;
      case 'Circle Chart Pie Charts':
        return <PieChart data={data} options={baseOptions} />;
      case 'Bar Chart Stacked':
        return <BarChartStacked data={data} options={baseOptions} />;
      default:
        return <div>Chart type not supported.</div>;
    }
  };

  return (
    <Box
      sx={{
        height: '300px',
        width: '100%',
        justifyItems: 'center',
        position: 'relative',
        '& .MuiIconButton-root': {
          opacity: 0,
          transition: 'opacity 0.3s',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          boxShadow: 2,
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
      }}
    >
      <IconButton
        onClick={handleToggleLegend}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <FontAwesomeIcon icon={faMap} />
      </IconButton>
      {renderChart()}
    </Box>
  );
};

ReportChart.propTypes = {
  reportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  chartId: PropTypes.number.isRequired,
};

export default ReportChart;
