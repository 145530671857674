import { useMemo } from 'react';
import {
  FINANCING_TYPES,
  LEASE_TYPE_OPTIONS,
  PAID_BY_TYPE_OPTIONS,
  PAYMENT_METHOD_OPTIONS,
  PM_FILE_OPTIONS,
  PM_STATUS_OPTIONS,
  REPRESENTING_OPTIONS,
  SALE_TYPE_OPTIONS,
  STATUS_OPTIONS,
  YES_OR_NO,
  TRANSACTION_TYPES,
} from 'consts';
import { getSelectOptions } from 'helpers/form';

export const useTransactionOptions = (form) => {
  const statusOptions = useMemo(() => {
    if (!form.getState().values.id) {
      return [STATUS_OPTIONS.DRAFT];
    }
    console.log(
      'form.getState().initialValues.status',
      form.getState().initialValues.status,
    );
    if (form.getState().initialValues.status === STATUS_OPTIONS.DRAFT.value) {
      if (
        form.getState().values.representing ===
        REPRESENTING_OPTIONS.SELLER.value
      ) {
        return [STATUS_OPTIONS.DRAFT, STATUS_OPTIONS.LISTED];
      } else if (
        form.getState().values.representing === REPRESENTING_OPTIONS.BUYER.value
      ) {
        return [STATUS_OPTIONS.DRAFT, STATUS_OPTIONS.PENDING];
      } else if (
        form.getState().values.representing ===
        REPRESENTING_OPTIONS.SELLER_AND_BUYER.value
      ) {
        return [
          STATUS_OPTIONS.DRAFT,
          STATUS_OPTIONS.LISTED,
          STATUS_OPTIONS.PENDING,
        ];
      }
      if (form.getState().values.representing?.includes('Landlord')) {
        if (form.getState().values.pmFile === 'Yes') {
          return [STATUS_OPTIONS.DRAFT, STATUS_OPTIONS.LEASED];
        } else if (form.getState().values.pmFile === 'Property Mgmt') {
          return [STATUS_OPTIONS.DRAFT, STATUS_OPTIONS.PROPERTY_MGMT];
        } else {
          return [
            STATUS_OPTIONS.DRAFT,
            STATUS_OPTIONS.LISTED,
            STATUS_OPTIONS.LEASED,
            STATUS_OPTIONS.PROPERTY_MGMT,
          ];
        }
      } else if (form.getState().values.representing?.includes('Tenant')) {
        return [STATUS_OPTIONS.DRAFT, STATUS_OPTIONS.LEASED];
      }
      if (form.getState().values.representing?.includes('REFERRAL')) {
        return [STATUS_OPTIONS.DRAFT, STATUS_OPTIONS.REFERRAL];
      }
    } else if (
      form.getState().initialValues.status === STATUS_OPTIONS.LISTED.value
    ) {
      if (form.getState().values.representing?.includes('Landlord')) {
        return [
          STATUS_OPTIONS.LISTED,
          STATUS_OPTIONS.LEASED,
          STATUS_OPTIONS.EXPIRED.value,
          STATUS_OPTIONS.WITHDRAWN.value,
        ];
      } else {
        return [
          STATUS_OPTIONS.LISTED,
          STATUS_OPTIONS.PENDING,
          STATUS_OPTIONS.EXPIRED.value,
          STATUS_OPTIONS.WITHDRAWN.value,
        ];
      }
    } else if (
      form.getState().initialValues.status === STATUS_OPTIONS.PENDING.value ||
      form.getState().initialValues.status === STATUS_OPTIONS.SOLD.value ||
      form.getState().initialValues.status === STATUS_OPTIONS.CANCELLED.value
    ) {
      if (
        form.getState().values.representing ===
        REPRESENTING_OPTIONS.SELLER_AND_BUYER.value
      ) {
        return [
          STATUS_OPTIONS.LISTED,
          STATUS_OPTIONS.PENDING,
          STATUS_OPTIONS.SOLD,
          STATUS_OPTIONS.CANCELLED,
        ];
      } else if (form.getState().values.representing?.includes('Seller')) {
        return [
          STATUS_OPTIONS.LISTED,
          STATUS_OPTIONS.PENDING,
          STATUS_OPTIONS.SOLD,
        ];
      } else if (form.getState().values.representing?.includes('Buyer')) {
        return [
          STATUS_OPTIONS.PENDING,
          STATUS_OPTIONS.SOLD,
          STATUS_OPTIONS.CANCELLED,
        ];
      }
    } else if (
      form.getState().initialValues.status === STATUS_OPTIONS.LEASED.value
    ) {
      return [STATUS_OPTIONS.LISTED, STATUS_OPTIONS.LEASED];
    } else if (
      form.getState().initialValues.status ===
      STATUS_OPTIONS.PROPERTY_MGMT.value
    ) {
      return [STATUS_OPTIONS.PROPERTY_MGMT];
    } else if (
      form.getState().initialValues.status === STATUS_OPTIONS.REFERRAL.value
    ) {
      return [STATUS_OPTIONS.REFERRAL];
    } else if (
      form.getState().initialValues.status ===
      STATUS_OPTIONS.EXPIRED_WITHDRAWN.value
    ) {
      return [STATUS_OPTIONS.LISTED, STATUS_OPTIONS.EXPIRED_WITHDRAWN];
    } else if (
      form.getState().initialValues.status === STATUS_OPTIONS.EXPIRED.value
    ) {
      return [STATUS_OPTIONS.LISTED, STATUS_OPTIONS.EXPIRED];
    } else if (
      form.getState().initialValues.status === STATUS_OPTIONS.WITHDRAWN.value
    ) {
      return [STATUS_OPTIONS.LISTED, STATUS_OPTIONS.WITHDRAWN];
    }
  }, [
    form.getState().values.representing,
    form.getState().values.status,
    form.getState().values.pmFile,
  ]);

  const representingOptions = useMemo(() => {
    if (
      form.getState().values.transactionType === TRANSACTION_TYPES.DRAFT_OFFER
    ) {
      if (
        form.getState().values.primaryTransaction?.representing ===
        REPRESENTING_OPTIONS.SELLER.value
      ) {
        return [
          REPRESENTING_OPTIONS.SELLER,
          REPRESENTING_OPTIONS.SELLER_AND_BUYER,
        ];
      }
      if (
        form.getState().values.primaryTransaction?.representing ===
        REPRESENTING_OPTIONS.BUYER.value
      ) {
        return [
          REPRESENTING_OPTIONS.BUYER,
          REPRESENTING_OPTIONS.SELLER_AND_BUYER,
        ];
      }
    }
    if (form.getState().values.status === STATUS_OPTIONS.LISTED.value) {
      if (form.getState().values.pmFile === 'YES') {
        return [REPRESENTING_OPTIONS.LANDLORD];
      } else {
        return [
          REPRESENTING_OPTIONS.SELLER,
          REPRESENTING_OPTIONS.SELLER_AND_BUYER,
          REPRESENTING_OPTIONS.LANDLORD,
          REPRESENTING_OPTIONS.LANDLORD_AND_TENANT,
        ];
      }
    } else if (form.getState().values.status === STATUS_OPTIONS.PENDING.value) {
      return [
        REPRESENTING_OPTIONS.SELLER,
        REPRESENTING_OPTIONS.BUYER,
        REPRESENTING_OPTIONS.SELLER_AND_BUYER,
      ];
    } else if (
      form.getState().values.status === STATUS_OPTIONS.PROPERTY_MGMT.value
    ) {
      return [REPRESENTING_OPTIONS.LANDLORD];
    } else if (form.getState().values.status === STATUS_OPTIONS.SOLD.value) {
      return [
        REPRESENTING_OPTIONS.SELLER,
        REPRESENTING_OPTIONS.BUYER,
        REPRESENTING_OPTIONS.SELLER_AND_BUYER,
      ];
    } else if (form.getState().values.status === STATUS_OPTIONS.LEASED.value) {
      return [
        REPRESENTING_OPTIONS.LANDLORD,
        REPRESENTING_OPTIONS.TENANT,
        REPRESENTING_OPTIONS.LANDLORD_AND_TENANT,
      ];
    } else {
      return [
        REPRESENTING_OPTIONS.SELLER,
        REPRESENTING_OPTIONS.BUYER,
        REPRESENTING_OPTIONS.SELLER_AND_BUYER,
        REPRESENTING_OPTIONS.LANDLORD,
        REPRESENTING_OPTIONS.LANDLORD_AND_TENANT,
        REPRESENTING_OPTIONS.REFERRAL,
      ];
    }
  }, [form.getState().values.status, form.getState().values.pmFile]);

  const pmFileOptions = Object.keys(PM_FILE_OPTIONS).map(
    (option) => PM_FILE_OPTIONS[option],
  );

  const saleTypeOptions = Object.keys(SALE_TYPE_OPTIONS).map(
    (option) => SALE_TYPE_OPTIONS[option],
  );

  const paidByTypeOptions = Object.keys(PAID_BY_TYPE_OPTIONS).map(
    (option) => PAID_BY_TYPE_OPTIONS[option],
  );

  const financingTypeOptions = getSelectOptions(FINANCING_TYPES);

  const leaseTypeOptions = Object.keys(LEASE_TYPE_OPTIONS).map(
    (option) => LEASE_TYPE_OPTIONS[option],
  );

  const pmStatusOptions = Object.keys(PM_STATUS_OPTIONS).map(
    (option) => PM_STATUS_OPTIONS[option],
  );

  const paymentMethodOptions = Object.keys(PAYMENT_METHOD_OPTIONS).map(
    (option) => PAYMENT_METHOD_OPTIONS[option],
  );

  const yesOrNoOptions = Object.keys(YES_OR_NO).map(
    (option) => YES_OR_NO[option],
  );

  return {
    statusOptions,
    representingOptions,
    pmFileOptions,
    saleTypeOptions,
    paidByTypeOptions,
    financingTypeOptions,
    leaseTypeOptions,
    pmStatusOptions,
    paymentMethodOptions,
    yesOrNoOptions,
  };
};
