// BarChartHorizontal.jsx
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { shortNumber } from 'helpers/report';

const BarChartHorizontal = ({ data, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      // Force update after mount
      chart.update();
    }
  }, [data, options]);

  const horizontalOptions = {
    indexAxis: 'y',
    ...options,
    animation: {
      duration: 100,
      onComplete: () => {
        const chart = chartRef.current;
        if (chart) {
          chart.update();
        }
      },
    },
    plugins: {
      ...options.plugins,

      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#ffffff',
        font: {
          family: 'Quicksand',
          size: 12,
          weight: 700,
        },
        formatter: (value) => shortNumber(value),
        display: (ctx) => {
          const { chart, dataIndex, datasetIndex } = ctx;
          const meta = chart.getDatasetMeta(datasetIndex);
          const bar = meta.data[dataIndex];
          if (!bar) return false;

          // 1) Check the actual value
          const dataset = chart.data.datasets[datasetIndex];
          const value = dataset.data[dataIndex];
          // If 0 (or falsy), hide the label
          if (!value) return false;

          // 2) Check the bar's width in pixels
          const barWidth = Math.abs(bar.base - bar.x);
          // 3) Check the bar's height in pixels.
          // If the bar doesn't have a height property, compute it as the difference between top and bottom.
          const barHeight = bar.height || Math.abs(bar.bottom - bar.top);

          // Only display the label if both the width and height are large enough (e.g. > 15px)
          return barWidth > 20 && barHeight > 9;
        },
      },
    },
  };

  return <Bar ref={chartRef} data={data} options={horizontalOptions} />;
};

BarChartHorizontal.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default BarChartHorizontal;
