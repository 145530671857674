import React from 'react';
import { Grid } from '@mui/material';
import { STATUS_OPTIONS } from 'consts';
import {
  listedData,
  draftData,
  pendingData,
  soldData,
  closingInProgressData,
} from 'helpers';
import { PipelineStatusCard } from 'pages/HomePages/HomePage/components/SalesPipelineCard/components/PipelineStatusCard';

export const SalesPipelineCard = () => {
  return (
    // <AbeCard title={'Sales Pipeline'}>
    //   <Divider />
    //   <TableContainer>
    //     <PipelineTable>
    //       <SalesPipelineHeader />
    //       <SalesPipelineSection status={'Listed'} data={listedData} />
    //       <SalesPipelineSection status={'Pending'} data={pendingData} />
    //       <SalesPipelineSection status={'Draft'} data={draftData} />
    //       <SalesPipelineSection
    //         title={'Closing in Progress'}
    //         status={'Sold'}
    //         data={soldData}
    //       />
    //       <SalesPipelineSection
    //         hideTable
    //         title={'Closed Sale'}
    //         extraText={'(12 mo.)'}
    //         status={'Sold'}
    //         data={{}}
    //       />
    //     </PipelineTable>
    //   </TableContainer>
    // </AbeCard>
    <Grid container spacing={2}>
      <PipelineStatusCard
        label="Draft"
        statusValue={STATUS_OPTIONS.DRAFT.value}
        data={draftData}
      />
      <PipelineStatusCard
        label="Listed"
        statusValue={STATUS_OPTIONS.LISTED.value}
        data={listedData}
      />
      <PipelineStatusCard
        label="Pending"
        statusValue={STATUS_OPTIONS.PENDING.value}
        data={pendingData}
      />
      <PipelineStatusCard
        label="Closing In Progress"
        statusValue={STATUS_OPTIONS.SOLD.value}
        data={closingInProgressData}
      />
      <PipelineStatusCard
        startClosed
        label="Sold"
        statusValue={STATUS_OPTIONS.SOLD.value}
        data={soldData}
      />
    </Grid>
  );
};

SalesPipelineCard.propTypes = {};
