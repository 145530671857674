import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Paper } from '@mui/material';
import { FontAwesomeIconComp, SideDrawer } from 'components/Common';
import {
  ButtonSection,
  Header,
  HeaderSubtitle,
  HeadingSection,
} from 'components/Styled';
import { conversion } from 'helpers';
import { useDrawer } from 'hooks';
import { useGetDocumentQuery } from 'redux/rtk-query';
import DocumentEsignPreview from './components/DocumentEsignPreview';

export function DocumentPreviewDrawer({ params, path }) {
  const { isOpen, closeDrawer } = useDrawer(true, path);
  const routeParams = useParams();
  const relatedModelType = routeParams.contactDetailsId
    ? 'contact'
    : 'transaction';

  const paramName = Object.keys(params)[0];
  const { data: documentData } = useGetDocumentQuery({
    id: params[paramName],
  });

  return (
    <SideDrawer width={'95%'} isOpen={isOpen}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <HeadingSection noborder={'true'} backgroundColor={'#F7F7F7'}>
          <Box>
            <Header variant="h4">
              {documentData?.data?.documentTemplate?.name}
            </Header>
            <HeaderSubtitle>
              {conversion.getName(documentData?.data[relatedModelType])}
            </HeaderSubtitle>
          </Box>
          <ButtonSection>
            <IconButton onClick={closeDrawer}>
              <FontAwesomeIconComp icon={faTimesCircle} />
            </IconButton>
          </ButtonSection>
        </HeadingSection>
        <Box style={{ flex: 1, overflowY: 'auto' }}>
          {documentData?.data && (
            <DocumentEsignPreview documentData={documentData?.data} />
          )}
        </Box>
      </Paper>
    </SideDrawer>
  );
}

DocumentPreviewDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string,
};
