import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  CappingAutocomplete,
  CurrencyInput,
  Select,
  TextInput,
} from 'components/Fields';
import { FormField, Row } from 'components/Forms';
import { RankAs } from 'consts';
import { getSelectOptions } from 'helpers/form';

const CappingItem = ({ cappingName, cappingLabel, splitName, splitLabel }) => (
  <Row>
    <FormField
      name={cappingName}
      label={cappingLabel}
      component={CappingAutocomplete}
      size={7}
    />
    <FormField
      label={splitLabel}
      name={splitName}
      component={TextInput}
      size={5}
    />
  </Row>
);

CappingItem.propTypes = {
  cappingName: PropTypes.string.isRequired,
  cappingLabel: PropTypes.string.isRequired,
  splitName: PropTypes.string.isRequired,
  splitLabel: PropTypes.string.isRequired,
};

export const CappingPanel = () => {
  const cappingItems = [
    {
      cappingName: 'salesCapping',
      cappingLabel: 'Sales Capping',
      splitName: 'salesSplit',
      splitLabel: 'Sales Split',
    },
    {
      cappingName: 'leaseCapping',
      cappingLabel: 'Lease Capping',
      splitName: 'leaseSplit',
      splitLabel: 'Lease Split',
    },
    {
      cappingName: 'propertyMgmtCapping',
      cappingLabel: 'Property Mgmt Capping',
      splitName: 'propertyMgmtSplit',
      splitLabel: 'Property Mgmt Split',
    },
    {
      cappingName: 'referralCapping',
      cappingLabel: 'Referral Capping',
      splitName: 'referralSplit',
      splitLabel: 'Referral Split',
    },
    {
      cappingName: 'commercialCapping',
      cappingLabel: 'Commercial Capping',
      splitName: 'commercialSplit',
      splitLabel: 'Commercial Split',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormPanel label="Overview" hideArrow>
          <FormField
            name="rankAs"
            label={'Rank As'}
            options={getSelectOptions(RankAs)}
            component={Select}
          />
          <FormField
            label={'To Agent Amount'}
            name={'toAgentAmount'}
            component={CurrencyInput}
          />
          <FormField
            label={'To Company Amount'}
            name={'toCompanyAmount'}
            component={CurrencyInput}
          />
        </FormPanel>
      </Grid>
      <Grid item xs={6}>
        <FormPanel label="Capping" hideArrow>
          <Grid container>
            {cappingItems.map((item, index) => (
              <CappingItem key={index} {...item} />
            ))}
          </Grid>
        </FormPanel>
      </Grid>
    </Grid>
  );
};
