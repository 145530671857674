// ResizableDraggable.js

import React, { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Rnd } from 'react-rnd';
import { ViewportHoverContext } from 'components/ModelViews/Documents/ClickMarketingViewer/ClickMarketingViewer';
import useIsActive from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useIsActive';
import { getComputedBorderRadius } from 'components/ModelViews/Documents/ClickMarketingViewer/utils/viewer';
import { CM_ITEM_TYPES, shapeTypes } from 'consts';
import {
  htmlTemplateSelectorCM as htmlTemplateSelector,
  scaleSelectorCM as scaleSelector,
  activeFieldIdsSelectorCM as activeFieldIdsSelector,
  cmBlockTypeSelectorCM,
} from 'redux/selectors';
import { updateItemCM, setActiveFieldCM } from 'redux/slices';

const ResizableDraggable = ({
  field,
  children,
  scale = null,
  preview = false,
}) => {
  const dispatch = useDispatch();
  const htmlTemplate = useSelector(htmlTemplateSelector);
  const selectorScale = useSelector(scaleSelector);
  const activeFieldIds = useSelector(activeFieldIdsSelector);
  const cmBlockType = useSelector(cmBlockTypeSelectorCM);
  const isActive = useIsActive(field.id);
  const isViewportHovered = useContext(ViewportHoverContext);
  const activeScale = scale || selectorScale;
  const computedBorderRadius = useMemo(
    () => getComputedBorderRadius(field),
    [field],
  );

  // Get the elements array (ordered list of item IDs) for the active page
  const page = useMemo(
    () => htmlTemplate?.options?.pages?.[field.page],
    [htmlTemplate, field.page],
  );
  const elements = useMemo(() => page?.elements || [], [page]);
  const disableDragging = cmBlockType === CM_ITEM_TYPES.modular.Template;
  const enableResizing =
    cmBlockType !== CM_ITEM_TYPES.modular.Template &&
    isActive &&
    field.type !== CM_ITEM_TYPES.shapes.line;

  // Calculate zIndex based on the position of the field.id in the elements array
  const zIndex = useMemo(() => {
    // If not found in elements, find index in htmlTemplate.items array
    const items = htmlTemplate?.items || [];
    const indexInItems = items.findIndex((item) => item.id === field.id);
    return indexInItems !== -1 ? indexInItems : 0; // default to 0 if not found
  }, [elements, field.id, htmlTemplate]);

  // Parse numerical values
  const left = useMemo(() => parseFloat(field.x), [field.x]);
  const top = useMemo(() => parseFloat(field.y), [field.y]);
  const width = useMemo(() => parseFloat(field.w), [field.w]);
  const height = useMemo(() => parseFloat(field.h), [field.h]);

  // Handlers

  // Add the handleDrag function
  // Updated handleDrag function with boundary checks
  const handleDrag = useCallback(
    (e, data) => {
      if (activeFieldIds.length > 1) {
        const deltaX = data.deltaX;
        const deltaY = data.deltaY;

        // Get the viewport element
        const viewport = document.querySelector('.viewport');
        if (!viewport) return;
        const viewportRect = viewport.getBoundingClientRect();

        activeFieldIds.forEach((id) => {
          if (id !== field.id) {
            const element = document.querySelector(`[data-field-id="${id}"]`);
            if (element) {
              const transform = element.style.transform;
              let match = /translate\((.*)px, (.*)px\)/.exec(transform);
              let x = 0,
                y = 0;
              if (match) {
                x = parseFloat(match[1]);
                y = parseFloat(match[2]);
              }
              x += deltaX;
              y += deltaY;

              // Get the element's current position and size
              const rect = element.getBoundingClientRect();
              const itemWidth = element.offsetWidth;
              const itemHeight = element.offsetHeight;

              // Calculate new position relative to viewport
              let newLeft = rect.left + deltaX - viewportRect.left;
              let newTop = rect.top + deltaY - viewportRect.top;

              // Adjust x and y to prevent moving outside viewport
              if (newLeft < 0) {
                x -= newLeft;
              }
              if (newTop < 0) {
                y -= newTop;
              }
              if (newLeft + itemWidth > viewportRect.width) {
                x -= newLeft + itemWidth - viewportRect.width;
              }
              if (newTop + itemHeight > viewportRect.height) {
                y -= newTop + itemHeight - viewportRect.height;
              }

              element.style.transform = `translate(${x}px, ${y}px)`;
            }
          }
        });
      }
    },
    [activeFieldIds, field.id],
  );

  // Updated handleDragStop function with boundary checks
  const handleDragStop = useCallback(
    (e, data) => {
      let newX = data.x;
      let newY = data.y;

      const isShiftPressed = e.shiftKey;
      if (isShiftPressed) return;
      console.log('newX:', newX);
      console.log('newY:', newY);

      // Get the viewport dimensions
      const viewport = document.querySelector('.viewport');
      if (!viewport) return;
      const viewportRect = viewport.getBoundingClientRect();

      // Get the dimensions of the main field
      const itemWidth = parseFloat(field.w);
      const itemHeight = parseFloat(field.h);

      // Adjust newX and newY to prevent going outside the viewport
      if (newX < 0) {
        newX = 0;
      }
      if (newY < 0) {
        newY = 0;
      }
      if (newX + itemWidth > viewportRect.width) {
        newX = viewportRect.width - itemWidth;
      }
      if (newY + itemHeight > viewportRect.height) {
        newY = viewportRect.height - itemHeight;
      }

      const updates = [
        {
          itemId: field.id,
          properties: {
            x: newX,
            y: newY,
          },
        },
      ];

      if (activeFieldIds.length > 1) {
        activeFieldIds.forEach((id) => {
          if (id !== field.id) {
            const element = document.querySelector(`[data-field-id="${id}"]`);
            if (element) {
              const transform = element.style.transform;
              let match = /translate\((.*)px, (.*)px\)/.exec(transform);
              let x = parseFloat(element.style.left) || 0;
              let y = parseFloat(element.style.top) || 0;

              if (match) {
                x += parseFloat(match[1]);
                y += parseFloat(match[2]);
              }

              // Get the item from the htmlTemplate
              const item = htmlTemplate?.items?.find((item) => item.id === id);
              if (item) {
                const itemWidth = parseFloat(item.w);
                const itemHeight = parseFloat(item.h);

                // Adjust x and y to prevent going outside viewport
                if (x < 0) {
                  x = 0;
                }
                if (y < 0) {
                  y = 0;
                }
                if (x + itemWidth > viewportRect.width) {
                  x = viewportRect.width - itemWidth;
                }
                if (y + itemHeight > viewportRect.height) {
                  y = viewportRect.height - itemHeight;
                }
              }

              updates.push({
                itemId: id,
                properties: {
                  x: x,
                  y: y,
                },
              });

              // Reset the transform style
              element.style.transform = '';
            }
          }
        });
      }

      // Dispatch updates
      updates.forEach((update) => {
        dispatch(updateItemCM(update));
      });
    },
    [dispatch, field, activeFieldIds, htmlTemplate],
  );

  const handleRndResizeStop = useCallback(
    (e, direction, ref, delta, position) => {
      const newWidth = parseFloat(ref.style.width);
      const newHeight = parseFloat(ref.style.height);
      const newX = position.x;
      const newY = position.y;

      dispatch(
        updateItemCM({
          itemId: field.id,
          properties: {
            w: `${newWidth}px`,
            h: `${newHeight}px`,
            x: newX,
            y: newY,
            // Only update backgroundW and backgroundH if necessary
            ...(field.backgroundW && { backgroundW: field.backgroundW }),
            ...(field.backgroundH && { backgroundH: field.backgroundH }),
          },
        }),
      );
    },
    [dispatch, field],
  );

  const handleRndClick = useCallback(
    (e) => {
      e.stopPropagation();

      const isShiftPressed = e.shiftKey;

      if (isShiftPressed) {
        // If shift key is held, add the current field.id to the selection
        if (!activeFieldIds.includes(field.id)) {
          dispatch(setActiveFieldCM([...activeFieldIds, field.id]));
        }
      } else {
        if (!activeFieldIds.includes(field.id)) {
          // If shift key is not held, set the selection to the current field.id
          dispatch(setActiveFieldCM([field.id]));
        }
      }
    },
    [dispatch, field.id, activeFieldIds],
  );

  const textStyle = {
    textAlign: field.textAlign,
    borderRadius: computedBorderRadius,
    overflow: computedBorderRadius > 0 ? 'hidden' : 'visible',
    zIndex: zIndex + 1,
  };

  const hoverStyle = {
    outlineWidth: isActive ? '4px' : '2px',
    outlineColor: '#097CC7',
    outlineStyle: 'dashed',
    outlineOffset: '-1px',
  };

  const showHoveredOutline =
    isViewportHovered &&
    !preview &&
    (cmBlockType !== CM_ITEM_TYPES.modular.Template ||
      (cmBlockType === CM_ITEM_TYPES.modular.Template &&
        !shapeTypes.includes(field.type)));

  const textContainerStyle = showHoveredOutline
    ? { ...textStyle, ...hoverStyle }
    : textStyle;

  const lockAspectRatio =
    field.type === CM_ITEM_TYPES.shapes.circle ||
    field.type === CM_ITEM_TYPES.image.agent ||
    field.type === CM_ITEM_TYPES.image.agent2 ||
    CM_ITEM_TYPES.image.logo[field.type];

  return (
    <Rnd
      className={`field ${isActive ? 'active' : ''}`}
      data-field-id={preview ? `preview-${field.id}` : field.id}
      bounds="parent"
      scale={activeScale}
      style={textContainerStyle}
      onMouseDown={handleRndClick}
      size={{ width: width, height: height }}
      position={{ x: left, y: top }}
      onResizeStop={handleRndResizeStop}
      onDrag={handleDrag} // Add this line
      onDragStop={handleDragStop} // Modified function
      lockAspectRatio={lockAspectRatio}
      dragGrid={[5, 5]}
      resizeGrid={[5, 5]}
      enableResizing={{
        top: enableResizing,
        right: enableResizing,
        bottom: enableResizing,
        left: enableResizing,
        topRight: enableResizing,
        bottomRight: enableResizing,
        bottomLeft: enableResizing,
        topLeft: enableResizing,
      }}
      disableDragging={disableDragging}
    >
      {children}
    </Rnd>
  );
};

ResizableDraggable.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    backgroundW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundH: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textAlign: PropTypes.string,
    style: PropTypes.object,
    page: PropTypes.string,
    textLabel: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  scale: PropTypes.number,
  preview: PropTypes.bool,
};

// Custom comparison function
const areEqual = (prevProps, nextProps) => {
  // Compare scale and preview props
  if (prevProps.scale !== nextProps.scale) return false;
  if (prevProps.preview !== nextProps.preview) return false;

  // Compare field prop shallowly
  const prevField = prevProps.field;
  const nextField = nextProps.field;

  if (prevField.id !== nextField.id) return false;
  if (prevField.x !== nextField.x) return false;
  if (prevField.y !== nextField.y) return false;
  if (prevField.w !== nextField.w) return false;
  if (prevField.h !== nextField.h) return false;
  if (prevField.backgroundW !== nextField.backgroundW) return false;
  if (prevField.backgroundH !== nextField.backgroundH) return false;
  if (prevField.textAlign !== nextField.textAlign) return false;
  if (prevField.style !== nextField.style) return false;
  if (prevField.page !== nextField.page) return false;
  if (prevField.textLabel !== nextField.textLabel) return false;

  // Assuming children are memoized or stable
  return true;
};

export default React.memo(ResizableDraggable, areEqual);
