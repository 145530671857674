import React from 'react';
import { faPaintRoller } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import AdjustImageMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu/components/AdjustImageMenuItem';
import ChangeImageMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu/components/ChangeImageMenuItem';
import RecommendedImageMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/components/ActiveFieldMenu/components/RecommendedImageMenuItem';
import BorderRadiusMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/BorderRadiusMenuItem';
import GradientPickerMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/GradientPickerMenuItem';
import OptionsMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/OptionsMenuItem';
import { TextLabelMenuItem } from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/TextLabelMenuItem';
import { useItemLabelValue } from 'components/ModelViews/Documents/ClickMarketingViewer/hooks/useItemLabelValue';
import { CM_ITEM_TYPES } from 'consts';
import useGetMediaUrl from 'hooks/useGetMediaUrl';
import {
  selectCurrentItem,
  cmBlockTypeSelectorCM,
  autofillDataSelectorCM,
} from 'redux/selectors';

export const ImageToolbar = () => {
  const cmBlockType = useSelector(cmBlockTypeSelectorCM);
  const autoFillData = useSelector(autofillDataSelectorCM);
  const activeItem = useSelector(selectCurrentItem);
  const labelValue = useItemLabelValue(activeItem);

  const adjustableTypes = [
    CM_ITEM_TYPES.image.attachment,
    CM_ITEM_TYPES.image.property,
  ];

  const showAdjust = adjustableTypes.includes(activeItem?.type);

  const { data: labelValueUrlData } = useGetMediaUrl({
    uuid:
      labelValue?.fileId ||
      autoFillData.propertyPhotos?.[Number(activeItem?.imageNumber)]?.uuid,
  });

  const hasImage = labelValueUrlData?.data?.url;

  return (
    <>
      {labelValueUrlData?.data?.url && (
        <Box
          display={'flex'}
          justifyContent={'center'}
          style={{
            backgroundColor: labelValueUrlData?.data?.url
              ? 'grey'
              : 'transparent',
            padding: '16px',
          }}
        >
          <img
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '200px',
            }}
            src={hasImage}
            alt={'Current Item Image'}
          />
        </Box>
      )}
      {cmBlockType !== CM_ITEM_TYPES.modular.Template ? (
        <>
          <TextLabelMenuItem />
          <BorderRadiusMenuItem />
          <GradientPickerMenuItem
            styleProperty={'background'}
            label={'Overlay'}
            icon={faPaintRoller}
          />
          <Divider />
          <OptionsMenuItem />
        </>
      ) : (
        <>
          <ChangeImageMenuItem hasImage={hasImage} />
          {showAdjust && (
            <>
              {hasImage && <AdjustImageMenuItem />}
              <RecommendedImageMenuItem />
            </>
          )}
        </>
      )}
    </>
  );
};
