import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { SkeletonSummary } from 'components/Common';
import { ToolbarRow } from 'components/Common/Table/TableToolbar/styled';
import { ListPagination } from 'components/ListPagination';
import { TaskSummary } from 'components/ModelViews/Tasks';
import { SearchInput } from 'components/SearchInput';
import { TASK_STATUSES } from 'consts';
import { useDebounce } from 'hooks';
import { useGetMyTasksQuery } from 'redux/rtk-query';

export const MyTaskList = ({ type }) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const debouncedValue = useDebounce(searchValue, 500);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { data: myTaskData } = useGetMyTasksQuery({
    search: debouncedValue,
    taskType: type,
    page: page,
    status: [
      TASK_STATUSES.NEW,
      TASK_STATUSES.IN_PROGRESS,
      TASK_STATUSES.REVISE,
    ],
    limit: rowsPerPage,
  });

  const AllTasks = myTaskData?.data?.items;
  const totalPages = myTaskData?.data?.meta.totalPages;
  const totalCount = myTaskData?.data?.meta.total;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div>
      <ToolbarRow>
        <SearchInput changeHandler={setSearchValue} />
      </ToolbarRow>
      <>
        {!myTaskData ? (
          <SkeletonSummary />
        ) : (
          AllTasks &&
          AllTasks.length > 0 &&
          AllTasks.map((task) => (
            <React.Fragment key={task.id}>
              <TaskSummary task={task} showRelatedModel />
            </React.Fragment>
          ))
        )}
        <Box style={{ textAlign: 'center' }}>
          {totalPages > 1 && (
            <ListPagination
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[5]}
            />
          )}
        </Box>
      </>
    </div>
  );
};

MyTaskList.propTypes = {
  type: PropTypes.string.isRequired,
};
