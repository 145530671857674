import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/system';
import abeLottie from 'images/abe-lottie-no-text.json';

const AbeNavLottie = ({
  src = abeLottie,
  style,
  speed = 0.7,
  targetFrame = 125,
  ...props
}) => {
  const playerRef = useRef(null);
  // lottieRef holds the underlying Lottie instance.
  const [lottieRef, setLottieRef] = useState(null);
  const [totalFrames, setTotalFrames] = useState(null);
  // segment state determines which part of the animation is playing.
  // "initial" means the initial segment (0 to target) has played and we're paused at target.
  // "hoverForward" means we're playing from target to end.
  // "hoverBackward" means we're playing from 0 to target to complete the loop.
  const [segment, setSegment] = useState('initial');

  const handleLottieEvent = useCallback(
    (eventName) => {
      if (eventName === 'load') {
        const total = lottieRef?.totalFrames;
        console.log('Lottie load event:', lottieRef, 'Total frames:', total);
        if (total) {
          setTotalFrames(total);
          // Start with the initial segment: 0 to targetFrame.
          setSegment('initial');
          if (lottieRef && typeof lottieRef.playSegments === 'function') {
            lottieRef.playSegments([0, targetFrame], true);
          }
        }
      } else if (eventName === 'complete') {
        // When a segment finishes playing, handle based on our current segment.
        if (segment === 'initial') {
          // After playing 0 -> targetFrame, lock the animation at the target.
          if (lottieRef && typeof lottieRef.goToAndStop === 'function') {
            lottieRef.goToAndStop(targetFrame, true);
          }
        } else if (segment === 'hoverForward') {
          // After playing target -> end, start the backward segment.
          setSegment('hoverBackward');
          if (lottieRef && typeof lottieRef.playSegments === 'function') {
            lottieRef.playSegments([0, targetFrame], true);
          }
        } else if (segment === 'hoverBackward') {
          // After playing 0 -> target, lock again at the target frame.
          if (lottieRef && typeof lottieRef.goToAndStop === 'function') {
            lottieRef.goToAndStop(targetFrame, true);
          }
          setSegment('initial');
        }
      }
    },
    [lottieRef, segment, targetFrame],
  );

  const handleMouseEnter = useCallback(() => {
    // Only trigger the hover loop if we're in the "initial" (paused) state.
    if (segment === 'initial' && totalFrames) {
      setSegment('hoverForward');
      if (lottieRef && typeof lottieRef.playSegments === 'function') {
        lottieRef.playSegments([targetFrame, totalFrames], true);
      }
    }
  }, [segment, totalFrames, lottieRef, targetFrame]);

  return (
    <Box onMouseEnter={handleMouseEnter}>
      <Player
        lottieRef={(instance) => {
          if (instance) {
            setLottieRef(instance);
          }
        }}
        ref={playerRef}
        src={src}
        style={style}
        speed={speed}
        loop={false}
        onEvent={(eventName, eventData) =>
          handleLottieEvent(eventName, eventData)
        }
        {...props}
      />
    </Box>
  );
};

AbeNavLottie.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  style: PropTypes.object,
  speed: PropTypes.number,
  targetFrame: PropTypes.number,
};

export default AbeNavLottie;
