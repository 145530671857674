import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import { Grid } from '@mui/material';
import {
  AbeForm,
  FormCondition,
  FormValidationProvider,
} from 'components/Forms';
import {
  PM_FILE_OPTIONS,
  REPRESENTING_OPTIONS,
  STATUS_OPTIONS,
  TRANSACTION_TYPES,
} from 'consts';
import { useValidateTransactionMutation } from 'redux/rtk-query';
import {
  ContractPanel,
  LeasePanel,
  ListingPanel,
  MyCommissionPanel,
  OverviewPanel,
  PropertyMgmtAgreementPanel,
  PropertyMgmtPanel,
  RolesPanel,
  TeamShareAndReferralPanel,
  TimeframesPanel,
  BuyerAgentCommissionPanel,
} from './components';
import { processFormData } from './formData';

const removeTeamMember = (args, state, utils) => {
  utils.changeValue(state, 'team', (team) => {
    return team.filter((t) => t.order !== args[0]);
  });
};

const updateSharedWithUsers = (team, currentSharedWithUsers, form, owner) => {
  // Extract owners from the team members, excluding the modelAccess.owner
  const teamOwners = team
    .map((member) => member.teamMember?.abeUser)
    .filter((user) => user && user.id !== owner.id);

  const updatedSharedWithUsers = new Set(currentSharedWithUsers);

  // Add new team members
  teamOwners.forEach((owner) => updatedSharedWithUsers.add(owner));

  // Remove old team members not in the current team
  currentSharedWithUsers.forEach((user) => {
    if (!teamOwners.includes(user)) {
      updatedSharedWithUsers.delete(user);
    }
  });

  // Only update if there is a change to avoid unnecessary re-renders
  const newSharedWithUsers = Array.from(updatedSharedWithUsers);
  if (
    currentSharedWithUsers.length !== newSharedWithUsers.length ||
    currentSharedWithUsers.some((user) => !updatedSharedWithUsers.has(user))
  ) {
    form.change('modelAccess.sharedWithUsers', newSharedWithUsers);
  }
};

export const TransactionForm = (props) => {
  const previousTeamRef = useRef([]);

  const handleFormChange = (form, values) => {
    const currentSharedWithUsers =
      form.getFieldState('modelAccess.sharedWithUsers')?.value || [];
    const currentTeam = values.team || [];
    const previousTeam = previousTeamRef.current;
    const owner = values.modelAccess?.owner; // Retrieve the owner from the form values

    // Check for changes in team members
    if (
      currentTeam.length !== previousTeam.length ||
      currentTeam.some(
        (member, index) =>
          member.teamMember?.abeUser !==
          previousTeam[index]?.teamMember?.abeUser,
      )
    ) {
      previousTeamRef.current = [...currentTeam]; // Update the reference to the latest team state
      updateSharedWithUsers(currentTeam, currentSharedWithUsers, form, owner);
    }
  };

  const renderFields = (form, values) => (
    <Grid container spacing={2} style={{ padding: '0 16px' }}>
      <Grid
        item
        xs={12}
        md={values.transactionType !== TRANSACTION_TYPES.PRIMARY ? 6 : 4}
      >
        <OverviewPanel />
        {values.transactionType === TRANSACTION_TYPES.PRIMARY && (
          <FormCondition when="addressConfirmed" operator="equals" is={true}>
            <MyCommissionPanel />
            {values.transactionType !== TRANSACTION_TYPES.PRIMARY &&
              (values.representing === REPRESENTING_OPTIONS.SELLER.value ||
                values.representing ===
                  REPRESENTING_OPTIONS.LANDLORD.value) && (
                <BuyerAgentCommissionPanel />
              )}
          </FormCondition>
        )}
      </Grid>
      {values.transactionType === TRANSACTION_TYPES.PRIMARY && (
        <Grid item xs={12} md={4}>
          {((values.transactionType === TRANSACTION_TYPES.PRIMARY &&
            values.representing === REPRESENTING_OPTIONS.SELLER.value) ||
            (values.transactionType !== TRANSACTION_TYPES.PRIMARY &&
              values.representing === REPRESENTING_OPTIONS.BUYER.value)) && (
            <ListingPanel />
          )}
          {((values.transactionType === TRANSACTION_TYPES.PRIMARY &&
            values.representing === REPRESENTING_OPTIONS.BUYER.value) ||
            (values.transactionType !== TRANSACTION_TYPES.PRIMARY &&
              values.representing === REPRESENTING_OPTIONS.SELLER.value) ||
            values.status === STATUS_OPTIONS.PENDING.value ||
            values.status === STATUS_OPTIONS.SOLD.value) && <ContractPanel />}
          {(values.status === STATUS_OPTIONS.LEASED.value ||
            values.representing?.includes('Tenant')) && <LeasePanel />}
          {(values.status === STATUS_OPTIONS.PROPERTY_MGMT.value ||
            values.pmFile === PM_FILE_OPTIONS.PROPERTY_MGMT.value) && (
            <>
              <PropertyMgmtPanel />
              <PropertyMgmtAgreementPanel />
            </>
          )}
          <TimeframesPanel />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={values.transactionType !== TRANSACTION_TYPES.PRIMARY ? 6 : 4}
      >
        <RolesPanel />
        {values.transactionType === TRANSACTION_TYPES.PRIMARY && (
          <TeamShareAndReferralPanel />
        )}
      </Grid>
    </Grid>
  );

  const [validate] = useValidateTransactionMutation();

  return (
    <FormValidationProvider value={{ validate, processFormData }}>
      <AbeForm {...props} mutators={{ removeTeamMember }}>
        {(form, values) => (
          <>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => handleFormChange(form, values)}
            />
            {renderFields(form, values)}
          </>
        )}
      </AbeForm>
    </FormValidationProvider>
  );
};

TransactionForm.propTypes = {
  afterSave: PropTypes.func,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  nameButton: PropTypes.string,
  isLoading: PropTypes.bool,
};
