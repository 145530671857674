import { Link } from 'react-router-dom';
import { ListItemIcon, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'consts/colors';

export const PageLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: colors.BLUE_1,
  },
});

export const PageName = styled(Typography)({
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '32px',
  textAlign: 'center',
  width: 'fit-content',
  fontFamily: 'Quicksand',
  color: colors.LIGHT_BLACK,
});

export const SelectPlaceholderText = styled(Typography)({
  color: colors.GREY,
});

SelectPlaceholderText.defaultProps = {
  variant: 'body2',
};

export const SectionHeader = styled(Typography)(({ textAlign }) => ({
  position: 'relative',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: textAlign ? textAlign : 'center',
  letterSpacing: '0.04em',
  // color: "#969696",
  '& > label': {
    position: 'absolute',
    top: -20,
    left: 10,
    backgroundColor: 'white',
    padding: '0 4px',
    borderRadius: 10,
  },
}));

export const NoResults = styled(Typography)(({ textAlign }) => ({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  textAlign: textAlign ? textAlign : 'center',
  letterSpacing: '0.04em',
  color: '#969696',
  padding: 10,
}));

export const CircleBadge = styled('span')({
  background: '#969696',
  borderRadius: '100%',
  width: 'fit-content',
  fontWeight: 500,
  fontSize: 11,
  textAlign: 'center',
  color: '#FFFFFF',
  padding: '1px 4px',
  position: 'relative',
});

export const InfoIcon = styled(ListItemIcon)({
  minWidth: 30,
});

export const ClickableTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  // color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.dark,
  },
  '&:focus': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
  '&:active': {
    color: theme.palette.primary.light,
  },
}));
