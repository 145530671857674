const commentStyles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '8px',
    padding: '8px 12px',
    backgroundColor: '#f0f2f5',
    borderRadius: '18px',
    marginBottom: '4px',
  },
  avatar: {
    width: '32px',
    height: '32px',
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  authorName: {
    fontWeight: 'bold',
  },
  timestamp: {
    fontSize: '12px',
    color: '#65676b',
  },
  message: {
    fontSize: '14px',
  },
};

export default commentStyles;
