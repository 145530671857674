import React from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemText } from '@mui/material';
import { Box, styled } from '@mui/system';
import { MenuButton, ColorChip } from 'components/Common';
import { useGetCategoriesListQuery } from 'redux/rtk-query';

const StyledBox = styled(Box)(({ theme }) => ({
  maxHeight: '300px',
  overflowY: 'auto',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export const CategoriesDropDown = ({ categories, setCategories }) => {
  const { data } = useGetCategoriesListQuery({
    page: 1,
    limit: 100,
    order: 'asc',
    orderBy: 'name',
    parentId: 15,
  });

  const isColorSelected = (category) => categories.includes(category.id);

  return (
    <Box textAlign={'right'}>
      <MenuButton sx={{ padding: 0 }} size={'x-small'} title={'All Categories'}>
        <StyledBox>
          <ListItemButton
            dense
            selected={!categories.length}
            onClick={() => {
              setCategories([]);
            }}
          >
            <ListItemText primary={'All Categories'} />
          </ListItemButton>
          {data?.data?.map((category) => (
            <ColorChip
              key={category.id}
              chipColor={category.color}
              label={category.name}
              variant={isColorSelected(category) ? 'primary' : 'outlined'}
              onClick={() => {
                if (categories.includes(category.id)) {
                  setCategories(categories.filter((id) => id !== category.id));
                } else {
                  setCategories([...categories, category.id]);
                }
              }}
            />
          ))}
        </StyledBox>
      </MenuButton>
    </Box>
  );
};

CategoriesDropDown.propTypes = {
  categories: PropTypes.array,
  setCategories: PropTypes.func,
};
