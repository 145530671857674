import { createApi } from '@reduxjs/toolkit/query/react';
import { transformResponse } from 'helpers';
import { axiosBaseQuery } from 'utils/baseQuery';
import { contactUrlBuilder } from './urlBuilder/contacts';

export const contactsAPI = createApi({
  reducerPath: 'contactsAPI',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  tagTypes: ['Lists', 'Contacts', 'Notes', 'Tags'],
  endpoints: (build) => ({
    getContacts: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getContacts(args),
        method: 'GET',
      }),
      providesTags: ['Contacts', 'Notes', 'Audit Logs'],
    }),
    getContact: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getContact(args),
        method: 'GET',
      }),
      providesTags: ['Contacts'],
    }),
    validateContact: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.validateContact(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [],
    }),
    createContact: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.createContact(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Contacts', 'Lists', 'Audit Logs'],
    }),
    updateContact: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.updateContact(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Contacts', 'Lists', 'Audit Logs'],
    }),
    getContactLists: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getContactLists(args),
        method: 'GET',
      }),
      providesTags: ['Lists'],
    }),
    getContactTags: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getContactTags(args),
        method: 'GET',
      }),
      providesTags: ['Tags'],
    }),
    getLists: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getLists(args),
        method: 'GET',
      }),
      providesTags: ['Lists'],
    }),
    getList: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getList(args),
        method: 'GET',
      }),
      transformResponse,
      providesTags: ['Lists', 'Contacts'],
    }),
    createList: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.createList(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Lists'],
    }),
    updateList: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.updateList(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Lists', 'Contacts'],
    }),
    deleteList: build.mutation({
      query: (args) => ({
        url: contactUrlBuilder.deleteList(args),
        method: 'DELETE',
      }),
      invalidatesTags: ['Lists'],
    }),
    addToFavourite: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.addToFavourite(),
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Lists'],
    }),
    removeFromFavorite: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.removeFromFavorite(),
        method: 'DELETE',
        data: body,
      }),
      invalidatesTags: ['Lists'],
    }),
    addToList: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.addToList(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Contacts', 'Lists'],
    }),
    removeFromList: build.mutation({
      query: (body) => ({
        url: contactUrlBuilder.removeFromList(),
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Contacts', 'Lists'],
    }),
    getContactAuditLogs: build.query({
      query: (args) => ({
        url: contactUrlBuilder.getAuditLogs(args),
        method: 'GET',
      }),
      providesTags: ['Audit Logs'],
    }),
  }),
});

export const {
  useValidateContactMutation,
  useCreateListMutation,
  useUpdateListMutation,
  useDeleteListMutation,
  useGetListQuery,
  useGetContactListsQuery,
  useGetContactTagsQuery,
  useGetListsQuery,
  useGetContactsQuery,
  useAddToFavouriteMutation,
  useRemoveFromFavoriteMutation,
  useRemoveFromListMutation,
  useAddToListMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
  useGetContactQuery,
  useGetContactAuditLogsQuery,
} = contactsAPI;
