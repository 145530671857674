import React from 'react';
import { useRoutes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { colors, routesConfig } from 'consts';
import { usePageTitle } from 'hooks';
import GlobalSnackbar from './components/GlobalSnackbar/GlobalSnackbar';

const font = "'Open Sans', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.LIGHT_BLUE_1,
      lightRed: colors.LIGHT_RED,
      red: colors.RED,
      grey: colors.LIGHT_BLACK,
      lightgrey: colors.GREY,
      white: colors.WHITE,
    },
    secondary: {
      main: colors.GREY,
    },
    white: {
      main: colors.WHITE,
    },
  },
  typography: {
    color: colors.LIGHT_BLACK,
    fontFamily: ['Open Sans', 'Roboto'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Open Sans',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
          },
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#f6fafd',
          },
          '& .MuiInputLabel-root': {
            color: '#8c8c8c',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            color: '#989898',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused': {
            color: '#45aaf2',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.10)',
          },
        },
        outlined: {
          '& .MuiInputBase-root': {
            backgroundColor: '#f6fafd',
          },
        },
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            '& .MuiInputBase-root > input': {
              fontSize: 20,
              padding: '10.5px 14px',
            },
          },
        },
        {
          props: { variant: 'borderless' },
          style: {
            '& .MuiInputBase-root:before': {
              borderBottom: 0,
            },
          },
        },
      ],
    },
    MuiDivider: {
      // styleOverrides: {
      //     root: {
      //         width: "100%"
      //     },
      // },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: font,
          '& .filterComponent': {
            opacity: 0,
            transition: 'opacity 0.3s',
          },
          '&:hover .filterComponent': {
            opacity: 1,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 18,
          boxShadow: '0px 0px 3px 2px rgb(0 0 0 / 4%)',
          border: '1px solid #e5e5e5',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 15px',
          textTransform: 'none',
          borderRadius: '8px',
        },
        contained: {
          color: 'white',
        },
        outlined: {
          backgroundColor: 'white',
        },
        endIcon: {
          marginRight: -1,
          marginLeft: 6,
          '&>*:nth-of-type(1)': {
            fontSize: 'inherit',
          },
        },
        sizeSmall: {
          padding: '3px 9px',
        },
      },
      variants: [
        {
          props: { variant: 'grey' },
          style: {
            border: '1px solid #d0d0d0',
            color: colors.LIGHT_BLACK,
            backgroundColor: 'white',
          },
        },
        {
          props: { size: 'x-small' },
          style: {
            borderRadius: 5,
            padding: '2px 10px',
            fontSize: 14,
            minWidth: 25,
            lineHeight: 1,
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'rowButton' },
          style: {
            border: `1px solid ${colors.LIGHT_GREY}`,
            color: colors.LIGHT_BLACK,
            backgroundColor: 'white',
            borderRadius: 5,
            width: 25,
            '&:not(:last-child)': {
              marginRight: 5,
            },
          },
        },
        {
          props: { size: 'x-small' },
          style: {
            padding: 0,
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.LIGHT_BLACK,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.BLUE_1,
          },
          '&.Mui-selected .MuiTypography-root': {
            color: colors.WHITE,
          },
          '&:hover .MuiTypography-root': {
            color: colors.BLUE_1,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colors.LIGHT_BLACK,
          '&.Mui-selected': {
            backgroundColor: colors.BLUE_1,
            color: colors.WHITE,
          },
        },
      },
    },
  },
});

export const App = () => {
  const routes = useRoutes(routesConfig);
  usePageTitle(); // This will automatically set the page title based on the current route
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {routes}
      <GlobalSnackbar />
    </ThemeProvider>
  );
};
