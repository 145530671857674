import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField } from 'react-final-form';
import { IconButton, Card, Grid } from '@mui/material';
import { Box } from '@mui/system';
import HoverReveal from 'components/Common/HoverReveal/HoverReveal';
import { CheckboxField, TextInput } from 'components/Fields';
import { Path, Condition, Value } from './';

const Trigger = ({ name, onRemove }) => {
  // Subscribe to the `isEditable` field so we can conditionally render the TextInput.
  const {
    input: { value: isEditable },
  } = useField(`${name}.isEditable`, { subscription: { value: true } });

  return (
    <Grid item xs position="relative">
      <HoverReveal
        hoverContent={
          <IconButton color="error" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        }
        sx={{
          flexDirection: 'column',
        }}
      >
        <Card sx={{ px: 2, py: 1 }}>
          <Box display="flex" alignItems="center" sx={{ mr: 3 }}>
            <Path name={name} />
            <Condition name={name} />
            <Value name={name} />
          </Box>
          <Box display="flex" alignItems="center" sx={{ mr: 3 }}>
            <Field
              name={`${name}.isEditable`}
              label="Live Filter"
              size="small"
              component={CheckboxField}
              sx={{ mr: 2 }}
            />
            {isEditable && (
              <Field name={`${name}.name`} label="Name" component={TextInput} />
            )}
          </Box>
        </Card>
      </HoverReveal>
    </Grid>
  );
};

Trigger.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Trigger;
