import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { FileUploadDrop } from 'components/Common';
import { useUploadFilesMutation } from 'redux/rtk-query';

const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];

const PhotoSetPlaceholderUpload = ({ documentId }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [postFile] = useUploadFilesMutation();

  // Function to create FormData from a list of files
  const createFormData = (files) => {
    const formData = new FormData();
    formData.append('id', documentId);
    files.forEach((file) => {
      formData.append('files', file);
    });
    return formData;
  };

  // Automatically process file upload on file drop
  const processFileUpload = useCallback(
    (files) => {
      const validFiles = [];
      const invalidFiles = [];

      Array.from(files).forEach((file) => {
        if (allowedFileTypes.includes(file.type)) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      });

      // Set rejected files if any invalid files are found
      if (invalidFiles.length > 0) {
        setRejectedFiles(invalidFiles);
      }

      if (validFiles.length > 0) {
        setUploading(true);
        const formData = createFormData(validFiles);

        // --- Upload Progress Simulation ---
        // If you want a real progress bar, consider using axios or another library
        // that supports onUploadProgress callbacks.
        const simulationInterval = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 100) {
              clearInterval(simulationInterval);
              return 100;
            }
            // Increase progress by a random value
            return Math.min(prev + Math.random() * 20, 100);
          });
        }, 300);
        // -----------------------------------

        // Automatically call the upload mutation
        postFile(formData)
          .unwrap()
          .then(() => {
            // Upload successful: reset states as needed
            setUploading(false);
            setUploadProgress(100);
            // Optionally, update your UI with response data here
          })
          .catch((error) => {
            // Handle upload error
            setUploading(false);
            setUploadProgress(0);
            console.error('Upload failed:', error);
          });
      }
    },
    [documentId, postFile],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 10px)',
        position: 'relative',
        p: 1,
        borderRadius: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f9f9f9',
        },
      }}
    >
      <FileUploadDrop
        minWidth={'0px'}
        uploading={uploading}
        uploadedFiles={[]}
        rejectedFiles={rejectedFiles}
        uploadProgress={uploadProgress}
        onFileChange={processFileUpload}
        allowedFileTypes={allowedFileTypes}
      />
    </Box>
  );
};

PhotoSetPlaceholderUpload.propTypes = {
  documentId: PropTypes.string,
};

export default PhotoSetPlaceholderUpload;
