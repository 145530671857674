import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField } from 'react-final-form';
import { Typography, Grid, IconButton, Card } from '@mui/material';
import { Select } from 'components/Fields';
import { colors } from 'consts';
import { conversion } from 'helpers';

const Sorting = ({ name, removeSorting }) => {
  const visualPathValue = useField(`${name}.visualPath`).input.value;
  const lastItem = visualPathValue[visualPathValue.length - 1];
  const groupingFunctionValue = useField(`${name}.groupingFunction`).input
    .value;
  const dateSeparationValue = useField(`${name}.dateSeparation`).input.value;
  return (
    <Card
      display="flex"
      alignItems="center"
      sx={{
        px: 2,
        py: 1,
        borderRadius: '8px',
        mb: 1,
      }}
    >
      <Grid container alignItems={'center'}>
        {/* Display the visualPath */}
        <Grid item width={300}>
          <Typography variant="body1" color={colors.BLACK}>
            {conversion.camelCaseToWords(lastItem)}
            {groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''}
            {dateSeparationValue ? ` - (${dateSeparationValue})` : ''}
          </Typography>
        </Grid>
        <Grid item xs>
          <Field
            name={`${name}.direction`}
            component={Select}
            defaultValue={'ASC'}
            options={[
              { label: 'Ascending', value: 'ASC' },
              { label: 'Descending', value: 'DESC' },
            ]}
          />
        </Grid>
        <IconButton onClick={removeSorting}>
          <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
        </IconButton>
      </Grid>
    </Card>
  );
};

Sorting.propTypes = {
  name: PropTypes.string.isRequired,
  removeSorting: PropTypes.func.isRequired,
};

export default Sorting;
