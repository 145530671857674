/* eslint-disable no-dupe-keys */
import React from 'react';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { HeaderBox } from 'components/Styled';

export const StickyHeader = styled(HeaderBox, {
  shouldForwardProp: (prop) => prop !== 'headerColor',
})(({ headerColor }) => ({
  padding: 16,
  // width: 'calc(100% - 16px)',
  zIndex: 2,
  position: 'sticky',
  top: 0,
  background: headerColor ? headerColor : 'rgb(247, 247, 247)',
}));

export const CreateContainer = styled('form')({});

export const CreateBox = styled(Box)({
  fontFamily: 'Quicksand',
  '& > form .MuiBox-root .MuiFormControl-root': {
    margin: '8px 0',
  },
  '& > form .MuiGrid-item:not(.MuiGrid-grid-xs-12):not(:last-child) > .MuiBox-root':
    {
      marginRight: 10,
    },
  '& > form .MuiGrid-item.MuiGrid-grid-xs-6:nth-of-type(2n) > .MuiBox-root': {
    // marginRight: 0,
  },
  '& > form .MuiBox-root .MuiFormControl-root .MuiBox-root .MuiFormControl-root':
    {
      margin: 0,
    },
  '& > form .MuiGrid-grid-xs-6 + .MuiGrid-grid-xs-6:not(:first-of-type) .MuiFormControl-root':
    {},
});

export const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
});
export const EyeIcon = () => (
  <FontAwesomeIconComp icon={faEye} style={{ width: '20px', height: '20px' }} />
);
