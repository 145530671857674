import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select, MenuItem } from '@mui/material';
import { colors } from 'consts';

export const FilterCompareCondition = ({ name }) => {
  return (
    <Field name={`${name}.conditionType`} defaultValue="And">
      {({ input }) => {
        return (
          <Select
            {...input}
            IconComponent={() => null}
            sx={{
              fontSize: '16px',
              marginLeft: '-8px',
              marginRight: '-8px',
              fontWeight: '600',
              background: colors.BLACK,
              color: 'white',
              borderRadius: 8,
              borderWidth: 2,
              zIndex: 7,
              minWidth: '56px',
              textAlign: 'center',
              '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
                padding: '2px',
                paddingRight: '2px',
              },
            }}
          >
            <MenuItem value="And">AND</MenuItem>
            <MenuItem value="Or">OR</MenuItem>
            <MenuItem value="And Not">AND NOT</MenuItem>
          </Select>
        );
      }}
    </Field>
  );
};

FilterCompareCondition.propTypes = {
  name: PropTypes.string.isRequired,
};
