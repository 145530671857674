import { ActiveUsers, Users } from 'components/Common/Table/components';

export const getCells = (getRowClickHandler) => [
  {
    id: 'name',
    value: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    isCollapsibleCell: true,
  },
  {
    id: 'usersCount',
    value: 'usersCount',
    numeric: true,
    disablePadding: false,
    label: 'Total Users',
    width: 200,
    Wrapper: Users,
    // Pass "Inactive" (or "All", as desired) for total users.
    getRowClickHandler: (row) => getRowClickHandler(row),
  },
  {
    id: 'activeUsersCount',
    value: 'activeUsersCount',
    numeric: true,
    disablePadding: false,
    label: 'Active Users',
    width: 200,
    Wrapper: ActiveUsers,
    // Pass "Active" for the active users cell.
    getRowClickHandler: (row) => getRowClickHandler(row, 'Active'),
  },
];
