import React from 'react';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItemButton,
  FontAwesomeIconComp,
  ConfirmDialog,
} from 'components/Common';
import { selectCurrentItem } from 'redux/selectors';
import { deleteItemCm } from 'redux/slices';

const DeleteMenuItem = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const activeField = useSelector(selectCurrentItem);

  const handleDeleteClick = () => {
    dispatch(deleteItemCm(activeField.id));
  };

  return (
    <>
      <MenuItemButton
        icon={<FontAwesomeIconComp fontSize={18} icon={faTrash} />}
        label={'Delete'}
        onClick={() => setOpen(true)}
      />
      <ConfirmDialog
        title={'Delete Item'}
        message={'Are you sure you want to delete this item?'}
        open={open}
        setOpen={setOpen}
        onConfirm={handleDeleteClick}
      />
    </>
  );
};

export default DeleteMenuItem;
