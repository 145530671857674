import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Grid, Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AbeCard } from 'components/Common';
import { AbeForm } from 'components/Forms';
import {
  Value,
  Condition,
} from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters/Trigger';
import { ExtraParamOptionsContext } from 'pages/ReportPages/ReportPage/context/ExtraParamOptionsContext';
import { useGetFiltersQuery } from 'redux/rtk-query';

const LiveFilters = ({ reportId }) => {
  const { data: filtersData } = useGetFiltersQuery(reportId, {
    skip: !reportId,
  });

  const { setExtraParamOptions } = useContext(ExtraParamOptionsContext);

  // Recursive helper function to collect all filters from a group and its children.
  // Wrapped in useCallback to ensure the function reference is stable.
  const getAllFilters = useCallback((group) => {
    let allFilters = [];
    if (group.filters && Array.isArray(group.filters)) {
      allFilters = allFilters.concat(group.filters);
    }
    if (group.childGroups && Array.isArray(group.childGroups)) {
      group.childGroups.forEach((childGroup) => {
        allFilters = allFilters.concat(getAllFilters(childGroup));
      });
    }
    return allFilters;
  }, []);

  // Compute a flattened array of filters using useMemo.
  const flattenedFilters = useMemo(() => {
    return filtersData?.data ? getAllFilters(filtersData.data) : [];
  }, [filtersData, getAllFilters]);

  // Filter out only the editable filters (also memoized).
  const editableFilters = useMemo(() => {
    return flattenedFilters.filter((filter) => filter.isEditable);
  }, [flattenedFilters]);

  // State to manage the form's initial values.
  const [formInitialValues, setFormInitialValues] = useState({
    filters: editableFilters,
  });

  // Update form initial values when the API data changes (if needed).
  useEffect(() => {
    if (!formInitialValues.filters || formInitialValues.filters.length === 0) {
      setFormInitialValues({ filters: editableFilters });
    }
    // If desired, you can add logic here to update form values when the API changes.
  }, [editableFilters, formInitialValues.filters]);

  // Memoized submit handler.
  const handleSubmitFunc = useCallback(
    (values) => {
      setExtraParamOptions((prev) => ({
        ...prev,
        options: {
          ...prev.options,
          editableFilters: values.filters.map((filter) => ({
            filterId: filter.id,
            value: filter.value,
          })),
        },
      }));
      // Update the local state so that the form reflects the submitted values.
      setFormInitialValues({ filters: values.filters });
    },
    [setExtraParamOptions],
  );

  // Memoized reset handler.
  const handleResetFilters = useCallback(() => {
    setFormInitialValues({ filters: editableFilters });
  }, [editableFilters]);

  // Memoized render function for the filter fields.
  const renderFields = useCallback(
    // eslint-disable-next-line no-unused-vars
    (form, values, showAllFields) => (
      <Grid container>
        <FieldArray name="filters">
          {({ fields }) => (
            <Grid container justifyContent="space-between">
              {fields.map((filterName) => (
                <Grid
                  item
                  key={filterName}
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Field name={`${filterName}.name`}>
                    {({ input }) => (
                      <Typography
                        component="div"
                        sx={{ whiteSpace: 'no-wrap' }}
                      >
                        {input.value}
                      </Typography>
                    )}
                  </Field>
                  <Condition name={filterName} />
                  <Value name={filterName} />
                </Grid>
              ))}
            </Grid>
          )}
        </FieldArray>
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="outlined" type="submit">
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    ),
    [handleResetFilters],
  );

  return (
    <Grid container>
      {editableFilters.length > 0 && (
        <Grid item xs={6}>
          <AbeCard title="Live Filters">
            <Divider />
            <Box p={2} pt={0}>
              <AbeForm
                handleSubmitFunc={handleSubmitFunc}
                initialValues={formInitialValues}
              >
                {renderFields}
              </AbeForm>
            </Box>
          </AbeCard>
        </Grid>
      )}
    </Grid>
  );
};

LiveFilters.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default memo(LiveFilters);
