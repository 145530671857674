import React from 'react';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/lab';
import { AppBar } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { bannerMessagesSelector } from 'redux/selectors';

export const ViewportBanner = () => {
  const bannerMessages = useSelector(bannerMessagesSelector);
  let messageText = '';
  let severity = 'info';

  if (bannerMessages && bannerMessages[0]) {
    let validation =
      bannerMessages.find((validation) => validation.severity === 'error') ||
      bannerMessages.find((validation) => validation.severity === 'warning') ||
      bannerMessages.find((validation) => validation.severity === 'info');
    messageText = validation.bannerMessage;
    severity = validation.severity;
  } else {
    return <></>;
  }

  return (
    <AppBar color="primary" position="sticky">
      <Alert
        icon={severity === 'error'}
        variant="filled"
        severity={severity}
        sx={{ justifyContent: 'center' }}
      >
        {severity === 'info' && (
          <FontAwesomeIconComp
            style={{ marginRight: '8px' }}
            icon={faLockAlt}
          />
        )}
        {messageText}
      </Alert>
    </AppBar>
  );
};
