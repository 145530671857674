import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Field } from 'react-final-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AbeCard } from 'components/Common';
import { Wizard } from 'components/Common/Wizard/Wizard';
import { LayoutHeader } from 'components/Layouts';
import { PageMainContent } from 'components/Styled';
import { REPORT_DETAILS } from 'consts';
import { addModelAccessValues } from 'helpers';
import GeneralInfoForm from 'pages/ReportPages/CreateReportPage/GeneralInfoForm';
import ReportColumnsPage from 'pages/ReportPages/CreateReportPage/ReportColumnsPage/ReportColumnsPage';
import { ReportFiltersPage } from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFiltersPage';
import ReportGroupingsPage from 'pages/ReportPages/CreateReportPage/ReportGroupingsPage/ReportGroupingsPage';
import ReportSortingsPage from 'pages/ReportPages/CreateReportPage/ReportSortingsPage/ReportSortingsPage';
import SelectReportType from 'pages/ReportPages/CreateReportPage/SelectReportType';
import {
  useUpdateReportMutation,
  useGetReportQuery,
  useCreateColumnsMutation,
  useUpdateColumnMutation,
  useDeleteColumnMutation,
  useCreateGroupingsMutation,
  useUpdateGroupingMutation,
  useDeleteGroupingMutation,
  useCreateReportMutation,
  useCreateFiltersMutation,
  useGetColumnsQuery,
  useGetGroupingsQuery,
  useCreateSortingsMutation,
  useGetSortingsQuery,
  useGetFiltersQuery,
  useDeleteSortingMutation,
  useUpdateSortingMutation,
} from 'redux/rtk-query';

const initialFormValues = {
  name: '',
  module: '',
  description: '',
  systemDescription: '',
  testMode: true,
  filters: [{}],
  ownerRecord: 'All',
};

const mapFormValuesToDTO = (values) => ({
  name: values.name,
  systemDescription: values.systemDescription,
  description: values.description,
  status: values.status,
  testMode: values.testMode,
  allowMassUpdate: values.allowMassUpdate,
  unrestrictedReport: values.unrestrictedReport,
  limit: values.limit,
  module: values.module,
  ownerRecord: values.ownerRecord,
  ownerRecordSelectedUserId: values.ownerRecordSelectedUserId,
  isOwnerRecordFilterEditable: values.isOwnerRecordFilterEditable,
  unitRecord: values.unitRecord,
  iUnitRecordFilterEditable: values.iUnitRecordFilterEditable,
  whoCanReadIds: values.whoCanReadIds || [],
  whoCanWriteIds: values.whoCanWriteIds || [],
  ...addModelAccessValues(values.modelAccess),
});

const mapFiltersToDTO = (formGroups) => {
  const rootGroup = {
    groupingCondition: 'And',
    childGroups: [],
    filters: [],
  };

  formGroups.forEach((groupItem) => {
    const groupDTO = {
      groupingCondition: groupItem.groupingCondition || 'And',
      filters: [],
      childGroups: [],
    };

    if (groupItem.filters && groupItem.filters.length > 0) {
      groupItem.filters.forEach((filterItem) => {
        const filterDTO = {
          name: filterItem.name,
          path: filterItem.path,
          condition: filterItem.condition,
          value: filterItem.value,
          isEditable: filterItem.isEditable || false,
        };

        if (filterItem.conditionType === 'Or') {
          groupDTO.childGroups.push({
            childGroups: [],
            groupingCondition: 'Or',
            filters: [filterDTO],
          });
        } else {
          groupDTO.filters.push(filterDTO);
        }
      });
    }

    rootGroup.childGroups.push(groupDTO);
  });

  return rootGroup;
};

function mapApiFilterDataToFormData(apiData) {
  const result = [];

  if (apiData.childGroups && apiData.childGroups.length > 0) {
    apiData.childGroups.forEach((group) => {
      const formGroup = {
        groupingCondition: group.groupingCondition || 'And',
        filters: [],
      };

      if (group.filters && group.filters.length > 0) {
        group.filters.forEach((filter) => {
          formGroup.filters.push({
            name: filter.name,
            path: filter.visualPath || filter.path || [],
            condition: filter.condition || 'Equals',
            value: filter.value || '',
            isEditable: filter.isEditable || false,
            visualPath: filter.visualPath || [],
            conditionType: 'And', // Default conditionType
            type: filter.type,
            enum: filter.enum,
          });
        });
      }

      if (group.childGroups && group.childGroups.length > 0) {
        group.childGroups.forEach((childGroup) => {
          if (
            childGroup.groupingCondition === 'Or' &&
            childGroup.filters &&
            childGroup.filters.length > 0
          ) {
            childGroup.filters.forEach((filter) => {
              formGroup.filters.push({
                name: filter.name,
                path: filter.visualPath || filter.path || [],
                condition: filter.condition || 'Equals',
                value: filter.value || '',
                isEditable: filter.isEditable || false,
                visualPath: filter.visualPath || [],
                conditionType: 'Or',
                type: filter.type,
                enum: filter.enum,
              });
            });
          }
        });
      }

      formGroup.filters.forEach((filter, index) => {
        if (index === 0) {
          filter.conditionType = null;
        } else if (!filter.conditionType) {
          filter.conditionType = formGroup.groupingCondition || 'And';
        }
      });

      result.push(formGroup);
    });
  }

  return result;
}

// Modify getProcessedColumns to optionally remove IDs when cloning.
const getProcessedColumns = (
  columnsData,
  reportType,
  groupingsData,
  removeIds = false,
) => {
  let processedColumns = columnsData.map((column) => {
    const newColumn = {
      ...column,
      path: column.visualPath,
    };
    if (removeIds) {
      delete newColumn.id;
    }
    return newColumn;
  });
  let processedDrilldowns = [];

  if (reportType === 'chart') {
    const groupingPaths =
      groupingsData?.data.map((group) => JSON.stringify(group.visualPath)) ||
      [];
    processedDrilldowns = processedColumns.filter(
      (column) =>
        !column.groupingFunction &&
        !groupingPaths.includes(JSON.stringify(column.path)),
    );
    processedColumns = processedColumns.filter(
      (column) =>
        column.groupingFunction ||
        groupingPaths.includes(JSON.stringify(column.path)),
    );
  }

  processedColumns.sort((a, b) => a.order - b.order);
  processedDrilldowns.sort((a, b) => a.order - b.order);

  return [processedColumns, processedDrilldowns];
};

export const CreateReportPage = () => {
  const { reportId, cloneId } = useParams();
  const navigate = useNavigate();

  // Use cloneId if available, otherwise use reportId.
  const effectiveReportId = cloneId || reportId;

  const [initialValues, setInitialValues] = useState(initialFormValues);
  const [saveProgress, setSaveProgress] = useState(false);

  // These refs hold the original columns and groupings (as loaded from the API)
  const originalColumnsRef = useRef([]);
  const originalGroupingsRef = useRef([]);
  // New ref for original sortings
  const originalSortingsRef = useRef([]);

  const { data: reportData } = useGetReportQuery(effectiveReportId, {
    skip: !effectiveReportId,
  });
  const { data: columnsData } = useGetColumnsQuery(effectiveReportId, {
    skip: !effectiveReportId,
  });
  const { data: groupingsData } = useGetGroupingsQuery(effectiveReportId, {
    skip: !effectiveReportId,
  });
  const { data: sortingsData } = useGetSortingsQuery(effectiveReportId, {
    skip: !effectiveReportId,
  });
  const { data: filtersData } = useGetFiltersQuery(effectiveReportId, {
    skip: !effectiveReportId,
  });

  const [createReport] = useCreateReportMutation();
  const [updateReport] = useUpdateReportMutation();
  const [createFilters] = useCreateFiltersMutation();

  // Columns mutations
  const [createColumns] = useCreateColumnsMutation();
  const [updateColumn] = useUpdateColumnMutation();
  const [deleteColumn] = useDeleteColumnMutation();

  // Groupings mutations
  const [createGroupings] = useCreateGroupingsMutation();
  const [updateGrouping] = useUpdateGroupingMutation();
  const [deleteGrouping] = useDeleteGroupingMutation();

  // Sortings mutations
  const [createSortings] = useCreateSortingsMutation();
  const [updateSorting] = useUpdateSortingMutation();
  const [deleteSorting] = useDeleteSortingMutation();

  // When API data is loaded, update the form's initial values and store the original columns, groupings, and sortings.
  useEffect(() => {
    if (reportData?.data && columnsData?.data) {
      // If cloning, remove the id from report data so that saving creates a new report.
      const reportDataForForm = cloneId
        ? { ...reportData.data, id: undefined }
        : reportData.data;
      const [processedColumns, processedDrilldowns] = getProcessedColumns(
        columnsData.data,
        reportData.data.reportType,
        groupingsData,
        !!cloneId,
      );
      const groupings =
        groupingsData?.data.map((grouping) => {
          const newGrouping = { ...grouping, path: grouping.visualPath };
          if (cloneId) {
            delete newGrouping.id;
          }
          return newGrouping;
        }) || [];
      const processedSortings = (sortingsData?.data || []).map((sorting) => {
        const newSorting = { ...sorting, path: sorting.visualPath };
        if (cloneId) {
          delete newSorting.id;
        }
        return newSorting;
      });
      processedSortings.sort((a, b) => a.order - b.order);

      setInitialValues((prevValues) => ({
        ...prevValues,
        ...reportDataForForm,
        filters: filtersData?.data
          ? mapApiFilterDataToFormData(filtersData.data)
          : [{}],
        columns: processedColumns,
        drilldowns: processedDrilldowns,
        groupings: groupings,
        sortings: processedSortings,
      }));

      // Only store original columns, groupings, and sortings when not cloning.
      if (!cloneId) {
        originalColumnsRef.current = processedColumns;
        originalGroupingsRef.current = groupings;
        originalSortingsRef.current = processedSortings;
      }
    }
  }, [
    reportData,
    filtersData,
    columnsData,
    groupingsData,
    sortingsData,
    cloneId,
  ]);

  // -----------------------------
  // Helper: Save Groupings (update, create, and delete removed)
  // -----------------------------
  const saveGroupings = async (reportId, groupings = []) => {
    if (!groupings) return;
    // Build DTO with proper order.
    const processedGroupings = groupings.map((grouping, index) => ({
      name: grouping.name,
      dateSeparation: grouping.dateSeparation,
      path: grouping.path,
      order: index,
      ...(typeof grouping.id === 'number' ? { id: grouping.id } : {}),
    }));

    const groupingsToUpdate = processedGroupings.filter((g) => g.id);
    const groupingsToCreate = processedGroupings.filter((g) => !g.id);
    const deletedGroupings = originalGroupingsRef.current.filter(
      (orig) => orig.id && !processedGroupings.some((g) => g.id === orig.id),
    );

    if (groupingsToUpdate.length > 0) {
      await Promise.all(
        groupingsToUpdate.map((g) =>
          updateGrouping({ reportId, data: g }).unwrap(),
        ),
      );
    }
    if (groupingsToCreate.length > 0) {
      await createGroupings({ reportId, data: groupingsToCreate }).unwrap();
    }
    if (deletedGroupings.length > 0) {
      await Promise.all(
        deletedGroupings.map((g) =>
          deleteGrouping({ reportId, groupingId: g.id }).unwrap(),
        ),
      );
    }
    setSaveProgress((prev) => ({ ...prev, groupings: 'Saved' }));
  };

  // -----------------------------
  // Helper: Save Columns (including drilldowns; update, create, and delete removed)
  // -----------------------------
  const saveColumns = async (reportId, columns = [], drilldowns = []) => {
    // Combine main columns and drilldowns (ensuring order is maintained).
    const allColumns = [
      ...columns.map((column, index) => ({
        name: column.name,
        path: column.path,
        order: index,
        groupingFunction: column.groupingFunction,
        dateSeparation: column.dateSeparation,
        ...(column.id ? { id: column.id } : {}),
      })),
      ...(drilldowns || []).map((column, index) => ({
        name: column.name,
        path: column.path,
        order: columns.length + index,
        groupingFunction: column.groupingFunction,
        dateSeparation: column.dateSeparation,
        ...(column.id ? { id: column.id } : {}),
      })),
    ];

    const columnsToUpdate = allColumns.filter(
      (col) => typeof col.id === 'number',
    );
    const columnsToCreate = allColumns.filter(
      (col) => typeof col.id !== 'number',
    );
    const deletedColumns = originalColumnsRef.current.filter(
      (orig) => orig.id && !allColumns.some((col) => col.id === orig.id),
    );

    if (columnsToUpdate.length > 0) {
      await Promise.all(
        columnsToUpdate.map((col) =>
          updateColumn({ reportId, data: col }).unwrap(),
        ),
      );
    }
    if (columnsToCreate.length > 0) {
      await createColumns({ reportId, data: columnsToCreate }).unwrap();
    }
    if (deletedColumns.length > 0) {
      await Promise.all(
        deletedColumns.map((col) =>
          deleteColumn({ reportId, columnId: col.id }).unwrap(),
        ),
      );
    }
    setSaveProgress((prev) => ({ ...prev, columns: 'Saved' }));
  };

  // -----------------------------
  // Helper: Save Sortings (update, create, and delete removed)
  // -----------------------------
  const saveSortings = async (reportId, sortings = []) => {
    if (!sortings) return;

    const processedSortings = sortings.map((sorting, index) => ({
      name: sorting.name,
      path: sorting.path,
      order: index,
      direction: sorting.direction,
      dateSeparation: sorting.dateSeparation,
      groupingFunction: sorting.groupingFunction,
      ...(typeof sorting.id === 'number' ? { id: sorting.id } : {}),
    }));

    const sortingsToUpdate = processedSortings.filter((s) => s.id);
    const sortingsToCreate = processedSortings.filter((s) => !s.id);
    const deletedSortings = originalSortingsRef.current.filter(
      (orig) => orig.id && !processedSortings.some((s) => s.id === orig.id),
    );

    if (sortingsToUpdate.length > 0) {
      await Promise.all(
        sortingsToUpdate.map((s) =>
          updateSorting({ reportId, data: s }).unwrap(),
        ),
      );
    }
    if (sortingsToCreate.length > 0) {
      await createSortings({ reportId, data: sortingsToCreate }).unwrap();
    }
    if (deletedSortings.length > 0) {
      await Promise.all(
        deletedSortings.map((s) =>
          deleteSorting({ reportId, sortingId: s.id }).unwrap(),
        ),
      );
    }
    setSaveProgress((prev) => ({ ...prev, sortings: 'Saved' }));
  };

  // -----------------------------
  // Main saveReport Function
  // -----------------------------
  const saveReport = async (values) => {
    try {
      setSaveProgress({});
      const dtoData = mapFormValuesToDTO(values);
      console.log('DTO Data:', dtoData);

      let reportResponse;
      if (values.id) {
        reportResponse = await updateReport({
          id: values.id,
          everyone: true,
          ...dtoData,
        }).unwrap();
      } else {
        reportResponse = await createReport(dtoData).unwrap();
      }
      const savedReportId = reportResponse?.data?.id;
      console.log('Report ID:', savedReportId);
      setInitialValues((prevValues) => ({
        ...prevValues,
        id: savedReportId,
      }));
      setSaveProgress((prev) => ({
        ...prev,
        report: 'Saved',
      }));

      // Save filters (always creating new ones in this example).
      const filtersDTO = mapFiltersToDTO(values.filters);
      await createFilters({ reportId: savedReportId, ...filtersDTO }).unwrap();
      setSaveProgress((prev) => ({
        ...prev,
        filters: 'Saved',
      }));

      // Process groupings, columns, and sortings.
      await saveGroupings(savedReportId, values.groupings);
      await saveColumns(savedReportId, values.columns, values.drilldowns);
      await saveSortings(savedReportId, values.sortings);

      navigate(`/${REPORT_DETAILS(savedReportId)}`);
      return savedReportId;
    } catch (error) {
      console.error('Failed to save report:', error);
      setSaveProgress((prev) => ({
        ...prev,
        error: error.message || 'An error occurred',
      }));
      return false;
    }
  };

  // -----------------------------
  // Build wizard steps (largely unchanged)
  // -----------------------------
  const steps = useMemo(() => {
    return getSteps();
  }, [reportId, initialValues, saveProgress]);

  function getSteps() {
    const steps = [];

    if (!reportId && !cloneId) {
      steps.push({
        label: 'Select Report Type',
        onNext: () => true,
        render: (form, values, showAllFields, handleNext) => (
          <SelectReportType
            values={values}
            setInitialValues={setInitialValues}
            handleNext={handleNext}
          />
        ),
      });
    }
    if (!initialValues.reportType) {
      return steps;
    }

    steps.push(
      {
        label: 'General',
        onNext: async (values) => {
          const dtoData = mapFormValuesToDTO(values);
          try {
            const errors = [];

            if (!values.module) {
              errors.push('["module", "required"]');
            }
            if (!values.name) {
              errors.push('["name", "required"]');
            }
            if (!values.systemDescription) {
              errors.push('["systemDescription", "required"]');
            }
            if (!values.status) {
              errors.push('["status", "required"]');
            }

            if (errors.length > 0) {
              return { error: { message: errors } };
            }

            if (values.id) {
              await updateReport({ id: values.id, ...dtoData }).unwrap();
            }

            return true;
          } catch (error) {
            console.error('Failed to create/update report:', error);
            return false;
          }
        },
        render: () => <GeneralInfoForm />,
      },
      {
        label: 'Filters',
        onNext: async () => true,
        render: () => (
          <Field name="filters" label="Filters" component={ReportFiltersPage} />
        ),
      },
    );

    if (initialValues.reportType === 'chart') {
      steps.push({
        label: 'Groupings',
        onNext: async (values) => {
          if (!values.groupings || values.groupings?.length === 0) {
            return { error: 'Please add at least one grouping' };
          }
          return true;
        },
        render: () => (
          <Box
            sx={{
              p: 1,
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#f5f5f5',
            }}
          >
            <Field
              name="groupings"
              label="Groupings"
              component={ReportGroupingsPage}
            />
          </Box>
        ),
      });
    }

    steps.push({
      label: 'Columns',
      onNext: async (values) => {
        if (values.columns.length === 0) {
          return { error: 'Please add at least one column' };
        }
        return true;
      },
      render: () => (
        <Box
          sx={{
            p: 1,
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Field name="columns" label="Columns" component={ReportColumnsPage} />
        </Box>
      ),
    });

    if (initialValues.reportType === 'chart') {
      steps.push({
        label: 'Drill Down',
        onNext: async () => true,
        render: () => (
          <Box
            sx={{
              p: 1,
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#f5f5f5',
            }}
          >
            <Field
              name="drilldowns"
              label="Drill Down Columns"
              component={ReportColumnsPage}
            />
          </Box>
        ),
      });
    }
    steps.push({
      label: 'Sorting',
      onNext: async (values) => {
        await saveReport(values);
        return true;
      },
      render: () => (
        <Box
          sx={{
            p: 1,
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#f5f5f5',
          }}
        >
          <Field
            name="sortings"
            label="Sortings"
            component={ReportSortingsPage}
          />
        </Box>
      ),
    });

    return steps;
  }

  return (
    <>
      <LayoutHeader
        title={initialValues?.name ? initialValues?.name : 'Create Report'}
      />
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <AbeCard sx={{ width: '100%' }} noTitle>
          <Box>
            <Wizard
              steps={steps}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              onFinalSubmit={(reportId) => {
                console.log('Final submit:', reportId);
              }}
            />
          </Box>
        </AbeCard>
      </PageMainContent>
    </>
  );
};
