import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { SideDrawer, FontAwesomeIconComp } from 'components/Common';
import { DetailsLayout } from 'components/Layouts';
import { AcceptOfferButton } from 'components/ModelViews/Transactions/OffersCard/components/OfferDetailsDrawer/components/AcceptOfferButton';
import { OfferDashboard } from 'components/ModelViews/Transactions/OffersCard/components/OfferDetailsDrawer/components/OfferDashboard';
import { RejectOfferButton } from 'components/ModelViews/Transactions/OffersCard/components/OfferDetailsDrawer/components/RejectOfferButton';
import { UPDATE_OFFER } from 'consts';
import { useDrawer, useOpenDrawer } from 'hooks';
import { TransactionDetails } from 'pages/TransactionsPages/DetailsTransactionPage/components';
import { useGetTransactionQuery } from 'redux/rtk-query';

export const OfferDetailsDrawer = ({ params, path }) => {
  const { isOpen, closeDrawer } = useDrawer(true, path);
  const openDrawer = useOpenDrawer();
  const paramName = Object.keys(params)[0];
  const offerId = params[paramName];
  const { data: offerData } = useGetTransactionQuery(
    {
      id: offerId,
    },
    { skip: !offerId },
  );

  const offer = offerData?.data;

  const handleClose = () => {
    closeDrawer();
  };

  const { data: transactionData } = useGetTransactionQuery(
    {
      id: offer?.id,
    },
    {
      skip: !offer?.id,
    },
  );

  return (
    <SideDrawer width={'60%'} isOpen={isOpen}>
      <Box p={2}>
        <DetailsLayout
          twoColumns
          header={
            <>
              {offer?.name}{' '}
              <Button
                size={'small'}
                onClick={() => {
                  openDrawer(UPDATE_OFFER(offer.id));
                }}
              >
                Edit
              </Button>
            </>
          }
          buttons={
            <>
              <AcceptOfferButton offer={offer} handleClose={handleClose} />
              <RejectOfferButton offer={offer} handleClose={handleClose} />
              <IconButton size={'small'} onClick={handleClose}>
                <FontAwesomeIconComp size="xl" icon={faTimesCircle} />
              </IconButton>
            </>
          }
          leftContent={
            <TransactionDetails transactionInfo={transactionData?.data} />
          }
          rightContent={<OfferDashboard id={offer?.id?.toString()} />}
        />
      </Box>
    </SideDrawer>
  );
};

OfferDetailsDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};
