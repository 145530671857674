import React from 'react';
import { useRoutes } from 'react-router-dom';
import { createTheme, ThemeProvider, GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { colors, routesConfig } from 'consts';
import { UserProvider } from 'contexts/UserContext';
import { usePageTitle } from 'hooks';
import { useBackendLogin } from 'redux/hooks/useBackendLogin';
import GlobalSnackbar from './components/GlobalSnackbar/GlobalSnackbar';

const font = "'Quicksand', sans-serif";

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: colors.LIGHT_BLUE_1,
  //     lightRed: colors.LIGHT_RED,
  //     red: colors.RED,
  //     grey: colors.LIGHT_BLACK,
  //     lightgrey: colors.GREY,
  //     white: colors.WHITE,
  //   },
  //   secondary: {
  //     main: colors.GREY,
  //   },
  //   white: {
  //     main: colors.WHITE,
  //   },
  // },
  palette: {
    primary: {
      main: '#494d50', // Blue
      light: '#63a4ff',
      dark: '#004ba0',
      contrastText: '#fff',
      red: colors.RED,
    },
    secondary: {
      main: '#c9222e', // Pink
      light: '#ff5c8d',
      dark: '#9a0036',
      contrastText: '#fff',
    },
    error: {
      main: '#c9222e', // Red
    },
    warning: {
      main: '#ffa726', // Orange
    },
    info: {
      main: colors.MUTED_BLUE, // Light Blue
      secondary: '#48484a',
    },
    success: {
      main: '#66bb6a', // Green
    },
    field: {
      text: '#b1b3b6',
      background: '#f6f7f9',
    },
  },
  typography: {
    fontFamily: 'Quicksand, Arial, sans-serif', // Default font
    fontSize: 14, // Base font size (16px)
    color: colors.BLACK,
    fontWeightRegular: 500,
    h1: {
      fontSize: '3rem', // 48px
      fontWeight: 500,
    },
    h2: {
      fontSize: '2.5rem', // 40px
      fontWeight: 500,
    },
    h3: {
      fontSize: '2rem', // 32px
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.75rem', // 28px
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.6rem', // 24px
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.25rem', // 20px
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem', // 16px
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.4rem', // 16px
      fontWeight: 500,
    },
    body2: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    button: {
      fontSize: '0.875rem', // 14px
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
    },
    overline: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Quicksand',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem', // 16px
          transform: 'translate(12px, 7px) scale(1)',
          transition: 'all 0.2s ease-in-out',
        },
        // Optional: Override the shrink state if needed
        shrink: {
          transform: 'translate(10px, -11px) scale(0.75)',
          fontSize: '1.4rem', // Adjust as needed
          color: '#494d50', // Ensure it contrasts with the background
          // background: 'white',
          padding: '0px 6px 2px 6px',
          borderRadius: 6,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          fontWeight: 500,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          '& .MuiInputBase-root:before': {
            borderBottom: '0 !important',
          },
          '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
          },
          '& .MuiOutlinedInput-root': {
            background: '#f6f7f9',
            borderRadius: 16,
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            background: 'white',
            boxShadow: 'inset 0 0 2px #0000004d',
          },
          '& .MuiInputLabel-root': {
            color: colors.LIGHT_BLACK,
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            color: colors.LIGHT_BLACK,
            fontSize: '1.7rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused': {
            // color: '#45aaf2',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0 !important',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0 !important',
          },
          '& .MuiSelect-icon': {
            fontSize: '2rem',
          },
          '&:hover': {
            backgroundColor: 'inherit',
            boxShadow: 'inset 0 0 2px #0000004d',
          },
          '&.Mui-focused': {
            backgroundColor: 'white',
            boxShadow: 'inset 0 0 2px #0000004d',
          },
          '&.Mui-disabled': {
            backgroundColor: 'inherit',
            boxShadow: 'inset 0 0 2px #0000004d',
          },
          '& .MuiInputAdornment-root': {
            marginTop: 0,
          },
        },
        outlined: {
          backgroundColor: 'rgb(246, 247, 249)',
          '& .MuiInputBase-root': {
            fontSize: '1.5rem',
          },
        },
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            '& .MuiInputBase-root > input': {
              fontSize: 20,
              padding: '10.5px 14px',
            },
          },
        },
        {
          props: { variant: 'borderless' },
          style: {
            '& .MuiInputBase-root:before': {
              borderBottom: 0,
            },
          },
        },
      ],
    },
    MuiDialog: {
      defaultProps: {
        BackdropProps: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(4px)',
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        BackdropProps: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(4px)',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '1.2rem',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          fontFamily: font,
          '& .filterComponent': {
            opacity: 0,
            transition: 'opacity 0.3s',
          },
          '&:hover .filterComponent': {
            opacity: 1,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 18,
          boxShadow: '0px 0px 8px 4px rgb(0 0 0 / 3%)',
          border: '1px solid #e5e5e5',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.4rem', // 14px
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontSize: '1.4rem',
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       padding: '6px 15px',
    //       textTransform: 'none',
    //       borderRadius: '8px',
    //       fontSize: '1.2rem',
    //     },
    //     contained: {
    //       color: 'white',
    //     },
    //     outlined: {
    //       backgroundColor: 'white',
    //     },
    //     endIcon: {
    //       marginRight: -1,
    //       marginLeft: 6,
    //       '&>*:nth-of-type(1)': {
    //         fontSize: 'inherit',
    //       },
    //     },
    //     sizeSmall: {
    //       padding: '3px 9px',
    //     },
    //   },
    //   variants: [
    //     {
    //       props: { variant: 'grey' },
    //       style: {
    //         border: '1px solid #d0d0d0',
    //         color: colors.LIGHT_BLACK,
    //         backgroundColor: 'white',
    //       },
    //     },
    //     {
    //       props: { size: 'x-small' },
    //       style: {
    //         borderRadius: 5,
    //         padding: '2px 10px',
    //         fontSize: 14,
    //         minWidth: 25,
    //         lineHeight: 1,
    //       },
    //     },
    //   ],
    // },
    MuiButton: {
      styleOverrides: {
        endIcon: {
          marginRight: -1,
          marginLeft: 6,
          '&>*:nth-of-type(1)': {
            fontSize: 'inherit',
          },
        },
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          fontSize: '1.4rem', // 14px
          '& .MuiTouchRipple-ripple .MuiTouchRipple-child': {
            backgroundColor: colors.MUTED_BLUE,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            boxShadow: 'inset 0 0 2px #0000004d',
            border: 0,
            backgroundColor: '#f6f7f9',
            fontWeight: 600,
            transition: 'box-shadow 0.15s ease-in-out',
            '&:hover': {
              border: 'none',
              backgroundColor: '#f6f7f9',
              boxShadow: 'inset 0 0 4px #0000004d',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: 'rowButton' },
          style: {
            border: `1px solid ${colors.LIGHT_GREY}`,
            color: colors.LIGHT_BLACK,
            backgroundColor: 'white',
            borderRadius: 5,
            width: 25,
            '&:not(:last-child)': {
              marginRight: 5,
            },
          },
        },
        {
          props: { size: 'x-small' },
          style: {
            padding: 0,
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.BLACK,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.BLUE_1,
          },
          '&.Mui-selected .MuiTypography-root': {
            color: colors.WHITE,
          },
          '&:hover .MuiTypography-root': {
            color: colors.BLUE_1,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiDayPicker-weekDayLabel': {
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          fontSize: '1.9rem',
          color: colors.LIGHT_BLACK,
          '& .MuiTypography-overline': {
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        dayWithMargin: {
          fontSize: '1.4rem',
          color: colors.LIGHT_BLACK,
          '&.Mui-selected': {
            backgroundColor: colors.BLUE_1,
            color: colors.WHITE,
          },
        },
      },
    },
  },
});

export const App = () => {
  const routes = useRoutes(routesConfig);
  useBackendLogin();
  usePageTitle(); // This will automatically set the page title based on the current route
  const isDevelop =
    process.env.REACT_APP_BASE_URL === 'http://localhost:3000/api/v1';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isDevelop && (
        <GlobalStyles
          styles={{
            '#webpack-dev-server-client-overlay': {
              display: 'none',
            },
          }}
        />
      )}
      <UserProvider>{routes}</UserProvider>
      <GlobalSnackbar />
    </ThemeProvider>
  );
};
