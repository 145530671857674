// AutofillDataProvider.js

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSettingQuery } from 'redux/rtk-query';
import { isTemplateViewSelectorCM as isTemplateViewSelector } from 'redux/selectors';
import { setAutofillDataCM as setAutofillData } from 'redux/slices/';
import propertyPhoto1 from './assets/propertyPhoto1.png';
import propertyPhoto10 from './assets/propertyPhoto10.png';
import propertyPhoto2 from './assets/propertyPhoto2.png';
import propertyPhoto3 from './assets/propertyPhoto3.png';
import propertyPhoto4 from './assets/propertyPhoto4.png';
import propertyPhoto5 from './assets/propertyPhoto5.png';
import propertyPhoto6 from './assets/propertyPhoto6.png';
import propertyPhoto7 from './assets/propertyPhoto7.png';
import propertyPhoto8 from './assets/propertyPhoto8.png';
import propertyPhoto9 from './assets/propertyPhoto9.png';

const AutofillDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isTemplateView = useSelector(isTemplateViewSelector);

  // Fetch agent settings
  const { data: settingData } = useGetSettingQuery({ id: 45 }); // Agent 1
  const { data: setting2Data } = useGetSettingQuery({ id: 29 }); // Agent 2

  useEffect(() => {
    if (isTemplateView) {
      let agent1AutoFillData = {
        themeFonts: {},
      };
      let agent2AutoFillData = {};

      // Extract agent 1 data if available
      if (settingData?.data) {
        let testimonialAutofills = {};
        if (settingData.data.testimonials) {
          const copiedTestimonials = JSON.parse(
            JSON.stringify(settingData.data.testimonials),
          );
          testimonialAutofills = copiedTestimonials.reduce(
            (acc, testimonial, index) => {
              acc[`agentTestimonialCustomer${index + 1}`] =
                testimonial.customerName;
              acc[`agentTestimonialContent${index + 1}`] =
                testimonial.description;
              return acc;
            },
            {},
          );
        }
        agent1AutoFillData = {
          agentName: settingData.data.agentName,
          agentTitle: settingData.data.title,
          agentPhone: settingData.data.phoneNumber,
          agentEmail: settingData.data.emailAddress,
          agentTeam: settingData.data.teamName,
          agentHeadshot: settingData.data.headshotFile,
          agentIcon: settingData.data.iconFile,
          agentSettingId: settingData.data.id,
          defaultThemeFonts: {
            headerFont: settingData.data.headerFont,
            bodyFont: settingData.data.bodyFont,
            scriptFont: settingData.data.scriptFont,
          },
          themeFonts: {
            headerFont: settingData.data.headerFont,
            bodyFont: settingData.data.bodyFont,
            scriptFont: settingData.data.scriptFont,
          },
          ...testimonialAutofills,
        };
      }

      // Extract agent 2 data if available
      if (setting2Data?.data) {
        agent2AutoFillData = {
          agent2Name: setting2Data.data.agentName,
          agent2Title: setting2Data.data.title,
          agent2Phone: setting2Data.data.phoneNumber,
          agent2Email: setting2Data.data.emailAddress,
          agent2Team: setting2Data.data.teamName,
          agent2Headshot: setting2Data.data.headshotFile,
          agent2SettingId: setting2Data.data.id,
        };
      }

      // Dispatch action to set autofillData in Redux
      dispatch(
        setAutofillData({
          fullAddress: '1010 Circuitry Springs St, Orlando FL 32801',
          streetAddress: '1010 Circuitry Springs St',
          cityStateZip: 'Orlando FL 32801',
          beds: '3',
          baths: '2/1',
          sqft: '2,200',
          listPrice: '$529,000',
          status: 'LISTED',
          defaultColor: '#4a494b',
          customColor: '#4a494b',
          propertyPhotos: [
            propertyPhoto1,
            propertyPhoto2,
            propertyPhoto3,
            propertyPhoto4,
            propertyPhoto5,
            propertyPhoto6,
            propertyPhoto7,
            propertyPhoto8,
            propertyPhoto9,
            propertyPhoto10,
          ],
          ...agent1AutoFillData,
          ...agent2AutoFillData,
        }),
      );
    }
  }, [isTemplateView, settingData, setting2Data, dispatch]);

  return children;
};

export default AutofillDataProvider;
