import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Field } from 'react-final-form';
import { ReactSortable } from 'react-sortablejs';
import { Box, Grid, IconButton, Link, Tooltip } from '@mui/material';
import {
  FontAwesomeIconComp,
  LabeledOutline,
  Required,
  SideDrawer,
} from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { ContactsTable } from 'components/Fields/Contact/components/ContactsTable';
import { ErrorMsg, Header, StickyHeader } from 'components/Styled';
import { getError } from 'helpers';
import { useIsRequired } from 'hooks';
import { RoleField } from '../RoleField';
import { RolesWrapper } from './styled';

export const RolesField = ({ role, textOnly, input, createType, noAdd }) => {
  const [showModalList, setShowModalList] = useState(false);
  const [searchAbeContacts, setSearchAbeContacts] = useState(false);

  const roleLabel = role;

  //make a deep copy of the roles object
  let fieldRoles = input.value
    ? [...input.value].filter((roleOption) => roleOption.role === role)
    : [];
  fieldRoles =
    JSON.parse(JSON.stringify(fieldRoles)).sort((a, b) => a.order - b.order) ||
    [];

  const required =
    useIsRequired(`${input.name}.${role}`) && fieldRoles.length === 0;

  const addRole = (roleToAdd) => {
    let roles = [...input.value];
    const newOrder = fieldRoles.length + 1;
    roles.push({
      id: roleToAdd.id,
      contact: roleToAdd,
      order: newOrder,
      role: role,
    });
    input.onChange(roles);
    setShowModalList(false);
  };

  const removeRole = (roleToRemove) => {
    let roles = [...input.value];

    const indexToRemove = roles.findIndex(
      (role) =>
        role.role === roleToRemove.role && role.order === roleToRemove.order,
    );

    if (indexToRemove > -1) {
      roles.splice(indexToRemove, 1);

      roles.forEach((role) => {
        if (
          role.role === roleToRemove.role &&
          role.order > roleToRemove.order
        ) {
          // role.order -= 1;
        }
      });
    }
    input.onChange(roles);
  };

  const updateRoleOrder = (rolesToUpdate) => {
    let roles = JSON.parse(JSON.stringify(input.value));
    roles.map((role) => {
      const index = rolesToUpdate.findIndex((roleToUpdate) => {
        return (
          roleToUpdate.role === role.role && roleToUpdate.order === role.order
        );
      });
      if (index !== -1) {
        role.order = index + 1;
      }
    });
    input.onChange(roles);
  };

  const updateRoleName = useCallback(
    (role, name) => {
      let roles = [...input.value];
      roles.forEach((roleOption) => {
        if (roleOption.role === role.role && roleOption.order === role.order) {
          roleOption.contactName = name;
        }
      });
      input.onChange(roles);
    },
    [input.value],
  );

  const getPanelTitle = () => {
    const plural = fieldRoles.length > 1 ? 's' : '';
    if (textOnly) {
      return (
        <>
          {roleLabel}
          {plural}
          <span style={{ fontSize: 14, paddingLeft: 10 }}>
            Text-only field
            <Tooltip
              title={
                'You can only enter the name for customers that you do not represent. If you want to add full contact information for this person, change the Represented status to include their role.'
              }
            >
              <span style={{ paddingLeft: 10 }}>
                <FontAwesomeIconComp icon={faCircleExclamation} />
              </span>
            </Tooltip>
          </span>
        </>
      );
    }
    return `${roleLabel}${plural}`;
  };

  const getErrorMessage = (meta) => {
    let error = getError(meta);
    if (fieldRoles.length === 0 && error) {
      return <ErrorMsg component={'span'}>{error}</ErrorMsg>;
    }
  };

  const getSetSameAs = () => {
    const handleSetSameAs = () => {
      let gettingRole = role === 'escrowAgent' ? 'closingAgent' : 'escrowAgent';
      let roles = { ...input.value };
      let roleCopy = [...roles[gettingRole]];
      roles[role] = roleCopy;
      // input.onChange(roles);
    };

    if (
      (role === 'escrowAgent' &&
        (!input.value['escrowAgent'] ||
          input.value['escrowAgent'].length === 0) &&
        input.value['closingAgent'] &&
        input.value['closingAgent'].length > 0) ||
      (role === 'closingAgent' &&
        (!input.value['closingAgent'] ||
          input.value['closingAgent'].length === 0) &&
        input.value['escrowAgent'] &&
        input.value['escrowAgent'].length > 0)
    ) {
      return (
        <Link
          style={{
            display: 'block',
            padding: 5,
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={handleSetSameAs}
        >
          Same as {role === 'escrowAgent' ? 'Closing Agent' : 'Escrow Agent'}
        </Link>
      );
    }
    return <></>;
  };

  const copyMlsAgentData = async (item) => {
    let url = `/mlsAgents/default/createOrGetAgent?hjid=${item.hjid}`;
    try {
      await fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          addRole(data);
        });
    } catch (error) {
      /* empty */
    }
  };

  const handleTableRowClick = (item) => {
    if (role === 'coopAgent' && !searchAbeContacts) {
      copyMlsAgentData(item);
    } else {
      addRole(item);
    }
    setShowModalList(false);
    setSearchAbeContacts(false);
  };

  const handleAddRoleClick = (e) => {
    if (
      e.nativeEvent.target.closest('.add-role-button') ||
      e.nativeEvent.target.classList.contains('labeled-outline-container')
    ) {
      if (!textOnly) {
        setShowModalList(true);
      } else {
        let roles = [...input.value];
        const newOrder = fieldRoles.length + 1;
        roles.push({
          contactName: '',
          order: newOrder,
          role: roleLabel,
        });
        input.onChange(roles);
      }
    }
  };
  const addRoleButton = (
    <IconButton
      size="small"
      onClick={handleAddRoleClick}
      className={'add-role-button'}
    >
      <FieldTooltip
        show
        right={9}
        top={-2}
        tooltip={{
          title: `Add a ${roleLabel}`,
          icon: faPlus,
        }}
      />
    </IconButton>
  );

  return (
    <Field name={`${input.name}.${role}`}>
      {({ meta }) => (
        <RolesWrapper>
          <Grid item xs={12}>
            <Box style={{ cursor: 'pointer' }}>
              <LabeledOutline
                large
                error={!!getError(meta)}
                label={
                  <>
                    {getPanelTitle()}
                    {required ? <Required /> : ''}
                  </>
                }
                onClick={handleAddRoleClick}
                toolbar={!noAdd && addRoleButton}
              >
                {((textOnly && showModalList) ||
                  Object.keys(fieldRoles).length > 0) && (
                  <div>
                    {input.value && (
                      <ReactSortable
                        list={fieldRoles}
                        setList={(sortableState) => {
                          updateRoleOrder(sortableState);
                        }}
                        filter={'.clear-button'}
                      >
                        {fieldRoles.map((roleOption, index) => {
                          return (
                            <React.Fragment key={`${role}${index}`}>
                              <RoleField
                                textOnly={textOnly}
                                index={index}
                                roleOption={roleOption}
                                updateRoleName={updateRoleName}
                                removeRole={removeRole}
                                hideModalList={() => {
                                  setShowModalList(false);
                                  setSearchAbeContacts(false);
                                }}
                                addRole={addRole}
                              />
                            </React.Fragment>
                          );
                        })}
                      </ReactSortable>
                    )}
                    {textOnly && showModalList && (
                      <RoleField
                        updateRoleName={() => {}}
                        roleOption={{
                          name: '',
                        }}
                        removeRole={() => {
                          setShowModalList(false);
                          setSearchAbeContacts(false);
                        }}
                        textOnly
                        hideModalList={() => {
                          setShowModalList(false);
                          setSearchAbeContacts(false);
                        }}
                        addRole={addRole}
                      />
                    )}
                  </div>
                )}
                {getSetSameAs()}
                {getErrorMessage(meta)}
              </LabeledOutline>
            </Box>
          </Grid>
          <SideDrawer
            closeOnClickAway={() => {
              setShowModalList(!showModalList);
            }}
            width={'40%'}
            isOpen={showModalList}
          >
            <Box>
              <StickyHeader>
                <Box>
                  <Header variant="h4">{roleLabel}</Header>
                </Box>
              </StickyHeader>
              <ContactsTable
                onRowClick={handleTableRowClick}
                // title={roleLabel}
                type={createType}
              />
            </Box>
          </SideDrawer>
        </RolesWrapper>
      )}
    </Field>
  );
};

RolesField.propTypes = {
  role: PropTypes.string,
  textOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hinttext: PropTypes.string,
  options: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  contactType: PropTypes.string,
  createType: PropTypes.string,
  noAdd: PropTypes.bool,
};
