import queryString from 'query-string';
import { endpoints } from 'consts';

export const userUrlBuilder = {
  getList: function ({ page = 1, limit = 15, search } = {}) {
    return `${endpoints.users}?page=${page}&limit=${limit}${
      search ? `&search=${search}` : ''
    }`;
  },
  getUser: function (id) {
    return `${endpoints.users}/${id}`;
  },
  getLanguagesList: function ({ page = 1, limit = 15 } = {}) {
    return `${endpoints.languagesList}?page=${page}&limit=${limit}`;
  },
  getLocalesList: function ({ page = 1, limit = 15 } = {}) {
    return `${endpoints.localesList}?page=${page}&limit=${limit}`;
  },
  getTimezonesList: function ({ page = 1, limit = 15 } = {}) {
    return `${endpoints.timezonesList}?page=${page}&limit=${limit}`;
  },
  getCurrenciesList: function ({ page = 1, limit = 15 } = {}) {
    return `${endpoints.currenciesList}?page=${page}&limit=${limit}`;
  },
  getAuditTrail: function ({ uuid, page = 1, limit = 15 } = {}) {
    return `${endpoints.users}/${uuid}/audit-trail?page=${page}&limit=${limit}`;
  },
  getGroupDetails: function ({ id }) {
    return `${endpoints.groups}/${id}`;
  },
  createUser: function () {
    return endpoints.users;
  },
  editUser: function () {
    return endpoints.users;
  },
  deleteUser: function (id) {
    return `${endpoints.users}/${id}`;
  },
  changePassword: function () {
    return endpoints.changePassword;
  },
  getRecentlyViewed: function () {
    return `${endpoints.recentlyViewed}`;
  },
  getRecentlyViewedTransactions: function () {
    return `${endpoints.recentlyViewed}/transactions`;
  },
  getStateList: function (args) {
    const urlParams = queryString.stringify(args, { skipNull: true });
    return `${endpoints.users}/state/list?${urlParams}`;
  },
  setState: function (args) {
    const urlParams = queryString.stringify(args, { skipNull: true });
    return `${endpoints.users}/state?${urlParams}`;
  },
};
