import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Box,
  IconButton,
} from '@mui/material';
import { getNestedValue } from 'helpers/report';
import ReportTableHead from 'pages/ReportPages/DetailsReportPage/ReportTableHead';
import { ExtraParamOptionsContext } from 'pages/ReportPages/ReportPage/context/ExtraParamOptionsContext';
import { useReportGroupingDetailsMutation } from 'redux/rtk-query';

export const GroupDetails = ({ reportId, row, columns }) => {
  const [groupingQueryParams, setGroupingQueryParams] = useState(null);
  const [groupingDetails, setGroupingDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  console.log('columns', columns);
  const { extraParamOptions } = useContext(ExtraParamOptionsContext);

  const memoizedExtraParamOptions = useMemo(
    () => extraParamOptions,
    [JSON.stringify(extraParamOptions)],
  );

  const [reportGroupingDetails] = useReportGroupingDetailsMutation();

  // Update grouping parameters when the `row` prop changes
  useEffect(() => {
    setGroupingQueryParams(row);
  }, [row]);

  // Reset details and page when grouping parameters change
  useEffect(() => {
    setGroupingDetails([]);
    setPage(1);
  }, [groupingQueryParams]);

  // Fetch grouping details whenever grouping parameters or page changes
  useEffect(() => {
    const fetchGroupingDetails = async () => {
      setLoading(true);
      try {
        const response = await reportGroupingDetails({
          reportId,
          page,
          options: {
            groupBy: groupingQueryParams,
            ...memoizedExtraParamOptions.options,
          },
        });
        const data = response.data;
        // Accumulate the new set of grouping details
        setGroupingDetails((prev) => [...prev, ...data.data]);
        // Update total count from metadata (assumes response contains meta.total)
        if (data.meta?.total) {
          setTotalCount(data.meta.total);
        }
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
      setLoading(false);
    };

    if (groupingQueryParams) {
      fetchGroupingDetails();
    }
  }, [reportId, groupingQueryParams, reportGroupingDetails, page]);

  // Handler to load more data
  const handleSeeMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <>
      <Card>
        <Table size="small" aria-label="group details">
          <ReportTableHead columns={columns} hasGroupings={false} />
          <TableBody>
            {groupingDetails.map((group, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {getNestedValue(group, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      {/* Render the down-arrow button if not loading and more data is available */}
      {!loading && groupingDetails.length < totalCount && (
        <Box textAlign="center" my={2}>
          <IconButton onClick={handleSeeMore}>
            <FontAwesomeIcon icon={faChevronDown} />
          </IconButton>
        </Box>
      )}
    </>
  );
};

GroupDetails.propTypes = {
  reportId: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};
