import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { useField } from 'react-final-form';
import { Button } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { BoxInput, TextBox } from 'components/Styled';
import { FileInputText } from './styled';

export const FileInput = ({ name, dropZoneId, setFilesValues }) => {
  // const form = useForm();
  const filesInput = useField(name);

  const ref = useRef();
  const handleClick = () => {
    ref.current.click();
  };

  const handleFileInputChange = useCallback(
    (e) => {
      const { target } = e;
      const filesCopy = filesInput.input.value
        ? [...filesInput.input.value]
        : [];
      for (let i = 0; i < target.files.length; i++) {
        let file = target.files[i];
        filesCopy.push(file);
      }
      setFilesValues(filesCopy);
    },
    [filesInput.input.value, setFilesValues],
  );

  useEffect(() => {
    let dropZone = document.getElementById(dropZoneId);

    function allowDrag(e) {
      e.dataTransfer.dropEffect = 'copy';
      e.preventDefault();
    }

    function handleDrop(e) {
      e.preventDefault();
      if (e.dataTransfer.items) {
        const filesCopy = [...filesInput.input.value];
        [...e.dataTransfer.items].forEach((item) => {
          if (item.kind === 'file') {
            const file = item.getAsFile();
            filesCopy.push(file);
          }
        });
        setFilesValues(filesCopy);
      }
    }

    dropZone.addEventListener('dragenter', allowDrag);
    dropZone.addEventListener('dragover', allowDrag);
    dropZone.addEventListener('drop', handleDrop);
    return () => {
      dropZone.removeEventListener('dragenter', allowDrag);
      dropZone.removeEventListener('dragover', allowDrag);
      dropZone.removeEventListener('drop', handleDrop);
    };
  }, [dropZoneId, filesInput.input.value, setFilesValues]);

  return (
    <BoxInput>
      <input
        ref={ref}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
        multiple
        type="file"
      />
      <Button variant={'contained'} size={'small'} onClick={handleClick}>
        Add File
      </Button>
      <TextBox>
        <FontAwesomeIconComp icon={faCloudArrowUp} color="#969696" />
        <FileInputText>Drop files here...</FileInputText>
      </TextBox>
    </BoxInput>
  );
};

FileInput.defaultProps = {
  size: 'small',
  disabled: false,
};

FileInput.propTypes = {
  setFilesValues: PropTypes.func,
  dropZoneId: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  iconStart: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  name: PropTypes.string,
};
