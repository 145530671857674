import React, { useMemo } from 'react';
import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import { CollapsableMenuGroup, FontAwesomeIconComp } from 'components/Common';
import AddModularItemMenuItem from 'components/ModelViews/Documents/ClickMarketingEditorDrawer/components/ClickMarketingMenu/menuItems/AddModularItemMenuItem'; // Adjust the import path as necessary
import { activePageCM, itemGroupsSelectorCM } from 'redux/selectors';
import ItemGroupMenuItem from './ItemGroupMenuItem';

export const ItemGroupMenu = () => {
  const activePage = useSelector(activePageCM);

  const modelItemGroups = useSelector(itemGroupsSelectorCM);

  const defaultGroups = ['Header', 'Body', 'Footer'];

  const itemGroupsEntries = useMemo(() => {
    const pageItemGroups = modelItemGroups[activePage] || {};

    // Map over defaultGroups to ensure order
    return defaultGroups.map((group) => [group, pageItemGroups[group] || {}]);
  }, [modelItemGroups, activePage]);

  return (
    <CollapsableMenuGroup
      isSelected
      label="Groups"
      icon={<FontAwesomeIconComp fontSize={18} icon={faBoxesStacked} />}
    >
      {itemGroupsEntries.map(([modularElementId, itemGroupTemplates]) => {
        const itemTemplatesForActivePage = Object.entries(
          itemGroupTemplates,
        ).filter(
          ([, itemTemplate]) =>
            itemTemplate && itemTemplate.page === activePage,
        );

        return (
          <React.Fragment key={modularElementId}>
            {itemTemplatesForActivePage.length > 0 ? (
              itemTemplatesForActivePage.map(
                ([itemTemplateId, itemTemplate], index) => (
                  <React.Fragment
                    key={`${itemTemplateId}-${itemTemplate.activeStyle}`}
                  >
                    <div style={{ paddingLeft: 0, paddingBottom: 5 }}>
                      <ItemGroupMenuItem
                        itemTemplate={itemTemplate}
                        modularType={modularElementId}
                        index={index}
                      />
                    </div>
                    <Divider />
                  </React.Fragment>
                ),
              )
            ) : (
              <>
                {/* Render the AddModularItemMenuItem when there are no item templates */}
                <AddModularItemMenuItem
                  key={modularElementId}
                  modularType={modularElementId}
                />
                <Divider />
              </>
            )}
          </React.Fragment>
        );
      })}
    </CollapsableMenuGroup>
  );
};
