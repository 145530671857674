export const colors = {
  LIGHT_RED: '#fc5c65',
  RED: '#eb3b5a',
  DARK_RED: '#c9232d',
  LIGHT_ORANGE: '#f89b53',
  ORANGE: '#fa8231',
  LIGHT_YELLOW: '#fed330',
  YELLOW: '#f7b731',
  LIGHT_GREEN: '#26de81',
  GREEN: '#20bf6b',
  MUTED_BLUE: '#7b94ad',
  LIGHT_BLUE_1: '#45aaf2',
  DARK_BLUE_1: '#097CC7',
  BLUE_1: '#2d98da',
  LIGHT_BLUE_2: '#4b7bec',
  BLUE_2: '#3867d6',
  LIGHT_PURPLE: '#a55eea',
  PURPLE: '#8854d0',
  LIGHTER_GREY: '#f5f6fa',
  LIGHT_GREY: '#d1d8e0',
  GREY: '#a5b1c2',
  LIGHT_BLACK: '#808080',
  BLACK: '#565656',
  WHITE: '#ffffff',
};

export const roleColors = [
  '#F44336', // Red
  '#E91E63', // Pink
  '#9C27B0', // Purple
  '#673AB7', // Deep Purple
  '#3F51B5', // Indigo
  '#2196F3', // Blue
  '#00BCD4', // Cyan
  '#009688', // Teal
  '#4CAF50', // Green
  '#8BC34A', // Light Green
  '#CDDC39', // Lime
  '#FFEB3B', // Yellow
  '#FFC107', // Amber
  '#FF9800', // Orange
  '#FF5722', // Deep Orange
  '#795548', // Brown
  '#607D8B', // Blue Grey
  '#EC407A', // Pink Lighten
  '#AB47BC', // Purple Lighten
  '#7E57C2', // Deep Purple Lighten
  '#5C6BC0', // Indigo Lighten
  '#42A5F5', // Blue Lighten
  '#29B6F6', // Light Blue Lighten
  '#26C6DA', // Cyan Lighten
  '#26A69A', // Teal Lighten
  '#66BB6A', // Green Lighten
  '#9CCC65', // Light Green Lighten
  '#D4E157', // Lime Lighten
  '#ffcc64', // Yellow Lighten
  '#FFCA28', // Amber Lighten
  '#FFA726', // Orange Lighten
  '#FF7043', // Deep Orange Lighten
  '#8D6E63', // Brown Lighten
  '#78909C', // Blue Grey Lighten
];

export const transactionColors = {
  Draft: colors.GREY,
  Listed: colors.LIGHT_RED,
  Pending: colors.LIGHT_ORANGE,
  Sold: colors.GREEN,
  'Closing In Progress': colors.GREEN,
  'Expired/Withdrawn': colors.LIGHT_GREY,
  Canceled: colors.LIGHT_GREY,
  Referral: colors.LIGHT_GREY,
  Leased: colors.LIGHT_BLUE_1,
  'Property Mgmt': colors.PURPLE,
  Unknown: colors.LIGHT_GREY,
};

export const contactColors = {
  'Company Agent': colors.RED,
  'Cooperating Agent': colors.RED,
  'Closing/Escrow Agent': colors.ORANGE,
  Brokerage: colors.LIGHT_RED,
  Vendor: colors.GREEN,
  Customer: colors.BLUE_2,
  Lead: colors.GREEN,
  Lender: colors.BLUE_1,
};
