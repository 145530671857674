import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { processFormData, TaskForm } from 'components/ModelViews/Tasks';
import { conversion } from 'helpers';
import { usePrevious } from 'hooks';
import {
  contactsAPI,
  transactionsAPI,
  useGetTaskQuery,
  useUpdateTaskMutation,
} from 'redux/rtk-query';

export const EditTaskModal = ({ params }) => {
  const paramName = Object.keys(params)[0];
  const { data: taskData } = useGetTaskQuery({ id: params[paramName] });
  const [updateTask, { isSuccess: isSaveSuccess }] = useUpdateTaskMutation();
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  const handleClose = () => {
    setIsOpen(false);
  };

  const afterSave = () => {
    handleClose();
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(function () {
        navigate(-1);
      }, 500);
    }
  }, [isOpen]);

  const handleSubmitFunc = async (values) => {
    let formData = processFormData(values);
    let task = await updateTask(formData);
    //TODO: remove this when we have a proper way to invalidate tags
    dispatch(transactionsAPI.util.invalidateTags(['Tasks']));
    if (params['relatedType'] === 'contact') {
      dispatch(contactsAPI.util.invalidateTags(['Contacts']));
    }
    if (params['relatedType'] === 'transaction') {
      dispatch(transactionsAPI.util.invalidateTags(['Transactions']));
    }
    return task;
  };

  useEffect(() => {
    if (taskData) {
      setInitialValues({
        ...taskData.data,
        transactionId: taskData.data.transaction?.id,
        contactId: taskData.data.contact?.id,
        checklistItems: taskData.data.checklistItems.map((item) => ({
          ...item,
          chosen: false,
        })),
      });
      if (isSaveSuccess !== true) {
        setIsOpen(true);
      }
    }
  }, [taskData, isSaveSuccess]);

  const name = isOpen
    ? conversion.getName(initialValues?.transaction || initialValues?.contact)
    : '';

  return (
    <SideDrawer width={'60%'} isOpen={isOpen}>
      <TaskForm
        title={`Edit ${taskData?.data?.type}`}
        subTitle={name}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
      />
    </SideDrawer>
  );
};

EditTaskModal.propTypes = {
  params: PropTypes.object,
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  uuid: PropTypes.string,
  subTitle: PropTypes.string,
};
