import { nanoid } from '@reduxjs/toolkit';

export const documentReducer = {
  // *** Page Management ***
  sortPagesCM: (state, action) => {
    state.currentDocument.htmlTemplate.options.pageOrder = action.payload;
  },
  setActivePageCM: (state, action) => {
    state.activePage = action.payload;
  },
  addNewPageCM: (state) => {
    const newPageId = nanoid();
    state.currentDocument.htmlTemplate.options.pages[newPageId] = {
      id: newPageId,
      elements: [],
    };
    state.currentDocument.htmlTemplate.options.pageOrder.push(newPageId);
    state.activePage = newPageId;
  },
  setDocumentDataCM: (state, action) => {
    state.currentDocument = action.payload;

    // Set templateView if there is a parentTemplate
    if (state.currentDocument?.parentTemplate) {
      state.templateView = true;
    }

    // Ensure htmlTemplate is initialized properly
    if (!state.currentDocument?.htmlTemplate) {
      state.currentDocument.htmlTemplate = {
        items: [],
        options: {
          pageOrder: [],
          pages: {},
        },
      };
    }

    // Check if pageOrder has existing pages and set the activePage
    if (state.currentDocument?.htmlTemplate?.options?.pageOrder.length > 0) {
      state.activePage =
        state.currentDocument.htmlTemplate.options.pageOrder[0];
    } else {
      // If there are no pages, create a new one and set it as the activePage
      const newPageId = nanoid();
      state.currentDocument.htmlTemplate.options.pages[newPageId] = {
        id: newPageId,
        elements: [],
      };
      state.currentDocument.htmlTemplate.options.pageOrder.push(newPageId);
      state.activePage = newPageId; // Set the newly created page as the activePage
    }
  },

  setAutofillDataCM: (state, action) => {
    state.autofillData = action.payload;
  },
  changeThemeFonts: (state, action) => {
    state.autofillData.themeFonts = action.payload;
  },
  changeDefaultThemeFonts: (state, action) => {
    state.autofillData.defaultThemeFonts = action.payload;
  },
  changeThemeColor: (state, action) => {
    state.autofillData.customColor = action.payload;
  },
  changeDefaultThemeColor: (state, action) => {
    state.autofillData.defaultColor = action.payload;
  },
  setScaleCM: (state, action) => {
    state.scale = action.payload;
  },
  setIsDeleting: (state, action) => {
    state.isDeleting = action.payload;
  },
};
