import React from 'react';
import { CreateButton, Table } from 'components/Common';
import { DOCUMENT_CATEGORY_CREATE } from 'consts';
import { useTableData } from 'hooks';
import { useGetReportCategoriesQuery } from 'redux/rtk-query';
import { Cells, MenuItems } from './config';

export const CategoriesTable = () => {
  const {
    page,
    rowsPerPage,
    search,
    loading,
    setPage,
    setSearch,
    totalPages,
    totalCount,
    order,
    orderBy,
    handleRequestSort,
    setRowsPerPage,
    rows,
  } = useTableData(useGetReportCategoriesQuery, Cells, 'name', 'asc', 20);

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        afterSearch={
          <CreateButton path={DOCUMENT_CATEGORY_CREATE} title="Add New" />
        }
        isLoading={loading}
        rows={rows}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        menuItems={MenuItems()}
        cells={Cells}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        isCollapsible
      />
    </>
  );
};
