import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN } from 'consts';
import { isAuthenticatedSelector } from 'redux/selectors';

export const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) =>
    isAuthenticatedSelector(state),
  );
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    navigate(LOGIN);
  }, [isAuthenticated, location.pathname, location.search]);

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};
