import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { FontAwesomeIconComp, ModuleIcon } from 'components/Common';
import { PageLink } from 'components/Styled';
import { conversion } from 'helpers';
import { useGetRecentlyViewedQuery } from 'redux/rtk-query';
import {
  LowerListItemButton,
  LowerListItemIcon,
  RecentlyViewedBtn,
  RecentlyViewedBtnTxt,
} from '../../styled';

export const RecentlyViewed = ({ fullMenu, setFullMenu, open }) => {
  const location = useLocation(); // ✅ Get the current URL
  const recentItems = useGetRecentlyViewedQuery(undefined, { force: true });

  // ✅ Refetch whenever the URL changes
  useEffect(() => {
    recentItems.refetch();
  }, [location.pathname]); // ✅ Trigger refetch on URL change

  // ✅ Use `useMemo` to prevent re-renders
  const recentlyViewedItemsToDisplay = useMemo(() => {
    return (
      recentItems?.data?.data.map((item) => {
        const model =
          item.capping || item.contact || item.setting || item.transaction;
        const module = item.capping
          ? 'Compensations'
          : item.contact
            ? 'Contacts'
            : item.setting
              ? 'Settings'
              : 'Transactions';
        const typeOrStatus = item.contact
          ? item.contact.type || null
          : item.transaction
            ? item.transaction.status || null
            : item.capping
              ? item.capping.status || null
              : item.setting
                ? item.setting.status || null
                : null;

        return {
          name: conversion.getName(model),
          icon: (
            <ModuleIcon
              fontSize={'1.4rem'}
              module={module}
              type={typeOrStatus}
              closedApproved={model.closedApproved}
            />
          ),
          module,
          modelId: model.id,
        };
      }) || []
    );
  }, [recentItems.data]); // ✅ Only re-run when data changes

  return (
    <List disablePadding={open}>
      <ListItem disablePadding sx={{ display: open ? 'block' : 'none' }}>
        <RecentlyViewedBtn
          onClick={() => setFullMenu(!fullMenu)}
          sx={{ justifyContent: open ? 'initial' : 'center' }}
        >
          <RecentlyViewedBtnTxt
            disableTypography
            primary="RECENTLY VIEWED"
            sx={{ opacity: open ? 1 : 0 }}
          />
          <FontAwesomeIconComp
            icon={faChevronDown}
            color={'#969696'}
            size="xs"
          />
        </RecentlyViewedBtn>
      </ListItem>

      {recentlyViewedItemsToDisplay.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          sx={{ display: 'block' }}
          component={PageLink}
          to={`/${item.module.toLowerCase()}/details/${item.modelId}/`}
        >
          <LowerListItemButton
            sx={{
              justifyContent: open ? 'center' : 'center',
              padding: open ? '0 16px' : 0.5,
            }}
          >
            <LowerListItemIcon sx={{ mr: open ? 1 : '0' }}>
              {item.icon}
            </LowerListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body4',
                noWrap: true,
              }}
              primary={item.name}
              sx={{ display: open ? 'block' : 'none', fontSize: '13px' }}
            />
          </LowerListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

RecentlyViewed.propTypes = {
  fullMenu: PropTypes.bool,
  setFullMenu: PropTypes.func,
  open: PropTypes.bool,
};
