import React from 'react';
import { Grid } from '@mui/material';
import {
  DocumentCard,
  EmailCard,
  ListCard,
  NoteCard,
  TaskCard,
} from 'components/ModelViews';

export const ContactDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <TaskCard />
        <NoteCard />
        <EmailCard />
        <ListCard />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <DocumentCard />
      </Grid>
    </Grid>
  );
};
