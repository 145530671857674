// selectors.js

import { createSelector } from '@reduxjs/toolkit';
import { getPixelSize } from 'helpers/clickMarketing';

const clickMarketingSelector = (state) => state.clickMarketingReducer;

export const currentDocumentSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.currentDocument || {},
);

export const pixelSizeSelector = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => getPixelSize(currentDocument),
);

export const isTemplateViewSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.templateView,
);

export const aspectRatioSelectorCM = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => currentDocument.aspectRatio,
);

export const isDeletingSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.isDeleting,
);

export const activePageCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.activePage,
);

export const activeFieldIdsSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.activeFieldIds || [],
);

export const importingSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.importing,
);

export const croppingSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.cropping,
);

export const copyItemToPageSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.copyItemToPage,
);

export const htmlTemplateSelectorCM = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => currentDocument.htmlTemplate || {},
);

export const itemsSelectorCM = createSelector(
  [htmlTemplateSelectorCM],
  (htmlTemplate) => htmlTemplate.items || [],
);

export const selectCurrentItem = createSelector(
  [itemsSelectorCM, activeFieldIdsSelectorCM],
  (items, activeFieldIds) => {
    if (activeFieldIds.length === 0) {
      return null;
    }
    const currentItemId = activeFieldIds[0];
    return items.find((item) => item.id === currentItemId) || null;
  },
);

export const selectCurrentItems = createSelector(
  [itemsSelectorCM, activeFieldIdsSelectorCM],
  (items, activeFieldIds) => {
    return items.filter((item) => activeFieldIds.includes(item.id));
  },
);

export const itemGroupsSelectorCM = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => currentDocument.itemGroups || {},
);

export const cmBlockTypeSelectorCM = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => currentDocument.cmBlockType,
);

export const transparentBackgroundSelectorCM = createSelector(
  [currentDocumentSelectorCM],
  (currentDocument) => currentDocument.transparentBackground,
);

export const showGridSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.showGrid,
);

export const cropperPropsSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.cropperProps || {},
);

export const scaleSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.scale,
);

export const autofillDataSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.autofillData || {},
);

export const customColorSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.autofillData?.customColor || '#FFFFFF',
);

export const defaultColorSelectorCM = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.autofillData?.defaultColor || '#FFFFFF',
);

export const agentViewSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.agentView || false,
);

export const themeFontsSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.autofillData?.themeFonts || {},
);

export const defaultThemeFontsSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.autofillData?.defaultThemeFonts || {},
);

export const isScriptDisabledSelector = createSelector(
  [clickMarketingSelector],
  (clickMarketing) => clickMarketing.disableScriptFont || false,
);
