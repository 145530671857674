import React from 'react';
import PropTypes from 'prop-types';
import hexRgb from 'hex-rgb';
import { NumericFormat } from 'react-number-format';
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
} from '@mui/material';
import { ListPagination } from 'components/ListPagination';
import { DocumentProgress } from 'components/ModelViews';
import { transactionColors } from 'consts';

export const SalesPipelineSection = ({ status, data }) => {
  // const [showTable, setShowTable] = React.useState(hideTable ? false : true);
  const [page, setPage] = React.useState(1);
  const pageSize = 5;

  const getBgColor = (alpha) => {
    const color = transactionColors[status];
    let rgb = hexRgb(color);
    return `rgba(${rgb.red},${rgb.green},${rgb.blue},${alpha})`;
  };

  // const getExtraText = () => {
  //   if (extraText) {
  //     return (
  //       <span
  //         style={{
  //           fontStyle: 'italic',
  //           fontWeight: 100,
  //           fontSize: 12,
  //         }}
  //       >
  //         {extraText}
  //       </span>
  //     );
  //   }
  // };

  const getSecondCell = (row) => {
    if (status === 'Property Mgmt') {
      return <TableCell align="left">{row.pmStatus}</TableCell>;
    } else {
      return (
        <TableCell align="right" width={'150px'}>
          <DocumentProgress documentProgress={row.documentProgress} />
        </TableCell>
      );
    }
  };

  const getThirdCell = (row) => {
    if (status === 'Property Mgmt') {
      return <TableCell align="right">{row.period}</TableCell>;
    } else if (status.includes('Lease')) {
      return <TableCell align="right">{row.status}</TableCell>;
    } else {
      return (
        <TableCell align="right">
          <Typography mr={1} variant="body1">
            {row.netCommission ? (
              <NumericFormat
                decimalScale={0}
                fixedDecimalScale={true}
                value={row.netCommission}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            ) : (
              row.agent
            )}
          </Typography>
        </TableCell>
      );
    }
  };

  const getFourthCell = (row) => {
    if (status === 'Property Mgmt') {
      return (
        <TableCell align="right">
          <Typography mr={1} variant="body1">
            {row.netCommission ? (
              <NumericFormat
                decimalScale={0}
                fixedDecimalScale={true}
                value={row.netCommission}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            ) : (
              row.agent
            )}
          </Typography>
        </TableCell>
      );
    }
  };

  const getTable = () => {
    return (
      <>
        {data.items.map((row) => (
          <TableRow
            style={{ borderLeft: `4px solid ${getBgColor(1)}` }}
            className={'data-rows'}
            key={row.name}
          >
            <TableCell width={'auto'}>
              <Typography variant="body1" noWrap style={{ fontWeight: 500 }}>
                {row.name}
              </Typography>
            </TableCell>
            <TableCell width={'auto'}>
              <Typography variant="body1" noWrap style={{ fontWeight: 500 }}>
                {row.representing}
              </Typography>
            </TableCell>
            {getSecondCell(row)}
            {getThirdCell(row)}
            {getFourthCell(row)}
          </TableRow>
        ))}
        {data && data.totalItemCount && data.totalItemCount / pageSize > 1 ? (
          <TableRow>
            <TableCell
              style={{
                textAlign: 'center',
                padding: 3,
                borderLeft: `4px solid ${getBgColor(1)}`,
              }}
              colSpan={3}
            >
              <ListPagination
                totalCount={data.totalItemCount}
                page={page}
                rowsPerPage={pageSize}
                handleChangePage={(pageNo) => {
                  setPage(pageNo);
                }}
                rowsPerPageOptions={[5]}
              />
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Table>
      <TableBody
        sx={{
          borderBottom: '1px solid lightgrey',
          '& .MuiTableCell-root': { borderBottom: 'none', p: 1 },
        }}
      >
        {getTable()}
      </TableBody>
    </Table>
  );
};

SalesPipelineSection.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string.isRequired,
  extraText: PropTypes.string,
  data: PropTypes.object.isRequired,
  hideTable: PropTypes.bool,
};
