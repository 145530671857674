import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/system';

export const SmallDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: 65,
    top: 35,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    marginRight: 0,
    borderRight: 'none',
  },
  '&:hover': {
    width: 220,
    '& .MuiDrawer-paper': {
      width: 220,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
}));
