import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  faTimesCircle,
  faStar,
  faBusinessFront,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Box, IconButton, Divider, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import { MenuItemButton } from 'components/Common';
import { HeadingSection, Header, ButtonSection } from 'components/Styled';
import { CM_ITEM_TYPES } from 'consts'; // Adjust the import path as needed
import {
  useGetProfileQuery,
  useGetFavoritesQuery,
  useGetMediaLibraryQuery,
} from 'redux/rtk-query';
import { currentDocumentSelectorCM, selectCurrentItem } from 'redux/selectors';
import GalleryImages from './GalleryImages';

const FiltersContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  overflowY: 'auto',
  width: 250,
  backgroundColor: theme.palette.background.paper,
}));

const MediaLibrary = ({ onClose }) => {
  const [page, setPage] = useState(0);
  const [view, setView] = useState('recommended');
  const [files, setFiles] = useState([]);

  const currentDocument = useSelector(currentDocumentSelectorCM);
  const activeItem = useSelector(selectCurrentItem);
  const { data: userData } = useGetProfileQuery();
  const { data: favorites } = useGetFavoritesQuery(
    {
      userId: userData?.data?.id,
      documentTemplateId: currentDocument?.id,
    },
    {
      skip:
        view !== 'recommended' || !userData?.data?.id || !currentDocument?.id,
    },
  );

  const { data: library } = useGetMediaLibraryQuery(
    {
      svg: activeItem?.type === CM_ITEM_TYPES.image.svgImage,
      limit: 12,
      page: page + 1, // Adjust page number as needed
    },
    {
      skip: view !== 'companyLibrary',
    },
  );

  const totalCount =
    view === 'recommended'
      ? favorites?.data?.meta?.total
      : library?.data?.meta?.total;

  useEffect(() => {
    setPage(0);
  }, [view]);

  useEffect(() => {
    if (view === 'recommended') {
      setFiles(favorites?.data?.items || []);
    } else if (view === 'companyLibrary') {
      setFiles(library?.data?.items || []);
    }
  }, [view, favorites, library]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* Header */}
      <HeadingSection>
        <Header variant="h4">Media Library</Header>
        <ButtonSection>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
        </ButtonSection>
      </HeadingSection>

      {/* Main Content */}
      <Box display="flex" flexGrow={1} minHeight={0}>
        {/* Sidebar Filters */}
        <FiltersContainer>
          <MenuItemButton
            label="Recommended"
            icon={faStar}
            isSelected={view === 'recommended'}
            onClick={() => setView('recommended')}
          />
          <MenuItemButton
            label="Company Library"
            icon={faBusinessFront}
            isSelected={view === 'companyLibrary'}
            onClick={() => setView('companyLibrary')}
          />
        </FiltersContainer>

        {/* Gallery and Pagination */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
          }}
        >
          {/* GalleryImages Component */}
          <Box sx={{ flexGrow: 1, minHeight: 0, overflowY: 'auto' }}>
            <GalleryImages files={files} onClose={onClose} />
          </Box>

          {/* Pagination */}
          <Divider />
          <Box sx={{ padding: '0px', flexShrink: 0 }}>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={12}
              rowsPerPageOptions={[12]}
              labelRowsPerPage=""
              sx={{ justifyContent: 'center' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

MediaLibrary.propTypes = {
  onClose: PropTypes.func,
};

export default MediaLibrary;
