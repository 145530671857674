import React, { useState } from 'react';
import { faPen, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Common';
import { ConfirmDialog } from 'components/Common/ConfirmDialog';
import { UsersModal } from 'components/ModelViews/Users/UsersModal';
import { useTableData } from 'hooks';
import {
  useGetCompanyUnitsQuery,
  useDeleteCompanyUnitMutation,
  useGetCompanyUnitUsersQuery,
} from 'redux/rtk-query';
import { getCells } from './config';

export const CompanyUnitsTable = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  // useTableData handles pagination, sorting, and (optionally) filtering.
  const { page, setPage, totalPages, rows, isLoading, setRowsPerPage } =
    useTableData(
      useGetCompanyUnitsQuery,
      [],
      'name', // sort field
      'asc', // sort order
      10, // page size
      { search }, // query parameters (if your query hook supports filtering by search)
    );

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentCompanyUnit, setCurrentCompanyUnit] = useState(null);
  const [deleteCompanyUnit] = useDeleteCompanyUnitMutation();

  const MenuItems = [
    {
      name: 'Edit',
      icon: faPen,
      onClick: ({ uuid }) => navigate(`/admin/company-units/edit/${uuid}`),
    },
    {
      name: 'Delete',
      icon: faTrashCan,
      onClick: ({ uuid }) => setConfirmOpen(uuid),
    },
  ];

  // Update the row click handler so that when a cell is clicked it receives an extra status.
  // For example, clicking the "Active Users" cell might pass "Active" and clicking the "Total Users" cell might pass "Inactive" or "All".
  const getClickHandler = (companyUnit, status) => () =>
    setCurrentCompanyUnit({ ...companyUnit, status });

  const handleConfirm = () => deleteCompanyUnit(confirmOpen);

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        isLoading={isLoading}
        rows={rows}
        totalPages={totalPages}
        menuItems={MenuItems}
        cells={getCells(getClickHandler)}
        page={page}
        rowsPerPage={10}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        isCollapsible
      />
      {currentCompanyUnit && (
        <UsersModal
          currentItem={currentCompanyUnit}
          setCurrentItem={setCurrentCompanyUnit}
          useGetList={useGetCompanyUnitUsersQuery}
        />
      )}
      <ConfirmDialog
        title="Delete Company Unit?"
        message="Are you sure you want to permanently delete this company unit?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
};

CompanyUnitsTable.propTypes = {};
