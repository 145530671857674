import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Avatar } from 'components/Styled';
import { contactColors } from 'consts';

export const ContactAvatar = ({ contactInfo }) => {
  let firstInitial = '';
  let lastInitial = '';
  if (contactInfo.lastName) {
    firstInitial = contactInfo.firstName?.charAt(0) || '';
    lastInitial = contactInfo.lastName.charAt(0);
  } else if (contactInfo.companyName) {
    firstInitial = contactInfo.companyName.charAt(0);
  }
  return (
    <Stack pr={2} direction="row" spacing={2} justifyContent={'center'}>
      <Avatar sx={{ bgcolor: contactColors[contactInfo.type] }}>
        {firstInitial + lastInitial}
      </Avatar>
    </Stack>
  );
};

ContactAvatar.propTypes = {
  contactInfo: PropTypes.object,
};
