import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormState, Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Select } from 'components/Fields';
import { getLastPathName } from 'helpers/report';
import { useGetGroupingsQuery, useGetColumnsQuery } from 'redux/rtk-query';

export const DatasetsFields = ({ input }) => {
  const name = input.name;
  const { values } = useFormState();
  const { fields } = useFieldArray(name);

  // Fetch Columns & Groupings Data
  const { data: columnsData } = useGetColumnsQuery(values?.reportId, {
    skip: !values?.reportId,
  });
  const { data: groupingsData } = useGetGroupingsQuery(values?.reportId, {
    skip: !values?.reportId,
  });

  // Memoized Table Columns
  const tableColumns = useMemo(() => {
    const columns = columnsData?.data || [];
    const groupingPaths =
      groupingsData?.data.map((group) => JSON.stringify(group.visualPath)) ||
      [];

    return columns
      .filter(
        (column) =>
          column.groupingFunction ||
          groupingPaths.includes(JSON.stringify(column.visualPath)),
      )
      .sort((a, b) => a.order - b.order);
  }, [columnsData, groupingsData]);

  // Memoized Axes Definition
  const axes = useMemo(() => {
    switch (values.type) {
      case 'Circle Chart Pie Charts':
      case 'Bar Chart Horizontal':
      case 'Bar Chart Vertical':
        return ['X', 'Y'];
      case 'Bar Chart Stacked':
      case 'Bar Chart Stacked Horizontal':
      case 'Bar Chart Stacked with Groups':
        return ['X', 'X', 'Y', 'Y'];
      default:
        return [];
    }
  }, [values.type]);

  // Sync Fields Array with `axes`
  useEffect(() => {
    if (fields.length !== axes.length) {
      fields.forEach((_, i) => {
        if (!axes[i]) fields.remove(i);
      });

      for (let i = fields.length; i < axes.length; i++) {
        fields.push({ axis: axes[i], columnId: '' });
      }
    }
  }, [axes, fields]); // ✅ Correct dependency array

  // Memoized function to get labels
  const getLabel = useCallback(
    (index) => {
      if (values.type === 'Circle Chart Pie Charts') {
        return index === 0 ? 'Grouping' : 'Value';
      }
      if (values.type === 'Bar Chart Horizontal') {
        return index === 0 ? 'Y Axis' : 'X Axis';
      }
      if (values.type === 'Bar Chart Vertical') {
        return index === 0 ? 'X Axis' : 'Y Axis';
      }
      if (values.type === 'Bar Chart Stacked') {
        return (
          ['Stacking', 'X Axis', 'Y Axis', 'Extra Label Value'][index] ||
          `${axes[index]} Axis`
        );
      }
      if (values.type === 'Bar Chart Stacked Horizontal') {
        return (
          ['Stacking', 'Y Axis', 'X Axis', 'Extra Label Value'][index] ||
          `${axes[index]} Axis`
        );
      }
      return `${axes[index]} Axis`;
    },
    [values.type, axes],
  );

  // Memoized function to filter column options
  const filterColumnOptions = useCallback(
    (index) => {
      if (!tableColumns.length) return [];
      if (values.type === 'Bar Chart Horizontal') {
        return index === 0
          ? tableColumns.filter(
              (col) => !col.groupingFunction && !col.dateSeperation,
            )
          : tableColumns.filter(
              (col) => col.groupingFunction || col.dateSeperation,
            );
      }
      if (values.type === 'Bar Chart Vertical') {
        return index === 0
          ? tableColumns.filter(
              (col) => col.groupingFunction || col.dateSeperation,
            )
          : tableColumns.filter(
              (col) => !col.groupingFunction && !col.dateSeperation,
            );
      }
      if (
        ['Bar Chart Stacked', 'Bar Chart Stacked Horizontal'].includes(
          values.type,
        )
      ) {
        return index < 2
          ? tableColumns.filter(
              (col) => !col.groupingFunction && !col.dateSeperation,
            )
          : tableColumns.filter(
              (col) => col.groupingFunction || col.dateSeperation,
            );
      }
      return tableColumns;
    },
    [values.type, tableColumns],
  );

  return (
    <div>
      {fields.map((name, index) => (
        <div key={name}>
          <Field
            label={getLabel(index)}
            name={`${name}.columnId`}
            component={Select}
            options={filterColumnOptions(index).map((column) => ({
              value: column.id,
              label: getLastPathName(column, true),
            }))}
          />
        </div>
      ))}
    </div>
  );
};

DatasetsFields.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};
