import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGetMediaUrl from 'hooks/useGetMediaUrl';

function AbeFileImage({ file, style, thumbnail = false }) {
  const [fileSrc, setFileSrc] = useState(null);

  // For File instances, use a FileReader to display a local preview.
  useEffect(() => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  // Determine which file object to use for fetching the media URL.
  // If thumbnail is true and file has children, try to find a child with type "CROP_SMALL".
  let mediaFile = file;
  let mediaThumbnail = thumbnail;
  if (
    thumbnail &&
    file &&
    typeof file === 'object' &&
    file.child &&
    Array.isArray(file.child)
  ) {
    const cropSmallChild = file.child.find(
      (child) => child.type === 'CROP_MEDIUM',
    );
    if (cropSmallChild) {
      mediaFile = cropSmallChild;
      // Child is already the thumbnail so we don't need to pass thumbnail flag.
      mediaThumbnail = false;
    }
  }

  // Fetch the media URL using the selected file (child or original) and thumbnail flag.
  const { data: fileData } = useGetMediaUrl(mediaFile, mediaThumbnail);

  useEffect(() => {
    if (fileData && fileData.data) {
      setFileSrc(fileData.data.url);
    }
  }, [fileData]);

  return (
    <>
      {fileSrc ? (
        <img width="100%" src={fileSrc} alt={file.name} style={style} />
      ) : null}
    </>
  );
}

AbeFileImage.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      child: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          uuid: PropTypes.string,
        }),
      ),
    }),
  ]).isRequired,
  style: PropTypes.object,
  thumbnail: PropTypes.bool,
};

export default AbeFileImage;
