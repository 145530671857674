import { extractIdsOrValues, getIdOrValue, omitNull } from 'helpers';
import { defaultValues } from './defaultValues';

export const processFormData = (values) => {
  const formData = values.values || values;
  const allValues = { ...defaultValues, ...omitNull(formData) };

  const {
    // Settings
    agentContact,
    officeSetting,
    assistantSetting,
    teamSetting,
    connectionSetting,
    brokerSetting,
    officeAdminSetting,
    marketingCoordinatorSetting,
    complianceCoordinatorSetting,
    // Cappings
    salesCapping,
    leaseCapping,
    propertyMgmtCapping,
    referralCapping,
    commercialCapping,
    // Share
    agent,
    shareWith,
    companyStructureUnits,
  } = allValues;

  return {
    ...allValues,
    // Settings
    agentContactId: getIdOrValue(agentContact),
    officeSettingId: getIdOrValue(officeSetting),
    assistantSettingId: getIdOrValue(assistantSetting),
    teamSettingId: getIdOrValue(teamSetting),
    connectionSettingId: getIdOrValue(connectionSetting),
    brokerSettingId: getIdOrValue(brokerSetting),
    officeAdminSettingId: getIdOrValue(officeAdminSetting),
    marketingCoordinatorSettingId: getIdOrValue(marketingCoordinatorSetting),
    complianceCoordinatorSettingId: getIdOrValue(complianceCoordinatorSetting),
    // Cappings
    salesCappingId: getIdOrValue(salesCapping),
    leaseCappingId: getIdOrValue(leaseCapping),
    propertyMgmtCappingId: getIdOrValue(propertyMgmtCapping),
    referralCappingId: getIdOrValue(referralCapping),
    commercialCappingId: getIdOrValue(commercialCapping),
    // Share
    agentId: agent?.id ? agent.id : agent?.value,
    shareWithIds: extractIdsOrValues(shareWith),
    companyStructureUnitsIds: extractIdsOrValues(companyStructureUnits),
  };
};
