// AutofillDataProvider.js

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { conversion } from 'helpers';
import useOrderedPhotos from 'hooks/transaction/useOrderedPhotos';
import {
  useGetSettingQuery,
  useGetMlsListingQuery,
  useGetParcelQuery,
  useGetProfileQuery,
  useGetTransactionQuery,
} from 'redux/rtk-query';
import {
  isTemplateViewSelectorCM as isTemplateViewSelector,
  currentDocumentSelectorCM,
  isPreviewSelectorCM,
} from 'redux/selectors';
import { setAutofillDataCM as setAutofillData } from 'redux/slices/';
import propertyPhoto1 from './assets/propertyPhoto1.png';
import propertyPhoto10 from './assets/propertyPhoto10.png';
import propertyPhoto2 from './assets/propertyPhoto2.png';
import propertyPhoto3 from './assets/propertyPhoto3.png';
import propertyPhoto4 from './assets/propertyPhoto4.png';
import propertyPhoto5 from './assets/propertyPhoto5.png';
import propertyPhoto6 from './assets/propertyPhoto6.png';
import propertyPhoto7 from './assets/propertyPhoto7.png';
import propertyPhoto8 from './assets/propertyPhoto8.png';
import propertyPhoto9 from './assets/propertyPhoto9.png';

const AutofillDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const isTemplateView = useSelector(isTemplateViewSelector);
  const isPreview = useSelector(isPreviewSelectorCM);
  const currentDocument = useSelector(currentDocumentSelectorCM);
  const { data: userData } = useGetProfileQuery();
  let { transactionDetailsId } = useParams();
  const { data: transactionData } = useGetTransactionQuery(
    { id: transactionDetailsId },
    {
      skip: !transactionDetailsId,
    },
  );

  let settingId = null;
  //TODO: Fix migration to map old agentId to new agentId
  // if (currentDocument.htmlTemplate?.options?.agentId) {
  //   settingId = currentDocument.htmlTemplate.options.agentId;
  // } else
  if (currentDocument?.transaction?.modelAccess?.owner?.setting?.id) {
    settingId = currentDocument?.transaction?.modelAccess?.owner?.setting?.id;
  } else if (!isTemplateView || isPreview) {
    if (!isPreview && currentDocument) {
      settingId = currentDocument.modelAccess?.owner?.setting?.id;
    } else if (transactionData?.data?.modelAccess?.owner?.setting?.id) {
      settingId = transactionData?.data?.modelAccess?.owner?.setting?.id;
    } else if (userData?.data?.setting?.id) {
      settingId = userData?.data?.setting?.id;
    }
  } else if (isTemplateView) {
    settingId = 45;
  }
  // console.log('isTemplateView', isTemplateView);
  const { data: mlsListingData } = useGetMlsListingQuery(
    { id: currentDocument?.transaction?.mlsListing },
    { skip: typeof currentDocument?.transaction?.mlsListing !== 'string' },
  );
  const { data: parcelData } = useGetParcelQuery(
    { hjid: currentDocument?.transaction?.parcel },
    { skip: typeof currentDocument?.transaction?.parcel !== 'string' },
  );

  // $sqft = number_format($document->account->mlsListing->size);
  // $beds = $document->account->mlsListing->beds;
  //
  // if (intval($document->account->mlsListing->bathHalf) > 0) {
  //   $baths = $document->account->mlsListing->bathFull . "/" . $document->account->mlsListing->bathHalf;
  // } else {
  //   $baths = $document->account->mlsListing->bathFull;
  // }
  //
  // if ($document->account->parcel->apn) {
  //   if (!$sqft) {
  //     $sqft = number_format($document->account->parcel->size);
  //   }
  //   if (!$beds) {
  //     $beds = $document->account->parcel->beds;
  //   }
  //   if (!$baths) {
  //     $bathsArray = CJSON::decode($document->account->parcel->baths);
  //     if (isset($bathsArray['half']) && intval($bathsArray['half']) > 0) {
  //       $baths = $bathsArray['full'] . "/" . $bathsArray['half'];
  //     } else if (isset($bathsArray['full'])){
  //       $baths = $bathsArray['full'];
  //     }
  //   }
  // }

  const { orderedPhotos } = useOrderedPhotos(currentDocument?.transaction?.id);

  const propertyPhotos = useMemo(() => {
    return isTemplateView
      ? [
          propertyPhoto1,
          propertyPhoto2,
          propertyPhoto3,
          propertyPhoto4,
          propertyPhoto5,
          propertyPhoto6,
          propertyPhoto7,
          propertyPhoto8,
          propertyPhoto9,
          propertyPhoto10,
        ]
      : orderedPhotos || [];
  }, [orderedPhotos, isTemplateView]);

  // Fetch agent settings
  const { data: settingData } = useGetSettingQuery(
    { id: settingId },
    {
      skip: !settingId,
    },
  ); // Agent 1
  const { data: setting2Data } = useGetSettingQuery(
    { id: 29 },
    {
      skip: !isTemplateView,
    },
  ); // Agent 2

  useEffect(() => {
    // ----- Beds/Baths/SqFt logic -----
    let sqft = '';
    let beds = '';
    let baths = '';

    // Prefer values from MLS listing if available
    if (mlsListingData?.data) {
      const { size, beds: mlsBeds, bathFull, bathHalf } = mlsListingData.data;
      if (size) {
        sqft = size;
      }
      if (mlsBeds) {
        beds = mlsBeds;
      }
      if (bathFull) {
        // If there's a half bath
        if (parseInt(bathHalf, 10) > 0) {
          baths = `${bathFull}/${bathHalf}`;
        } else {
          baths = `${bathFull}`;
        }
      }
    }

    // If parcelData has an apn, use it as a fallback for any missing fields
    if (parcelData?.data?.apn) {
      // Fallback sqft
      if (!sqft && parcelData.data.size) {
        sqft = parcelData.data.size;
      }
      // Fallback beds
      if (!beds && parcelData.data.beds) {
        beds = parcelData.data.beds;
      }
      // Fallback baths
      if (!baths && parcelData.data.baths) {
        const { full, half } = parcelData.data.baths;
        if (parseInt(half, 10) > 0) {
          baths = `${full}/${half}`;
        } else if (full !== undefined) {
          baths = `${full}`;
        }
      }
    }

    let agent1AutoFillData = {
      themeFonts: {},
    };
    let agent2AutoFillData = {};

    // Extract agent 1 data if available
    if (settingData?.data) {
      let testimonialAutofills = {};
      if (settingData.data.testimonials) {
        const copiedTestimonials = JSON.parse(
          JSON.stringify(settingData.data.testimonials),
        );
        testimonialAutofills = copiedTestimonials.reduce(
          (acc, testimonial, index) => {
            acc[`agentTestimonialCustomer${index + 1}`] =
              testimonial.customerName;
            acc[`agentTestimonialContent${index + 1}`] =
              testimonial.description;
            return acc;
          },
          {},
        );
      }

      agent1AutoFillData = {
        agentName: settingData.data.agentName,
        agentTitle: settingData.data.title,
        agentPhone: settingData.data.phoneNumber,
        agentEmail: settingData.data.emailAddress,
        agentTeam: settingData.data?.teamSetting?.agentName,
        agentHeadshot: settingData.data.headshotFile,
        agentIcon: settingData.data.iconFile,
        agentSettingId: settingData.data.id,
        defaultThemeFonts: {
          headerFont:
            settingData.data.headerFont ||
            settingData.data.officeSetting?.headerFont ||
            'Quicksand Medium',
          bodyFont:
            settingData.data.bodyFont ||
            settingData.data.officeSetting?.bodyFont ||
            'Quicksand Regular',
          scriptFont:
            settingData.data.scriptFont ||
            settingData.data.officeSetting?.scriptFont ||
            'Pacifico',
        },
        themeFonts: currentDocument.htmlTemplate?.options?.themeFonts || {
          headerFont:
            settingData.data.headerFont ||
            settingData.data.officeSetting?.headerFont,
          bodyFont:
            settingData.data.bodyFont ||
            settingData.data.officeSetting?.bodyFont,
          scriptFont:
            settingData.data.scriptFont ||
            settingData.data.officeSetting?.scriptFont,
        },
        ...testimonialAutofills,
      };
    }

    // Extract agent 2 data if available
    if (setting2Data?.data) {
      agent2AutoFillData = {
        agent2Name: setting2Data.data.agentName,
        agent2Title: setting2Data.data.title,
        agent2Phone: setting2Data.data.phoneNumber,
        agent2Email: setting2Data.data.emailAddress,
        agent2Team: setting2Data.data.teamName,
        agent2Headshot: setting2Data.data.headshotFile,
        agent2SettingId: setting2Data.data.id,
      };
    }

    const customColor = isTemplateView
      ? '#4a494b'
      : currentDocument.htmlTemplate?.options?.customColor ||
        settingData?.data?.customColor ||
        'mainframeTheme';

    const fullAddress = `${currentDocument?.transaction?.address?.street1}, ${currentDocument?.transaction?.address?.city}, ${currentDocument?.transaction?.address?.state} ${currentDocument?.transaction?.address?.zipCode}`;
    dispatch(
      setAutofillData({
        fullAddress: currentDocument?.transaction?.address
          ? fullAddress
          : '1010 Circuitry Springs St, Orlando FL 32801',
        streetAddress:
          currentDocument?.transaction?.address?.street1 ||
          '1010 Circuitry Springs St',
        cityStateZip: currentDocument?.transaction?.address
          ? `${currentDocument?.transaction?.address?.city}, ${currentDocument?.transaction?.address?.state} ${currentDocument?.transaction?.address?.zipCode}`
          : 'Orlando FL 32801',
        beds: beds || '3',
        baths: baths || '2/1',
        sqft: sqft ? conversion.formatNumberWithCommas(sqft) : '2,200',
        listPrice: currentDocument?.transaction?.listPrice || '$529,000',
        status: currentDocument?.transaction?.status || 'LISTED',
        defaultColor: '#4a494b',
        customColor,
        propertyPhotos,
        ...agent1AutoFillData,
        ...agent2AutoFillData,
      }),
    );
  }, [
    isTemplateView,
    settingData,
    setting2Data,
    dispatch,
    propertyPhotos,
    currentDocument,
  ]);

  return children;
};

export default AutofillDataProvider;
