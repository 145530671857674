import { createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import { documentTemplatesAPI, documentsAPI } from 'redux/rtk-query'; // Adjust the import path
import { activePageCM } from 'redux/selectors';
import {
  addItemGroupCM,
  setActiveFieldCM,
  deleteItemGroupCM,
  setIsDeleting,
  setImporting,
} from 'redux/slices/clickMarketing/clickMarketing';
import { setSnackbarOpen } from 'redux/slices/snackbar';

export const saveCM = createAsyncThunk(
  'clickMarketing/saveHtmlTemplate',
  async (args, { dispatch, getState }) => {
    const updatedDocument = getState().clickMarketingReducer.currentDocument;

    try {
      let response;
      if (getState().clickMarketingReducer.templateView) {
        const { updateDocumentTemplate } = documentTemplatesAPI.endpoints;
        response = await dispatch(
          updateDocumentTemplate.initiate({
            id: updatedDocument.id,
            htmlTemplate: updatedDocument.htmlTemplate,
            itemGroups: updatedDocument.itemGroups,
            fileType: 'Click Marketing',
          }),
        ).unwrap();
      } else {
        const { updateDocument } = documentsAPI.endpoints;
        response = await dispatch(
          updateDocument.initiate({
            id: updatedDocument.id,
            htmlTemplate: updatedDocument.htmlTemplate,
            itemGroups: updatedDocument.itemGroups,
          }),
        ).unwrap();
      }

      // Dispatch success snackbar action
      dispatch(
        setSnackbarOpen({
          message: 'Fields saved successfully',
          severity: 'success',
        }),
      );

      return response;
    } catch (error) {
      // Dispatch error snackbar action
      dispatch(
        setSnackbarOpen({
          message: 'Failed to save fields',
          severity: 'error',
        }),
      );

      throw error;
    }
  },
);

export const loadItemGroupsCM = createAsyncThunk(
  'clickMarketing/loadItemGroupsCM',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const currentDocument = state.clickMarketingReducer.currentDocument;
    const promises = [];

    const itemGroups = currentDocument.itemGroups || {};
    for (const [pageId, itemGroupsByPage] of Object.entries(itemGroups)) {
      for (const [modularElementId, itemGroupTemplates] of Object.entries(
        itemGroupsByPage || {},
      )) {
        for (const [itemTemplateId, itemTemplate] of Object.entries(
          itemGroupTemplates || {},
        )) {
          if (itemTemplate) {
            // Dispatch the RTK Query endpoint
            const promise = dispatch(
              documentTemplatesAPI.endpoints.getDocumentTemplate.initiate({
                id: itemTemplateId,
              }),
            )
              .unwrap()
              .then((repsonse) => {
                if (repsonse) {
                  const data = repsonse.data;
                  const firstPageIndex = itemTemplate.activeStyle - 1;
                  const pageOrder = data.htmlTemplate?.options?.pageOrder || [];
                  const firstPageId = pageOrder[firstPageIndex];
                  const firstPage =
                    data.htmlTemplate?.options?.pages?.[firstPageId] || null;

                  if (data.htmlTemplate?.items) {
                    const newItems = [];
                    for (const item of data.htmlTemplate.items) {
                      if (
                        !firstPage ||
                        (firstPage.elements &&
                          firstPage.elements.includes(item.id))
                      ) {
                        const newItem = { ...item, id: nanoid() };
                        newItems.push(newItem);
                      }
                    }

                    dispatch(
                      addItemGroupCM({
                        pageElements: newItems,
                        templateId: itemTemplateId,
                        templateName: data.name,
                        pageCount: Object.keys(
                          data.htmlTemplate?.options?.pages || {},
                        ).length,
                        activeStyle: itemTemplate.activeStyle,
                        modularType: modularElementId,
                        page: pageId,
                        pagesWithAgentIcon: data.pagesWithAgentIcon,
                        pagesWithTeamName: data.pagesWithTeamName,
                      }),
                    );
                  }
                }
              })
              .catch((error) => {
                console.error('Failed to fetch document template:', error);
              });

            promises.push(promise);
          }
        }
      }
    }

    await Promise.all(promises);
    // If you have a loading state, you can dispatch an action to set loading to false here
  },
);

export const changeItemGroupActivePage = createAsyncThunk(
  'clickMarketing/changeItemGroupActivePage',
  async (
    { templateId, modularType, page },
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState();
    if (state.clickMarketingReducer.isDeleting) {
      console.warn('Cannot change active page while deletion is in progress');
      return rejectWithValue('Deletion in progress');
    }

    try {
      const activePage = getState().clickMarketingReducer.activePage;
      const activeStyle =
        getState().clickMarketingReducer.currentDocument.itemGroups?.[
          activePage
        ]?.[modularType]?.[templateId]?.activeStyle;
      if (activeStyle === page) {
        return { success: true };
      }
      dispatch(setIsDeleting(true));
      // 1. Clear current selection
      dispatch(setActiveFieldCM([]));

      // 2. Delete the existing item group
      dispatch(
        deleteItemGroupCM({ templateId, modularType, removeGroup: false }),
      );

      // 3. Fetch new data using RTK Query's initiate

      // Initiate the getDocumentTemplate query
      const response = await dispatch(
        documentTemplatesAPI.endpoints.getDocumentTemplate.initiate({
          id: templateId,
        }),
      ).unwrap();
      const data = response?.data;
      const items = data?.htmlTemplate?.items || [];
      const pageOrder = data?.htmlTemplate?.options?.pageOrder || [];
      const pages = data?.htmlTemplate?.options?.pages || {};

      const firstPage =
        pageOrder && pageOrder.length > 0 ? pages[pageOrder[page - 1]] : null;

      if (items && firstPage) {
        const newItems = [];
        const pageElements = [];

        for (let item of items) {
          if (!firstPage || firstPage.elements.includes(item.id)) {
            const newItem = { ...item };
            newItem.id = nanoid();
            newItem.modularType = modularType;
            newItem.page = activePage;
            newItem.templateId = templateId;
            newItems.push(newItem);
            pageElements.push(newItem.id);
          }
        }

        // 4. Add the new item group
        dispatch(
          addItemGroupCM({
            pageElements: newItems,
            templateId: templateId,
            templateName: data.name,
            pageCount: pages ? Object.keys(pages).length : 0,
            activeStyle: page,
            modularType: modularType,
            page: activePage,
            pagesWithAgentIcon: data.pagesWithAgentIcon || [],
            pagesWithTeamName: data.pagesWithTeamName || [],
          }),
        );
      }
      dispatch(setIsDeleting(false));

      return { success: true };
    } catch (error) {
      console.error('Error in changeItemGroupActivePage:', error);
      return rejectWithValue(error.message || 'Failed to change active page');
    }
  },
);

export const importTemplateCM = createAsyncThunk(
  'clickMarketing/importTemplateCM',
  async ({ templateId, page }, { dispatch, getState }) => {
    try {
      // Fetch the template data using RTK Query
      const response = await dispatch(
        documentTemplatesAPI.endpoints.getDocumentTemplate.initiate({
          id: templateId,
        }),
      ).unwrap();
      const data = response?.data;

      if (data) {
        const state = getState();
        const modularType = state.clickMarketingReducer.importing;
        const activePage = activePageCM(state); // Use the selector

        const pageOrder = data.htmlTemplate?.options?.pageOrder || [];
        const pages = data.htmlTemplate?.options?.pages || {};
        const firstPageId = pageOrder[page - 1];
        const firstPage = pages[firstPageId];

        if (data.htmlTemplate?.items) {
          const newItems = [];
          const pageElements = [];

          for (const item of data.htmlTemplate.items) {
            if (!firstPage || firstPage.elements.includes(item.id)) {
              const newItemId = nanoid(); // Generate a new unique ID
              const newItem = {
                ...item,
                id: newItemId,
                modularType,
                page,
                templateId,
              };
              newItems.push(newItem);
              pageElements.push(newItem); // Pass the entire item with the new ID
            }
          }
          if (modularType) {
            // Dispatch the action to add the item group with pre-generated IDs
            dispatch(
              addItemGroupCM({
                modularType: modularType,
                pageElements: newItems, // Pass the items with new IDs
                templateId: templateId,
                templateName: data.name,
                pageCount: Object.keys(pages).length,
                activeStyle: page,
                page: activePage,
                pagesWithAgentIcon: data.pagesWithAgentIcon || [],
                pagesWithTeamName: data.pagesWithTeamName || [],
              }),
            );
          }
        }
      }
    } catch (error) {
      console.error('Failed to import template:', error);
      // Optionally, dispatch an error action or set a snackbar notification
    } finally {
      // Dispatch the action to set importing to false
      dispatch(setImporting(false));
    }
  },
);
