import { useMemo } from 'react';
import { useGetDocumentsListQuery, useGetDocumentQuery } from 'redux/rtk-query';

/**
 * Custom hook to fetch and order photos based on a transaction ID.
 *
 * @param {number|string} transactionId - The ID of the transaction.
 * @returns {Object} An object containing the ordered photos and query statuses.
 */
const useOrderedPhotos = (transactionId) => {
  // Ensure transactionId is a number
  const parsedTransactionId =
    typeof transactionId === 'string'
      ? parseInt(transactionId, 10)
      : transactionId;

  // Fetch the list of photo sets associated with the transaction ID
  const {
    data: photoSetListData,
    isLoading: isPhotoSetListLoading,
    error: photoSetListError,
  } = useGetDocumentsListQuery(
    {
      page: 1,
      limit: 10,
      search: 'Photo Set',
      transactionId: parsedTransactionId,
    },
    {
      skip: !parsedTransactionId, // Skip the query if transactionId is not provided
    },
  );

  // Extract the first photo set ID from the list
  const photoSetId = photoSetListData?.data?.[0]?.id;

  // Fetch the photo set details using the photoSetId
  const {
    data: photoSetData,
    isLoading: isPhotoSetLoading,
    error: photoSetError,
  } = useGetDocumentQuery(
    { id: photoSetId },
    {
      skip: !photoSetId, // Skip the query if photoSetId is not available
    },
  );

  // Memoize the ordered photos to optimize performance
  const orderedPhotos = useMemo(() => {
    if (!photoSetData?.data) return [];

    const { order, attachments } = photoSetData.data;

    if (!order) return attachments || [];

    const orderArray = order.split(',').map((id) => parseInt(id, 10));

    if (orderArray.length <= 1) return attachments || [];

    // Create a map for faster lookup
    const attachmentsMap = new Map();
    attachments.forEach((attachment) => {
      attachmentsMap.set(attachment.id, attachment);
    });

    // Order the photos based on the orderArray
    const ordered = orderArray
      .map((id) => attachmentsMap.get(id))
      .filter((attachment) => attachment !== undefined); // Remove any undefined entries

    return ordered;
  }, [photoSetData]);

  return {
    orderedPhotos,
    isLoading: isPhotoSetListLoading || isPhotoSetLoading,
    error: photoSetListError || photoSetError,
  };
};

export default useOrderedPhotos;
