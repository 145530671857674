import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SideDrawer } from 'components/Common';
import { NoteForm } from 'components/ModelViews/Notes';
import { addModelAccessValues, conversion } from 'helpers';
import { usePrevious } from 'hooks';
import { useGetNoteQuery, useUpdateNoteMutation } from 'redux/rtk-query';

export const EditNoteModal = ({ params }) => {
  const [initialValues, setInitialValues] = useState({});
  const paramName = Object.keys(params)[0];
  const { data: noteData } = useGetNoteQuery({ id: params[paramName] });
  const [updateNote, { isSuccess: isSaveSuccess }] = useUpdateNoteMutation();
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const prevIsOpen = usePrevious(isOpen);

  const handleClose = () => {
    setIsOpen(false);
  };

  const afterSave = () => {
    handleClose();
  };

  useLayoutEffect(() => {
    if (prevIsOpen && !isOpen) {
      setTimeout(function () {
        navigate(-1);
      }, 500);
    }
  }, [isOpen]);

  const handleSubmitFunc = async ({
    // abeUser,
    // agent,
    // shareWith,
    // companyStructureUnits,
    ...values
  }) => {
    console.log(
      'addModelAccessValues(values.modelAccess),',
      addModelAccessValues(values.modelAccess),
    );
    return await updateNote({
      ...values,
      noteId: values.id,
      ...addModelAccessValues(values.modelAccess),
      // abeUserId: abeUser?.value,
      // agentId: agent?.value,
      // shareWithIds: shareWith?.map(({ value }) => value),
      // companyStructureUnitsIds:
      //   companyStructureUnits && companyStructureUnits[0] !== null
      //     ? companyStructureUnits?.map(({ value }) => value)
      //     : null,
    });
  };

  useEffect(() => {
    if (noteData) {
      setInitialValues({
        ...noteData.data,
      });
      if (isSaveSuccess !== true) {
        setIsOpen(true);
      }
    }
  }, [noteData, isSaveSuccess]);

  const name = conversion.getName(initialValues?.contact);
  return (
    <SideDrawer width={'40%'} isOpen={isOpen}>
      <NoteForm
        title={'Edit Note'}
        subTitle={name}
        initialValues={initialValues}
        afterSave={afterSave}
        handleSubmitFunc={handleSubmitFunc}
        handleClose={handleClose}
      />
    </SideDrawer>
  );
};

EditNoteModal.propTypes = {
  closeHandler: PropTypes.func,
  open: PropTypes.bool,
  uuid: PropTypes.string,
  subTitle: PropTypes.string,
  params: PropTypes.object,
};
