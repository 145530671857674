import React from 'react';
import { useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { Box, Button, Grid } from '@mui/material';
import { useDashboard } from 'pages/ReportPages/ReportPage/context/DashboardContext';
import Row from 'pages/ReportPages/ReportPage/Row';

const ReportDashboard = () => {
  const { view } = useParams();

  const { activeDashboard, isEditMode, updateDashboard } = useDashboard();

  const handleAddRow = () => {
    if (!activeDashboard) return;

    const newRow = { id: Date.now(), columns: [] };

    const updatedDashboard = {
      ...activeDashboard,
      rows: [...activeDashboard.rows, newRow],
    };

    updateDashboard(activeDashboard.id, updatedDashboard);
  };

  const handleReorderRows = (newRows) => {
    if (!activeDashboard) return;
    // Update the dashboard rows with the newly sorted array
    const updatedDashboard = { ...activeDashboard, rows: newRows };
    updateDashboard(activeDashboard.id, updatedDashboard);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', flexShrink: 1, minWidth: 0 }}>
      {/* Right View: Switch Between Dashboard, Reports List, and Report Details */}
      <Box
        sx={{ flexGrow: 1, flexShrink: 1, pl: 1, minWidth: 0, width: '300px' }}
      >
        {!view && activeDashboard && (
          <>
            <Grid container sx={{ mt: 0, mb: 2 }} flexDirection="column">
              {/* Wrap our row mapping inside ReactSortable */}
              <ReactSortable
                list={activeDashboard.rows}
                setList={handleReorderRows}
                animation={200}
                delayOnTouchStart
                delay={2}
                handle={'.handle'}
                swapThreshold={0.5}
                // Only enable sorting in Edit Mode
                disabled={!isEditMode}
                // Use a div or fragment as the parent tag
                // so it doesn't conflict with MUI <Grid container>
                tag="div"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {activeDashboard.rows.map((row) => (
                  <Row key={row.id} row={row} dashboard={activeDashboard} />
                ))}
              </ReactSortable>
            </Grid>
            {isEditMode && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  py: 2,
                  mt: 1,
                  border: '3px dashed #ccc',
                  borderRadius: 4,
                }}
              >
                <Button variant="contained" onClick={handleAddRow}>
                  + Add Row
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ReportDashboard;
