export const Cells = [
  {
    id: 'deliveryLine',
    value: 'deliveryLine',
    disablePadding: true,
    label: 'Address',
  },
  {
    id: 'status',
    value: 'status',
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'hjid',
    value: 'hjid',
    disablePadding: false,
    label: 'MLS ID',
  },
];
