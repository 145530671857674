// Column.js
import React from 'react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useField } from 'react-final-form';
import { Typography, Grid, IconButton, Card } from '@mui/material';
import { TextInput } from 'components/Fields';
import { colors } from 'consts';
import { pathArrayToPathLabel } from 'helpers/report';

const Column = ({ name, removeColumn }) => {
  const visualPathValue = useField(`${name}.visualPath`).input.value;
  const groupingFunctionValue = useField(`${name}.groupingFunction`).input
    .value;
  return (
    <Card
      display="flex"
      alignItems="center"
      mb={2}
      sx={{
        backgroundColor: '#f5f5f5',
        px: '16px',
        borderRadius: '8px',
      }}
    >
      <Grid container spacing={3} alignItems={'center'}>
        {/* Display the visualPath */}
        <Grid item width={300}>
          <Typography variant="body1" color={colors.BLACK}>
            {pathArrayToPathLabel(visualPathValue)}
            {groupingFunctionValue ? ` - (${groupingFunctionValue})` : ''}
          </Typography>
        </Grid>
        <Grid item xs>
          {/* TextField to edit the column's "name" */}
          <Field
            name={`${name}.name`}
            label="Column Name"
            size="small"
            component={TextInput}
            sx={{ mr: 2 }}
            iconEnd={
              <IconButton onClick={removeColumn}>
                <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
              </IconButton>
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};

Column.propTypes = {
  name: PropTypes.string.isRequired,
  removeColumn: PropTypes.func.isRequired,
};

export default Column;
