import React from 'react';
import { Grid } from '@mui/material';
import { TaskCard } from 'components/ModelViews';
import { MyTaskCard } from './components/MyTaskCard';
import { SalesPipelineCard } from './components/SalesPipelineCard';

export const HomePage = () => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <SalesPipelineCard />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ '& .MuiCard-root:not(:first-of-type)': { marginTop: 2 } }}
      >
        <TaskCard type={'Task'} view={'sections'} />
        <MyTaskCard type={'Timeframe'} />
      </Grid>
    </Grid>
  );
};
