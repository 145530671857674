// ReportFiltersTree.js
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { TreeItem } from '@mui/x-tree-view';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { conversion } from 'helpers';
import { useGetReportModulesQuery } from 'redux/rtk-query';

const ReportFiltersTree = ({ onSelect, type }) => {
  const { data: moduleData } = useGetReportModulesQuery();

  const columnsValue = useField('columns')?.input.value || [];
  const groupingsValue = useField('groupings')?.input.value || [];
  const moduleValue = useField('module')?.input.value;
  const reportType = useField('reportType')?.input.value;

  const [expanded, setExpanded] = useState([]);
  const [loadedChildren, setLoadedChildren] = useState({});
  const [visitedPaths, setVisitedPaths] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');

  // -----------------------------
  // Memoized helper functions/data
  // -----------------------------

  // Pure function for sorting filters; wrapped in useCallback so its identity remains stable.
  const sortFilters = useCallback((filters) => {
    return [...filters].sort((a, b) => {
      if (a.type === 'relation' && b.type !== 'relation') return 1;
      if (a.type !== 'relation' && b.type === 'relation') return -1;
      return a.name.localeCompare(b.name);
    });
  }, []);

  // Build a tree structure from selected columns.
  // (This function is pure; we memoize its result below.)
  const buildTreeFromColumns = (columns) => {
    const tree = {};
    columns.forEach((column) => {
      let currentLevel = tree;
      column.path.forEach((part, index) => {
        const partName = `${part}${column.groupingFunction || ''}${column.dateSeparation || ''}`;
        if (!currentLevel[partName]) {
          currentLevel[partName] = {
            name: part,
            children: {},
            filter: {
              name: part,
              key: part,
              type:
                index === column.path.length - 1
                  ? column.type || 'string'
                  : 'relation',
              ...(index === column.path.length - 1 && {
                groupingFunction: column.groupingFunction,
                dateSeparation: column.dateSeparation,
              }),
            },
          };
        }
        currentLevel = currentLevel[partName].children;
      });
    });
    // eslint-disable-next-line no-console
    console.log(tree);
    return tree;
  };

  // Memoize the tree built from columns (for sortings/chart)
  const columnsTree = useMemo(
    () => buildTreeFromColumns(columnsValue),
    [columnsValue],
  );

  // Memoize groupingsByVisualPath (for grouping/date separation)
  const groupingsByVisualPath = useMemo(() => {
    return groupingsValue.reduce((acc, grouping) => {
      const key = JSON.stringify(grouping.visualPath);
      if (grouping.dateSeparation) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(grouping);
      }
      return acc;
    }, {});
  }, [groupingsValue]);

  // Memoize the sorted module filters (for modules/filters view)
  const moduleFilters = useMemo(() => {
    const filters = moduleData?.data?.[moduleValue] || [];
    return sortFilters(filters);
  }, [moduleData, moduleValue, sortFilters]);

  // -----------------------------
  // Callbacks (wrapped in useCallback)
  // -----------------------------

  // Handle changes in the search bar
  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  // Function to load children lazily when a node is expanded
  const handleNodeExpansion = useCallback(
    (nodeId) => {
      const [fullPath, filterString] = nodeId.split('||');
      const filter = JSON.parse(filterString);
      if (filter.type === 'relation') {
        if (visitedPaths.has(fullPath)) {
          console.warn('Cyclic relation detected for path:', fullPath);
          return;
        }
        setVisitedPaths((prev) => new Set([...prev, fullPath]));
        if (!loadedChildren[fullPath]) {
          let relatedFields = moduleData?.data?.[filter.relatedModule] || [];
          relatedFields = sortFilters(relatedFields);
          setLoadedChildren((prev) => ({
            ...prev,
            [fullPath]: relatedFields,
          }));
        }
      }
    },
    [moduleData, loadedChildren, sortFilters, visitedPaths],
  );

  // Handle expand/collapse of tree nodes
  const handleToggle = useCallback(
    (event, nodeIds) => {
      event.preventDefault();
      const newExpandedNodes = nodeIds.filter((id) => !expanded.includes(id));
      setExpanded(nodeIds);
      newExpandedNodes.forEach((nodeId) => {
        handleNodeExpansion(nodeId);
      });
    },
    [expanded, handleNodeExpansion],
  );

  // Function to handle node selection
  const handleNodeSelect = useCallback(
    (event, nodeId) => {
      event.preventDefault();
      const [fullPath, filterString] = nodeId.split('||');
      const selectedFilter = JSON.parse(filterString);
      if (selectedFilter.type === 'relation') {
        return;
      }
      if (onSelect) {
        onSelect({
          name: selectedFilter.name,
          path: fullPath.split(' -> '),
          label: fullPath,
          type: selectedFilter.type,
          enum: selectedFilter.enum,
          groupingFunction: selectedFilter.groupingFunction,
          dateSeparation: selectedFilter.dateSeparation,
        });
      }
    },
    [onSelect],
  );

  // -----------------------------
  // Recursive Render Functions (wrapped in useCallback)
  // -----------------------------

  // Recursive function to render tree items from columns (used for sortings/chart)
  const renderTreeFromColumns = useCallback(
    (node, path = '', currentSearchQuery = '') => {
      const hasChildren = Object.keys(node.children).length > 0;
      const fullPath = path ? `${path} -> ${node.name}` : node.name;
      const filter = node.filter;
      const nodeId = `${fullPath}||${JSON.stringify(filter)}`;
      const label = filter.groupingFunction
        ? `${conversion.camelCaseToWords(node.name)} (${filter.groupingFunction})`
        : filter.dateSeparation
          ? `${conversion.camelCaseToWords(node.name)} (${filter.dateSeparation})`
          : conversion.camelCaseToWords(node.name);

      // Determine if this node matches the search term
      const labelMatches = label
        .toLowerCase()
        .includes(currentSearchQuery.toLowerCase());
      // If parent's label matches, use an empty query so children are not filtered out
      const effectiveQuery = labelMatches ? '' : currentSearchQuery;

      const children = hasChildren
        ? Object.values(node.children)
            .map((child) =>
              renderTreeFromColumns(child, fullPath, effectiveQuery),
            )
            .filter((child) => child !== null)
        : [];

      if (!labelMatches && children.length === 0) {
        return null;
      }

      return (
        <TreeItem nodeId={nodeId} label={label} key={nodeId}>
          {children}
        </TreeItem>
      );
    },
    [], // conversion is assumed stable; adjust if needed.
  );

  // Recursive function to render tree items (used for modules and filters)
  const renderTreeItems = useCallback(
    (filter, path = '', currentSearchQuery = '') => {
      const hasChildren = filter.type === 'relation';
      const fullPath = path ? `${path} -> ${filter.name}` : filter.name;
      const nodeId = `${fullPath}||${JSON.stringify(filter)}`;
      const mainLabel = conversion.camelCaseToWords(filter.name);
      const labelMatches = mainLabel
        .toLowerCase()
        .includes(currentSearchQuery.toLowerCase());
      const effectiveQuery = labelMatches ? '' : currentSearchQuery;
      const items = [];

      const shouldExcludeMainItem =
        (type === 'groupings' && filter.type === 'date') ||
        (type === 'groupedColumns' &&
          (filter.type === 'date' || filter.type === 'number'));

      if (!shouldExcludeMainItem) {
        const childrenItems =
          hasChildren &&
          (loadedChildren[fullPath]?.length > 0 ? (
            loadedChildren[fullPath]
              .flatMap((childFilter) =>
                renderTreeItems(childFilter, fullPath, effectiveQuery),
              )
              .filter((child) => child !== null)
          ) : (
            <TreeItem
              nodeId={`${nodeId}-placeholder`}
              label="Loading..."
              key={`${nodeId}-placeholder`}
            />
          ));

        if (
          labelMatches ||
          (childrenItems &&
            (Array.isArray(childrenItems) ? childrenItems.length > 0 : true))
        ) {
          items.push(
            <TreeItem
              nodeId={nodeId}
              label={mainLabel}
              key={nodeId}
              collapseIcon={hasChildren ? <ExpandMoreIcon /> : null}
              expandIcon={hasChildren ? <ChevronRightIcon /> : null}
            >
              {childrenItems}
            </TreeItem>,
          );
        }
      }

      // Add 'Count' item under each module and relation
      if (type !== 'filters' && type !== 'groupings' && filter.name === 'id') {
        const countFilter = {
          ...filter,
          name: `Count of ${filter.relatedModule}`,
          groupingFunction: 'Count',
          type: 'number',
        };
        const countNodeId = `${fullPath}||${JSON.stringify(countFilter)}`;
        const countLabel = `${conversion.camelCaseToWords(filter.name)} - (Count)`;
        if (
          countLabel.toLowerCase().includes(currentSearchQuery.toLowerCase())
        ) {
          items.push(
            <TreeItem
              nodeId={countNodeId}
              label={countLabel}
              key={countNodeId}
            />,
          );
        }
      }

      // New logic to add groupings with dateSeparation as columns
      if (type === 'groupedColumns' && filter.type === 'date') {
        const visualPathKey = JSON.stringify([
          ...(path ? path.split(' -> ') : []),
          filter.name,
        ]);
        const dateGroupings = groupingsByVisualPath[visualPathKey] || [];
        dateGroupings.forEach((grouping) => {
          const groupingFilter = {
            ...filter,
            name: grouping.name,
            dateSeparation: grouping.dateSeparation,
          };
          const groupingNodeId = `${fullPath}||${JSON.stringify(groupingFilter)}`;
          const groupingLabel = `${conversion.camelCaseToWords(filter.name)} - (${grouping.dateSeparation})`;
          if (
            groupingLabel
              .toLowerCase()
              .includes(currentSearchQuery.toLowerCase())
          ) {
            items.push(
              <TreeItem
                nodeId={groupingNodeId}
                label={groupingLabel}
                key={groupingNodeId}
              />,
            );
          }
        });
        ['Min', 'Max'].forEach((agg) => {
          const aggFilter = {
            ...filter,
            name: `${agg} of ${filter.name}`,
            groupingFunction: agg,
          };
          const aggNodeId = `${fullPath}||${JSON.stringify(aggFilter)}`;
          const aggLabel = `${conversion.camelCaseToWords(filter.name)} - (${agg})`;
          if (
            aggLabel.toLowerCase().includes(currentSearchQuery.toLowerCase())
          ) {
            items.push(
              <TreeItem nodeId={aggNodeId} label={aggLabel} key={aggNodeId} />,
            );
          }
        });
      } else if (type === 'groupedColumns' && filter.type === 'number') {
        ['Min', 'Max', 'Avg', 'Sum'].forEach((agg) => {
          const aggFilter = {
            ...filter,
            name: `${agg} of ${filter.name}`,
            groupingFunction: agg,
          };
          const aggNodeId = `${fullPath}||${JSON.stringify(aggFilter)}`;
          const aggLabel = `${conversion.camelCaseToWords(filter.name)} - (${agg})`;
          if (
            aggLabel.toLowerCase().includes(currentSearchQuery.toLowerCase())
          ) {
            items.push(
              <TreeItem nodeId={aggNodeId} label={aggLabel} key={aggNodeId} />,
            );
          }
        });
      }

      if (type === 'groupings' && filter.type === 'date') {
        const aggregates = [
          'Day',
          'Month',
          'Quarter',
          'Week',
          'Year',
          'Year&Month',
        ];
        aggregates.forEach((agg) => {
          const aggFilter = {
            ...filter,
            name: `${filter.name} - (${agg})`,
            dateSeparation: agg,
          };
          const aggNodeId = `${fullPath}||${JSON.stringify(aggFilter)}`;
          const aggLabel = `${conversion.camelCaseToWords(filter.name)} - (${agg})`;
          if (
            aggLabel.toLowerCase().includes(currentSearchQuery.toLowerCase())
          ) {
            items.push(
              <TreeItem nodeId={aggNodeId} label={aggLabel} key={aggNodeId} />,
            );
          }
        });
      }
      return items;
    },
    [conversion, groupingsByVisualPath, loadedChildren, type],
  );

  // -----------------------------
  // Render
  // -----------------------------
  if (!moduleValue || !moduleData) {
    return <div>Loading...</div>;
  }

  if (type === 'sortings' && reportType === 'chart') {
    return (
      <>
        <Box px={2}>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
          />
        </Box>
        <TreeView
          expanded={expanded}
          onNodeToggle={handleToggle}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleNodeSelect}
        >
          {Object.values(columnsTree)
            .map((node) => renderTreeFromColumns(node, '', searchQuery))
            .filter((node) => node !== null)}
        </TreeView>
      </>
    );
  }

  return (
    <>
      <Box px={2}>
        <TextField
          label="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
          fullWidth
          margin="normal"
        />
      </Box>
      <TreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={handleNodeSelect}
      >
        {moduleFilters
          .flatMap((filter) => renderTreeItems(filter, '', searchQuery))
          .filter((item) => item !== null)}
      </TreeView>
    </>
  );
};

ReportFiltersTree.propTypes = {
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ReportFiltersTree;
