import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { PageMainContent } from 'components/Styled';
import { DashboardProvider } from 'pages/ReportPages/ReportPage/context/DashboardContext';
import DashboardSidebar from 'pages/ReportPages/ReportPage/DashboardSidebar';

export const ReportPage = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  return (
    <PageMainContent maxWidth={false} component="main" disableGutters>
      <DashboardProvider>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <DashboardSidebar
            page={page}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            totalCount={totalCount}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            setTotalPages={setTotalPages}
            setTotalCount={setTotalCount}
          />

          <Box sx={{ flexGrow: 1, pl: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </DashboardProvider>
    </PageMainContent>
  );
};
