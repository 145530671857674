import React from 'react';
import { Field } from 'react-final-form';
import { FormPanel } from 'components/Common';
import { YearOptionalDatePicker } from 'components/Fields';
import { ImportantDatesBoxStyled } from './styled';

export const ImportantDatesBox = () => {
  return (
    <FormPanel label={'Important Dates'}>
      <ImportantDatesBoxStyled>
        <Field
          name="birthday"
          label="Birthday"
          component={YearOptionalDatePicker}
        />
        <Field
          name="anniversaryDate"
          label="Anniversary Image"
          component={YearOptionalDatePicker}
        />
        <Field
          name="homeAnniversary"
          label="Home Anniversary"
          component={YearOptionalDatePicker}
        />
      </ImportantDatesBoxStyled>
    </FormPanel>
  );
};

ImportantDatesBox.propTypes = {};
