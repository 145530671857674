import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN_ROUTES } from 'consts';
import { omitNull } from 'helpers';
import {
  useEditCompanyUnitMutation,
  useGetCompanyUnitQuery,
} from 'redux/rtk-query';
import CompanyUnitForm, { defaultValues } from '../CompanyUnitForm';

export const EditCompanyUnitPage = () => {
  const [initialValues, setInitialValues] = useState(defaultValues);

  let { structureId } = useParams();
  const navigate = useNavigate();
  const { data } = useGetCompanyUnitQuery(structureId);
  const [editCompanyUnit] = useEditCompanyUnitMutation();

  const onSubmit = ({ parent, ...values }) => {
    editCompanyUnit({
      ...values,
      parentId: parent?.value ? parent.value : parent?.id,
    });
  };

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...omitNull({ ...defaultValues }),
        ...omitNull({ ...data.data }),
        structureId: data.data.id,
      });
    }
  }, [data]);

  const afterSave = () => {
    navigate(ADMIN_ROUTES.COMPANY_UNITS.path);
  };

  return (
    <CompanyUnitForm
      initialValues={initialValues}
      handleSubmit={onSubmit}
      title={'Edit Company Unit'}
      headerColor={'white'}
      afterSave={afterSave}
    />
  );
};
