import React, { useMemo, useState } from 'react';
import { faEye, faPen } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Common';
import { Cells } from 'components/Fields/Contact/components/ContactsTable/config';
import { UsersModal } from 'components/ModelViews/Users/UsersModal';
import { useTableData } from 'hooks';
import { useGetGroupsQuery, useGetGroupUsersQuery } from 'redux/rtk-query';
import { getCells } from './config';

const RowsPerPageOptions = [15, 25, 50];

export const GroupsTable = () => {
  const [currentGroup, setCurrentGroup] = useState(false);

  const navigate = useNavigate();

  const {
    page,
    rowsPerPage,
    search,
    setPage,
    setRowsPerPage,
    setSearch,
    totalPages,
    rows,
    loading: isLoading,
  } = useTableData(useGetGroupsQuery, Cells, 'name', 'asc', 10);

  const MenuItems = useMemo(
    () => [
      {
        name: 'View',
        icon: faEye,
        onClick: ({ uuid }) => navigate(`/admin/users/${uuid}`),
      },
      {
        name: 'Edit',
        icon: faPen,
        onClick: ({ id }) => navigate(`/admin/groups/edit/${id}`),
      },
    ],
    [navigate],
  );

  const getClickHandler = (companyUnit, active) => () =>
    setCurrentGroup({ ...companyUnit, active });

  return (
    <>
      <Table
        handleSearch={setSearch}
        search={search}
        isLoading={isLoading}
        rows={rows}
        totalPages={totalPages}
        menuItems={MenuItems}
        cells={getCells(getClickHandler)}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={RowsPerPageOptions}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      {currentGroup ? (
        <UsersModal
          currentItem={currentGroup}
          setCurrentItem={setCurrentGroup}
          useGetList={useGetGroupUsersQuery}
        />
      ) : null}
    </>
  );
};

GroupsTable.propTypes = {};
