import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItemText, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FontAwesomeIconComp } from 'components/Common';
import { SmallDrawer } from 'components/Layouts/common';
import { HOME_ROUTE } from 'consts';
import {
  BackBtn,
  LeftIcon,
  ListItem,
  UpperListItemButton,
  UpperListItemIcon,
} from './styled';

export const LeftMenuDrawer = ({ menuItems }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleBack = () => navigate(HOME_ROUTE);

  return (
    <SmallDrawer
      variant="permanent"
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
    >
      <BackBtn
        variant="outlined"
        onClick={handleBack}
        sx={{ minWidth: open ? '100px' : 'auto', mt: 3 }}
      >
        <LeftIcon />{' '}
        {open ? <Box ml={open ? 1 : 0}>Back to Application</Box> : ''}
      </BackBtn>
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            disablePadding
            component={Link}
            to={item.path}
            sx={{
              '& .MuiTouchRipple-child': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <UpperListItemButton
              sx={{ justifyContent: open ? 'initial' : 'center' }}
            >
              <UpperListItemIcon sx={{ mr: open ? 3 : 0 }}>
                <FontAwesomeIconComp {...item} />
              </UpperListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{ display: open ? 'block' : 'none', width: '10px' }}
              />
            </UpperListItemButton>
          </ListItem>
        ))}
      </List>
    </SmallDrawer>
  );
};

LeftMenuDrawer.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape),
};
