import React from 'react';
import { useForm } from 'react-final-form';
import { Grid } from '@mui/material';
import { ReportFilters } from 'pages/ReportPages/CreateReportPage/ReportFiltersPage/ReportFilters';

export const ReportFiltersPage = () => {
  const form = useForm();

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReportFilters form={form} />
      </Grid>
    </Grid>
  );
};
