import React from 'react';
import PropTypes from 'prop-types';
import { faTable, faChartBar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Button, Typography } from '@mui/material';

function SelectReportType({ values, setInitialValues, handleNext }) {
  return (
    <Grid container width="50%" justifySelf="center" sx={{ p: 2 }}>
      <Grid item xs={6} justifyItems="center">
        <Button
          onClick={() => {
            setInitialValues((prevValues) => ({
              ...prevValues,
              reportType: 'table',
            }));
            handleNext({ reportType: 'table' });
          }}
          variant={values.reportType === 'table' ? 'contained' : 'outlined'}
          sx={{
            display: 'block',
            width: 200,
            height: 150,
          }}
        >
          <FontAwesomeIcon icon={faTable} size="3x" />
          <Typography
            sx={{ mt: 2 }}
            color={values.reportType === 'table' ? '#FFFFFF' : 'primary'}
          >
            Rows & Columns
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={6} justifyItems="center">
        <Button
          onClick={() => {
            setInitialValues((prevValues) => ({
              ...prevValues,
              reportType: 'chart',
            }));
            handleNext({ reportType: 'chart' });
          }}
          variant={values.reportType === 'chart' ? 'contained' : 'outlined'}
          sx={{ display: 'block', width: 200, height: 150 }}
        >
          <FontAwesomeIcon icon={faChartBar} size="3x" />
          <Typography
            sx={{ mt: 2 }}
            color={values.reportType === 'chart' ? '#FFFFFF' : 'primary'}
          >
            Summation
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

export default SelectReportType;

SelectReportType.propTypes = {
  values: PropTypes.object.isRequired,
  setInitialValues: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};
