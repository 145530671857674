import React from 'react';
import PropTypes from 'prop-types';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { SideDrawer, FontAwesomeIconComp } from 'components/Common';
import { HeadingSection, Header } from 'components/Styled';
import { CategoriesTable } from 'pages/ReportPages/CategoriesTable/CategoriesTable';

const ReportCategoriesDrawer = ({ isOpen, closeDrawer }) => {
  return (
    <SideDrawer width={'40%'} isOpen={isOpen} closeDrawer={closeDrawer}>
      <HeadingSection>
        <Header variant="h4">Categories</Header>
        <FontAwesomeIconComp
          icon={faCircleXmark}
          style={{ height: '28px', color: '#969696', cursor: 'pointer' }}
          onClick={() => closeDrawer()}
        />
      </HeadingSection>
      <CategoriesTable />
    </SideDrawer>
  );
};

export default ReportCategoriesDrawer;

ReportCategoriesDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};
