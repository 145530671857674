import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'consts/colors';

export const LabeledOutlinedWrapper = styled(FormControl)({
  position: 'relative',
  minHeight: 20,
  '&:hover .labeled-outline-container fieldset': {
    borderColor: 'rgba(0, 0, 0, 0.90) !important',
  },
  '&:focus-within .labeled-outline-container fieldset': {
    borderColor: `${colors.LIGHT_BLUE_1} !important`,
    borderWidth: '2px !important',
  },
  '&:focus-within .MuiFormLabel-root': {
    color: `${colors.LIGHT_BLUE_1} !important`,
  },

  '& + &': {
    marginTop: 16,
  },
  '& MuiInput-root:before': {
    borderWidth: 0,
  },
});
