import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { colors } from 'consts/colors';

const allowedFileTypes = ['application/pdf'];

export const FileUploadDrop = ({
  uploading,
  uploadedFiles,
  rejectedFiles,
  uploadProgress,
  onFileChange,
  triggerResize,
}) => {
  const [dragging, setDragging] = useState(false);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragging(false);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    onFileChange(files);
    setDragging(false);
  }, []);

  useEffect(() => {
    // Attach the event listeners
    document.body.addEventListener('dragover', handleDragOver);
    document.body.addEventListener('dragleave', handleDragLeave);
    document.body.addEventListener('drop', handleDrop);

    // Cleanup function to remove the event listeners
    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
      document.body.removeEventListener('dragleave', handleDragLeave);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, [handleDragOver, handleDrop]);

  useEffect(() => {
    if (triggerResize) {
      triggerResize();
    }
  }, [uploading, uploadedFiles, rejectedFiles, triggerResize]);

  return (
    <Box sx={{ minWidth: '300px', maxHeight: '300px' }}>
      <Box
        sx={{
          border: `2px dashed ${
            dragging ? colors.LIGHT_BLUE_1 : colors.LIGHT_GREY
          }`,
          borderRadius: '20px',
          padding: '20px',
          position: 'relative', // Added for positioning the input
        }}
      >
        <input
          type="file"
          id="file-upload"
          accept={allowedFileTypes.join(',')}
          multiple
          onChange={(event) => onFileChange(event.target.files)}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            cursor: 'pointer',
          }}
        />
        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
          <Typography variant="body1" textAlign={'center'}>
            {dragging ? 'Drop' : 'Drag'} files anywhere or click here to upload
          </Typography>
        </label>
      </Box>
      {uploading && (
        <Box p={1}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
      {uploadedFiles.length > 0 && (
        <List dense disablePadding>
          {uploadedFiles.map((file, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={file.name}
                secondary={`Size: ${file.size} bytes`}
              />
            </ListItem>
          ))}
          {rejectedFiles.length > 0 && (
            <Box>
              <Typography variant="body2" color="error">
                Rejected Files:
              </Typography>
              <List>
                {rejectedFiles.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={file.name}
                      secondary="Unsupported file type"
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </List>
      )}
    </Box>
  );
};

FileUploadDrop.propTypes = {
  uploading: PropTypes.bool,
  uploadedFiles: PropTypes.array,
  rejectedFiles: PropTypes.array,
  uploadProgress: PropTypes.number,
  onFileChange: PropTypes.func,
  triggerResize: PropTypes.func,
};
