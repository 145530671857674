import React, { useEffect, useMemo, useState } from 'react';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { DateTime } from 'luxon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Typography } from '@mui/material';
import {
  AbeCard,
  DetailsPanels,
  FontAwesomeIconComp,
  ModuleIcon,
  SideDrawer,
} from 'components/Common';
import {
  MonthPickerField,
  YearPickerField,
} from 'components/Fields/YearOptionalDatePicker/components';
import { InvoiceTemplateSelection } from 'components/ModelViews/InvoiceTemplates/InvoiceTemplateSelection/InvoiceTemplateSelection';
import { colors, CREATE_INVOICE } from 'consts';
import { conversion } from 'helpers';
import { useDrawer } from 'hooks';
import { InvoiceRow } from 'pages/TransactionsPages/DetailsTransactionPage/components/TransactionDashboard/components/PropertyMgmtInvoicesCards/components/components';
import {
  useGetAvailablePeriodsQuery,
  useGetTransactionInvoicesQuery,
  useGetTransactionQuery,
} from 'redux/rtk-query'; // const StyledInvoiceName = styled(Box)(({ theme }) => ({

// const StyledInvoiceName = styled(Box)(({ theme }) => ({
//   overflow: 'hidden',
//   textOverflow: 'ellipsis',
//   whiteSpace: 'nowrap',
//   maxWidth: 'calc(100% - 60px)',
//   textAlign: 'right',
//   paddingRight: theme.spacing(2),
// }));

const calculateMgmtInvoiceTotal = (invoices) =>
  invoices.reduce((total, invoice) => total + parseFloat(invoice.price), 0);

const getInvoices = (mgmtInvoices) => (
  <Box display="flex" flexDirection="column" flexGrow={1}>
    {mgmtInvoices.map((invoice) => (
      <InvoiceRow key={invoice.id} invoice={invoice} showIndent />
    ))}
  </Box>
);

const type = ['Mgmt Real Estate Service', 'Owner Charge', 'Mgmt Debit/Credit'];

export const OwnerInvoicesCard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { transactionDetailsId } = useParams();

  const { data: transactionData } = useGetTransactionQuery({
    id: transactionDetailsId,
  });
  const { isOpen, openDrawer, closeDrawer } = useDrawer(false);
  const [availablePeriods, setAvailablePeriods] = useState([]);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [period, setPeriod] = useState(transactionData?.data?.period || '');

  const { data: availablePeriodData } = useGetAvailablePeriodsQuery(
    transactionDetailsId,
    { skip: !transactionDetailsId },
  );

  const { data: transactionInvoiceData } = useGetTransactionInvoicesQuery(
    {
      id: transactionDetailsId,
      period,
      type: type,
      limit: 100,
    },
    { skip: !transactionDetailsId },
  );

  const transactionInvoices = transactionInvoiceData?.data?.items || [];

  const { rentInvoice, mgmtInvoices, futureMgmtInvoices } = useMemo(() => {
    let rentInvoice = null;
    const mgmtInvoices = [];
    const futureMgmtInvoices = [];

    transactionInvoices.forEach((invoice) => {
      if (invoice.name.includes('Rent for ')) {
        rentInvoice = invoice;
      } else if (
        invoice.type === 'Owner Charge' ||
        invoice.type === 'Mgmt Real Estate Service'
      ) {
        if (invoice.status === 'Future') {
          futureMgmtInvoices.push(invoice);
        } else {
          mgmtInvoices.push(invoice);
        }
      }
    });

    return { rentInvoice, mgmtInvoices, futureMgmtInvoices };
  }, [transactionInvoices]);

  const mgmtInvoiceTotal = useMemo(
    () => calculateMgmtInvoiceTotal(mgmtInvoices),
    [mgmtInvoices],
  );

  const formattedDate = useMemo(
    () => DateTime.fromISO(period).toFormat('LLLL yyyy'),
    [period],
  );

  const rentInvoicePrice = useMemo(
    () => Math.abs(rentInvoice?.price || 0),
    [rentInvoice],
  );

  const rentMinusMgmtTotal = useMemo(
    () => rentInvoicePrice - mgmtInvoiceTotal,
    [rentInvoicePrice, mgmtInvoiceTotal],
  );

  const onRowClick = (invoiceTemplate) => {
    navigate(`${pathname}${CREATE_INVOICE(invoiceTemplate.id)}`);
    closeDrawer();
  };

  useEffect(() => {
    if (period) {
      const newDate = DateTime.fromISO(period);
      setYear(newDate.year);
      setMonth(newDate.month);
    }
  }, [period]);

  useEffect(() => {
    if (transactionData?.data?.period) {
      setPeriod(transactionData.data.period);
    }
  }, [transactionData?.data?.period]);

  useEffect(() => {
    if (month && year) {
      setPeriod(
        DateTime.local(parseInt(year), parseInt(month))
          .startOf('month')
          .toISODate(),
      );
    }
  }, [month, year]);

  useEffect(() => {
    if (availablePeriodData) {
      console.log(availablePeriodData.data);
      setAvailablePeriods(availablePeriodData.data);
    }
  }, [availablePeriodData]);

  const isMonthDisabled = (d) =>
    !availablePeriods.some((p) => p.month === month && p.year === d.year);
  const isYearDisabled = (d) =>
    !availablePeriods.some((p) => p.year === d.year);

  return (
    <>
      <AbeCard
        icon={<ModuleIcon module={'Invoices'} />}
        title={'Owner Charges'}
      >
        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Box fontSize={14} px={2} alignContent="center">
            <Box display={'inline-block'}>
              <Box display={'inline-block'} mr={0.5}>
                <MonthPickerField
                  availablePeriods={availablePeriods}
                  year={year}
                  input={{
                    value: DateTime.fromISO(period),
                    onChange: (value) => setMonth(value.month),
                  }}
                  sx={{
                    padding: 0,
                    fontSize: 14,
                    borderBottom: `1px solid ${colors.LIGHT_BLUE_1}`,
                    borderRadius: 0,
                  }}
                  disabled={(value, value2) => {
                    console.log(value, value2);
                    return isMonthDisabled(value);
                  }}
                />
              </Box>
              <YearPickerField
                availablePeriods={availablePeriods}
                noPadding
                input={{
                  value: DateTime.fromISO(period),
                  onChange: (value) => {
                    setYear(value.year);
                  },
                }}
                sx={{
                  padding: 0,
                  fontSize: 14,
                  borderBottom: `1px solid ${colors.LIGHT_BLUE_1}`,
                  borderRadius: 0,
                }}
                disabled={isYearDisabled(year)}
              />
            </Box>
          </Box>
          <Button
            size={'small'}
            variant="text"
            onClick={openDrawer}
            endIcon={<FontAwesomeIconComp icon={faCirclePlus} />}
          >
            New
          </Button>
        </Box>
        <Divider />
        <DetailsPanels
          data={{
            month: formattedDate,
            rent: conversion.formatCurrency(rentInvoicePrice),
            invoices: conversion.formatCurrency(mgmtInvoiceTotal),
            invoiceItems: getInvoices(mgmtInvoices, navigate),
            toOwner: conversion.formatCurrency(rentMinusMgmtTotal),
          }}
          translatedLabels={{ invoiceItems: 'none' }}
          panels={{
            ' ': ['month', 'rent', 'invoices', 'invoiceItems', 'toOwner'],
          }}
        />
        {futureMgmtInvoices.length > 0 && (
          <>
            <Divider />
            <Box px={2} py={0.5}>
              <Typography variant={'body2'}>Future Invoices</Typography>
              <Box py={0.5}>
                {futureMgmtInvoices.map((invoice) => (
                  <InvoiceRow key={invoice.id} invoice={invoice} showIndent />
                ))}
              </Box>
            </Box>
          </>
        )}
      </AbeCard>
      <SideDrawer width={'40%'} isOpen={isOpen} closeDrawer={closeDrawer}>
        <InvoiceTemplateSelection
          type={'Owner Charge'}
          onRowClick={onRowClick}
          closeDrawer={closeDrawer}
        />
      </SideDrawer>
    </>
  );
};
