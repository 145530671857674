import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, ListSubheader, MenuItem } from '@mui/material';
import { Required } from 'components/Common';
import { FieldTooltip } from 'components/Fields';
import { ErrorMsg, TextInputField } from 'components/Styled';
import { hasValue } from 'helpers';
import { useFormValidationProcess, useIsRequired } from 'hooks';

const SelectGroup = ({ label, options }) => (
  <>
    <ListSubheader key={label}>{label}</ListSubheader>
    {options.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </>
);

SelectGroup.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export const Select = ({
  label,
  size = 'small',
  options,
  input,
  meta,
  isGrouped = false,
  onOpen = null,
  loadMoreResults = null,
  tooltip,
  isRequired = false,
}) => {
  const error =
    meta.submitError && !meta.dirtySinceLastSubmit && meta.submitError;
  const isFilled = hasValue(input?.value);
  const required = (useIsRequired(input?.name) || isRequired) && !isFilled;
  const validateFormData = useFormValidationProcess();

  // Ensure the value is the proper type:
  useEffect(() => {
    if (input?.multiple && !Array.isArray(input.value)) {
      input.onChange([]); // force to an empty array
    } else if (!input?.multiple && Array.isArray(input.value)) {
      input.onChange(''); // force to an empty string
    }
  }, [input?.multiple, input.value, input.onChange]);

  // Create a value that is guaranteed to be an array if multiple is true
  const valueForSelect = input?.multiple
    ? Array.isArray(input.value)
      ? input.value
      : []
    : input.value || '';

  return (
    <Box position="relative">
      <TextInputField
        className={isFilled ? 'filled' : ''}
        label={
          (label || required) && (
            <>
              {label}
              {required && <Required />}
            </>
          )
        }
        SelectProps={{
          multiple: input?.multiple,
          onOpen,
          ...(loadMoreResults && {
            MenuProps: {
              onScroll: ({ currentTarget }) => {
                if (
                  currentTarget.scrollTop + currentTarget.clientHeight >=
                  currentTarget.scrollHeight
                ) {
                  loadMoreResults();
                }
              },
            },
          }),
        }}
        size={size}
        error={!!error}
        fullWidth
        select
        {...input}
        value={valueForSelect}
        onChange={async (event) => {
          await validateFormData(input.name, event.target.value);
          input.onChange(event);
        }}
      >
        {/* Add Empty Option */}
        <MenuItem value="">
          <em>None</em>
        </MenuItem>

        {/* Render Options */}
        {isGrouped
          ? options.map((group) => (
              <SelectGroup
                key={group.label}
                label={group.label}
                options={group.options}
              />
            ))
          : options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
      </TextInputField>
      {tooltip && <FieldTooltip tooltip={tooltip} />}
      {error && (
        <ErrorMsg className="error-message" component="span">
          {error}
        </ErrorMsg>
      )}
    </Box>
  );
};

Select.propTypes = {
  tooltip: PropTypes.object,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  size: PropTypes.string,
  isGrouped: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }).isRequired,
  loadMoreResults: PropTypes.func,
  onOpen: PropTypes.func,
  isRequired: PropTypes.bool,
};

export default Select;
