import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ShowMoreCollapse } from 'components/Common';
import { conversion } from 'helpers';

function ModelDetailsCollapse({ data }) {
  const whoCanReadAndWrite = data.modelAccess?.everyone
    ? 'Everyone'
    : data.modelAccess?.sharedWithUnits?.length > 0
      ? 'Shared with Units'
      : data.modelAccess?.sharedWithUsers?.length > 0
        ? 'Shared with Users'
        : 'Owner';

  return (
    <ShowMoreCollapse>
      <Box textAlign={'center'}>
        <Typography variant="caption" component={'p'} color="text.secondary">
          Last Modified{' '}
          {DateTime.fromISO(data.updatedAt).toLocaleString(
            DateTime.DATETIME_SHORT,
          )}{' '}
          by {conversion.getName(data.modifiedBy)}
        </Typography>
        <Typography variant="caption" component={'p'} color="text.secondary">
          Created On{' '}
          {DateTime.fromISO(data.createdAt).toLocaleString(
            DateTime.DATETIME_SHORT,
          )}{' '}
          by {conversion.getName(data.createdBy)}
        </Typography>

        <Typography variant="caption" component={'p'} color="text.secondary">
          Latest Activity {DateTime.fromISO(data.latestActivityAt).toRelative()}
        </Typography>
        <Typography variant="caption" component={'p'} color="text.secondary">
          Agent {conversion.getName(data.modelAccess?.owner)}
        </Typography>
        <Typography variant="caption" component={'p'} color="text.secondary">
          Who can read and write {whoCanReadAndWrite}
        </Typography>
      </Box>
    </ShowMoreCollapse>
  );
}

ModelDetailsCollapse.propTypes = {
  data: PropTypes.object,
};

export default ModelDetailsCollapse;
