import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PopperButton } from 'components/Common';
import { UPDATE_TRANSACTION, AUDIT_LOG } from 'consts';
import { useGetTransactionQuery } from 'redux/rtk-query';

const MenuItems = (viewingId) => {
  const navigate = useNavigate();

  const { data: transactionData } = useGetTransactionQuery({ id: viewingId });
  const uuid = transactionData?.data?.uuid;

  return [
    {
      name: 'Edit',
      onClick: () => {
        navigate(UPDATE_TRANSACTION(viewingId));
      },
      closeOnClick: true,
    },
    {
      name: 'Audit Log',
      onClick: () => {
        navigate(AUDIT_LOG(uuid, 'Transaction'));
      },
      closeOnClick: true,
    },
  ];
};

export function TransactionOptionsButton() {
  let { transactionDetailsId } = useParams();

  return (
    <>
      <PopperButton menuItems={MenuItems(transactionDetailsId)} size={'small'}>
        Options
      </PopperButton>
    </>
  );
}
