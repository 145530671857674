import React from 'react';
import PropTypes from 'prop-types';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, Box, Button, Tooltip } from '@mui/material';
import { ConfirmationButton } from 'components/Common/ConfirmationButton';
import { colors } from 'consts';
import ChartForm from 'pages/ReportPages/DetailsReportPage/ChartForm';
import { ReportChart } from 'pages/ReportPages/DetailsReportPage/ReportChart';
import { useGetChartListQuery, useDeleteChartMutation } from 'redux/rtk-query';

export const ChartView = ({ reportId, handleChartSelect }) => {
  const [addChart, setAddChart] = React.useState(false);
  const { data: charts } = useGetChartListQuery(reportId);
  const [deleteChart] = useDeleteChartMutation();

  // ✅ Ensure charts are always displayed in 3 columns per row
  const chartList = charts?.data || [];
  const totalCharts = chartList.length + (addChart ? 1 : 0); // ✅ Count added chart

  const getGridSize = () => {
    if (totalCharts === 1) return 12; // Full width if 1 chart
    if (totalCharts === 2) return 6; // Split into two if 2 charts
    return 4; // 3+ charts = 3 columns per row
  };

  const handleDeleteChart = (id) => {
    deleteChart({ reportId, chartId: id });
  };

  return (
    <>
      <Grid container spacing={2}>
        {chartList.map((chart) => (
          <Grid item key={chart.id} xs={12} sm={getGridSize()}>
            <Card
              onClick={() => handleChartSelect && handleChartSelect(chart.id)}
              sx={{ p: 2, cursor: 'pointer', position: 'relative' }}
            >
              <ReportChart reportId={reportId} chartId={chart.id} />
              {!handleChartSelect && (
                <ConfirmationButton
                  sx={{
                    minWidth: 'auto',
                    position: 'absolute',
                    top: 4,
                    left: 4,
                  }}
                  variant={'text'}
                  label={
                    <Tooltip title={'Delete Chart'}>
                      <FontAwesomeIcon color={colors.RED} icon={faTrash} />
                    </Tooltip>
                  }
                  confirmTitle={'Remove Row'}
                  confirmMessage={'Are you sure you want to delete this chart?'}
                  handleConfirm={() => {
                    handleDeleteChart(chart.id);
                  }}
                />
              )}
            </Card>
          </Grid>
        ))}

        {/* ✅ Extra slot for adding a new chart */}
        {addChart ? (
          <Grid item xs={12} sm={getGridSize()}>
            <ChartForm
              reportId={reportId}
              afterSave={() => setAddChart(false)}
            />
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: '13px 20px',
              zIndex: 10,
            }}
          >
            <Button variant="outlined" onClick={() => setAddChart(true)}>
              + Add Chart
            </Button>
          </Box>
        )}
      </Grid>
    </>
  );
};

ChartView.propTypes = {
  reportId: PropTypes.string.isRequired,
  handleChartSelect: PropTypes.func,
};
