import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Typography, Box } from '@mui/material';
import { ModuleIcon } from 'components/Common';

/**
 * A reusable grid card showing:
 * - An icon on the left
 * - A vertical stack (count + label) on the right
 */
export const StatCard = ({
  xs = 4,
  iconModule,
  iconFontSize = '2.5rem',
  iconMarginRight = 10,
  count,
  label,
  ...props
}) => {
  return (
    <Grid item xs={xs} {...props}>
      <Card
        elevation={1}
        sx={{
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'translateY(-3px)',
            boxShadow: 3,
          },
        }}
      >
        <Box px={2} py={4} display="flex" justifyContent={'center'}>
          {/* Left side: Icon */}

          {/* Right side: count + label */}
          <Box
            p={1}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            {count !== undefined && (
              <>
                <Typography variant="h2">
                  <ModuleIcon
                    fontSize={iconFontSize}
                    marginRight={iconMarginRight}
                    module={iconModule}
                  />
                  {count}
                </Typography>
              </>
            )}
            <Typography sx={{ whiteSpace: 'pre', mt: 1 }} variant="h4">
              {label}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

StatCard.propTypes = {
  /**
   * Number of columns to occupy (MUI grid).
   * Defaults to 4 so that three cards fit in 12 columns
   */
  xs: PropTypes.number,
  /**
   * The "module" prop for your <ModuleIcon>
   * e.g. "Tasks", "Timeframes", "Notes"
   */
  iconModule: PropTypes.string.isRequired,
  /** Font size for the icon, e.g. "3rem" */
  iconFontSize: PropTypes.string,
  /** Margin-right for the icon, default 10 */
  iconMarginRight: PropTypes.number,
  /** Number or string shown as the primary count (optional) */
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** The label text below the count (e.g. "Tasks") */
  label: PropTypes.string.isRequired,
};
