import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Box } from '@mui/material';
import { FormPanel } from 'components/Common';
import {
  Contact,
  CurrencyInput,
  DatePicker,
  Select,
  TextInput,
  CheckboxField,
} from 'components/Fields';
import { ButtonFileInput } from 'components/Fields/ButtonFileInput';
import { AbeForm, FormValidationProvider } from 'components/Forms';
import { INVOICE_STATUS, PRICE_FREQUENCY } from 'consts';
import { getSelectOptions } from 'helpers';
import {
  useGetInvoiceMediaQuery,
  useValidateInvoiceMutation,
} from 'redux/rtk-query';
import { processFormData } from './formData';

export function InvoiceForm(props) {
  const renderFields = () => (
    <Box p={2}>
      <FormPanel startOpen>
        <Field
          name="status"
          label="Status"
          options={getSelectOptions(INVOICE_STATUS)}
          component={Select}
        />
        <Field name="date" label={'Date'} component={DatePicker} />
        <Field
          name="priceFrequency"
          label="Price Frequency"
          options={getSelectOptions(PRICE_FREQUENCY)}
          component={Select}
        />
        <Field label="Price" name={'price'} component={CurrencyInput} />
        <Field
          name={'invoiceNumber'}
          label="Invoice Number"
          component={TextInput}
        />
        <Field
          name="files"
          component={ButtonFileInput}
          identifier={'id'}
          useGetMediaQuery={useGetInvoiceMediaQuery}
          dropZoneId={'invoice-form'}
        />
        <Field name="paidFrom" label="Paid From" component={Contact} />
        <Field name="paidTo" label="Paid To" component={Contact} />
        <Field name={'description'} label="Message" component={TextInput} />
        <Field
          name="closedApproved"
          label={'Closed Approved'}
          component={CheckboxField}
        />
      </FormPanel>
    </Box>
  );

  const onSubmit = async (values) => {
    return await props.handleSubmitFunc(values);
  };

  const [validate] = useValidateInvoiceMutation();

  return (
    <FormValidationProvider value={{ validate, processFormData }}>
      <AbeForm {...props} formId={'invoice-form'} handleSubmitFunc={onSubmit}>
        {renderFields}
      </AbeForm>
    </FormValidationProvider>
  );
}

InvoiceForm.propTypes = {
  afterSave: PropTypes.func,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string || PropTypes.object,
  handleSubmitFunc: PropTypes.func,
  initialValues: PropTypes.object,
};
