// ReportFiltersTree.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView, TreeItem } from '@mui/lab';
import { conversion } from 'helpers';
import { useGetReportModulesQuery } from 'redux/rtk-query';

const ReportFiltersTree = ({ onSelect, type }) => {
  const { data: moduleData } = useGetReportModulesQuery();
  const form = useForm();
  const columnsValue = form.getFieldState('columns')?.value || [];
  const moduleValue = form.getFieldState('module')?.value;

  const [expanded, setExpanded] = useState([]);
  const [loadedChildren, setLoadedChildren] = useState({});
  const [visitedPaths, setVisitedPaths] = useState(new Set());

  // Handle expand/collapse of tree nodes
  const handleToggle = (event, nodeIds) => {
    // Determine which nodes are newly expanded
    event.preventDefault();
    const newExpandedNodes = nodeIds.filter((id) => !expanded.includes(id));
    setExpanded(nodeIds);

    // When nodes are expanded, load their children
    newExpandedNodes.forEach((nodeId) => {
      handleNodeExpansion(nodeId);
    });
  };

  // Function to handle node selection
  const handleNodeSelect = (event, nodeId) => {
    event.preventDefault();

    const [fullPath, filterString] = nodeId.split('||');
    const selectedFilter = JSON.parse(filterString);

    if (selectedFilter.type === 'relation') {
      return;
    }
    if (onSelect) {
      onSelect({
        name: selectedFilter.name,
        path: fullPath.split(' -> '),
        label: fullPath,
        type: selectedFilter.type,
        enum: selectedFilter.enum,
        groupingFunction: selectedFilter.groupingFunction,
      });
    }
  };

  // Function to load children lazily when a node is expanded
  const handleNodeExpansion = (nodeId) => {
    const [fullPath, filterString] = nodeId.split('||');
    const filter = JSON.parse(filterString);

    if (filter.type === 'relation') {
      // Check for cycles
      if (visitedPaths.has(fullPath)) {
        console.warn('Cyclic relation detected for path:', fullPath);
        return;
      }

      // Mark this path as visited
      setVisitedPaths((prev) => new Set([...prev, fullPath]));

      // Load and sort children if not already loaded
      if (!loadedChildren[fullPath]) {
        let relatedFields = moduleData?.data?.[filter.relatedModule] || [];

        // Sort relatedFields
        relatedFields = sortFilters(relatedFields);

        setLoadedChildren((prev) => ({
          ...prev,
          [fullPath]: relatedFields,
        }));
      }
    }
  };

  // Build a tree structure from selected columns
  // Build a tree structure from selected columns
  const buildTreeFromColumns = (columns) => {
    const tree = {};
    columns.forEach((column) => {
      let currentLevel = tree;
      column.path.forEach((part, index) => {
        if (!currentLevel[part]) {
          currentLevel[part] = {
            name: part,
            children: {},
            filter: {
              name: part,
              key: part,
              type:
                index === column.path.length - 1
                  ? column.type || 'string'
                  : 'relation',
              // Only include 'groupingFunction' at the leaf node
              ...(index === column.path.length - 1 && {
                groupingFunction: column.groupingFunction,
              }),
            },
          };
        }
        currentLevel = currentLevel[part].children;
      });
    });
    return tree;
  };

  // Recursive function to render tree items from columns
  // Recursive function to render tree items from columns
  const renderTreeFromColumns = (node, path = '') => {
    const hasChildren = Object.keys(node.children).length > 0;
    const fullPath = path ? `${path} -> ${node.name}` : node.name;

    const filter = node.filter;

    const nodeId = `${fullPath}||${JSON.stringify(filter)}`;

    // Construct label with groupingFunction if present
    const label = filter.groupingFunction
      ? `${conversion.camelCaseToWords(node.name)} (${filter.groupingFunction})`
      : conversion.camelCaseToWords(node.name);

    return (
      <TreeItem nodeId={nodeId} label={label} key={nodeId}>
        {hasChildren &&
          Object.values(node.children).map((childNode) =>
            renderTreeFromColumns(childNode, fullPath),
          )}
      </TreeItem>
    );
  };

  // Recursive function to render tree items
  const renderTreeItems = (filter, path = '') => {
    const hasChildren = filter.type === 'relation';
    const fullPath = path ? `${path} -> ${filter.name}` : filter.name;

    // Unique nodeId combining fullPath and filter object
    const nodeId = `${fullPath}||${JSON.stringify(filter)}`;

    const items = [];

    // Determine if we should exclude the main item
    const shouldExcludeMainItem =
      (type === 'groupings' && filter.type === 'date') ||
      (type === 'groupedColumns' &&
        (filter.type === 'date' || filter.type === 'number'));

    // Build the main TreeItem
    if (!shouldExcludeMainItem) {
      const mainItem = (
        <TreeItem
          nodeId={nodeId}
          label={conversion.camelCaseToWords(filter.name)}
          key={nodeId}
          collapseIcon={hasChildren ? <ExpandMoreIcon /> : null}
          expandIcon={hasChildren ? <ChevronRightIcon /> : null}
        >
          {hasChildren &&
            (loadedChildren[fullPath]?.length > 0 ? (
              loadedChildren[fullPath].flatMap((childFilter) =>
                renderTreeItems(childFilter, fullPath),
              )
            ) : (
              // Render a placeholder to ensure expand icon appears
              <TreeItem
                nodeId={`${nodeId}-placeholder`}
                label="Loading..."
                key={`${nodeId}-placeholder`}
              />
            ))}
        </TreeItem>
      );

      items.push(mainItem);
    }

    // Add 'Count' item under each module and relation
    if (type !== 'filters' && type !== 'groupings' && filter.name === 'id') {
      const countFilter = {
        ...filter,
        name: `Count of ${filter.relatedModule}`,
        groupingFunction: 'Count',
        type: 'number',
      };

      const countNodeId = `${fullPath}||${JSON.stringify(countFilter)}`;

      const countItem = (
        <TreeItem
          nodeId={countNodeId}
          label={`${conversion.camelCaseToWords(filter.name)} - (Count)`}
          key={countNodeId}
        />
      );

      items.push(countItem);
    }

    // Add additional items for 'date' and 'number' types when type === 'groupedColumns'
    if (
      type === 'groupedColumns' &&
      (filter.type === 'date' || filter.type === 'number')
    ) {
      const aggregates =
        filter.type === 'date' ? ['Min', 'Max'] : ['Min', 'Max', 'Avg', 'Sum'];

      aggregates.forEach((agg) => {
        const aggFilter = {
          ...filter,
          name: `${agg} of ${filter.name}`,
          groupingFunction: agg,
        };

        const aggNodeId = `${fullPath}||${JSON.stringify(aggFilter)}`;

        const aggItem = (
          <TreeItem
            nodeId={aggNodeId}
            label={`${conversion.camelCaseToWords(filter.name)} - (${agg})`}
            key={aggNodeId}
          />
        );

        items.push(aggItem);
      });
    }

    if (type === 'groupings' && filter.type === 'date') {
      const aggregates = ['Day', 'Month', 'Quarter', 'Week', 'Year'];

      aggregates.forEach((agg) => {
        const aggFilter = {
          ...filter,
          name: `${filter.name} - (${agg})`,
          groupingFunction: agg,
        };

        const aggNodeId = `${fullPath}||${JSON.stringify(aggFilter)}`;

        const aggItem = (
          <TreeItem
            nodeId={aggNodeId}
            label={`${conversion.camelCaseToWords(filter.name)} - (${agg})`}
            key={aggNodeId}
          />
        );

        items.push(aggItem);
      });
    }

    return items;
  };

  if (!moduleValue || !moduleData) {
    return <div>Loading...</div>;
  }

  // Sorting function
  const sortFilters = (filters) =>
    [...filters].sort((a, b) => {
      // Move 'relation' items to the end
      if (a.type === 'relation' && b.type !== 'relation') return 1;
      if (a.type !== 'relation' && b.type === 'relation') return -1;
      // Sort alphabetically by name
      return a.name.localeCompare(b.name);
    });

  if (type === 'sortings') {
    // Build tree from selected columns
    const tree = buildTreeFromColumns(columnsValue);
    return (
      <TreeView
        expanded={expanded}
        onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeSelect={handleNodeSelect}
      >
        {Object.values(tree).map((node) => renderTreeFromColumns(node))}
      </TreeView>
    );
  }

  let moduleFilters = sortFilters(moduleData?.data?.[moduleValue] || []);

  return (
    <TreeView
      expanded={expanded}
      onNodeToggle={handleToggle}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={handleNodeSelect}
    >
      {moduleFilters.flatMap((filter) => renderTreeItems(filter))}
    </TreeView>
  );
};

ReportFiltersTree.propTypes = {
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ReportFiltersTree;
