import React from 'react';
import PropTypes from 'prop-types';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';
import { OverviewButtons } from 'components/ModelViews/Commissions/CommissionCard/components/CloseAllCommissionsButton/OverviewButtons';
import { COMMISSION_OVERVIEW } from 'consts';
import { isAdmin } from 'helpers';

export const CommissionAfterTitle = ({
  relatedModelType,
  relatedModelId,
  overview,
}) => {
  const isUserAdmin = isAdmin();
  if (relatedModelType === 'transaction') {
    return (
      <>
        {overview ? (
          <OverviewButtons />
        ) : (
          <>
            {isUserAdmin && (
              <Tooltip title={'Commission Overview'} placement="top">
                <Link to={`/${COMMISSION_OVERVIEW(relatedModelId)}`}>
                  <IconButton size={'small'}>
                    <FontAwesomeIcon
                      size={'xs'}
                      icon={faLightbulbOn}
                      style={{ marginLeft: '0.5rem' }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  }
  return <> </>;
};

CommissionAfterTitle.propTypes = {
  relatedModelType: PropTypes.string.isRequired,
  relatedModelId: PropTypes.string.isRequired,
  overview: PropTypes.bool,
};
