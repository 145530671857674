import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormState, useForm } from 'react-final-form';
import { Select } from 'components/Fields';
import { getSelectOptions } from 'helpers';
import { useGetGroupingsQuery } from 'redux/rtk-query';

const chartTypes = [
  'Bar Chart Horizontal',
  'Bar Chart Vertical',
  'Bar Chart Stacked',
  'Bar Chart Stacked Horizontal',
  'Circle Chart Pie Charts',
];

const ChartTypeSelect = ({ input, meta }) => {
  const form = useForm();
  const { values } = useFormState();
  const prevValue = useRef(input.value); // Store previous value to detect changes

  const { data: groupingsData } = useGetGroupingsQuery(values?.reportId, {
    skip: !values?.reportId,
  });

  // Filter chart types based on groupings data
  const filteredOptions = chartTypes.filter((option) => {
    if (groupingsData?.data?.length < 2) {
      return !option.includes('Stacked');
    }
    return true;
  });

  // Reset datasets when the chart type changes
  useEffect(() => {
    if (input.value !== prevValue.current) {
      form.change('datasets', []);
      prevValue.current = input.value;
    }
  }, [input.value, form]);

  return (
    <Select
      label={'Chart Type'}
      options={getSelectOptions(filteredOptions)}
      input={input}
      meta={meta}
    />
  );
};

ChartTypeSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ChartTypeSelect;
