import queryString from 'query-string';
import { endpoints } from 'consts';

export const reportUrlBuilder = {
  createReport: function () {
    return `${endpoints.reports}`;
  },
  deleteReport: function (id) {
    return `${endpoints.reports}/${id}`;
  },
  deleteChart: function ({ reportId, chartId }) {
    return `${endpoints.reports}/${reportId}/chart/${chartId}`;
  },
  updateReport: function ({ id }) {
    return `${endpoints.reports}/${id}`;
  },
  createFilters: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/filters`;
  },
  getReportDetails: function ({ reportId, ...params }) {
    const urlParams = queryString.stringify(params, {
      skipNull: true,
      encode: true,
    });
    return `${endpoints.reports}/${reportId}/details?${urlParams}`;
  },
  getReportSummationDetails: function ({ reportId, ...params }) {
    const urlParams = queryString.stringify(params, {
      skipNull: true,
      encode: true,
    });
    return `${endpoints.reports}/${reportId}/details/summation?${urlParams}`;
  },
  getReportGroupingDetails: function ({ reportId, ...params }) {
    const urlParams = queryString.stringify(params, {
      skipNull: true,
      encode: true,
    });
    return `${endpoints.reports}/${reportId}/grouping/details?${urlParams}`;
  },
  createColumns: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/columns`;
  },
  updateColumn: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/column`;
  },
  deleteColumn: function ({ reportId, columnId }) {
    return `${endpoints.reports}/${reportId}/column/${columnId}`;
  },
  getColumns: function (id) {
    return `${endpoints.reports}/${id}/columns?limit=1000`;
  },
  getFilters: function (id) {
    return `${endpoints.reports}/${id}/filters?limit=1000`;
  },
  createGroupings: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/groupings`;
  },
  updateGrouping: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/grouping`;
  },
  deleteGrouping: function ({ reportId, groupingId }) {
    return `${endpoints.reports}/${reportId}/grouping/${groupingId}`;
  },
  getGroupings: function (id) {
    return `${endpoints.reports}/${id}/groupings?limit=1000`;
  },
  createSortings: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/sortings`;
  },
  updateSorting: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/sorting`;
  },
  deleteSorting: function ({ reportId, sortingId }) {
    return `${endpoints.reports}/${reportId}/sorting/${sortingId}`;
  },
  getSortings: function (id) {
    return `${endpoints.reports}/${id}/sortings?limit=1000`;
  },
  getReportModules: function () {
    return `${endpoints.reports}/modules-info`;
  },
  getReport: function (id) {
    return `${endpoints.reports}/${id}`;
  },
  getList: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.reports}?${urlParams}`;
  },
  getCharts: function (id) {
    return `${endpoints.reports}/${id}/charts`;
  },
  createChart: function ({ reportId }) {
    return `${endpoints.reports}/${reportId}/chart`;
  },
  getChart: function ({ reportId, chartId }) {
    return `${endpoints.reports}/${reportId}/chart/${chartId}`;
  },
  getChartData: function ({ reportId, chartId, ...params }) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.reports}/${reportId}/chart/${chartId}/data?${urlParams}`;
  },
  getReportCategories: function (params) {
    const urlParams = queryString.stringify(params, { skipNull: true });
    return `${endpoints.reports}/categories/list?${urlParams}`;
  },
};
