import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { DatePicker } from 'components/Fields';
import { FormCondition } from 'components/Forms';
import { STATUS_OPTIONS } from 'consts';

export const CloseDateField = () => {
  return (
    <Grid item xs={12}>
      <FormCondition
        when="status"
        operator="oneOf"
        is={[
          STATUS_OPTIONS.LEASED.value,
          STATUS_OPTIONS.SOLD.value,
          STATUS_OPTIONS.REFERRAL.value,
          STATUS_OPTIONS.EXPIRED_WITHDRAWN.value,
          STATUS_OPTIONS.EXPIRED.value,
          STATUS_OPTIONS.WITHDRAWN.value,
          STATUS_OPTIONS.CANCELLED.value,
        ]}
      >
        <Field
          label="Close Image"
          name={'contractClosingDate'}
          component={DatePicker}
        />
      </FormCondition>
    </Grid>
  );
};
