import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { customColorSelectorCM } from 'redux/selectors';

const TextUnderline = ({ item }) => {
  const customColor = useSelector(customColorSelectorCM);

  const getColor = (color) => {
    if (customColor === 'mainframeTheme') {
      return '#c9232d';
    }
    if (color === 'customColor') {
      return customColor ? customColor : '#48484A';
    }
    return color;
  };

  let underlineColor = item?.style?.underlineColor
    ? getColor(item.style.underlineColor)
    : '#c9232d';

  return (
    <Box
      sx={{
        height: '3px',
        width: '100%',
        position: 'absolute',
        bottom: '2px',
        borderRadius: '3px',
        underlineColor: 'underlineColor',
      }}
      style={{ background: underlineColor }}
    ></Box>
  );
};

export default TextUnderline;

TextUnderline.propTypes = {
  item: PropTypes.object,
};
