import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { AbeCard } from 'components/Common';
import { DocumentPdfViewer } from 'components/ModelViews';
import { DocumentCard } from '../../DocumentCard';
import DocumentPreviewToolbar from './DocumentPreviewToolbar';

function DocumentEsignPreview({ documentData }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{ background: '#F7F7F7' }}
    >
      <Box display="flex" flexGrow={1} height="0px">
        <AbeCard
          stickyHeader
          hideArrow
          title={
            documentData && <DocumentPreviewToolbar document={documentData} />
          }
          sx={{
            flex: 2,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 2,
            marginRight: 2,
            overflowY: 'auto',
            zIndex: 1,
          }}
        >
          <DocumentPdfViewer documentData={documentData} preview />
        </AbeCard>
        <Box flex={1} sx={{ overflowY: 'auto' }} my={0} mx={2}>
          <DocumentCard />
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentEsignPreview;

DocumentEsignPreview.propTypes = {
  documentData: PropTypes.object.isRequired,
};
