import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import DesignItems from 'components/ModelViews/Documents/ClickMarketingViewer/DesignItems';
import {
  pixelSizeSelector,
  activePageCM,
  copyItemToPageSelector,
} from 'redux/selectors';
import { setActivePageCM, copyItemToPage } from 'redux/slices';

export const PREVIEW_SCALE = 0.2;

const PageTab = ({ pageId }) => {
  const dispatch = useDispatch();
  const activePageId = useSelector(activePageCM);
  const copyToPage = useSelector(copyItemToPageSelector);
  const [width, height] = useSelector(pixelSizeSelector);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const boxRef = useRef(null);

  const pageWidth = width * PREVIEW_SCALE;
  const pageHeight = height * PREVIEW_SCALE;

  const handleClick = useCallback(() => {
    if (copyToPage.item && !copyToPage.page) {
      dispatch(
        copyItemToPage({
          item: copyToPage.item,
          page: pageId,
        }),
      );
    } else {
      dispatch(setActivePageCM(pageId));
    }
  }, [dispatch, pageId, copyToPage]);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting) {
        if (!isVisible) setIsVisible(true);
        if (!isLoaded) setIsLoaded(true);
      } else {
        if (isVisible) setIsVisible(false);
      }
    };

    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, [isVisible, isLoaded]);

  return (
    <Grid
      py={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      item
    >
      <Box
        onClick={handleClick}
        sx={{
          width: `${pageWidth}px`,
          position: 'relative',
        }}
      >
        <Box
          ref={boxRef}
          position="relative"
          sx={{
            pointerEvents: 'none',
            width: `${pageWidth}px`,
            height: `${pageHeight}px`,
            background: 'white',
            outline: activePageId === pageId ? '4px solid #097CC7' : 'none',
          }}
        >
          {(isVisible || isLoaded) && (
            <Box sx={{ transform: 'scale(0.2)', transformOrigin: 'left' }}>
              <DesignItems pageId={pageId} preview scale={PREVIEW_SCALE} />
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

PageTab.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default React.memo(PageTab);
