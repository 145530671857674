import React from 'react';
import { Checkbox } from '@mui/material';
import { conversion } from 'helpers/conversion';

const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function shortNumber(value) {
  const num = Number(value) || 0;
  if (num >= 1_000_000) {
    const formatted = parseFloat((num / 1_000_000).toFixed(1));
    return formatted + 'M';
  } else if (num >= 1_000) {
    const formatted = parseFloat((num / 1_000).toFixed(1));
    return formatted + 'k';
  }
  return String(num);
}

export const pathArrayToPathLabel = (pathArray) => {
  return (
    pathArray &&
    pathArray.length > 0 &&
    pathArray.map((item) => conversion.camelCaseToWords(item)).join(' > ')
  );
};
export const getLastPathName = (obj, showGrouping = false) => {
  const visualPathValue = obj.visualPath || obj.path || [];
  const lastItem = visualPathValue[visualPathValue.length - 1];
  const groupingFunctionValue = obj.groupingFunction || '';
  const dateSeparationValue = obj.dateSeparation || '';
  return `${conversion.camelCaseToWords(lastItem)}${
    showGrouping ? ` - (${groupingFunctionValue || dateSeparationValue})` : ''
  }`;
};

export const getNestedValue = (obj, column) => {
  const pathArray = column.visualPath || column.path;
  if (!Array.isArray(pathArray) || pathArray.length === 0) {
    return '';
  }

  let value = '';
  const pathLength = pathArray.length;
  if (pathLength > 0) {
    // Traverse the object up to the parent of the last key
    const traversePath = pathArray.slice(0, pathLength - 1);
    const lastKey = pathArray[pathLength - 1];

    let currentObj = obj;
    for (let i = 0; i < traversePath.length; i++) {
      currentObj =
        currentObj && currentObj[traversePath[i]]
          ? currentObj[traversePath[i]]
          : undefined;
      if (!currentObj) {
        return '';
      }
    }
    if (column.groupingFunction) {
      // Construct the key using the last key and grouping function
      const key = `${lastKey}__${column.groupingFunction}`;
      value = currentObj[key];
    } else if (column.dateSeparation) {
      // Construct the key using the last key and dateSeparation
      const key = `${lastKey}__${column.dateSeparation}`;
      value = currentObj[key];

      if (column.dateSeparation === 'Year') {
        return value;
      } else if (column.dateSeparation === 'Month') {
        const monthIndex = parseInt(value, 10) - 1; // Convert to 0-based index

        const monthName =
          monthIndex >= 0 && monthIndex < 12 ? fullMonthNames[monthIndex] : '';
        console.log(monthName);
        return monthName;
      } else if (column.dateSeparation === 'Year&Month') {
        return value
          ? new Date(value).toLocaleString('default', {
              month: 'long',
              year: 'numeric',
              timeZone: 'UTC',
            })
          : '';
      }
    } else {
      // Access the value directly
      value = currentObj[lastKey];
    }
  }

  return formatValue(value, column.type);
};

export const getGroupingUrlParams = (obj, column) => {
  const groupingValue = getNestedValue(obj, column);
  const pathArray = column.visualPath || column.path;
  const path = pathArray.join('.');
  if (column.groupingFunction) {
    return { [`${path}__${column.groupingFunction}`]: groupingValue };
  } else if (column.dateSeparation) {
    return { [`${path}__${column.dateSeparation}`]: groupingValue };
  }
  return { [path]: groupingValue };
};

export const getGroupingNestedValue = (modelType, obj, column) => {
  let pathArray = column.visualPath || column.path;
  if (!Array.isArray(pathArray) || pathArray.length === 0) {
    return '';
  }
  pathArray = pathArray.filter((item) => item !== modelType);
  let value = pathArray.reduce(
    (acc, key) => (acc && acc[key] !== null ? acc[key] : ''),
    obj,
  );

  return value;
};

export const formatValue = (value, formatType) => {
  switch (formatType) {
    case 'number':
      return parseFloat(value).toLocaleString();
    case 'currency':
      return conversion.formatCurrency(value);
    case 'percentage':
      return conversion.formatPercentage(value);
    case 'date':
      return conversion.formatDate(value, 'M/d/yy');
    case 'boolean':
      return value ? 'Yes' : 'No';
    case 'string':
      return value ? value.replace(/&nbsp;/g, ' ') : '';
    case 'checkbox':
      return value ? (
        <Checkbox sx={{ p: 0 }} size="small" checked disabled />
      ) : (
        <Checkbox sx={{ p: 0 }} size="small" disabled />
      );
    default:
      return typeof value === 'object' ? JSON.stringify(value) : value;
  }
};
