import React from 'react';
import { Outlet } from 'react-router-dom';
import { CreateButton } from 'components/Common';
import { LayoutHeader } from 'components/Layouts';
import { ButtonSection, PageMainContent } from 'components/Styled';
import * as RoutesLinks from 'consts/routes';
import { CappingsTable } from './components';

export const CappingsPage = () => {
  return (
    <>
      <LayoutHeader title={'Cappings'}>
        <ButtonSection>
          <CreateButton
            path={RoutesLinks.CREATE_CAPPING}
            title="Create Capping"
          />
        </ButtonSection>
      </LayoutHeader>
      <PageMainContent maxWidth={false} component="main" disableGutters>
        <CappingsTable />
      </PageMainContent>
      <Outlet />
    </>
  );
};
