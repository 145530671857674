import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIconComp } from 'components/Common';
import { useDebounce } from 'hooks';

export const SearchIcon = () => (
  <FontAwesomeIconComp
    icon={faMagnifyingGlass}
    style={{ width: '12px', height: '12px' }}
  />
);

export const SearchInput = ({
  placeholder,
  changeHandler,
  variant = 'standard',
}) => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    if (changeHandler) {
      changeHandler(debouncedSearchValue);
    }
  }, [debouncedSearchValue, changeHandler]);

  const inputValueHandler = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <TextField
      id="outlined-input"
      variant={variant}
      size="small"
      fullWidth
      style={{ maxWidth: 350 }}
      placeholder={placeholder ? placeholder : 'Search'}
      value={searchValue}
      onChange={(event) => inputValueHandler(event)}
      sx={{
        alignSelf: 'center',
        '&:hover': {
          boxShadow: 'none',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              width: '20px',
              justifyContent: 'center',
            }}
          >
            <SearchIcon />
          </InputAdornment>
        ),
        sx: {
          fontSize: 14,
          '&:before': {
            borderBottom: 0,
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
          },
          '&:after': {
            borderBottom: 0,
          },
          '& .MuiInput-input': {
            padding: 0,
            marginLeft: '2px',
          },
          '& fieldset': {
            borderWidth: 0,
          },
        },
      }}
    />
  );
};

SearchInput.propTypes = {
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
};
