import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import { Box, Divider } from '@mui/material';
import { AbeCard } from 'components/Common';

export const FormPanel = ({
  label,
  children,
  toolbar,
  startOpen,
  noPadding,
  hideArrow = false,
}) => {
  const [forceOpen, setForceOpen] = React.useState(false);
  const formRef = useRef(null);
  const formId = `form-panel-${uuid()}`;

  const handleOpenOnRequired = useCallback(() => {
    if (formRef.current) {
      const requiredElements =
        formRef.current.getElementsByClassName('required');
      const filledElements = formRef.current.getElementsByClassName('filled');
      if (requiredElements.length > 0 || filledElements.length > 0) {
        setForceOpen(true);
      }
    }
  }, [formRef, children]);

  useEffect(() => {
    if (!forceOpen) {
      setTimeout(() => {
        if (startOpen) {
          setForceOpen(true);
        } else {
          handleOpenOnRequired();
        }
      }, 1000);
    }
  }, [children, forceOpen]);

  return (
    <AbeCard
      hideArrow={hideArrow}
      hideContent
      forceOpen={forceOpen}
      id={formId}
      sx={{ mb: 2 }}
      noTitle={!label}
      title={
        <>
          <span style={{ fontWeight: 600 }}>{label}</span>
          {toolbar}
        </>
      }
    >
      <Divider />
      <Box p={noPadding ? 0 : 1} ref={formRef}>
        {children}
      </Box>
    </AbeCard>
  );
};

FormPanel.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    notchedOutline: PropTypes.string,
    contentWrapper: PropTypes.string,
    inputLabel: PropTypes.string,
  }),
  small: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  toolbar: PropTypes.node,
  className: PropTypes.string,
  startOpen: PropTypes.bool,
  noPadding: PropTypes.bool,
  hideArrow: PropTypes.bool,
};
