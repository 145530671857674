import React from 'react';
import PropTypes from 'prop-types';
import { faTrash, faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import AbeFileImage from 'components/Common/AbeFileImage/AbeFileImage';
import { ConfirmationButton } from 'components/Common/ConfirmationButton';

export const PhotoSetItem = ({ attachment, handleUnlinkFile }) => {
  return (
    <Box
      sx={{
        overflow: 'hidden', // Hide the icons when they slide out of view
        position: 'relative', // For the absolute positioning of the icons
        display: 'inline-block',
        '&:hover .slide-icons': {
          transform: 'translateY(0)', // Slide down into view
          opacity: 1,
        },
      }}
    >
      {/* Icon container that slides down on hover */}
      <Box
        className="slide-icons"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          p: 0.5,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          transform: 'translateY(-100%)', // Start hidden above the image
          opacity: 0,
          transition: 'transform 0.3s ease, opacity 0.3s ease',
          zIndex: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <Button
          sx={{
            minWidth: 0,
          }}
          variant={'text'}
          color={'info'}
        >
          <FontAwesomeIcon color={'white'} icon={faStar} />
        </Button>
        <ConfirmationButton
          sx={{
            minWidth: 0,
          }}
          label={
            <>
              <FontAwesomeIcon color={'white'} icon={faTrash} />
            </>
          }
          confirmTitle={'Delete Photo?'}
          confirmMessage={
            'Are you sure you want to delete this photo? This action cannot be undone.'
          }
          handleConfirm={() => handleUnlinkFile(attachment.uuid)}
          color={'info'}
          variant={'text'}
        />
      </Box>
      {/* The image component */}
      <AbeFileImage file={attachment} style={{ borderRadius: 4 }} thumbnail />
    </Box>
  );
};

PhotoSetItem.propTypes = {
  attachment: PropTypes.object,
  handleUnlinkFile: PropTypes.func,
};

export default PhotoSetItem;
