import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Assuming AbeForm is in the same directory
import { FORM_ERROR } from 'final-form';
import { useNavigate } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { RightsPermissions } from 'components/Fields';
import { AbeForm } from 'components/Forms';
import { colors } from 'consts';

export const Wizard = ({
  steps,
  initialValues = {},
  setInitialValues,
  onFinalSubmit,
}) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = async (values) => {
    setInitialValues((prevValues) => ({ ...prevValues, ...values }));

    if (steps[activeStep].onNext) {
      const canProceed = await steps[activeStep].onNext(values);
      if (canProceed?.error?.message) {
        return {
          error: {
            data: canProceed?.error,
          },
        };
      } else if (canProceed?.error) {
        return {
          error: {
            message: canProceed?.error,
          },
        };
      }

      if (isLastStep) {
        onFinalSubmit(canProceed);
        return;
      }
    }
    if (isLastStep) {
      onFinalSubmit(values);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(-1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const renderStepContent = (stepIndex, form, values, showAllFields) => {
    const { render } = steps[stepIndex];
    const sumbitErrors = form.getState().submitErrors?.[FORM_ERROR];
    return (
      <>
        {render(form, values, showAllFields)}
        {sumbitErrors && (
          <Typography color={'error'}>{sumbitErrors}</Typography>
        )}
      </>
    ); // Passing form and values to child components
  };

  if (!steps.length) return null;

  return (
    <Box p={2}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                //make active blue
                '& .MuiStepIcon-root.Mui-active': { color: colors.BLUE_1 },
                '& .MuiStepLabel-label.Mui-active': { color: colors.BLUE_1 },
                '& .MuiStepIcon-text': { fill: '#ffffff' },
              }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <AbeForm
        formId={`wizard-step-${activeStep}`}
        initialValues={initialValues}
        handleSubmitFunc={handleNext}
        showAllFieldsButton={false}
        mutators={{
          clearSubmitError: (args, state) => {
            state.formState.submitError = undefined;
          },
        }}
      >
        {(form, values, showAllFields) => (
          <Box>
            {renderStepContent(activeStep, form, values, showAllFields)}

            {steps[activeStep].onNext && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 2,
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <RightsPermissions />
                </Box>
                <Box>
                  <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                    {activeStep === 0 ? 'Cancel' : 'Back'}
                  </Button>

                  <Button type="submit" variant="contained">
                    {isLastStep ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </AbeForm>
    </Box>
  );
};

Wizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
      onNext: PropTypes.func,
    }),
  ).isRequired,
  initialValues: PropTypes.object,
  setInitialValues: PropTypes.func,
  onFinalSubmit: PropTypes.func,
};
