import React from 'react';
import PropTypes from 'prop-types';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, Button, IconButton, Paper } from '@mui/material';
import {
  DetailsPanels,
  FontAwesomeIconComp,
  SideDrawer,
} from 'components/Common';
import {
  ButtonSection,
  DetailsBox,
  Header,
  HeadingSection,
} from 'components/Styled';
import { useDrawer } from 'hooks';
import { DocumentTemplateEditPage } from 'pages/DocumentTemplatePages';
import { useGetDocumentTemplateQuery } from 'redux/rtk-query';

export function DocumentTemplateDetailsDrawer({ params, path }) {
  const [activeView, setActiveView] = React.useState('details');
  const { isOpen, closeDrawer } = useDrawer(true, path);

  const paramName = Object.keys(params)[0];
  const { data: documentData } = useGetDocumentTemplateQuery({
    id: params[paramName],
  });

  return (
    <>
      <SideDrawer width={'40%'} isOpen={isOpen && activeView === 'details'}>
        <Paper
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <HeadingSection backgroundColor={'#F7F7F7'}>
            <Box>
              <Header variant="h4">{documentData?.data?.name}</Header>
              <Button
                onClick={() => {
                  setActiveView('edit');
                }}
              >
                Edit
              </Button>
            </Box>
            <ButtonSection>
              <IconButton onClick={closeDrawer}>
                <FontAwesomeIconComp icon={faTimesCircle} />
              </IconButton>
            </ButtonSection>
          </HeadingSection>
          <DetailsBox
            style={{
              alignItems: 'flex-start',
              padding: 16,
              paddingBottom: 8,
              width: '100%',
            }}
          >
            {activeView === 'details' && documentData?.data && (
              <DetailsPanels
                data={documentData?.data}
                panels={{
                  '': [
                    'name',
                    'type',
                    'fileType',
                    'moduleClassName',
                    'moduleRelationType',
                    'parentTemplate',
                    'aliasDocument',
                    'nameFormat',
                  ],
                }}
                formatTypes={{
                  parentTemplate: 'model',
                  aliasDocument: 'model',
                }}
              />
            )}
          </DetailsBox>
        </Paper>
      </SideDrawer>
      <DocumentTemplateEditPage
        open={isOpen && activeView === 'edit'}
        path={path}
        params={params}
      />
    </>
  );
}

DocumentTemplateDetailsDrawer.propTypes = {
  params: PropTypes.object.isRequired,
  path: PropTypes.string,
};
